import React from "react";
import { useShallow } from "zustand/react/shallow";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { ResizableBox } from "react-resizable";

import ChatbotHeader from "./ChatbotHeader";
import ChatbotBody from "./ChatbotBody";
import ChatbotFooter from "./ChatbotFooter";
import useStore from "./store";

function Chatbot() {
  const { isOpen, open, thread, startThread, isFullScreen } = useStore(
    useShallow((state) => ({
      isOpen: state.isOpen,
      open: state.open,
      thread: state.thread,
      startThread: state.startThread,
      isFullScreen: state.isFullScreen,
    }))
  );

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const ResizableBoxAny = ResizableBox as any;

  // Update window size when the window is resized
  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpen = () => {
    open();

    if (thread.length === 0) {
      startThread();
    }
  };

  return (
    <div className="relative w-full h-screen">
      <img
        src="/bg_desktop.webp"
        alt="Full screen backgroud cover across desktop"
        className="hidden lg:block absolute inset-0 w-full h-full object-cover"
      />
      <img
        src="/bg_tablet.webp"
        alt="Full screen backgroud cover across tablet"
        className="hidden md:block lg:hidden absolute inset-0 w-full h-full object-cover"
      />
      <img
        src="/bg_mobile.webp"
        alt="Full screen backgroud cover across mobile"
        className="md:hidden absolute inset-0 w-full h-full object-cover"
      />

      {isOpen ? (
        <ResizableBoxAny
          width={448}
          height={640}
          minConstraints={[448, 640]}
          maxConstraints={[
            windowSize.width - 4 * 16, // subtract 4rem from the window's width
            windowSize.height - 4 * 16, // subtract 4rem from the window's height
          ]}
          resizeHandles={["nw", "w", "n"]} // Handle for resizing upwards and to the left
          className={cn("z-10", {
            "md:absolute md:bottom-8 md:right-8": !isFullScreen,
          })}
        >
          <div
            className={cn("z-10 md:m-0 flex flex-col relative", {
              "w-screen h-screen": isFullScreen,
              "md:w-full md:shadow-xl md:rounded-xl h-screen md:h-full":
                !isFullScreen,
            })}
          >
            <ChatbotHeader />
            <ChatbotBody />
            <ChatbotFooter />
          </div>
        </ResizableBoxAny>
      ) : (
        <button
          className="inline-flex items-center justify-center bg-gradient-to-r from-[#0D9488] to-[#042F2E] rounded-full text-white flex-shrink-0 w-12 h-12 shadow-lg absolute bottom-8 right-8 z-10"
          onClick={handleOpen}
        >
          <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />
        </button>
      )}
    </div>
  );
}

export default Chatbot;
