import { FAQWithEmbedding } from "../scripts/generate-faq-embeddings";

export const FAQS: FAQWithEmbedding[] = [
  {
    id: "faq-1",
    question: "Πόσες μέρες χρειάζονται για την παραλαβή της παραγγελίας μου;",
    answer:
      "H παράδοση των προϊόντων στο χώρο σας γίνεται με συνεργαζόμενη εταιρία ταχυμεταφορών συνήθως την επόμενη εργάσιμη μέρα. (θα λάβετε ενημερωτικό e-mail ή SMS για την πορεία παράδοσης). Εξαίρεση αποτελούν περιοχές με δυσπρόσιτη καθυστέρηση όπου παραλαμβάνετε σε 2-3 εργάσιμες, δυσπρόσιτες περιοχές και νησιωτικοί προορισμοί. Σε κάθε περίπτωση μπορείτε να επικοινωνήσετε μαζί μας και να σας λύσουμε οποιαδήποτε απορία.",
    embedding: [
      0.013743963, 0.0048898053, -0.0010378531, -0.043056544, -0.028774373,
      -0.010993858, -0.0027927682, -0.0065142713, -0.01563425, -0.0022266665,
      0.009005118, 0.021502018, -0.009293912, -0.011125128, 0.0115976995,
      -0.012562534, 0.02874812, 0.020556875, -0.0057857228, 0.0057890047,
      -0.013953995, -0.0057135243, -0.007666165, 0.0083684595, -0.0016261064,
      -0.00003181758, 0.014347806, -0.012024327, 0.008355333, -0.0258208,
      0.025111942, -0.019900523, -0.008545673, 0.0042892457, -0.018850364,
      -0.010980731, 0.025230084, 0.01212278, 0.03079593, 0.016224965,
      0.019007888, 0.0023989582, 0.016014934, 0.00011055391, -0.019257301,
      0.02227651, -0.019979287, -0.037832, -0.01634311, 0.023733607, 0.0129104,
      -0.0065831877, -0.036204252, -0.018495936, -0.008335642, 0.017957728,
      0.011177636, 0.0053722225, 0.0099830795, -0.024836274, 0.012641296,
      -0.0007195234, -0.009011682, -0.01398025, -0.0115845725, -0.0002781282,
      -0.01869284, 0.007613657, -0.042295177, -0.009582706, -0.0025171014,
      0.025925815, 0.0013102381, -0.041297525, 0.02293286, -0.018469682,
      0.015739266, -0.005250798, -0.025755163, 0.009274222, 0.01825965,
      -0.033053774, 0.007534895, 0.020464985, 0.020648763, 0.013822725,
      0.0028255857, 0.01961173, -0.032528695, -0.0061565605, 0.017537665,
      0.02393051, 0.015214187, 0.023116637, 0.0026614983, 0.010895406,
      -0.017301379, 0.046023242, 0.0023645, -0.010803517, -0.00092299184,
      -0.0019526405, -0.0038593365, -0.0038199555, -0.00880165, -0.0146103455,
      0.014190282, 0.012057145, -0.0054509845, -0.019716745, -0.037805744,
      0.07387873, -0.013638947, -0.01105293, 0.00681291, 0.004820889,
      0.011407359, 0.00013034696, -0.029982056, -0.009412055, 0.016618775,
      -0.006931053, -0.0018722377, -0.0010296487, 0.039984826, 0.0029158336,
      -0.008171555, -0.014321552, -0.021291986, 0.016408743, 0.006189378,
      0.011925874, 0.015804902, 0.03000831, -0.022696573, 0.023208527,
      -0.036020473, 0.013953995, -0.033132534, 0.014636599, 0.010645993,
      0.0048635514, 0.017038839, -0.013225447, -0.008112483, -0.000012556535,
      0.010029024, -0.0066619497, 0.0017032275, -0.0028879389, 0.008808213,
      -0.005657735, 0.028538087, -0.0005767673, -0.0036164871, 0.008112483,
      -0.033631362, 0.0027780002, 0.001957563, -0.010074968, 0.010449088,
      0.0028190222, 0.011079184, -0.0048799603, -0.018115252, 0.019152286,
      -0.0005652812, 0.013547058, 0.02393051, -0.011781477, -0.015187933,
      0.034445234, 0.0014685825, 0.02276221, 0.008335642, 0.032554947,
      -0.0010518004, -0.007416752, 0.0074364427, 0.0006961409, -0.06752526,
      0.01904727, 0.0017376859, 0.011020112, -0.020648763, 0.016710665,
      0.0013873592, -0.0033112844, 0.0035344434, -0.00027915376, 0.004729,
      0.01621184, 0.013520804, 0.009700849, -0.6237948, 0.009313603,
      0.010619739, -0.0018197297, 0.0054148855, 0.018942254, 0.009425182,
      0.0060679535, -0.034655266, 0.03218739, 0.01334359, 0.0019903805,
      -0.026083339, -0.010560667, 0.012483772, -0.018364666, 0.0046929005,
      -0.0017032275, -0.008355333, 0.018102126, -0.01734076, 0.003787138,
      -0.004650238, 0.024455592, 0.015476727, 0.0055166194, 0.008749142,
      -0.028170532, -0.00022356914, 0.011755223, 0.015476727, 0.012916963,
      -0.023090383, -0.00031586832, 0.049199976, -0.0034655265, -0.006747275,
      0.017314507, 0.01786584, 0.021252604, -0.032922503, -0.0012224513,
      0.013573312, -0.010304691, -0.009582706, -0.017918348, 0.025006926,
      -0.0018623924, 0.029798279, -0.008000904, 0.006553652, 0.024350576,
      -0.0049029323, -0.030060818, 0.016592521, 0.0051392186, 0.023103511,
      -0.03599422, -0.000081684775, 0.0031701692, 0.016723791, -0.019546095,
      -0.0077383635, -0.013389534, 0.0032751851, -0.014045885, 0.005523183,
      0.0012503463, 0.018719094, 0.011945565, 0.009097007, 0.013284518,
      -0.013796471, 0.027435418, 0.01829903, -0.013264828, -0.0040956223,
      -0.009668032, -0.021685796, 0.0021052419, -0.01054754, -0.010849461,
      -0.026306497, 0.02057, 0.034366474, -0.022552177, -0.009950262,
      -0.023838622, 0.0066882037, 0.019467333, -0.00056323013, 0.032344915,
      0.021515144, 0.010816644, -0.01734076, 0.012227796, -0.018640332,
      0.0014685825, 0.0057135243, 0.008775396, 0.0019231047, 0.0050342027,
      0.030559644, -0.014780996, 0.023195399, 0.0010280078, -0.050118867,
      0.012457518, 0.045629434, -0.01839092, 0.002206976, 0.00097467937,
      -0.006205787, -0.013422352, 0.0006600417, -0.020478113, 0.0016416948,
      0.0018279341, -0.0072854823, 0.005457548, 0.013848979, -0.0075020776,
      0.021777684, -0.016198711, 0.00527377, 0.022460287, -0.0051917266,
      -0.019939905, -0.020780033, -0.001696664, -0.02109508, 0.0056085084,
      0.010311254, -0.022985367, -0.0015383197, 0.0004104237, 0.011341724,
      -0.032554947, 0.040168606, -0.01944108, 0.007784308, 0.014872885,
      0.010383453, -0.026962847, 0.01585741, -0.02809177, 0.022434033,
      0.0009976516, -0.0068982355, 0.037175648, 0.008138737, -0.004896369,
      -0.00051482435, 0.000733881, -0.004925905, -0.0013405944, -0.011276089,
      -0.02043873, -0.012693804, -0.011702715, 0.010882279, 0.007889324,
      -0.0076989825, 0.014164028, -0.02289348, -0.0063173664, 0.022027098,
      0.0060679535, -0.013993377, -0.037490696, 0.01869284, 0.00077080075,
      -0.017012585, 0.005848076, 0.020806286, 0.004742127, 0.013468296,
      -0.00587433, -0.007653038, -0.011492684, 0.0042170472, 0.011210454,
      -0.0010288282, 0.009818992, 0.01293009, 0.019506713, -0.014203409,
      0.043529116, -0.005680707, 0.019808635, 0.032056123, -0.0006169687,
      0.005355814, 0.022250256, 0.0061729695, -0.013993377, 0.023969892,
      0.00039873246, 0.0031422745, 0.021305112, 0.02568953, 0.020373097,
      0.018154634, -0.014780996, 0.01625122, -0.01572614, 0.021685796,
      0.011886493, 0.031347264, 0.030900946, 0.00091396703, 0.0028879389,
      0.001906696, 0.002246357, 0.0053853495, 0.0130547965, -0.030244596,
      -0.010613175, -0.0027336967, 0.026411513, 0.010403143, -0.0011650207,
      0.0022086168, -0.019887397, -0.023116637, -0.008959174, 0.0011395873,
      0.032869995, 0.023037875, -0.018482808, -0.03586295, 0.00025351509,
      -0.028013008, 0.036755584, 0.024350576, -0.026582165, 0.016553141,
      -0.00086884294, 0.010993858, -0.0037050943, 0.022092732, 0.024613116,
      0.01362582, -0.0068457276, -0.02887939, -0.02096381, 0.024993798,
      0.0296145, -0.0005455907, 0.014045885, -0.03502282, 0.0043811346,
      0.007981213, -0.0099699525, 0.01240501, -0.046837118, 0.0027615915,
      0.010580358, 0.0039249714, 0.030743422, 0.017616427, 0.009536762,
      0.012634733, -0.017760824, 0.010186548, 0.025847053, -0.013665201,
      -0.0032505721, 0.001803321, 0.001135485, 0.010560667, -0.020254953,
      0.021397002, 0.006153279, -0.0010255465, 0.000063686435, 0.018758476,
      -0.005887457, 0.002297224, -0.008027158, -0.056866143, -0.044973083,
      0.004571476, 0.015345457, -0.010311254, -0.046023242, -0.020990064,
      0.0115386285, -0.01707822, 0.011840549, -0.01856157, 0.015739266,
      -0.0068326006, -0.0050079487, 0.006127025, -0.021331366, 0.060909256,
      0.0047224364, -0.000029407545, -0.02633275, -0.011381105, -0.0011855317,
      0.008342206, -0.035101585, 0.024586862, 0.0004171923, -0.011794604,
      -0.021895828, -0.019060396, -0.039512254, -0.012162161, 0.012733185,
      0.0060942075, -0.0010312896, 0.0026434485, 0.0000030766394, -0.015713012,
      0.0066127237, 0.024481846, 0.0017590174, -0.00081346347, -0.010521286,
      -0.010055278, 0.012313121, 0.08007467, 0.02393051, 0.021987716,
      0.0053459685, -0.0063567474, 0.003393328, -0.011827422, -0.001906696,
      0.0029519328, 0.0057693142, 0.0051785996, 0.016054315, 0.007534895,
      0.0070229424, 0.01647438, 0.014216536, -0.004922623, 0.006491299,
      0.022735955, -0.029588247, -0.010770699, 0.015450473, 0.0044697416,
      0.020202445, -0.024311194, -0.0312685, 0.030559644, 0.012713495,
      0.007587403, -0.015004155, 0.00033945587, -0.029404469, 0.013862106,
      0.003892154, -0.010317818, -0.0018279341, 0.0074233157, 0.009182333,
      0.020031795, 0.02764545, 0.011249835, 0.012903836, 0.0038888722,
      0.026700307, 0.020228699, -0.010324381, -0.022735955, 0.030585898,
      0.012155597, -0.016329981, 0.013953995, 0.0009615524, -0.022644065,
      -0.008663816, 0.00022562023, -0.0049062143, -0.0037018126, 0.002950292,
      0.012188415, 0.006658668, -0.0017869122, -0.021541398, 0.016421871,
      -0.01092166, -0.0037379118, -0.029903295, 0.011958692, 0.009398928,
      -0.008315952, -0.022998495, 0.006799783, -0.03242368, -0.015778648,
      -0.016960077, 0.028643103, 0.021790812, 0.017695189, 0.020491239,
      0.018377792, 0.019624857, 0.024613116, -0.011374541, 0.017970856,
      -0.015699886, -0.017156983, 0.01786584, -0.026490275, -0.012615042,
      0.001867315, 0.020675017, 0.017629554, 0.028275548, -0.0048504244,
      -0.018338412, -0.02725164, -0.016133077, -0.027881736, 0.024599988,
      -0.013507677, -0.002259484, 0.01724887, 0.012555971, -0.018194014,
      -0.019756127, -0.005434576, 0.02503318, 0.032029867, 0.0056380443,
      -0.012608479, -0.000463547, -0.0032177547, -0.020622509, -0.0028305082,
      0.0021314959, 0.008598181, 0.02516445, -0.0013766936, 0.029772025,
      0.018705968, 0.016146204, -0.007226411, -0.021370748, 0.010271873,
      0.016999459, -0.025282592, 0.0010616457, -0.015135425, -0.0024892064,
      0.0073182997, -0.013337026, -0.0020100712, 0.03888216, -0.0065766242,
      -0.014662853, -0.0253351, 0.0026598573, -0.00024469537, 0.005578973,
      -0.013186066, -0.009202023, -0.008342206, -0.0063928463, -0.016461251,
      -0.017747696, 0.021397002, -0.033605106, -0.008696634, -0.0017442494,
      -0.0015563694, 0.02013681, 0.009123261, 0.007239538, -0.026398387,
      -0.015043536, -0.013730836, -0.0040463964, 0.033185042, -0.019795507,
      0.0067932196, 0.03630927, 0.037018124, -0.0036328959, 0.0009443232,
      -0.021370748, -0.00082166784, -0.014767869, 0.013796471, -0.024179924,
      -0.008119047, 0.017813332, 0.01336328, 0.030297104, 0.028538087,
      -0.007909014, 0.005378786, 0.007626784, -0.011656771, -0.0335526,
      -0.031951107, -0.022814717, 0.028433071, -0.004834016, 0.018889746,
      0.0034556813, 0.011039803, -0.018285904, 0.021869574, -0.026188355,
      0.000927094, 0.025243212, 0.024993798, -0.025046306, 0.021528272,
      -0.010967604, 0.0030192088, 0.016933823, -0.027304148, -0.011886493,
      0.00063419796, 0.0026270398, 0.0070885774, -0.008847594, -0.012496899,
      0.01934919, -0.02874812, -0.00041411567, -0.031819835, -0.013665201,
      -0.008092793, -0.0011642004, -0.0026828295, -0.007718673, -0.024206178,
      -0.002141341, -0.0029059884, -0.021397002, -0.03145228, 0.0012716777,
      0.008217499, -0.028669357, -0.0059202746, -0.002651653, 0.014977901,
      0.0011756865, 0.025190704, 0.03105847, -0.006734148, -0.033053774,
      -0.03943349, -0.0002518742, -0.007981213, 0.02096381, 0.042872764,
      0.008742578, -0.0011970178, 0.00022787643, 0.021252604, -0.01921792,
      -0.015778648, 0.02738291, -0.006189378, 0.0011051289, 0.00007860814,
      0.006616005, -0.015555489, 0.052166678, 0.006760402, 0.0012339375,
      -0.026201481, -0.0116239535, -0.011282652, 0.0065831877, -0.0020428887,
      0.023103511, 0.004194075, -0.029850787, -0.021856446, -0.03502282,
      -0.0030454628, 0.031951107, -0.0044992776, 0.01812838, 0.008867285,
      0.018929126, -0.0064748903, -0.004358162, -0.0039019992, -0.0011404076,
      -0.028800627, 0.017616427, 0.0016014933, -0.010632866, -0.0073182997,
      0.018614078, 0.01621184, 0.021777684, 0.0073182997, -0.009484254,
      -0.003619769, -0.005181881, 0.010468778, -0.01983489, -0.03441898,
      -0.018180888, -0.009759921, 0.0041350033, -0.0026992382, 0.007508641,
      0.015240441, 0.031242248, -0.0030224905, -0.009713976, 0.0045911665,
      0.019651111, 0.007364244, 0.022867225, 0.0028501987, 0.0052146986,
      -0.012851328, -0.01664503, 0.014886012, -0.0046535195, 0.011650207,
      0.0073839347, -0.03294876, -0.013304209, 0.04053616, -0.0038888722,
      -0.013481423, -0.01839092, -0.001803321, 0.016106823, -0.0031045342,
      -0.023917384, -0.0017622991, 0.0030011593, -0.0009459641, -0.003787138,
      -0.03476028, -0.007928705, 0.004180948, -0.023444813, -0.025230084,
      -0.020254953, -0.019756127, -0.008066539, -0.012634733, 0.0067177396,
      -0.017944602, -0.013678328, 0.01321232, 0.016487505, -0.02135762,
      -0.030349612, -0.019729873, -0.015870536, 0.004584603, 0.03197736,
      0.0038068285, -0.021397002, -0.015923044, 0.01493852, 0.0085194195,
      0.024048654, -0.017485157, -0.0045616305, 0.010449088, -0.010934787,
      -0.0067669656, -0.027802974, -0.05185163, 0.031215994, 0.01734076,
      -0.036939364, 0.0072789188, -0.028538087, -0.011066057, 0.020464985,
      0.0049554403, 0.01375709, 0.008736015, 0.026700307, -0.0021741586,
      0.0027976907, -0.0040332694, 0.019598603, -0.034917805, 0.026057085,
      0.0035771062, -0.027855482, -0.032213647, -0.009694286, -0.015161679,
      -0.011689588, 0.049173724, -0.0054378575, 0.01533233, 0.029903295,
      -0.023878003, -0.023379177, 0.0025351509, -0.01533233, 0.015647378,
      -0.01039658, -0.0056610163, -0.0100093335, 0.03147853, -0.014282171,
      0.022053352, -0.03294876, 0.004768381, 0.043660384, -0.012372193,
      -0.002610631, -0.023221653, -0.031504788, -0.000019844325, -0.04066743,
      0.019716745, 0.01411152, -0.019664237, 0.008033721, 0.0026204763,
      -0.04001108, -0.025886433, 0.006360029, -0.0068194736, -0.034707773,
      0.0021265731, -0.038147047, 0.00412844, -0.011643644, -0.0064880173,
      -0.00059932936, -0.0042006383, -0.018088998, -0.0011239989, -0.038672127,
      0.012936654, 0.026621545, -0.04297778, 0.007351117, 0.00065429864,
      0.004765099, -0.02043873, -0.034261458, -0.0073708077, -0.0076202205,
      0.011374541, 0.015148552, -0.012523153, -0.029456977, 0.016093696,
      -0.0007351117, -0.003070076, 0.016789427, 0.24720757, -0.019493587,
      0.008578491, 0.038855903, -0.0036886856, -0.0028370717, 0.018049618,
      -0.006602878, -0.012726622, 0.004062805, -0.011971819, -0.012464082,
      -0.031872343, -0.001983817, -0.0005898943, -0.039853554, -0.014912266,
      -0.018574698, -0.026162101, -0.0013865388, 0.016750045, 0.03174107,
      0.025387608, -0.0033391793, 0.011085747, 0.0033998915, -0.013875233,
      0.0011141537, 0.005365659, 0.0009467845, -0.010212802, -0.0035902332,
      -0.0008655612, 0.017222617, -0.0040857773, 0.025046306, 0.030507136,
      -0.018482808, 0.031347264, -0.0027189287, -0.0021183689, 0.017196363,
      0.00035032668, 0.012345939, -0.031583548, -0.0029043476, -0.008335642,
      -0.014059012, -0.022486541, 0.005871048, -0.015096044, -0.009622087,
      0.021016318, 0.024665624, -0.0004631368, -0.009681159, 0.006333775,
      0.005050611, -0.021685796, 0.0030602308, -0.000034586556, 0.008808213,
      0.011354851, 0.0115845725, -0.010514723, 0.010632866, -0.019060396,
      -0.00064732495, 0.009654905, -0.017170109, 0.010718191, 0.00009358112,
      0.009818992, 0.003186578, -0.037070632, 0.006504426, 0.004922623,
      0.038803395, 0.015870536, -0.0017918348, -0.0019936622, 0.021397002,
      0.0022512795, -0.01054754, 0.0017344041, -0.00823719, 0.018837238,
      0.004689619, 0.007068887, -0.018338412, 0.009333293, -0.00021803117,
      -0.016658157, 0.011295779, -0.007213284, 0.0035705427, 0.02848558,
      0.050748963, -0.010816644, -0.0037543206, 0.009484254, 0.02276221,
      -0.00760053, 0.017393269, -0.02477064, -0.009247968, -0.010875715,
      0.026529657, 0.0154636, -0.034839045, 0.0023562955, -0.014820377,
      0.007173903, -0.02227651, -0.0031045342, -0.006110616, -0.012962908,
      -0.012129343, 0.0027583097, 0.0042498647, 0.008401277, -0.00493575,
      -0.009654905, -0.0024350574, -0.0010288282, -0.03528536, -0.03830457,
      -0.0022562023, 0.021593906, -0.046574578, 0.0060548265, -0.009267658,
      0.007521768, 0.015017282, -0.029798279, 0.024455592, -0.0053755045,
      -0.005001385, -0.014662853, -0.021305112, 0.0050703017, -0.0042039203,
      0.002205335, 0.008709761, 0.009471127, -0.009661468, -0.007403625,
      0.0045616305, 0.0021101644, 0.005431294, -0.027540434, 0.0039610704,
      -0.0145184565, -0.013291082, 0.027724212, -0.005303306, -0.022565303,
      -0.031846087, 0.01065912, -0.0029322424, -0.025637021, -0.006127025,
      0.024980672, -0.0018344976, -0.015450473, 0.0016597444, -0.16561016,
      -0.004489432, -0.0069769975, -0.012437828, 0.005910429, 0.028433071,
      0.006917926, 0.025059434, -0.023654845, -0.011269525, 0.021790812,
      -0.0065273982, -0.021003192, -0.012706931, -0.0014505329, -0.016395617,
      -0.03166231, 0.023457939, 0.00013106484, 0.008473475, 0.018102126,
      -0.009576143, -0.019007888, 0.0018082436, -0.0013003929, -0.016526887,
      -0.02349732, 0.018233396, -0.0040431144, 0.0077646174, -0.0100355875,
      0.024179924, 0.008296261, 0.0011937361, 0.004896369, -0.00921515,
      0.005733215, -0.00010496468, -0.013953995, 0.04783477, -0.0016999458,
      0.019270428, -0.0013972045, 0.020898176, -0.024534354, 0.028039262,
      0.0447368, -0.008361896, -0.0084800385, 0.004144849, -0.000440985,
      0.0047486904, 0.003135711, 0.014951647, 0.008644126, 0.02874812,
      0.034051426, 0.021685796, -0.02158078, -0.003161965, -0.014019631,
      -0.009661468, 0.019861143, -0.016592521, 0.0084472215, -0.005693834,
      -0.009418619, -0.0071279583, -0.0010329304, 0.018023364, 0.033395074,
      -0.03502282, 0.010586921, -0.012175288, 0.009110134, 0.017498285,
      -0.020780033, 0.008742578, 0.017143855, -0.007324863, 0.0023431685,
      0.027750466, -0.018548444, 0.014282171, -0.0038494912, 0.007443006,
      0.023694225, 0.011853676, -0.034340218, -0.008814777, -0.0011272806,
      -0.021528272, 0.005313151, -0.03040212, 0.02398302, 0.0022627658,
      0.019467333, 0.005290179, -0.00446646, -0.00091888965, 0.011984946,
      -0.006360029, -0.046023242, 0.039249714, 0.021042572, -0.0017951166,
      -0.00016644619, 0.025059434, 0.011276089, 0.00077572337, -0.008532546,
      0.006878545, 0.024573734, 0.021567652, 0.003813392, 0.017537665,
      0.006826037, -0.028538087, 0.00046231635, -0.005339405, 0.031767327,
      -0.029693263, -0.0024186487, 0.010567231, -0.005995755, 0.0047749444,
      -0.08863347, 0.029089421, 0.016907569, 0.0066750767, 0.019887397,
      0.0016203634, 0.012726622, 0.00040037333, -0.011322033, 0.0021298549,
      -0.010344072, -0.01253628, -0.00085981813, 0.0038855905, 0.0058251037,
      -0.014977901, -0.004637111, -0.014269044, -0.000534925, 0.025440115,
      0.01878473, 0.0012331171, -0.013166375, -0.006320648, -0.0013217243,
      -0.011912747, -0.02629337, 0.023221653, 0.028984405, 0.015279822,
      0.011440176, -0.02642464, 0.0002578224, -0.01424279, -0.026700307,
      -0.011223581, -0.03342133, -0.010455651, -0.00405296, -0.025479497,
      0.01212278, -0.015581743, 0.017970856, -0.027829228, -0.0083947135,
      -0.0014710438, 0.010376889, 0.008637562, 0.026109593, -0.0076464745,
      -0.027409164, -0.016723791, 0.0015243723, -0.029456977, 0.01707822,
      -0.006360029, -0.024088036, 0.02118697, -0.02712037, 0.013862106,
      -0.025571385, 0.00063214684, -0.02375986, 0.027356656, 0.036283012,
      0.016461251, -0.016172457, -0.0065208348, -0.006271422, 0.012772566,
      0.00025515596, 0.0042006383, 0.0054017585, 0.017025713, -0.04145505,
      -0.024744386, -0.014702234, -0.009175769, -0.006468327, 0.01375709,
      -0.007443006, -0.01786584, -0.021081954, 0.009307039, -0.014269044,
      0.023326669, 0.0035311617, 0.0077383635, 0.0013496191, 0.011925874,
      -0.00906419, 0.013376407, 0.008919793, -0.010586921, -0.009549889,
      -0.00026643698, -0.006087644, -0.013743963, 0.025387608, -0.0052672066,
      -0.03350009, 0.005831667, -0.066947676, 0.009523635, -0.02184332,
      -0.010363762, 0.0018525472, -0.021764558, 0.02542699, -0.031242248,
      -0.033788886, -0.009563016, -0.0066225687, -0.01013404, -0.0068063466,
      -0.012024327, -0.01266755, -0.0070360694, 0.021659542, 0.0016999458,
      0.033080027, 0.013310772, -0.016907569, 0.0064092553, -0.029220691,
      -0.0034425543, -0.033473838, 0.013271391, -0.012601915, 0.026385259,
      0.0030503855, 0.01052785, -0.0022611248, -0.027487926, 0.015502981,
      0.010061841, -0.0042006383, -0.010993858, 0.0055855364, 0.008263444,
      -0.010619739, 0.037543204, -0.008112483, -0.017051967, 0.025833925,
      -0.025440115, -0.007909014, 0.009904318, 0.008716324, -0.0065011443,
      0.018404046, 0.008132174, 0.015279822, 0.019204793, 0.020097429,
      -0.011098874, -0.015043536, -0.027619196, -0.0060384176, -0.043030288,
      0.028459325, -0.048674896, 0.0066652317, 0.009412055, 0.006658668,
      -0.022840971, 0.0008253598, -0.02974577, -0.030454628, -0.016750045,
      -0.002246357, -0.027356656, -0.0048143254, 0.03268622, 0.016973205,
      0.008696634, -0.0042465827, 0.015214187, 0.00972054, -0.006826037,
      -0.0028567621, 0.0145709645, 0.017012585, -0.01133516, -0.04061492,
      -0.0145578375, 0.026398387, 0.028196786, -0.003593515, -0.010357199,
      0.0031652467, -0.0049816943, 0.011190763, 0.018338412, 0.0017311224,
      0.0075283316, 0.0075414586, 0.010311254, 0.0145447105, -0.03830457,
      -0.01013404, 0.02289348, 0.014728488, -0.0020412477, -0.006478172,
      -0.0037018126, -0.030559644, 0.008033721, -0.00974023, -0.05132655,
      0.0078039984, 0.015673632, 0.004899651, 0.009070753, 0.033473838,
      0.025807671, -0.0035869514, 0.014295298, 0.032397423, -0.017091347,
      -0.023969892, 0.024691878, 0.016224965, 0.02148889, 0.011138255,
      -0.006241886, 0.015043536, -0.011479557, -0.00045493242, -0.006346902,
      0.004765099, 0.018968508, 0.0037543206, -0.02725164, -0.06631758,
      -0.008309388, 0.021108208, 0.02148889, -0.0040201424, 0.008722888,
      -0.023641717, 0.05368941, 0.0014989388, -0.002559764, 0.0070229424,
      0.03481279, 0.005670862, 0.012188415, -0.010029024, 0.010481905,
      -0.009011682, -0.0016507196, -0.022512795, -0.0035869514, -0.029772025,
      -0.010757572, -0.020491239, -0.010239056, 0.027697958, -0.033657614,
      -0.021895828, 0.006044981, -0.0038462095, -0.036545552, 0.009175769,
      -0.010357199, 0.0005053893, 0.01563425, -0.0061959415, 0.019493587,
      -0.042741496, 0.0013635666, 0.022434033, -0.004962004, -0.014977901,
      -0.011151382, -0.015647378, 0.0021265731, -0.015358584, -0.001657283,
      0.0006686563, 0.0013282878, 0.01961173, -0.03473403, -0.018194014,
      -0.017091347, -0.022840971, 0.012057145, 0.004820889, -0.031688564,
    ],
  },
  {
    id: "faq-2",
    question: "Πως λειτουργεί το πρόγραμμα επιβράβευσης Care-δίζω ;",
    answer:
      "To πρόγραμμα επιβράβευσης Care-δίζω λειτουργεί πάρα πολύ απλά! Δημιούργησε τον προσωπικό σου λογαριασμό και κέρδισε 1000 πόντους άμεσα! Με κάθε αγορά (και όχι μόνο) από το Carespot.gr κερδίζεις πόντους με τους οποίους εντάσσεσαι σε κάποιο επίπεδο παίρνοντας σταθερές εκπτώσεις και παροχές. Κάθε φορά που αγοράζεις, τα ευρώ των αγορών σου μετατρέπονται αυτόματα σε πόντους και καταχωρούνται στον λογαριασμό σου. Μάθε περισσότερα εδώ και ξεκίνα να μαζεύεις πόντους.\n\nΑπλά προσπάθησε να παραμένεις στο επίπεδο Gold για μέγιστες εκπτώσεις και προνόμια!\n\nΜε ποιους άλλους τρόπους μπορώ να κερδίσω πόντους εκτός από το να αγοράσω;\n\n- Εγγράψου στο newsletter για πρώτη φορά και κέρδισε 1.000 πόντους\n- Συμπλήρωσε το προφίλ σου για πρώτη φορά και κέρδισε 2.000 πόντους\n- Γράψε Κριτική σε προϊόντα που έχεις αγοράσει και κέρδισε 200 πόντους για το καθένα\n- Γράψε ένα σχόλιο σε κάποιο άρθρο που σε ενδιαφέρει στο Blog μας, κοινοποίησε το άρθρο στα Social Media (πχ Facebook) και κέρδισε 50 πόντους για κάθε άρθρο",
    embedding: [
      0.006071263, -0.0063571297, -0.0055097393, -0.040865317, -0.029539553,
      0.021331096, -0.0037945393, 0.016702779, -0.023005458, 0.0046759616,
      0.007718399, 0.02473427, -0.010801675, -0.005407644, -0.019588672,
      -0.011114767, 0.016784456, -0.016634716, 0.00784772, 0.009787529,
      -0.0059317327, 0.0043424503, 0.0005496126, -0.0046113012, 0.020350982,
      -0.0048937644, 0.0051898407, -0.020759363, 0.025251552, -0.014878678,
      0.017546767, -0.0027021205, 0.011625243, -0.010917383, -0.00370946,
      -0.01837714, 0.024108086, 0.009140926, 0.03305163, -0.003202387,
      0.028042156, -0.0076094978, 0.01291845, 0.0033997712, -0.01325196,
      0.014946741, 0.0052647106, -0.009283859, 0.00420292, 0.02382222,
      -0.0008065523, 0.014851453, -0.03002961, -0.00073423487, 0.017328963,
      0.006694044, -0.00061597454, -0.013810081, 0.010910577, -0.021480836,
      0.007990654, -0.0051149707, -0.017478703, 0.0044853836, 0.010638323,
      0.0107063865, 0.012421586, 0.0011077332, 0.00399873, 0.013633116,
      0.023209648, 0.016498588, -0.020378208, -0.015164545, 0.030628568,
      -0.0071534724, 0.0011766475, -0.01403469, 0.01213572, 0.008453485,
      0.015736278, -0.03699931, -0.031962615, 0.012986513, 0.021222195,
      0.0060066027, 0.009984913, 0.026068315, -0.026640048, -0.014293332,
      0.009522081, 0.023019072, 0.02725262, 0.020977166, 0.018649396,
      0.003985117, 0.009733078, 0.0149059035, -0.020922715, -0.0017041395,
      -0.005570996, 0.027279845, -0.026585598, -0.013469764, -0.021412773,
      -0.017887084, -0.006928863, 0.011700113, 0.018077662, -0.019765636,
      -0.023046296, 0.053388998, -0.0028280378, -0.025401292, 0.009665015,
      0.015777117, 0.025823286, -0.0028569647, -0.05126542, 0.0079021705,
      0.004179098, -0.003995327, 0.005288533, 0.0022903362, 0.019575058,
      0.0065136757, -0.018309077, -0.0007993206, -0.0067518977, -0.012054043,
      0.0149059035, -0.010740418, 0.0007423174, 0.003985117, -0.019139452,
      0.02104523, -0.021290258, 0.032425445, -0.045466408, 0.009984913,
      0.033242207, 0.032942727, 0.00031224123, -0.0012285459, -0.007956621,
      0.0064149834, 0.007949815, 0.012721065, -0.010597485, 0.014551973,
      0.019820087, -0.026844239, -0.004533028, -0.00898438, 0.0023056506,
      0.0006295872, -0.007146666, -0.00941318, -0.014946741, -0.012809548,
      0.005805815, -0.0055029327, 0.00059130153, 0.0020248888, -0.012904837,
      0.024080861, 0.008882285, 0.0015203682, 0.0074257264, -0.0059691677,
      -0.031445332, 0.020350982, -0.016239947, 0.023999184, -0.0019857523,
      0.014769777, 0.00435266, -0.0064490153, -0.0004632571, 0.010100621,
      -0.043179475, 0.0011494221, 0.023046296, 0.012006399, -0.014933129,
      -0.024870398, 0.016008532, -0.012516875, 0.009324698, 0.00022822537,
      -0.010502196, 0.012714259, -0.012782322, -0.0012234411, -0.63032234,
      -0.0057819933, -0.001083911, -0.009508469, 0.00016622379, 0.021698639,
      0.019615896, 0.007030958, -0.037108213, 0.016512202, 0.0155457,
      0.004744025, 0.0033691425, 0.00013304285, -0.015014805, -0.0140483035,
      0.015804341, -0.007766044, 0.023100747, 0.008650869, -0.021970892,
      0.0069084438, -0.0067655104, 0.013871338, 0.009685434, 0.011829434,
      0.016975034, -0.013231542, -0.007446145, 0.027320683, -0.0013272379,
      0.010692773, -0.016920583, -0.0015144126, 0.05592096, -0.003886425,
      -0.0013272379, 0.015368735, 0.010434132, 0.04315225, -0.027742676,
      -0.0011698412, 0.041899882, -0.017274512, -0.01004617, -0.02210702,
      0.011298538, -0.006677028, 0.034739602, -0.020922715, 0.008249294,
      0.008208456, -0.006343517, -0.0042097266, 0.011536761, 0.00870532,
      0.027701838, -0.04214491, -0.0054791104, -0.0030254219, 0.02725262,
      -0.016607491, 0.00309859, -0.017982373, 0.0033266027, 0.0035427045,
      0.003729879, 0.007670755, 0.026340568, 0.020963553, 0.009447211,
      0.00030011742, 0.0108289, 0.011257701, 0.0002382222, -0.0056288503,
      0.015491249, -0.009460825, 0.010012139, 0.03296995, -0.017995985,
      -0.012442005, -0.013735211, 0.037026536, 0.016226334, 0.00069382216,
      -0.023903895, -0.01004617, 0.011434666, 0.025973026, 0.027906029,
      0.028722791, 0.0070581837, -0.0062141963, -0.01498758, 0.03351446,
      -0.0052715167, -0.009583339, 0.0044241264, -0.014007465, 0.0027514664,
      0.008997993, -0.001883657, -0.015137319, 0.010216329, -0.0056526726,
      -0.03552914, 0.0131907035, 0.055594254, -0.0058296374, -0.030192962,
      -0.00087886973, -0.00039498092, -0.009202183, -0.0028297394, -0.031663135,
      -0.00016356507, -0.0027344506, 0.0019874538, -0.014742551, 0.015586538,
      -0.010223135, 0.018172951, -0.006823364, 0.018132113, 0.015409573,
      0.010352456, -0.01686613, -0.041164797, 0.0015416379, -0.013081802,
      -0.024775108, 0.0043628695, -0.006343517, 0.010032558, -0.0051455996,
      0.03139088, -0.02391751, 0.010515808, -0.012360329, -0.019193903,
      -0.003210895, 0.020677688, -0.001669257, 0.018159337, 0.004975441,
      0.0014165713, 0.012537294, 0.006677028, 0.016416913, 0.0072283424,
      -0.019493382, -0.015640989, 0.0028569647, -0.0143613955, -0.0029114156,
      -0.022134246, -0.0049141836, -0.005880685, -0.014620037, 0.008501129,
      0.0181185, -0.02029653, 0.012176557, -0.03275215, 0.0129592875,
      0.010617903, 0.0007793269, -0.024489243, -0.03895954, 0.0068403804,
      0.009828367, -0.018349916, 0.023495516, 0.033214983, 0.014592811,
      -0.0046521393, -0.0066429963, 0.012877611, 0.0038319742, 0.0049550217,
      -0.00656132, 0.0012404571, 0.0015135617, -0.0018530284, 0.007636723,
      0.03746214, 0.014170818, -0.013483376, 0.038605608, -0.0016598982,
      -0.007834108, -0.0020061713, 0.030220188, 0.002782095, -0.021494448,
      -0.0037264759, 0.013265573, 0.0042845965, 0.017342577, 0.037108213,
      0.018594945, 0.056901075, -0.0012370539, 0.02225676, 0.0018394156,
      0.014742551, -0.002246095, 0.026571985, 0.012067656, 0.004529625,
      -0.013306411, 0.003689041, 0.005407644, 0.004516012, 0.018458817,
      -0.011019479, -0.0010907174, -0.0030645584, 0.022978233, -0.017233675,
      -0.0039510853, 0.0028654728, -0.014715325, -0.03179926, -0.0010201015,
      0.0030628568, 0.025006525, 0.015246221, -0.0074529517, -0.016471364,
      0.0017220061, -0.020473497, 0.02997516, 0.035556365, -0.020759363,
      -0.0015943871, 0.0036992505, 0.010665548, 0.024543693, -0.001380838,
      0.020827426, 0.016825294, 0.006013409, 0.0006865904, -0.006013409,
      0.02825996, 0.027116492, -0.010536227, 0.009481244, -0.018744685,
      0.022651527, -0.019452544, -0.00513539, 0.015314284, -0.025265167,
      0.019166678, 0.01198598, 0.013333636, 0.02740236, 0.018186564, 0.00890951,
      0.01569544, -0.009501662, -0.006853993, 0.01771012, -0.029539553,
      -0.01433417, -0.009596951, -0.02891337, 0.015028417, 0.004744025,
      -0.012196977, -0.008262907, 0.012870805, 0.02186199, 0.026313344,
      0.0154504115, 0.013428926, 0.01042052, -0.028450537, -0.02866834,
      0.014742551, 0.024952075, -0.024257826, -0.027565712, -0.033133306,
      0.022719592, -0.028341636, 0.017233675, -0.0014352887, 0.040402483,
      -0.00022163172, -0.000018105951, 0.015436798, -0.008712126, 0.035447463,
      0.017805409, -0.016621104, -0.022093408, -0.016975034, -0.0018496253,
      -0.0012812951, -0.057881188, 0.045902014, -0.013864532, -0.017927922,
      -0.031717584, 0.0019295998, -0.03765272, -0.0012838475, 0.008092748,
      0.004607898, -0.035311338, 0.017288126, -0.0006253333, -0.011625243,
      -0.00043113963, 0.014973966, -0.014660874, -0.02467982, -0.011346183,
      0.012162945, -0.0052034534, 0.031880938, 0.028287185, 0.01912584,
      0.003886425, -0.012986513, -0.0037026536, -0.009807948, -0.047372185,
      -0.001244711, 0.0046555423, 0.005649269, -0.017179223, 0.006540901,
      0.0027140314, 0.009903237, 0.031962615, 0.012319491, 0.0045670597,
      0.0146744875, -0.030982498, 0.017832633, 0.0031139045, 0.021358322,
      0.021834766, 0.007459758, -0.014211656, 0.02195728, 0.010611097,
      0.003927263, -0.027266232, 0.012700646, -0.008215263, 0.012612164,
      0.018404366, -0.019520607, 0.0146881, -0.007616304, 0.02538768,
      0.030247413, 0.021371935, 0.020881878, 0.013939402, 0.0037434916,
      0.005952152, 0.03209874, -0.0049856505, 0.013102221, 0.0162944,
      0.0020742347, -0.0022188695, 0.01776457, -0.01785986, -0.026857851,
      0.0037537012, 0.02352274, 0.0086032245, -0.015858792, 0.0018632379,
      0.001875149, -0.012428393, -0.01912584, -0.010148265, 0.005799009,
      -0.01873107, 0.0071534724, -0.033187754, -0.0007601841, 0.006704253,
      0.0015646094, -0.034494575, 0.021521674, -0.04334283, -0.027878804,
      0.0066021583, 0.04143705, 0.028287185, 0.038496707, 0.01004617,
      0.029294524, 0.025904963, 0.002332876, -0.012789129, -0.0073372438,
      -0.01161163, -0.007657142, -0.00074359355, -0.013156672, -0.02574161,
      -0.0017509331, 0.023781382, -0.01716561, 0.020786589, 0.008644063,
      -0.028722791, -0.025210716, 0.01746509, -0.004750831, 0.02836886,
      -0.00777285, -0.023944734, 0.029539553, -0.0026748949, -0.032833826,
      -0.0052000503, -0.007806882, -0.003964698, -0.0022426918, -0.014797002,
      0.0012208888, -0.026068315, 0.016444137, -0.011659275, 0.004468368,
      0.007378082, 0.0006074666, 0.008501129, -0.0002782095, 0.021113293,
      -0.015940469, 0.014443072, 0.013762437, -0.028559439, 0.02943065,
      -0.00068063487, -0.03375949, 0.005254501, -0.020500721, -0.012789129,
      0.010148265, -0.008439872, 0.008215263, 0.03296995, 0.0063401135,
      -0.028858919, -0.030791922, -0.0008529205, -0.018554106, 0.01362631,
      0.008439872, 0.0042879996, -0.02018763, -0.008283326, -0.005336177,
      -0.012932062, 0.023291325, -0.034657925, 0.0064013707, 0.0010617904,
      0.005254501, 0.023277711, 0.0056526726, -0.0012310983, -0.041219246,
      -0.012979707, 0.0026782982, -0.024475629, 0.01796876, -0.0053089517,
      0.023863059, 0.019575058, 0.01832269, -0.008848253, 0.0014820824,
      -0.021126905, 0.008916317, -0.02039182, -0.0031275172, -0.017696505,
      -0.007405307, 0.03678151, 0.006169955, 0.0035767362, 0.021331096,
      0.004764444, 0.0062856628, 0.0017985776, -0.000009046328, -0.034276772,
      -0.030301863, -0.024393953, -0.0013365967, -0.017587604, 0.013953014,
      -0.008684901, -0.0067110597, 0.0052306787, 0.00463172, -0.004883555,
      0.026571985, 0.046909355, 0.019071389, -0.013020544, 0.015831567,
      0.0136603415, 0.0008661079, 0.0089911865, -0.013388088, -0.019629508,
      -0.0027633775, 0.03765272, -0.010971834, 0.014266106, 0.0132723795,
      0.0028586665, -0.009549307, -0.0017415744, -0.012938868, -0.0064388057,
      -0.003886425, 0.0072283424, -0.025564644, -0.024135312, -0.010087009,
      -0.010944609, -0.020269306, 0.0014182728, -0.016825294, 0.009345116,
      -0.0029862854, -0.015082869, -0.005411047, -0.005131987, 0.03209874,
      -0.015205382, 0.01953422, 0.029811807, 0.0038421839, -0.0013493586,
      -0.036509253, -0.0044819806, 0.0018036824, 0.029648455, 0.037271563,
      0.0023720125, -0.035365786, -0.0065647233, 0.012993319, -0.005727542,
      -0.02689869, 0.008283326, -0.002703822, -0.0034882536, -0.001215784,
      0.010645129, -0.0042914026, 0.03179926, -0.0033640377, -0.017083935,
      -0.006425193, -0.00004035952, -0.0012863999, 0.02044627, -0.007187504,
      0.02467982, -0.0024298665, -0.016185496, -0.0310914, -0.012462424,
      -0.008766577, 0.018962488, -0.0007823047, 0.00037413646, 0.010134653,
      0.018132113, 0.0051217773, -0.023019072, 0.015872404, 0.00024715552,
      -0.022515401, 0.024870398, 0.0042641773, -0.01355144, 0.01858133,
      0.032343768, -0.015300672, 0.011802209, 0.0073372438, 0.0075686597,
      -0.031935386, -0.0224065, 0.0089911865, 0.0098487865, -0.029403426,
      0.0054961266, 0.0054382724, 0.0069424757, 0.015259833, 0.0010575365,
      0.03002961, 0.021970892, -0.002538768, -0.011176025, 0.01433417,
      0.030301863, 0.011536761, 0.024557305, 0.019983439, 0.016512202,
      -0.007303212, -0.0043458533, 0.0062141963, -0.012360329, -0.014075529,
      0.013483376, -0.024448404, -0.0312003, 0.05319842, 0.01239436,
      -0.0036447996, -0.01619911, -0.000069711896, 0.0008703618, 0.010930995,
      -0.011039897, -0.007051377, -0.0010498792, -0.00091630465, -0.0038013456,
      0.013755631, 0.0028756822, -0.006819961, 0.0021116696, 0.0021491046,
      -0.029158397, 0.0040906155, -0.0006602158, -0.000060406343, 0.0023175618,
      -0.034358446, -0.01519177, -0.01412998, 0.010740418, -0.021399159,
      -0.03716266, -0.013258767, -0.010679161, -0.025727997, 0.021371935,
      0.02997516, -0.024979299, 0.010536227, 0.0015918348, 0.0071806978,
      0.029103946, -0.027715452, -0.012299072, -0.015790729, -0.04002133,
      -0.011257701, -0.010719999, -0.02406725, 0.05597541, 0.03038354,
      -0.025319617, -0.026109153, -0.01947977, -0.011754564, 0.015314284,
      -0.0026936124, 0.038170002, 0.004182501, 0.0038489902, -0.007616304,
      0.009699047, 0.003466133, 0.00043858407, -0.0089911865, 0.0046521393,
      -0.005605028, -0.018935261, -0.011849853, -0.005805815, -0.03678151,
      0.0048733456, 0.045466408, -0.014783389, 0.020772975, 0.020160405,
      -0.0037945393, -0.026013864, 0.004938006, -0.016308011, -0.015640989,
      0.0018564316, -0.008963961, -0.022882944, 0.01903055, 0.009072863,
      0.005741155, -0.031118626, -0.001607149, 0.01862217, -0.015368735,
      0.016730005, -0.010788063, -0.026830627, -0.008528355, -0.030846372,
      0.007092215, 0.007248761, -0.0143613955, 0.010352456, -0.004740622,
      -0.02216147, -0.030111287, 0.012734679, -0.007616304, -0.021113293,
      0.004104228, -0.007861333, -0.0043288376, 0.00035350473, -0.012850386,
      -0.017573992, 0.0030645584, -0.015804341, 0.0072147297, -0.011114767,
      0.0070581837, 0.00045517457, -0.031717584, 0.010515808, 0.0105430335,
      -0.0022614093, -0.029947933, -0.011026285, 0.024666207, 0.014157205,
      0.015464024, 0.0057445583, -0.004318628, -0.0110943485, 0.013694373,
      0.0044581583, -0.0004641079, 0.014660874, 0.23152475, -0.026517535,
      -0.019289192, 0.010032558, -0.0059045074, 0.009576532, 0.0049890536,
      0.0002886317, -0.010168685, 0.0008061269, 0.0029692696, 0.009426793,
      -0.02195728, 0.0031411299, -0.016335236, -0.0072351485, -0.006380952,
      0.0020385014, -0.014021078, -0.019520607, 0.009249828, 0.013238348,
      0.012081268, 0.0061835675, 0.015055643, 0.018839972, -0.004594285,
      -0.0032074917, 0.029485103, 0.01433417, -0.012442005, 0.005169422,
      -0.00463172, 0.01560015, -0.020133179, 0.0073168245, 0.008011072,
      -0.013605891, 0.019493382, -0.016648328, 0.010583872, 0.012898031,
      -0.016784456, 0.004662349, -0.027130105, 0.0027957077, -0.0112440875,
      -0.01319751, 0.0040327613, 0.020609623, -0.036128096, -0.0023260696,
      0.009760303, 0.021535287, -0.024720658, -0.015981307, 0.0030050029,
      -0.0014812317, -0.0052408883, 0.00055173965, -0.012115301, 0.010638323,
      -0.009556114, 0.022814881, -0.023590803, -0.0044989963, -0.013013738,
      0.005176228, 0.028641114, -0.010917383, -0.0026714918, -0.012033625,
      0.016566653, -0.012183364, -0.028287185, -0.000033101187, 0.023658868,
      0.015858792, 0.027484035, 0.027538486, -0.004982247, 0.005605028,
      -0.03346001, -0.005952152, -0.016076595, -0.017533153, 0.008419453,
      0.009385955, -0.011577599, -0.004788266, 0.0058466536, 0.019234741,
      -0.03991243, -0.011325764, -0.00016335236, 0.0033691425, -0.006976507,
      0.03675428, -0.006898234, 0.010910577, -0.020432658, 0.07650336,
      -0.01325196, 0.0024060442, -0.012578132, 0.0058500567, 0.0035563172,
      0.031826485, 0.024584532, -0.027361521, -0.00033861585, -0.033323884,
      0.020772975, -0.013585472, -0.0062924693, 0.013796468, -0.015926855,
      -0.017505929, 0.010835707, -0.0012812951, 0.0060644564, -0.025047362,
      -0.026136378, 0.012625776, -0.0013280888, -0.020827426, -0.025564644,
      -0.009910043, -0.005754768, -0.04764444, 0.0019398093, -0.023740543,
      0.022474563, -0.016444137, -0.022651527, -0.0095356945, 0.007827301,
      -0.03286105, -0.0042845965, -0.0021746284, -0.003112203, 0.000768692,
      -0.018744685, 0.0012464126, -0.010719999, -0.01325196, 0.0069833137,
      0.024924848, -0.004594285, -0.025346842, -0.03250712, -0.009896431,
      -0.018839972, -0.017342577, 0.018281853, -0.027239006, -0.015001192,
      -0.026762562, 0.0098487865, 0.00010783808, -0.03746214, 0.005407644,
      0.03433122, -0.01785986, -0.012911643, 0.0067518977, -0.17456923,
      -0.0034831488, 0.0070377644, -0.011904304, 0.018145725, 0.0060031996,
      0.022147859, 0.04078364, -0.025455743, 0.0055403677, 0.025251552,
      -0.013354056, -0.048760682, 0.005329371, -0.002538768, -0.0110126715,
      -0.022882944, 0.0073712757, 0.012108494, 0.01261897, 0.015640989,
      -0.024761496, -0.009978107, -0.00024439045, 0.0067280754, -0.019792862,
      -0.042117685, 0.0024196568, 0.008739351, -0.015205382, -0.0005772634,
      0.021780316, 0.020664074, 0.006149536, 0.0003313841, -0.015409573,
      0.015314284, -0.01418443, -0.009522081, 0.009583339, -0.009331504,
      0.012401167, -0.011475503, 0.033242207, -0.009835173, 0.03002961,
      0.028178282, -0.012891225, 0.009474437, 0.014524748, -0.013844113,
      -0.01634885, -0.0027803935, 0.010965028, 0.0060270214, 0.0055846088,
      0.017750956, 0.028042156, -0.027538486, -0.00041752696, -0.012081268,
      -0.021058843, 0.021480836, 0.0023856252, 0.018703846, -0.0122582335,
      -0.0040872125, 0.01837714, -0.030301863, -0.0018938665, 0.022991845,
      -0.0053974343, 0.0028212315, -0.016389687, 0.016988646, 0.023291325,
      -0.019221129, -0.0073168245, -0.0006585142, 0.018390754, -0.0012293967,
      0.02180754, -0.014511135, -0.019153064, -0.008950348, 0.006234615,
      0.02361803, 0.032833826, -0.014538361, 0.0014174221, 0.00036073648,
      -0.039694626, 0.010182298, -0.012761904, 0.032534346, 0.029621229,
      -0.00024481586, 0.0110875415, 0.017846245, 0.019928988, 0.023236874,
      -0.013034157, -0.02624528, 0.03830613, 0.013735211, 0.00033989202,
      -0.0091477325, 0.013762437, 0.030465215, 0.009814754, 0.0025796061,
      -0.000502819, 0.02781074, 0.037979424, -0.020813813, 0.029267298,
      -0.01604937, -0.024257826, -0.01325196, -0.011298538, 0.053879056,
      -0.030274639, -0.004124647, 0.0037809266, -0.018894423, -0.022365661,
      -0.091586225, 0.0055029327, -0.013068189, -0.019806474, 0.014742551,
      0.033950068, 0.0036073648, -0.008875478, -0.0181185, -0.010502196,
      -0.020051504, -0.026013864, 0.020732138, -0.0020929521, -0.017519541,
      -0.004938006, -0.0034218917, -0.027906029, 0.0067076567, 0.021535287,
      0.015668215, -0.019071389, 0.011604824, 0.0030560505, -0.023590803,
      0.015232608, -0.0024792124, 0.007126247, 0.010311618, 0.035556365,
      0.0070990217, -0.021834766, 0.009208989, -0.017397027, -0.018036824,
      -0.030873597, -0.025564644, -0.028232735, 0.0012404571, -0.035910293,
      0.028995045, -0.02538768, 0.008351389, -0.024516467, -0.00021929204,
      -0.014660874, 0.00068829197, 0.015872404, -0.003466133, -0.011543567,
      -0.058207892, -0.018254627, -0.038905088, -0.0055914153, 0.017138386,
      -0.022283984, -0.004621511, 0.012870805, -0.035256885, -0.000110390465,
      -0.01625356, -0.01892165, -0.0241217, 0.012673421, 0.025129039,
      0.0057887994, -0.021616964, -0.01433417, 0.012755097, -0.016131045,
      -0.0019364061, -0.01858133, -0.0016624506, 0.014633649, -0.016131045,
      -0.009773917, -0.0138917575, -0.010066589, 0.011107961, 0.0052442914,
      -0.012380748, -0.015395961, 0.015001192, -0.013217929, 0.0154504115,
      0.012653002, -0.00840584, -0.0023022473, 0.004805282, 0.00449219,
      -0.019956214, 0.00031415554, 0.00548932, -0.008895897, -0.0017866665,
      -0.014293332, -0.0048393137, -0.0112440875, -0.010638323, -0.006540901,
      -0.03209874, -0.007595885, -0.0759044, 0.008283326, -0.018077662,
      -0.013592278, -0.011162411, -0.009249828, 0.028586663, -0.006823364,
      0.00076358725, 0.010304811, -0.015926855, 0.014851453, 0.0022426918,
      -0.0017781586, -0.031418104, -0.013639922, 0.029594004, 0.0047950726,
      0.02488401, 0.00427779, -0.015504862, -0.02533323, -0.010474971,
      -0.0048359106, -0.028559439, 0.021889217, -0.0051149707, 0.033133306,
      0.0053974343, 0.009733078, -0.0009690539, -0.0007133904, 0.0013076697,
      0.019711185, -0.006663415, 0.0065681264, 0.010311618, 0.03539301,
      0.029702906, 0.024897624, -0.0014029586, -0.026122766, 0.0014463491,
      -0.03326943, -0.019724797, -0.009903237, -0.011917916, -0.0042607742,
      -0.006704253, 0.005884088, 0.011237281, 0.0041688886, -0.002538768,
      -0.019112227, 0.0010617904, -0.012312684, 0.0057683806, -0.02665366,
      0.0065681264, -0.021249421, 0.008371809, 0.0007257269, 0.01761483,
      -0.015368735, 0.018458817, -0.0258369, 0.007303212, -0.010161879,
      -0.00971266, -0.0069662975, -0.013381281, 0.01312264, -0.00066617137,
      0.011863465, -0.028532213, -0.00427779, -0.024339503, 0.0012778919,
      -0.028178282, 0.0023873267, 0.018513268, -0.0017832633, -0.047889467,
      0.012360329, 0.018635781, 0.03286105, 0.013143059, 0.0053157583,
      -0.0075890785, 0.00046751107, 0.004999263, 0.017696505, 0.0066463994,
      0.014660874, 0.0022733205, 0.01791431, 0.020500721, -0.02488401,
      -0.014075529, 0.044077914, 0.004390095, 0.024366727, -0.0052374853,
      -0.030437991, -0.028477762, 0.022297598, -0.019520607, -0.06343517,
      -0.007010539, 0.0050401012, 0.009086476, -0.010161879, 0.016525814,
      0.017369801, -0.005530158, -0.01042052, 0.022896556, -0.026013864,
      -0.034303997, 0.010039364, 0.016158272, 0.0027991107, 0.022597076,
      0.0013110728, 0.033487234, 0.006823364, 0.031935386, -0.01686613,
      -0.0016233141, 0.0075142086, 0.012128913, -0.02635418, -0.034412898,
      -0.034848504, 0.009971301, 0.012687034, 0.0042165327, 0.011802209,
      -0.01433417, 0.085923344, 0.0022563045, 0.0026680885, 0.0042675803,
      0.023604417, 0.0039919233, 0.0064115804, 0.0014982475, 0.0059079104,
      0.006816558, 0.01574989, -0.01832269, 0.0022835298, -0.030710245,
      -0.0060882787, 0.0118702715, -0.006469434, 0.005370209, -0.019003324,
      0.0007138158, -0.00022269522, 0.0030305267, -0.013136253, -0.0056935106,
      -0.019071389, 0.0054552886, 0.020963553, 0.014701713, 0.001314476,
      -0.017601218, -0.002394133, 0.010209523, 0.0024366728, -0.019398093,
      -0.014347782, -0.009229409, -0.0072351485, -0.016008532, -0.02039182,
      0.0072555677, -0.02453008, 0.026925914, -0.03759827, -0.04633762,
      -0.030247413, -0.029049495, 0.016389687, -0.021126905, -0.013884951,
    ],
  },
  {
    id: "faq-3",
    question: "Πώς μπορώ να πραγματοποιήσω μία παραγγελία ;",
    answer:
      "Μπορείτε να πραγματοποιήσετε την παραγγελία σας με τους εξής τρόπους:\n\n**Παραγγελία ON LINE**\n\n- από την ιστοσελίδα μας\n- στα κανάλια κοινωνικής δικτύωσης Facebook Messenger & Instagram\n- με e-mail στο info@carespot.gr\n- μέσω Viber & WhatsApp στο 6948470358\n\n**Τηλεφωνική παραγγελία:**\nΜπορείτε να δώσετε την παραγγελία σας στο 2644038033, καθημερινά από 09:00 έως 17:00 εκτός Σαββάτου, Κυριακής και Αργιών.",
    embedding: [
      0.0007198608, 0.0029089928, -0.026410764, -0.014695995, -0.011688503,
      0.017165028, -0.01144554, 0.016665967, -0.013284181, -0.010887382,
      0.023495205, 0.014998057, -0.013816074, -0.0026988622, 0.012555291,
      -0.013487745, 0.0310205, 0.02005432, -0.008937108, 0.0010539353,
      -0.003529534, -0.0054305573, 0.020868575, 0.00019330355, 0.000013440958,
      0.009902395, 0.017362025, -0.003884129, 0.007938989, -0.018412676,
      0.030153712, -0.022247557, 0.0027481117, -0.023166876, -0.009120973,
      -0.003257021, 0.026647162, 0.0063137617, 0.031703424, 0.007216666,
      0.008621913, -0.005561889, 0.013750408, -0.007525295, 0.00083477586,
      0.01750649, -0.029339457, -0.023731602, -0.0033571615, 0.019817924,
      0.017204426, 0.0035328174, -0.040922895, -0.009843295, 0.01508999,
      0.03393606, 0.010178192, 0.01988359, 0.00178775, -0.022615284,
      0.002958242, 0.0034343188, -0.007984955, -0.010421154, -0.0034474519,
      0.0019863888, 0.005423991, 0.00860878, -0.02810494, -0.0033276118,
      0.015365785, 0.009672565, -0.012371427, -0.008470882, 0.03230755,
      -0.0059526004, 0.016324505, -0.0033473116, 0.00013081849, 0.003257021,
      0.00979733, -0.021748496, -0.00812942, 0.008726978, 0.016981162,
      0.0153789185, 0.019528994, 0.0064352434, -0.029917315, -0.023744736,
      0.012259795, 0.027343217, 0.0054305573, -0.0014175593, -0.0019765391,
      0.005515923, -0.008083453, 0.026174368, 0.015786046, -0.007676326,
      -0.007998088, 0.004179625, -0.020527113, 0.0039662113, -0.0010079693,
      -0.039268117, 0.013395813, 0.023193143, 0.010716651, -0.013908005,
      -0.018596541, 0.051770877, 0.009560933, -0.028262539, 0.006317045,
      0.010046859, 0.0048691155, -0.023416406, -0.026949223, -0.004419305,
      0.0054174243, 0.0009464076, -0.020159384, -0.020211918, 0.030495174,
      0.0068226713, -0.0018944569, -0.014695995, -0.031388227, -0.0023836668,
      0.033778463, 0.0110055795, -0.012121897, 0.01077575, -0.0064812093,
      0.013159417, -0.029497055, 0.029155593, -0.025990503, 0.007991522,
      0.016771032, 0.015418318, 0.020382648, -0.011983999, -0.0096988315,
      0.0008741753, 0.0105787525, -0.01073635, 0.0051120785, 0.0022457687,
      0.0035426673, 0.008457748, 0.010992447, -0.0037724974, 0.0032061301,
      0.02026445, -0.02747455, 0.0059722997, 0.002833477, -0.0068029715,
      0.02509745, 0.01639017, 0.011951166, -0.0053189257, -0.031808488,
      0.035722166, 0.013225082, 0.01572038, 0.0053156423, -0.014367666,
      -0.021617165, 0.03456645, -0.014748528, 0.012561858, -0.0119249,
      0.008431482, -0.00089797913, -0.008188519, 0.017204426, 0.021039305,
      -0.065455616, 0.0049216477, 0.0064713596, 0.03201862, -0.019029934,
      0.00017586109, 0.008556247, -0.014013071, -0.0020750377, -0.005630838,
      -0.010953047, 0.011110645, 0.006418827, -0.0052040108, -0.6253481,
      -0.014564663, 0.009193205, -0.016482104, 0.0016293314, 0.048855316,
      0.008254184, -0.014118136, -0.021485833, 0.032701544, -0.013290748,
      -0.0007473583, 0.000037244794, -0.008891143, 0.010394888, -0.021787895,
      0.009501834, -0.011563739, -0.00527296, 0.004090976, -0.011563739,
      -0.017099362, -0.015562783, 0.0034014857, 0.0028219856, 0.008687579,
      0.0076040938, -0.019003669, 0.004530937, 0.016363904, -0.010933347,
      0.014840459, -0.0011770586, 0.0002739493, 0.061935935, 0.011117212,
      -0.017519621, 0.0430242, 0.018360144, 0.016771032, -0.03230755,
      -0.012555291, 0.009744797, -0.020290717, -0.013579677, -0.026437031,
      0.008503715, -0.010677251, 0.03514431, -0.011583438, -0.0027710947,
      0.017874217, -0.007722292, -0.019082466, 0.023232542, 0.010900514,
      0.01796615, -0.019043067, -0.011393008, 0.004455421, 0.020619046,
      -0.020316983, 0.0040023276, -0.022378888, -0.002083246, -0.0005405112,
      0.0029763002, -0.00046335394, -0.009035607, 0.012286061, -0.001935498,
      0.006678207, -0.018071214, 0.024716588, -0.004957764, 0.0014249468,
      -0.012660356, -0.0078076576, 0.007932423, -0.008260751, -0.007952122,
      0.00051916984, -0.03435632, 0.012240096, 0.016823566, -0.008444616,
      -0.02672596, -0.011951166, 0.010999013, 0.03903172, 0.016600301,
      0.048881583, 0.005519206, 0.0029746585, -0.011136911, 0.007321731,
      -0.020408915, -0.00092096213, 0.020868575, 0.014078736, -0.0015259079,
      -0.0070525017, 0.019200666, -0.02405993, 0.023731602, -0.010099392,
      -0.030915435, 0.014459598, 0.039872244, -0.01217443, -0.0022112941,
      -0.005059546, 0.004481687, -0.014249467, 0.022444554, -0.027921077,
      0.0017779002, 0.016350772, -0.0037133982, 0.014026204, 0.00975793,
      0.013225082, 0.01646897, -0.020277584, 0.011090945, 0.027500816,
      -0.006284212, -0.032754075, -0.023836667, -0.008490581, -0.025714707,
      -0.011931467, 0.0018304328, 0.005519206, 0.015273853, -0.022904214,
      0.009514967, 0.0037889138, 0.026949223, 0.00083723833, -0.0013904723,
      -0.005161328, 0.0008204115, -0.016862964, 0.013947405, -0.011839535,
      -0.009475567, -0.0064450935, -0.030784104, 0.030127445, 0.0055487556,
      -0.0096988315, -0.0044948207, 0.0102372905, 0.000014736318, -0.007525295,
      -0.008497148, -0.008713845, -0.009961494, -0.008267318, 0.012128464,
      0.009252304, -0.02080291, 0.0016687309, -0.026896691, -0.0015726947,
      0.01219413, -0.005525773, -0.012121897, -0.030521441, 0.0215121,
      -0.0047935997, -0.008037488, -0.0021620449, 0.017046828, 0.0075318613,
      -0.0046754014, 0.007387397, 0.00931797, -0.010165058, 0.012049665,
      0.011990566, -0.00810972, 0.011314209, 0.0096134655, 0.0030468907,
      0.013120017, 0.03827, -0.009304836, 0.013336713, 0.01211533, 0.01148494,
      0.0143414, 0.01476166, -0.00856938, 0.010440854, 0.005525773, 0.018727873,
      -0.006796405, 0.036667753, 0.0143414, 0.0069146035, 0.039136786,
      -0.015221321, 0.01880667, -0.007833924, 0.02910306, 0.014288867,
      0.029864782, 0.026883557, 0.00908814, -0.009817029, 0.0050431294,
      0.0048034494, 0.018307611, 0.023521472, -0.02768468, 0.00072930026,
      0.0119249, 0.020907974, 0.009449301, 0.01579918, -0.0056603877,
      -0.020855442, -0.009311403, 0.012811388, 0.019542128, 0.02960212,
      -0.0049741804, -0.009298271, -0.055054165, 0.005410858, -0.014367666,
      0.02030385, 0.030679038, -0.024164995, -0.006336745, -0.0018008832,
      0.01336298, 0.0052466933, 0.010086259, -0.000017993954, 0.012620957,
      -0.01244366, -0.0019699724, -0.0067701386, 0.02551771, 0.000668149,
      0.0015866486, -0.007151, -0.051639546, -0.008057187, -0.031834755,
      -0.0005511819, 0.024572123, -0.030836636, 0.0017122345, 0.0008405216,
      0.01796615, 0.037429478, 0.019253198, 0.004261707, 0.016074976,
      -0.01286392, -0.0063695777, 0.013881739, -0.007013102, -0.016954897,
      -0.02109184, -0.015300119, -0.017834818, -0.010322656, 0.013303881,
      0.008766377, -0.0038217467, -0.016127508, 0.0083920825, -0.0005577485,
      0.00841835, -0.0015767989, -0.04530937, -0.02939199, 0.02034325,
      -0.0019141566, -0.0050102966, -0.03876906, -0.037770938, 0.019528994,
      -0.0086481795, 0.019279463, 0.005345192, 0.0020307133, -0.010532786,
      -0.00080153253, 0.0047377837, -0.0031486726, 0.062934056, 0.027789745,
      -0.000285646, -0.007906157, -0.0053583253, -0.0064352434, 0.004166492,
      -0.028919196, 0.026331965, 0.009639732, -0.025885439, -0.0024000832,
      -0.0049971635, -0.034487654, -0.0016112734, 0.0139868045, -0.0005138345,
      -0.02534698, 0.016508369, 0.0009488701, -0.023902332, 0.000023880786,
      0.011918333, 0.0013330147, -0.0013839056, -0.017086228, -0.0053287754,
      0.016363904, 0.040844098, 0.013908005, 0.009324537, -0.0029418257,
      -0.021196904, 0.008083453, -0.024650922, -0.034802847, 0.013684742,
      0.008024354, 0.0043109567, 0.0040745595, 0.023429539, 0.013684742,
      0.004898665, 0.0072888983, -0.0035525172, 0.007538428, 0.0012008623,
      -0.027658414, 0.005847535, 0.0069014705, -0.0038250298, 0.005102229,
      0.008963374, -0.021945493, 0.013973672, -0.0050858124, -0.018399542,
      -0.036431357, -0.0040745595, -0.012121897, 0.0045933193, -0.005689937,
      -0.0070787678, 0.005913201, 0.004816583, 0.012075932, 0.01215473,
      0.009212905, 0.025964238, 0.009882695, 0.0021177204, 0.012811388,
      0.021630298, -0.007544995, -0.029969849, 0.006569858, -0.00038434984,
      -0.0014750169, 0.023534605, -0.011898634, -0.0067898384, -0.0019634059,
      0.0026528963, 0.0068358043, -0.0077485587, -0.003032116, -0.0037429477,
      0.00809002, -0.019358262, -0.0052631097, 0.019174399, -0.011773868,
      0.005745753, -0.024716588, -0.014026204, 0.030862903, -0.0037593641,
      -0.0036510157, 0.024637789, -0.033594597, -0.041632086, -0.0045375032,
      0.017165028, 0.019581527, 0.019896721, 0.006609258, 0.008385516,
      0.01215473, 0.011944599, -0.030442642, 0.013179116, -0.020369515,
      -0.0021620449, 0.025123715, -0.0032192634, -0.02367907, 0.003493418,
      0.009817029, 0.006848938, 0.019082466, 0.0059526004, -0.024151864,
      -0.018491475, 0.014590929, -0.024270061, 0.02705429, 0.0006225934,
      -0.015877979, 0.0059000677, 0.0016859681, -0.019936122, -0.03207115,
      -0.010263557, 0.010716651, 0.018241946, 0.0069540027, -0.031125566,
      -0.017362025, 0.016206307, -0.019739125, 0.031677157, -0.0026758793,
      -0.003493418, 0.02931319, -0.008917409, 0.040423837, -0.00012271287,
      0.018741004, -0.009173505, -0.020710977, 0.011990566, 0.008805777,
      -0.041211825, 0.017033696, -0.012010266, -0.003018983, 0.02952332,
      -0.013894873, 0.008214786, 0.048802786, 0.0019765391, -0.006057665,
      -0.02676536, 0.012798254, 0.022116225, -0.0022178607, -0.00979733,
      -0.004005611, -0.009600333, -0.013750408, -0.023508338, -0.017467089,
      0.007820791, -0.03903172, -0.012601257, 0.016035575, -0.005929617,
      0.0286828, 0.0017976, 0.0012886904, -0.027868545, -0.019922988,
      0.0006976986, -0.016193174, 0.020028053, -0.010519653, -0.009232604,
      0.021669697, 0.031414494, 0.022234423, 0.00261678, -0.015956778,
      -0.0111763105, -0.021617165, -0.005653821, -0.021144371, -0.025255047,
      0.022195023, 0.020592779, 0.027028022, 0.029996114, 0.0014347966,
      -0.0021210038, 0.013658476, -0.021157505, -0.013999938, -0.03002238,
      -0.034067392, 0.0067898384, -0.002442766, 0.006625674, 0.0054601072,
      -0.0016794015, -0.018110614, 0.025123715, -0.022667816, 0.016482104,
      0.01173447, 0.012699756, -0.03472405, 0.016127508, -0.017270092,
      0.01717816, 0.0024755988, -0.025189381, -0.0059558833, 0.0090159075,
      0.015694113, 0.0109202145, -0.022838548, -0.021498967, 0.006569858,
      -0.026870424, -0.008529981, -0.016206307, 0.012929586, -0.0048494157,
      0.0045998855, -0.023876067, -0.016797299, -0.0048329993, -0.009738231,
      -0.0010432646, -0.009876128, -0.017493356, 0.020395782, 0.00021095123,
      -0.031388227, -0.0071247336, -0.008595646, 0.016508369, -0.0030682322,
      0.012627523, 0.012364861, 0.0054469737, -0.014433332, -0.02534698,
      0.010959614, -0.00067758845, -0.012292628, 0.023574004, 0.03207115,
      -0.026331965, 0.005164611, 0.009856429, -0.006363011, -0.010112526,
      0.021236304, -0.004461988, 0.00833955, 0.00430439, 0.012266362,
      -0.007919289, 0.03848013, -0.0011384799, -0.009449301, -0.020816043,
      -0.0075909607, -0.021801028, 0.008076888, -0.003893979, 0.03165089,
      0.0030403242, -0.019266332, -0.02214249, -0.023994265, -0.021801028,
      0.036904152, -0.008044055, -0.0004539145, 0.00787989, 0.016298238,
      -0.001050652, -0.006714323, -0.010000894, -0.0023721752, -0.011058113,
      0.01646897, -0.016981162, 0.0077748247, 0.0039530783, 0.02952332,
      0.0013412229, 0.024690323, 0.003907112, 0.0035952, 0.002690654,
      -0.0040253103, 0.0139868045, 0.006576425, -0.033200603, -0.00064762845,
      -0.0038414462, -0.0028876513, 0.008989641, 0.009679131, 0.011530906,
      0.019043067, 0.009324537, -0.026922958, 0.0026611045, 0.013238215,
      0.01579918, 0.020855442, 0.03031131, 0.01788735, -0.019056201,
      -0.023074944, 0.009856429, -0.029733451, -0.011511206, 0.01073635,
      -0.024755988, -0.012095631, 0.049932238, 0.017913617, -0.019752257,
      -0.009239171, 0.005105512, 0.029076794, 0.008746678, -0.0068226713,
      0.006349878, -0.0027349785, -0.005115362, -0.01621944, -0.013881739,
      -0.040371303, -0.0027497532, -0.004652418, -0.01759842, -0.023232542,
      -0.01838641, -0.009239171, -0.002091454, 0.012732589, -0.039504513,
      -0.018150013, 0.008070321, 0.040345035, -0.0128245205, -0.020211918,
      -0.0049643307, 0.00045145204, -0.005177744, 0.025583375, 0.013711008,
      -0.016193174, -0.008956809, 0.021604031, 0.02026445, 0.029575855,
      -0.020408915, -0.012909886, 0.002373817, -0.02960212, 0.0043569226,
      -0.022392021, -0.031256896, 0.04565083, 0.0058147023, -0.04126436,
      -0.024375126, -0.021564633, -0.015234454, 0.02318001, 0.008779511,
      0.005082529, 0.009574066, 0.024046797, -0.006323612, 0.0017352174,
      0.007196966, -0.01934513, -0.012377994, 0.013001818, -0.022562752,
      -0.030048648, -0.037928537, -0.0090159075, -0.023941733, -0.010854549,
      0.052086074, -0.025438912, 0.005276243, 0.033830993, -0.004987314,
      -0.023954865, -0.0025330563, -0.014459598, 0.011590005, 0.00028810848,
      0.009646298, -0.023534605, 0.018859204, -0.011662237, 0.012515892,
      -0.027238153, -0.0016695517, 0.037928537, -0.013251348, 0.012890187,
      -0.016232572, -0.031493295, 0.0054338407, -0.022549618, 0.01959466,
      -0.0019551977, -0.027211886, 0.011274809, -0.00006792301, -0.023206277,
      -0.03911052, 0.015103122, -0.009245737, -0.0017696919, 0.00812942,
      -0.028656533, 0.0006825134, 0.005913201, 0.003516401, -0.0045407866,
      -0.004862549, -0.020422049, -0.011360175, -0.013934272, 0.014538397,
      0.02989105, -0.019489594, -0.010880815, -0.005414141, -0.014196935,
      -0.024861053, -0.041080493, 0.007059068, 0.0015612032, 0.023915466,
      0.01976539, -0.017020563, -0.019371396, 0.01884607, 0.00837895,
      0.008923976, 0.026253168, 0.24690323, -0.031178098, -0.0066946233,
      0.019909855, -0.012752289, 0.0042518573, 0.027001757, -0.009002774,
      -0.0008064575, -0.0020028055, -0.0023885916, -0.028236272, -0.014932391,
      0.0038742793, 0.0023771, -0.027238153, -0.0332794, -0.027973609,
      -0.026660295, -0.022234423, 0.013711008, 0.002111154, 0.026450165,
      0.0031601642, 0.023836667, 0.03159836, -0.011977432, -0.004580186,
      0.014669728, 0.0119249, -0.010191324, 0.0013921139, 0.016534636,
      -0.0015053874, 0.0055093565, 0.01265379, 0.04244634, -0.01951586,
      0.01767722, -0.008070321, 0.013921139, 0.017151894, 0.014840459,
      0.014525264, -0.026712827, -0.00012732999, -0.018084347, -0.024020532,
      0.0058836513, 0.020251317, -0.026345098, -0.0036181828, 0.03010118,
      0.036877885, -0.005266393, -0.02647643, -0.013205382, 0.01721756,
      -0.006842371, 0.009941795, 0.0030468907, 0.017270092, 0.014420198,
      0.03165089, 0.0027251286, 0.0011713128, -0.013868607, -0.039583314,
      0.0026020054, -0.015392052, -0.012180996, 0.0019141566, 0.025360113,
      0.03648389, -0.03932065, -0.007728859, -0.008779511, 0.006848938,
      0.014906125, 0.029444521, -0.00833955, 0.030442642, -0.0016022443,
      -0.011150044, -0.009803897, -0.021669697, 0.014367666, 0.012213829,
      -0.025543977, -0.01618004, -0.0024493325, 0.017847952, -0.025149982,
      0.006133181, -0.004704951, -0.015221321, 0.012955853, 0.04097543,
      -0.008950242, 0.011642537, -0.012817955, 0.02810494, 0.0056111384,
      -0.0066486574, -0.017742885, 0.0051219286, 0.014315133, 0.01988359,
      0.0109202145, -0.03207115, 0.0032619461, -0.02960212, 0.0056242715,
      -0.0032061301, -0.0004015871, 0.003281646, -0.004931498, -0.0039202454,
      0.014512131, 0.015733514, 0.0018813238, -0.009862996, -0.014643462,
      0.0028088524, 0.011826402, -0.027868545, -0.02109184, 0.009324537,
      0.008201652, -0.028367603, 0.01173447, 0.0010325939, 0.021604031,
      0.013126584, -0.026200633, 0.023797268, -0.030206244, -0.0069343033,
      -0.008175386, -0.032596476, 0.00014169437, -0.0013313731, -0.012121897,
      0.0013313731, 0.009672565, -0.018938003, -0.0061069145, -0.014919259,
      0.020697845, -0.022786016, -0.027527083, 0.00406471, -0.007013102,
      -0.0028285522, 0.013553411, 0.0008511923, -0.026056169, -0.044784043,
      0.0052696764, -0.003253738, -0.012502759, 0.0017352174, 0.023035545,
      0.008884576, -0.0054305573, -0.021590898, -0.16621314, -0.007334864,
      0.010637851, -0.022129357, 0.017861083, 0.02293048, 0.009941795,
      0.017913617, -0.013829207, -0.007886456, 0.015523383, -0.004816583,
      -0.028840397, -0.022825414, -0.033883527, -0.032859143, -0.030363843,
      0.0116294045, 0.025977371, 0.017939882, 0.03380473, -0.033830993,
      0.00013358876, 0.0032520962, -0.00042190246, -0.023534605, -0.031677157,
      0.019213798, -0.013645343, -0.0032094135, -0.016022444, 0.03627376,
      0.016560903, 0.0010449062, 0.0024411243, -0.01855714, 0.021275703,
      -0.013080617, -0.02497925, 0.0309417, 0.014249467, 0.015733514,
      -0.0073151644, 0.031151831, -0.019909855, 0.023271943, 0.033962324,
      -0.0022474104, -0.0031092733, 0.00549294, 0.012693189, -0.008044055,
      -0.0075318613, 0.013829207, 0.015129388, 0.011268242, 0.00097513635,
      0.023271943, -0.023757868, 0.0031535977, -0.020592779, -0.015418318,
      0.011353608, 0.01240426, -0.006494343, 0.0013830848, -0.015352652,
      0.010946481, -0.021236304, 0.012778555, 0.004681968, -0.02197176,
      0.000010427003, -0.022273822, 0.01654777, 0.013500879, -0.018504608,
      0.0068029715, -0.004386472, 0.011038412, 0.013737275, 0.0119643,
      -0.02279915, 0.0108348485, -0.008805777, 0.013999938, 0.0027645282,
      0.012535592, -0.052480068, 0.014485864, 0.007564694, -0.017401423,
      0.011452107, -0.026949223, 0.012391127, 0.0074530626, 0.03130943,
      0.008234485, 0.0132776145, -0.019699724, 0.03514431, -0.015628448,
      -0.039136786, 0.037954804, 0.030600239, 0.012029965, 0.0032422463,
      0.029339457, 0.02668656, -0.016377037, 0.010453987, 0.016074976,
      0.00023701231, 0.034750316, 0.017112494, 0.022024292, 0.020185651,
      -0.021065572, 0.011386441, -0.0011959375, 0.048881583, -0.027763478,
      0.012148163, -0.0013691309, -0.0045178034, -0.017847952, -0.07921916,
      -0.008273885, 0.0043963217, -0.0077945245, 0.010145359, 0.0086481795,
      0.008891143, 0.0054469737, -0.013310447, 0.0116294045, -0.010605019,
      -0.01596991, 0.0035459504, 0.003870996, -0.013074051, -0.009554367,
      -0.003078082, -0.016337639, -0.008070321, 0.013921139, 0.031493295,
      -0.004672118, -0.017204426, -0.0007137046, -0.031335697, 0.021433301,
      -0.007833924, 0.012601257, 0.02563591, 0.008221352, -0.013947405,
      -0.013645343, -0.007787958, -0.006980269, -0.027211886, -0.009659432,
      -0.01575978, -0.005824552, 0.0023393424, -0.020238183, 0.011018713,
      -0.016495237, 0.022825414, -0.029943582, -0.00404501, 0.010716651,
      -0.020080587, 0.023258809, 0.020986773, 0.0053583253, -0.04465271,
      -0.0067504393, -0.040949162, -0.0008212323, 0.008950242, -0.024821654,
      -0.021801028, 0.019988654, -0.033778463, 0.02584604, -0.008438049,
      0.0028745183, -0.030416375, 0.023626536, 0.024191262, 0.02497925,
      0.0058573848, -0.018044949, 0.0027119955, -0.016587168, 0.011018713,
      0.0053714584, -0.008805777, 0.031861022, -0.033489533, -0.014709128,
      -0.022759749, -0.016403304, 0.010703517, 0.030416375, -0.013474612,
      -0.027605882, 0.002285168, -0.012706323, -0.017664086, 0.033016738,
      -0.0067438725, 0.012167864, 0.015536516, -0.01102528, -0.016665967,
      0.0014717336, -0.002547831, -0.00998776, -0.033016738, -0.01547085,
      0.007787958, -0.0040220274, 0.02989105, -0.0028761597, -0.038585193,
      -0.004406172, -0.06692653, 0.013369546, -0.012010266, -0.008674446,
      -0.005581589, -0.026831025, 0.029208126, -0.009882695, -0.017624687,
      -0.012561858, -0.0071641332, -0.0014413632, -0.009495268, -0.013724142,
      -0.016127508, -0.012266362, 0.025071183, 0.0020028055, 0.008260751,
      0.0065238923, -0.015483984, -0.014459598, -0.022536485, -0.007847057,
      -0.026634028, 0.009981194, -0.008851743, 0.02668656, -0.01313315,
      -0.013619076, -0.0125093255, -0.021643432, 0.0033620864, 0.029864782,
      -0.0028269105, 0.003179864, 0.014840459, 0.025885439, -0.005335342,
      0.044390045, 0.0013674892, -0.0123385945, 0.011649104, -0.02168283,
      0.012062798, 0.010355488, -0.008687579, 0.0010481895, 0.013297315,
      -0.0014347966, 0.019542128, 0.030337576, 0.010808582, -0.014472731,
      -0.0028121357, -0.036457624, -0.0063827108, -0.022786016, 0.03165089,
      -0.034829114, 0.017033696, -0.01930573, -0.023823533, -0.0144989975,
      -0.0066289576, -0.016140642, -0.0044784043, 0.0053583253, -0.0016498519,
      -0.025649041, -0.019371396, 0.029024262, 0.0042321575, 0.00856938,
      0.01315285, 0.009665999, -0.0060281157, 0.009679131, 0.0016859681,
      0.02176163, 0.023245675, -0.005594722, -0.0336734, 0.015746647,
      0.026345098, 0.037035484, 0.003266871, -0.01238456, -0.008674446,
      -0.005171178, -0.0012501117, 0.014420198, -0.0040417267, 0.004501387,
      0.007919289, 0.0066420906, 0.02072411, -0.033962324, -0.004222308,
      0.015168788, 0.018412676, 0.010014026, 0.01884607, -0.03414619,
      -0.019620925, 0.013008385, -0.02185356, -0.031808488, 0.010979313,
      0.022076825, -0.008641613, 0.0091866385, 0.040765297, 0.013605943,
      -0.007906157, 0.018465208, 0.015996177, -0.028604, -0.03606363,
      0.026134968, 0.014367666, -0.007059068, 0.01930573, -0.000039502054,
      0.012003699, 0.0049511977, 0.01813688, -0.005456824, 0.0035919165,
      0.012588124, -0.0071444334, -0.026673427, -0.05557949, -0.015326386,
      0.020789776, 0.006980269, -0.00022203232, 0.0071181674, -0.0070065353,
      0.09597706, 0.0016030651, 0.00073012104, 0.010112526, 0.02939199,
      0.013238215, 0.02789481, -0.0056177047, -0.01476166, -0.0017779002,
      0.014932391, 0.004481687, 0.011084379, -0.041658353, 0.0028564602,
      0.015168788, -0.018622806, 0.007958689, -0.03756081, -0.010742917,
      0.018307611, 0.012062798, -0.016889231, -0.007820791, -0.013724142,
      0.009514967, 0.022746615, -0.012187563, 0.012837654, -0.016482104,
      0.004337223, 0.020395782, -0.0053747417, -0.031467028, -0.020316983,
      -0.013500879, 0.0013559978, -0.008431482, -0.009107839, 0.021026174,
      -0.007919289, 0.025176248, -0.0452831, -0.020382648, -0.024703454,
      -0.016665967, 0.0153789185, -0.0026233466, -0.045099236,
    ],
  },
  {
    id: "faq-4",
    question: "Γιατί να εγγραφώ στο carespot.gr ;",
    answer:
      "Κάνοντας εγγραφή στο carespot.gr δημιουργείτε το δικό σας λογαριασμό στον οποίο έχετε αποκλειστικά και μόνον εσείς πρόσβαση ώστε να μπορείτε να κάνετε πιο εύκολα και γρήγορα τις αγορές σας. Με την εγγραφή σας συμμετέχετε στο Care-δίζω, το πρόγραμμα επιβράβευσης του carespot.gr και συλλέγετε πόντους ώστε να βγαίνετε κερδισμένοι σε κάθε αγορά. Η διαδικασία εγγραφής είναι πολύ γρήγορη και την κάνετε μόνο μία φορά. Απλά πατάτε στο πάνω δεξί μέρος της αρχικής σελίδας στο ανθρωπάκι, κάνετε εγγραφή πολύ γρήγορα και κερδίζετε αμέσως 1000 πόντους για την εγγραφή σας!",
    embedding: [
      0.018440641, -0.0071867174, 0.010529988, -0.061495133, -0.04046148,
      0.007265692, -0.02525881, 0.0047582383, 0.019638428, -0.024455898,
      0.030694917, 0.023982048, -0.016308319, -0.021968188, 0.0072327857,
      -0.019414667, 0.018624917, 0.0003337924, 0.008009372, -0.00034736624,
      0.009147927, 0.016347807, -0.017993119, -0.0011443136, -0.006051453,
      0.0036789146, -0.0038171208, -0.019480478, 0.0222446, -0.017282344,
      0.00651214, -0.017808843, 0.006587824, -0.0047746915, -0.013952235,
      -0.0027131173, 0.011944956, -0.026864633, 0.026206508, -0.019401504,
      0.015637033, 0.0045410576, -0.0040803705, -0.00471217, -0.007825098,
      0.000014576424, -0.018730218, -0.011148625, 0.017466618, 0.022915887,
      -0.000120519006, 0.015860796, -0.042804405, -0.02038869, 0.032274414,
      -0.008924165, 0.02223144, 0.02017809, 0.01361001, -0.026509246,
      0.01636097, 0.009759983, -0.023982048, -0.003797377, -0.0059658964,
      0.005847434, -0.010332551, -0.013044023, -0.018466968, -0.0071209045,
      0.00794356, 0.010891957, 0.0008876451, -0.011944956, 0.0039388738,
      -0.0149262585, 0.009779727, -0.002255721, -0.016611056, 0.013728472,
      0.0001285399, -0.015176346, -0.024218973, 0.006403549, 0.024877097,
      0.019427828, 0.009852121, 0.017479781, -0.023218624, -0.013846935,
      0.024758635, 0.009615196, 0.018769704, -0.0016066459, -0.00006740632,
      0.0018657823, -0.002002343, 0.023797775, -0.008989978, -0.013248042,
      -0.007127486, 0.015321134, 0.00044176594, -0.010852469, -0.022915887,
      -0.036144186, 0.014044372, 0.0022491398, 0.011299994, -0.0057684593,
      0.01329411, 0.044620827, -0.0076342416, -0.023758287, 0.015136858,
      0.010352295, 0.010411526, 0.00010077528, -0.037513085, -0.011339481,
      0.0102338325, 0.006252181, 0.015176346, -0.011852819, 0.0024893552,
      0.015531733, -0.008654335, -0.010457595, -0.020507153, -0.018980304,
      0.015505408, -0.010944607, -0.014439247, 0.010286483, -0.008140997,
      0.009365109, -0.029194392, 0.014886771, -0.043883726, -0.019954327,
      0.012978211, 0.01583447, -0.007811935, -0.012668893, 0.002027023,
      -0.0055479878, 0.006597696, 0.024929747, -0.007910654, 0.007700054,
      0.005962606, -0.0024712568, 0.01646627, -0.0046726824, -0.007456548,
      0.013965397, 0.0032955573, 0.013392829, 0.008502966, -0.019572616,
      0.010622126, 0.013280948, 0.015268483, -0.015505408, -0.023231788,
      0.03456469, 0.005755297, 0.008641172, 0.0007695941, 0.013531035,
      -0.0049688383, 0.030510642, -0.010990676, 0.023389736, -0.011299994,
      0.023310762, -0.010602382, -0.0041856705, -0.011767263, 0.002997756,
      -0.051175743, -0.017650893, 0.013886422, 0.040066607, -0.0050708475,
      0.0011739292, 0.026035396, -0.0041428925, 0.001260308, -0.0022063616,
      -0.002007279, 0.0015309616, -0.006015256, -0.012846586, -0.6322205,
      -0.006215984, 0.010089045, -0.016611056, 0.026561895, 0.033064164,
      0.008542453, -0.007397317, -0.0125701735, 0.029641917, 0.0006828039,
      0.006048162, -0.0060020937, 0.0032001294, -0.0061732056, -0.015136858,
      0.023073837, 0.023468712, -0.0030701498, 0.02785182, -0.025061373,
      0.0012306924, -0.01032597, 0.031326715, 0.018756542, 0.030879192,
      0.0022623022, -0.015228996, -0.0011689933, 0.016874306, 0.00624889,
      0.02722002, -0.0033959213, -0.003356434, 0.040066607, 0.009779727,
      -0.017650893, 0.051649593, 0.015018396, 0.042830728, -0.034406736,
      -0.015531733, 0.035617687, -0.01959894, -0.013754797, -0.01980954,
      0.014913096, 0.0034123743, 0.03419614, 0.003754599, 0.005067557,
      0.0061402996, -0.016492594, -0.015926607, 0.023679312, -0.0049787103,
      0.009720496, -0.029668242, -0.0060777776, 0.018124742, 0.005113626,
      -0.006146881, -0.020572966, -0.046937425, -0.00051950687, -0.0055479878,
      -0.014741984, -0.012932142, 0.017835168, 0.0017785808, -0.0010497082,
      -0.009082115, -0.008989978, 0.012747867, 0.0038138302, 0.0114382,
      -0.0056960657, -0.023113325, -0.013215136, 0.009253227, -0.010293064,
      -0.004294261, -0.029247044, 0.019901678, 0.019506805, -0.0005532357,
      -0.014794634, -0.0133270165, 0.004705589, 0.019283041, 0.030773891,
      0.022613151, 0.009838958, 0.011359225, -0.0049293507, 0.006798424,
      -0.0058704684, 0.005024779, 0.00439627, 0.011063069, -0.0055776034,
      -0.011135463, -0.005752006, -0.0050741383, 0.009555965, 0.0064430367,
      -0.0067786803, 0.005041232, 0.048885472, -0.032485016, -0.008187066,
      0.001064516, -0.0029632046, -0.007206461, 0.009404596, -0.030747566,
      0.011859399, 0.0008263573, -0.015492246, 0.0065812427, -0.00325607,
      0.017440293, 0.0065812427, -0.019230392, -0.002076382, 0.02700942,
      0.0041165673, -0.02181024, -0.039592758, -0.029878842, -0.02044134,
      -0.021191603, 0.019928003, -0.010365457, 0.03722351, -0.020336041,
      0.0053308066, -0.00868724, 0.03245869, 0.011273669, -0.007213042,
      -0.005804656, 0.005337388, -0.014557709, -0.0020533476, -0.030931842,
      0.004705589, 0.0019200776, -0.018190555, 0.038618732, 0.01615037,
      -0.0032610057, 0.0020878993, 0.023442388, -0.025930097, -0.01567652,
      -0.0067293206, 0.003649299, -0.01890133, -0.010477339, 0.00889784,
      0.017084906, -0.028562594, -0.01779568, -0.03230074, 0.008957071,
      0.006772099, 0.0012948596, 0.0034913493, -0.03590726, 0.011017,
      -0.003029017, -0.010734007, 0.016176695, 0.014215484, 0.00820681,
      0.007752704, 0.012866329, 0.02706207, -0.01620302, -0.008055441,
      0.0005766814, -0.025719497, 0.00020463552, 0.014755147, 0.0039322926,
      0.0105497325, 0.042277902, -0.03627581, 0.01795363, 0.010503664,
      -0.018835517, 0.000019820853, 0.0449104, 0.014004884, -0.01620302,
      0.018019443, 0.031747915, 0.0034913493, 0.026298646, 0.030168418,
      -0.003797377, 0.042277902, -0.012701798, 0.013241461, -0.015426433,
      -0.002451513, -0.005093882, 0.028720543, 0.0024054442, -0.006758936,
      -0.010089045, -0.005209054, 0.007568429, 0.008937328, 0.021902377,
      0.0073841545, -0.008904422, 0.010036395, 0.0044193044, -0.0034617337,
      -0.0006548337, -0.0075223604, -0.0074104792, -0.023942562, 0.029247044,
      0.010470757, 0.023218624, 0.0026127533, -0.01350471, -0.06375908,
      0.0058967937, -0.02611437, 0.03235339, 0.03332741, -0.03324844,
      0.0021421944, -0.0019464026, 0.01032597, -0.0030997654, 0.015426433,
      0.01800628, 0.026601383, -0.0012060128, 0.003071795, -0.00095757085,
      0.016281994, 0.0045739636, -0.021652289, 0.0061238464, -0.0242453,
      -0.0002638667, 0.0020648648, -0.012682055, 0.027720194, -0.02625916,
      0.003965199, 0.016558407, 0.000109516, 0.032642964, 0.016953282,
      0.0014495187, -0.00013368149, -0.008160741, 0.0023412772, 0.017808843,
      -0.01837483, -0.004198833, -0.012635986, -0.017690381, -0.0096875895,
      -0.0036163928, -0.0038204114, 0.01567652, -0.0119383745, -0.023718799,
      0.016505757, -0.002377474, 0.008259459, -0.002556813, -0.014202322,
      -0.03951378, 0.010049558, 0.014992071, -0.007752704, -0.046911098,
      -0.026232833, 0.018453805, -0.010174601, 0.010543151, 0.0011072941,
      0.0022261054, -0.00804886, -0.0028249985, 0.00022890385, -0.009259809,
      0.05214977, -0.01312958, -0.0031326716, -0.0067260303, 0.004468664,
      -0.002181682, 0.01260308, -0.021704938, 0.04369945, 0.008002792,
      -0.031853214, 0.0020796726, -0.004399561, -0.030326366, 0.010352295,
      -0.0035143837, -0.0052287974, 0.000048793747, 0.013471804, -0.007673729,
      -0.007423642, 0.005363713, 0.009200578, -0.0016658771, -0.015084209,
      -0.01376796, -0.013373085, 0.0054196534, 0.049806844, 0.02674617,
      0.010398364, 0.014478734, -0.014689334, -0.007403898, -0.010661613,
      -0.047227, 0.0007568429, 0.010372039, -0.010372039, -0.0031227998,
      0.013261205, -0.013300692, 0.019993816, 0.00942434, 0.018519618,
      0.020204416, 0.02017809, -0.01609772, 0.0035078025, -0.0041856705,
      -0.015479083, 0.024956074, -0.0002132734, -0.018611755, 0.0027558955,
      0.0016107592, 0.00524196, -0.011635637, 0.013570523, -0.0024070896,
      -0.006222565, 0.0060975216, -0.02748327, 0.01863808, -0.0049063168,
      0.029510293, 0.0045772544, 0.01730867, 0.021244252, 0.018940818,
      0.0071801357, 0.017453456, 0.008015954, -0.016953282, 0.0054887566,
      0.03182689, -0.005209054, -0.0013697211, 0.029404992, -0.020836215,
      -0.004955676, -0.017874656, 0.01662422, -0.0110762315, 0.0025535221,
      0.0009912997, 0.010490501, -0.020717753, -0.031142442, -0.017032256,
      0.0009485216, -0.002282046, -0.013300692, -0.02044134, -0.020362366,
      -0.001064516, -0.010944607, 0.012688636, 0.036933936, -0.034801614,
      -0.017216532, -0.026575059, 0.02589061, 0.031642616, 0.019625267,
      0.0079830475, 0.0065417555, 0.022415712, -0.00044505653, -0.022626312,
      0.0036196834, -0.029747218, -0.0063212835, 0.0028562595, 0.009490152,
      -0.033695962, 0.010859051, 0.030694917, 0.010062721, 0.022047164,
      0.005060976, -0.014491897, -0.018177392, 0.0071867174, -0.00006498977,
      -0.0008160741, -0.0029697858, -0.0059823496, 0.018532779, 0.007555267,
      -0.022099813, -0.013491548, 0.003728274, 0.0228764, 0.008628009,
      -0.015873957, -0.013649497, -0.016163532, 0.03464366, -0.0065055587,
      0.038302835, -0.008904422, -0.018559104, 0.01064187, 0.014452409,
      0.02362666, 0.013583685, -0.00471217, -0.0043304577, -0.03659171,
      0.03082654, 0.023745123, -0.00022623022, 0.011135463, -0.0055249534,
      -0.009398014, 0.015808145, -0.01069452, -0.0086148465, 0.03780266,
      -0.009950839, -0.011925212, -0.02753592, 0.007818516, -0.005788203,
      0.02790447, -0.0056829033, 0.003029017, -0.029168067, 0.0062324367,
      -0.007805354, -0.003468315, 0.006735902, -0.034827936, 0.0011344417,
      -0.02404786, 0.015228996, 0.016400456, -0.0062620523, -0.0054295254,
      -0.045937076, -0.015334296, 0.019928003, -0.0119383745, 0.015755495,
      -0.004603579, 0.013952235, 0.010562895, 0.023955723, 0.015071046,
      0.013952235, -0.0050445227, 0.0016592959, -0.011721194, -0.016847981,
      -0.010464177, -0.01779568, 0.016137207, 0.01191205, 0.020572966,
      0.0141891595, 0.0015408335, -0.013965397, -0.0025436503, -0.017598243,
      -0.012899236, -0.02652241, -0.03919788, -0.0074828733, -0.008542453,
      0.021573314, 0.009279552, -0.006561499, -0.012899236, 0.016913794,
      -0.011569825, 0.015966095, -0.0010562894, 0.016992768, -0.023982048,
      -0.00277893, -0.013688985, -0.017361319, -0.012543849, -0.0036295555,
      -0.008437153, -0.007660567, 0.018085254, 0.0006433165, 0.0051070442,
      -0.01737448, 0.005110335, -0.028509945, -0.0143997595, -0.0016642318,
      -0.000110544315, -0.006255471, -0.009641521, -0.022902725, -0.005209054,
      -0.022415712, -0.009608614, -0.010457595, -0.0069761174, -0.025732659,
      0.019454153, -0.0004668569, -0.0075815916, -0.0079633035, -0.017058581,
      0.026706683, 0.015321134, 0.03156364, 0.014386597, 0.01954629,
      -0.030905517, -0.006492396, 0.0044884076, -0.014584034, -0.0072262045,
      0.034933235, 0.008456897, -0.03177424, -0.0038928052, 0.021481177,
      -0.0147156585, -0.01588712, 0.013702148, -0.0057487157, 0.026496084,
      -0.008345016, 0.02049399, -0.009384852, 0.033011515, -0.0024070896,
      -0.0016609412, -0.009615196, -0.012787355, -0.007127486, 0.018111581,
      -0.0041593453, 0.03685496, -0.009740239, -0.0047352044, -0.008246297,
      -0.036933936, 0.0063344464, 0.022955375, 0.012076581, 0.008338435,
      0.007403898, -0.0024679662, 0.008009372, -0.010082464, 0.011497431,
      0.00025728546, -0.011721194, 0.024376923, 0.008305528, -0.020941515,
      0.0116224745, 0.022626312, 0.010832726, 0.02674617, 0.011536919,
      0.015097371, -0.0026440143, -0.031300392, 0.0025025175, -0.010734007,
      -0.023758287, -0.002372538, -0.0023001444, -0.0027246345, -0.0047845636,
      0.013965397, 0.023429224, 0.0106550325, -0.009727077, 0.0038960958,
      -0.0034189557, 0.0030043374, -0.002704891, 0.038408134, 0.015360621,
      0.020638777, -0.02568001, -0.0035472899, 0.014320784, -0.015926607,
      -0.006745774, 0.019190904, -0.004468664, -0.038960956, 0.051649593,
      0.016769007, -0.00060958765, -0.019612104, -0.0029368796, 0.002695019,
      0.002102707, 0.0128005175, 0.013583685, 0.029457644, -0.0032955573,
      -0.020296553, -0.0063147023, -0.021849725, -0.030142091, -0.009661265,
      -0.025219323, -0.025140347, -0.001329411, -0.010529988, 0.02128374,
      0.017690381, -0.025956422, -0.018309018, 0.0108656315, 0.024982398,
      -0.019888517, -0.029641917, 0.008871515, -0.035986237, -0.025390435,
      0.041014306, 0.010082464, -0.030984491, -0.0011673479, -0.001236451,
      -0.004952385, 0.008081766, 0.0058967937, 0.0020484119, -0.00053925056,
      -0.015597546, -0.012306924, 0.016926957, -0.041145932, 0.026403947,
      0.018967142, -0.032063816, -0.023850424, -0.009016302, -0.016782168,
      0.008377922, -0.0014305976, 0.02404786, 0.0032264544, 0.017269181,
      0.0011541855, 0.008476641, 0.0059494437, 0.012333249, -0.020401852,
      0.0103193885, -0.011892306, -0.01361001, -0.028878493, -0.007976466,
      -0.04622665, -0.004399561, 0.051175743, 0.0070879986, 0.008943909,
      0.033590663, -0.022270925, -0.013636335, 0.009378271, -0.013136161,
      -0.015571221, 0.010852469, -0.011128882, -0.038092233, 0.018493293,
      0.0016214537, 0.023692474, -0.022836912, -0.016492594, 0.015426433,
      -0.011023582, 0.00016864434, -0.017808843, -0.016953282, 0.01090512,
      -0.022376226, 0.010154857, 0.027772844, -0.0102140885, 0.024600687,
      0.0055216625, -0.019098766, -0.029641917, 0.006199531, -0.0042383205,
      -0.02159964, 0.0010579348, -0.032063816, 0.0038401552, -0.014623522,
      0.019230392, -0.006903724, 0.0066964147, -0.032642964, -0.008575359,
      0.017453456, 0.01683482, 0.022284089, -0.040171906, -0.0052880286,
      0.018453805, 0.0042712265, -0.023468712, -0.033959214, -0.01578182,
      -0.0055776034, 0.0013187166, 0.022139302, 0.011694868, -0.007923816,
      -0.0134323165, 0.0063245744, 0.0041396017, 0.006752355, 0.23839894,
      -0.02044134, -0.0057158093, 0.02674617, 0.0049918727, -0.0055841845,
      0.02117844, -0.0037644708, 0.0070287674, 0.00092055136, 0.0022919178,
      0.009088696, -0.018453805, -0.0060251276, -0.013445479, -0.041593455,
      -0.037565734, -0.021849725, -0.030352691, -0.005544697, 0.0011657026,
      0.008127835, 0.005501919, 0.0049359323, 0.0094769895, 0.004215286,
      -0.016439945, 0.015005234, 0.02785182, 0.021349551, -0.010062721,
      0.006156753, -0.01392591, -0.0022063616, -0.02404786, 0.0013080221,
      0.033827588, -0.012938723, 0.013248042, -0.00566645, -0.007127486,
      0.007127486, -0.008917584, 0.023745123, -0.03151099, -0.005702647,
      -0.0027888017, -0.019704241, -0.0015770303, 0.03140569, -0.009878445,
      0.0055841845, 0.015097371, 0.011089394, 0.0005203295, 0.0049063168,
      0.0049490947, 0.008542453, -0.010069301, 0.009128184, 0.025851121,
      0.011089394, 0.0038401552, 0.019625267, -0.016374132, 0.0066700894,
      -0.020336041, 0.003501221, 0.0071932985, -0.00852271, 0.020401852,
      0.008127835, 0.029562943, 0.033511687, -0.025192998, -0.004208705,
      -0.018348506, 0.039224207, 0.023508199, 0.020717753, -0.0031606418,
      0.026377622, -0.008075185, -0.011793587, 0.003527546, -0.024153162,
      0.011675125, 0.005360422, -0.013623172, -0.0058803405, 0.012886073,
      0.01980954, -0.02467966, -0.023942562, 0.0012430323, -0.010082464,
      0.013379667, 0.03135304, -0.01530797, 0.024692822, -0.032616638,
      0.028404644, -0.009358527, 0.014386597, -0.010845888, 0.008187066,
      -0.002573266, 0.03901361, -0.0034617337, -0.023416063, -0.0068905614,
      -0.030773891, 0.008127835, -0.015952932, -0.007153811, 0.0063344464,
      0.0014256617, 0.0025058081, 0.030563291, -0.0015046366, 0.008588522,
      -0.013280948, -0.0047911447, 0.014333947, 0.0055776034, -0.030536966,
      -0.018019443, 0.0030306624, 0.003961908, -0.05470329, 0.01048392,
      -0.009588871, 0.010141695, 0.012221368, -0.0022228148, 0.004570673,
      -0.010832726, -0.02362666, -0.00952964, 0.026338134, -0.013090092,
      -0.009042627, -0.013392829, -0.022731613, 0.008996559, -0.0018953979,
      -0.0054986286, 0.008246297, -0.006206112, 0.0012142393, -0.010102208,
      0.0019694369, -0.011885725, 0.0003459266, 0.034959562, -0.011787006,
      -0.037355132, -0.037486758, 0.0029056186, 0.0079633035, -0.031958517,
      0.001720995, 0.016821656, -0.013899585, -0.024021536, -0.017492943,
      -0.16795331, 0.010187764, -0.0008345838, -0.034538362, 0.018032605,
      0.027246345, 0.021428527, 0.010121952, -0.0061402996, -0.0073578293,
      0.038803007, 0.00038829332, -0.047753498, -0.00025461183, -0.009615196,
      -0.010687938, -0.032748263, 0.03780266, 0.046832126, 0.011109138,
      0.030247392, -0.048279997, 0.015571221, 0.005307772, 0.015268483,
      -0.009246646, -0.025245648, 0.005919828, -0.006735902, -0.019796379,
      -0.010615544, 0.022994863, 0.035196487, 0.01704542, -0.0018147777,
      -0.021941863, 0.01884868, -0.02250785, -0.002398863, 0.011510594,
      0.015176346, 0.006350899, -0.027246345, 0.029773543, -0.0009970582,
      0.02722002, 0.035722986, 0.008542453, 0.0020796726, -0.00630154,
      0.012833424, -0.026035396, 0.0113329, 0.028694218, 0.030484317,
      0.005544697, 0.0013960461, 0.025482573, -0.02758857, -0.007871167,
      -0.025706334, -0.01376796, 0.0013228299, -0.015479083, -0.0031803856,
      -0.01371531, 0.0053143534, -0.0048964447, -0.010286483, 0.009049209,
      -0.004320586, -0.0218234, 0.0062390184, -0.03103714, 0.025614196,
      0.018782867, -0.027930794, 0.0020747369, -0.010437851, -0.0049326415,
      0.0023857006, 0.026298646, -0.026377622, 0.00095757085, -0.020257065,
      -0.005199182, 0.011773843, -0.0010028168, -0.037986934, -0.018875005,
      -0.014794634, -0.01911193, -0.019256717, -0.015018396, 0.034406736,
      0.00055652636, -0.0037776334, 0.02695677, 0.011161787, 0.005913247,
      0.019348854, -0.02028339, -0.037907958, 0.019730566, 0.03664436,
      0.016387295, 0.012451711, 0.025719497, 0.02700942, -0.00460687,
      -0.007640823, 0.010299645, 0.028509945, 0.029299693, 0.003504512,
      0.0046364856, -0.019085605, -0.03906626, 0.008799122, -0.007818516,
      0.04046148, -0.039434806, -0.00006046517, -0.0059691872, -0.009305878,
      -0.0060975216, -0.052307718, -0.018980304, -0.016940119, 0.0045311856,
      0.025666848, 0.008068603, -0.00003869051, 0.029089093, -0.011457943,
      0.012372737, -0.0025930097, -0.045357924, -0.003823702, -0.009167671,
      -0.012050255, 0.012971629, 0.0039158394, -0.030352691, -0.01737448,
      0.01933569, 0.013438898, -0.01615037, -0.017545594, 0.00017481427,
      -0.039039932, 0.03293254, 0.0042350297, 0.001649424, 0.02811507,
      0.0060020937, -0.014768309, -0.005603928, 0.006360771, -0.0041066953,
      -0.0035933587, -0.013188811, -0.016426781, -0.024284786, -0.0048734103,
      -0.014649847, 0.015044721, -0.014886771, 0.036223162, -0.022994863,
      -0.022139302, 0.013517872, 0.009299296, 0.0029681404, 0.010062721,
      -0.020941515, -0.030510642, -0.014834121, -0.036196835, -0.013215136,
      0.009246646, -0.009450665, -0.0014964101, 0.014360271, -0.01690063,
      0.008832028, -0.011365807, -0.024363762, -0.029115418, 0.013741635,
      0.023955723, 0.0062192744, -0.015334296, -0.03659171, 0.010424688,
      0.011708031, 0.002525552, -0.003405793, -0.0043666544, 0.023258112,
      -0.031484667, -0.011708031, -0.018532779, 0.003124445, 0.010938026,
      0.00052444276, -0.018769704, -0.0238241, 0.01562387, -0.024416411,
      -0.013228298, 0.016479433, 0.0019085604, 0.010740588, 0.016782168,
      -0.026864633, -0.0064002587, 0.0053735846, 0.00942434, -0.00021923766,
      -0.016992768, -0.0067786803, -0.0019924713, -0.022468364, 0.016926957,
      -0.02070459, -0.014952583, -0.020823052, -0.07039297, 0.00092960056,
      -0.016439945, 0.0059494437, -0.0017769355, -0.029299693, 0.0129058175,
      -0.0011607667, -0.026561895, -0.01022067, -0.012346411, -0.014123347,
      0.018559104, -0.00064537313, -0.025877446, -0.017690381, 0.028904818,
      0.016847981, 0.032379713, 0.030221067, -0.014873609, -0.004909607,
      -0.006209403, 0.00021512437, -0.01842748, 0.044094328, 0.0036295555,
      0.018190555, -0.013438898, -0.010200926, -0.0040211393, 0.0028299345,
      0.00810151, 0.01239248, -0.000096250675, -0.009996908, -0.013386248,
      0.026140697, -0.0075486857, 0.021625964, -0.011069651, -0.014228647,
      0.024587523, -0.02578531, 0.0029171358, -0.0056730313, 0.0050543947,
      -0.002759186, 0.01201735, -0.0020303135, -0.0025716207, 0.013636335,
      0.01737448, -0.0063870964, -0.00018859374, -0.017334994, 0.010227252,
      0.0020451213, 0.016110882, -0.047490247, 0.0143997595, 0.0014635039,
      0.008891259, -0.030484317, 0.012109486, 0.0021224509, -0.013169067,
      -0.019717403, -0.0066207303, -0.025758984, -0.024969235, 0.016782168,
      0.012372737, 0.03151099, -0.0057750405, 0.025824796, 0.008331853,
      -0.016716357, -0.013096673, 0.0004520491, -0.01281368, 0.0074631292,
      -0.034880586, 0.024587523, 0.01228718, 0.032432366, 0.01609772,
      -0.0060251276, -0.014215484, 0.0018246495, -0.0063476088, 0.022797424,
      -0.0003298848, -0.017124394, 0.018743379, 0.0073578293, 0.0074828733,
      -0.026640872, 0.0011796878, 0.021955026, 0.022073489, 0.005640125,
      -0.004152764, -0.009095278, -0.009246646, 0.03235339, -0.014360271,
      -0.04319928, -0.003728274, 0.007127486, 0.035933584, 0.0042876797,
      0.008042279, 0.010503664, -0.039329506, 0.026614547, 0.028983794,
      -0.012300343, -0.012609662, 0.0010752105, 0.01646627, -0.0015062819,
      0.009661265, -0.021007327, 0.029747218, -0.011879143, 0.024139998,
      -0.011609312, 0.0028677767, 0.003432118, 0.007621079, -0.025192998,
      -0.016453108, -0.010931444, 0.019467317, 0.017979955, 0.005607219,
      0.022034, 0.0006741661, 0.104141586, 0.031432014, -0.013570523,
      0.019019792, 0.010819564, 0.028141394, 0.007752704, -0.0017654183,
      -0.0043271673, -0.013570523, -0.0012290471, -0.0042383205, 0.009049209,
      -0.029694568, -0.025285134, 0.0014610359, 0.008851771, 0.017150719,
      -0.009384852, -0.023100162, 0.024850773, -0.016755844, -0.012326668,
      -0.0075421045, -0.013149323, 0.028088745, 0.013287529, -0.0007786433,
      -0.0024860646, -0.012188462, 0.00046315495, 0.0051761474, -0.0087398905,
      -0.010121952, -0.019941166, 0.0013236525, 0.009970583, 0.0020418307,
      -0.0010184473, 0.01244513, -0.0046299044, 0.017558755, -0.03235339,
      -0.042277902, -0.02133639, 0.0013763024, 0.007292017, -0.017598243,
      -0.023942562,
    ],
  },
  {
    id: "faq-5",
    question: "Γιατί να εγγραφώ στο Newsletter του carespot.gr ;",
    answer:
      "Καταρχήν γιατί κερδίζετε πόντους (απαραίτητη προϋπόθεση η δημιουργία λογαριασμού στο carespot.gr)!! Στο carespot.gr έχουμε διαρκώς νέες προσφορές και καινούργιες παραλαβές προϊόντων. Εγγραφόμενοι στο newsletter ενημερώνεστε άμεσα για τα προϊόντα που σας ενδιαφέρουν και για όλες τις νέες προσφορές, εκπτώσεις και διαγωνισμούς - giveaways!\n\nΕπιπλέον ενημερώνεστε από τους έμπειρους επιστημονικούς συνεργάτες του carespot.gr για θέματα που αφορούν την υγεία, την πρόληψη, την ομορφιά, την αισθητική και διάφορες συμβουλές υγείας αλλά και μαθαίνετε πρώτοι για τα νέα μας προϊόντα!!",
    embedding: [
      0.010886359, -0.0068346937, 0.01303839, -0.052445814, -0.03910853,
      0.009079714, -0.02694025, 0.008043551, 0.018916626, -0.020098915,
      0.027763868, 0.01915574, -0.02040445, -0.017561642, 0.008887094,
      -0.010939496, 0.019819949, 0.00022126408, 0.017973451, 0.0015907766,
      0.009664217, 0.019899653, -0.01457271, 0.0042642113, -0.0023745412,
      -0.0015608873, -0.0067915204, -0.019169025, 0.016087102, -0.016565332,
      0.0067317416, -0.00043505582, 0.012062006, 0.0009091338, -0.008554991,
      -0.0033110736, 0.019262014, -0.032174204, 0.030580107, -0.020457586,
      0.009836911, 0.0053634746, -0.005971224, -0.0020474193, -0.014639131,
      0.0036564616, -0.006927683, -0.011377872, 0.024456115, 0.021573456,
      0.0013184516, 0.0139350705, -0.04474101, -0.017946884, 0.02866719,
      -0.0050380128, 0.02376534, 0.010182299, 0.015236917, -0.032174204,
      0.019102603, 0.008907021, -0.024283422, 0.0052804486, -0.010594107,
      0.011324735, -0.011583776, -0.0073594176, -0.017760905, -0.010361635,
      0.012719572, 0.010534329, -0.0076782373, -0.012580087, 0.0035701147,
      -0.009850196, 0.0150907915, -0.005323622, -0.016658321, 0.007831004,
      0.0014571049, -0.006216981, -0.02607678, 0.010693738, 0.024124011,
      0.024057591, 0.008608128, 0.01707013, -0.025957225, -0.011696692,
      0.016937288, 0.007850931, 0.011357946, -0.00065673504, -0.0068147676,
      0.0057420726, -0.0025256486, 0.019062752, -0.0031118116, -0.01175647,
      -0.010235435, 0.013855366, -0.005954619, -0.013722524, -0.024137296,
      -0.02110851, 0.010760159, 0.005854988, 0.012546877, -0.0060642133,
      -0.0007991245, 0.046813335, -0.0064096013, -0.016910719, 0.012048721,
      0.01351662, 0.004403695, -0.006402959, -0.036318857, -0.013330642,
      0.011397799, 0.002351294, 0.0033708524, -0.015834704, 0.01008931,
      0.02671442, -0.0176812, -0.007405912, -0.025133606, -0.015449463,
      0.008574917, -0.012128427, -0.006781557, 0.012174921, -0.015263486,
      0.021918843, -0.034671623, 0.013450199, -0.04877939, -0.012766066,
      0.014718835, 0.023260541, -0.001018728, -0.008508496, 0.00086762087,
      0.0008244474, 0.009484882, 0.03164284, -0.004038381, 0.00451661,
      0.009212556, 0.00032940533, 0.020922532, -0.011836175, -0.0035667936,
      0.014493004, -0.0021802606, 0.011729903, 0.014692267, -0.027790437,
      0.011816249, 0.013828797, 0.016286364, -0.017349096, -0.02001921,
      0.03785982, -0.0015094113, 0.010129162, -0.00051766663, 0.008309234,
      -0.014214038, 0.018053155, -0.018956479, 0.022264231, -0.012752782,
      0.021121794, -0.01175647, -0.000051450126, -0.0092191985, 0.004426942,
      -0.058609657, -0.018053155, 0.014400016, 0.032519594, -0.010129162,
      0.00075263, 0.015383042, -0.012918834, 0.007485617, 0.0010411451,
      -0.0022333972, 0.0006168826, 0.00054423494, -0.014519573, -0.6359387,
      0.0018647622, 0.0018149466, -0.007731374, 0.021958696, 0.030898927,
      0.005393364, -0.008315876, -0.016100386, 0.027604459, -0.00043214991,
      0.004682662, -0.00541329, 0.004051665, -0.0011341341, -0.017282676,
      0.02610335, 0.017561642, 0.0007949732, 0.026196338, -0.032785274,
      -0.0014164222, -0.0050811865, 0.02975649, 0.016950572, 0.02725907,
      -0.003593362, -0.0093188295, -0.012367541, 0.0142671745, 0.0005118548,
      0.028029552, -0.0033841366, -0.0037992662, 0.034299668, 0.0055228844,
      -0.017030276, 0.05029378, 0.0065690107, 0.04205761, -0.039321076,
      -0.015622158, 0.04173879, -0.0120553635, -0.0087144, -0.0140014915,
      0.020045778, -0.00014737101, 0.03610631, -0.012739497, 0.010726949,
      0.0100561, -0.021055374, -0.0092524085, 0.027737299, 0.001194743,
      0.020205189, -0.021799285, -0.006476022, 0.0120553635, -0.009491524,
      -0.0029424387, -0.036425132, -0.043279752, -0.0026302612, -0.010873075,
      -0.011245031, -0.009903332, 0.024070876, 0.0038889342, -0.0012354257,
      -0.014439868, -0.0015575662, 0.016897436, 0.0044103367, 0.011105548,
      -0.008767537, -0.013157948, -0.017800758, 0.008847242, -0.019979358,
      -0.0071535134, -0.026820693, 0.017096698, 0.025771245, -0.001716976,
      -0.020869395, -0.015688578, 0.00467602, 0.01886349, 0.029783059,
      0.01924873, 0.004865319, 0.02033803, -0.0053402274, -0.0018979725,
      -0.0067317416, 0.0140014915, -0.0072531444, 0.007472333, -0.011856101,
      -0.018491533, -0.009232483, -0.013948355, 0.009843553, 0.004881924,
      -0.0000536555, 0.004304064, 0.047477543, -0.03610631, -0.0001928277,
      0.0030636564, -0.0011980641, -0.007226576, 0.00015733411, -0.031855386,
      0.013297431, 0.00027211747, -0.017827326, 0.0059778662, 0.0029158704,
      0.0058118147, 0.0103749195, -0.019594118, -0.0046095992, 0.021626592,
      0.0030055384, -0.020842828, -0.043572005, -0.040144693, -0.012560162,
      -0.015396327, 0.019726958, -0.010567539, 0.02725907, -0.020590428,
      0.0091859875, -0.006459417, 0.03706277, 0.00042675325, -0.00090664305,
      -0.001851478, 0.013118096, -0.018438397, 0.006960893, -0.037647273,
      0.0066520367, -0.0007414215, -0.017548358, 0.029171988, 0.011895954,
      -0.006323254, 0.0013441896, 0.03036756, -0.0069077564, -0.011570493,
      -0.002623619, 0.005579342, -0.02171958, -0.0030055384, 0.02024504,
      0.018624375, -0.030128445, -0.018026588, -0.031297453, 0.007246502,
      0.004413658, -0.0030304461, 0.009205914, -0.033236936, 0.014585994,
      -0.004509968, -0.019355003, 0.018212566, 0.01008931, 0.008780821,
      0.0016057213, 0.0055428105, 0.021467183, -0.014559425, -0.0079970565,
      0.005499637, -0.01950113, 0.006764952, 0.010893001, 0.005147607,
      0.020856112, 0.04593658, -0.024283422, 0.024735082, 0.0036730669,
      -0.019806664, -0.0008858866, 0.040410377, 0.0041911486, -0.017867178,
      0.00650259, 0.027498186, 0.009039862, 0.023087848, 0.037567567,
      -0.0039420705, 0.042934366, -0.01697714, 0.015940977, -0.020869395,
      0.0025223275, 0.00060401356, 0.035574947, 0.0020440982, -0.0011515695,
      -0.009617723, -0.015582304, 0.002203508, 0.01121182, 0.009325472,
      0.0042011114, -0.021467183, 0.0047988985, 0.0050911494, -0.010547613,
      -0.0055394894, -0.018239135, -0.013456841, -0.011683407, 0.030048741,
      0.0147586875, 0.030287856, 0.001076016, -0.020616997, -0.055368327,
      0.009956469, -0.028746895, 0.034963876, 0.032466456, -0.0320945,
      0.0005429895, -0.0073195654, 0.0058250986, -0.0030669775, 0.01979338,
      0.02789671, 0.015768282, 0.014426584, 0.004583031, -0.008614769,
      0.017867178, -0.003017162, -0.021161648, 0.0021155004, -0.026209623,
      0.007850931, 0.00013460577, -0.017030276, 0.018650943, -0.039321076,
      -0.0015625479, 0.013310716, 0.0065557268, 0.025771245, 0.017787473,
      0.0037660557, -0.0014438208, -0.01149743, -0.0033940997, 0.020284893,
      -0.019514412, -0.0005928051, -0.0071003768, -0.015303338, -0.0066354317,
      -0.0020092274, -0.0011673444, 0.015130644, -0.011982301, -0.02258305,
      0.022410357, -0.0024393015, 0.006921041, 0.008355728, -0.020869395,
      -0.04067606, 0.011205179, 0.020869395, -0.006927683, -0.037780114,
      -0.029995605, 0.024947628, -0.016631752, 0.011590418, -0.004769009,
      0.006303328, -0.0074324803, -0.0018016625, 0.004599636, -0.009697428,
      0.036956497, -0.01313138, -0.0007210801, -0.004599636, 0.0110989055,
      -0.0054199323, 0.015648726, -0.006678605, 0.0342731, 0.013509978,
      -0.029623648, 0.00038171167, -0.0038324764, -0.024203716, 0.009245766,
      -0.009066431, -0.008674548, 0.0022682683, 0.014546141, -0.0053767585,
      0.00650259, 0.010640602, 0.012347615, -0.0022865338, -0.020271609,
      -0.007385986, -0.014665699, 0.014997803, 0.054146186, 0.03039413,
      0.014134333, 0.011138758, -0.0075453958, -0.013244295, -0.01316459,
      -0.038550597, 0.0023429915, -0.0018282308, -0.016339501, -0.011563851,
      -0.0050745443, -0.0061704866, 0.022715893, 0.0069011147, 0.009551302,
      0.011152042, 0.018664228, -0.017946884, 0.008621411, -0.0026717742,
      -0.019966073, 0.023552794, -0.0016032305, -0.014692267, 0.0055328473,
      0.006655358, 0.0036564616, -0.014944666, 0.017588211, -0.009551302,
      -0.004051665, 0.0021470503, -0.0358672, 0.020271609, -0.014692267,
      0.020829543, 0.0018249097, 0.010587465, 0.023778623, 0.02632918,
      0.007917352, 0.018239135, 0.00047034185, -0.0061771288, -0.0013159609,
      0.03177568, -0.015555737, 0.0022051684, 0.0310052, -0.021480467,
      -0.0027199292, -0.008402223, 0.0029557229, -0.0009581191, 0.0066387528,
      0.008289308, 0.00900001, -0.026953535, -0.031563133, -0.011736544,
      0.0038424397, -0.0028494496, -0.005064581, -0.026156487, -0.021467183,
      0.00009547981, -0.005204065, 0.0139350705, 0.03151, -0.029012578,
      -0.016512195, -0.012247984, 0.03613288, 0.03860373, 0.016884152,
      0.016565332, 0.008694475, 0.017442085, -0.005874914, -0.013602967,
      0.0050977916, -0.033316642, -0.008960158, -0.008820673, 0.0018016625,
      -0.021560172, 0.013629535, 0.023393383, 0.0035900408, 0.01697714,
      0.0048752823, 0.0013790606, -0.016259797, 0.011391156, 0.00060608925,
      -0.0011731563, -0.0036564616, 0.0033459447, 0.018743932, 0.009936542,
      -0.030606676, -0.010474551, -0.0064693796, 0.025386006, 0.01704356,
      -0.014838393, -0.002844468, -0.007472333, 0.034326237, -0.0058881985,
      0.03036756, -0.0121085, -0.026355749, 0.0025472352, 0.028109256,
      0.01870408, 0.011225104, -0.0037095982, -0.010354993, -0.039905578,
      0.020311462, 0.015316622, -0.0032230662, 0.00477233, -0.015064223,
      -0.0025904088, 0.0133439265, -0.0060608923, -0.0069077564, 0.026621431,
      -0.008083404, -0.0099299, -0.03926794, 0.009239124, -0.004861998,
      0.027232502, -0.004586352, -0.0032961292, -0.020869395, 0.004074912,
      -0.0074590486, -0.008814032, 0.0062269443, -0.035096716, -0.003048712,
      -0.01963397, 0.021095226, 0.012141711, -0.0070804507, -0.0034837676,
      -0.041499678, -0.019726958, 0.026780842, -0.008548349, 0.017628064,
      -0.005665689, 0.011537282, 0.010992632, 0.02517346, 0.004074912,
      0.0051542493, -0.009677501, -0.0006762461, -0.0083955815, -0.019461276,
      -0.018770501, -0.016472343, 0.0073394915, 0.019062752, 0.017481938,
      0.013191158, 0.0070605245, -0.012480456, 0.0021437292, -0.014519573,
      -0.01761478, -0.023951318, -0.0456709, -0.005429895, -0.0054664267,
      0.01956755, 0.014904814, -0.00736606, -0.0080966875, 0.014439868,
      0.0039985282, 0.0040981593, -0.0059845084, 0.012433962, -0.024031023,
      -0.0036730669, -0.0082627395, -0.011962375, -0.016100386, -0.004161259,
      -0.01924873, -0.0018531386, 0.020869395, 0.0037129193, -0.001303507,
      -0.019421423, 0.007107019, -0.027710732, -0.008893737, -0.004662736,
      -0.0045464993, -0.0026302612, -0.003776019, -0.03347605, 0.0034505574,
      -0.012958686, -0.0044501894, -0.0031333982, -0.0029773095, -0.028029552,
      0.021852423, -0.0007796134, -0.0130118225, -0.011650197, -0.025359437,
      0.02818896, 0.021998549, 0.024097443, 0.00989669, 0.019062752,
      -0.034963876, -0.01082658, -0.00083316513, -0.019912938, -0.0091859875,
      0.027790437, 0.006319933, -0.036557972, 0.012679718, 0.019169025,
      -0.014997803, -0.009816985, 0.019873085, -0.015688578, 0.026183054,
      -0.015781567, 0.012686361, -0.00014913532, 0.032811843, -0.00073934585,
      -0.010740234, -0.005791888, -0.014931382, -0.012380825, 0.02110851,
      -0.013410347, 0.035017014, -0.012673076, -0.013270863, -0.01063396,
      -0.033130664, 0.0057188254, 0.008926947, 0.008116614, 0.016857583,
      -0.0013101491, 0.0010477871, 0.014745404, -0.0051974226, 0.015980829,
      0.0036398564, -0.01800002, 0.023194121, 0.014373448, -0.02383176,
      0.015768282, 0.020643566, 0.01085979, 0.015874555, 0.013802229,
      0.014532858, -0.004865319, -0.03408712, 0.0074324803, -0.0123542575,
      -0.016273081, 0.0054365373, -0.00002645154, -0.004563105, -0.009411818,
      0.017508507, 0.01947456, 0.012626582, -0.011763113, -0.006482664,
      -0.012613298, -0.0045431782, 0.0018116256, 0.0320945, 0.008236172,
      0.020218473, -0.022862017, -0.019726958, 0.0065324795, -0.015755,
      -0.011391156, 0.020045778, -0.005323622, -0.03894912, 0.05829084,
      0.016060535, -0.007007388, -0.007864215, -0.0046328465, 0.008754253,
      0.008016983, 0.009066431, 0.0111454, 0.028986009, -0.009750565,
      -0.014466437, -0.020895964, -0.010388203, -0.029650217, -0.015874555,
      -0.025558699, -0.018185997, 0.006881188, -0.004161259, 0.01918231,
      0.018358693, -0.033555757, -0.024668662, 0.014054628, 0.017601496,
      -0.0037793398, -0.029703354, -0.0021736186, -0.029092282, -0.027205935,
      0.04224359, 0.019288583, -0.03650484, -0.0010469569, -0.005307017,
      -0.0063664275, 0.00900001, 0.00050106144, -0.0050214077, 0.0023562757,
      -0.008960158, -0.014678983, 0.007631743, -0.03549524, 0.03674395,
      0.01822585, -0.030181583, -0.024336558, -0.008256097, -0.009538018,
      -0.0027298923, -0.0042941007, 0.023154268, -0.011703334, 0.011291525,
      -0.0034472363, 0.0055394894, -0.0044767577, 0.016472343, -0.022277515,
      0.011523997, -0.011656839, -0.01643249, -0.0288266, -0.013988207,
      -0.040277533, 0.0018332123, 0.04285466, 0.0071535134, 0.0030155014,
      0.038895983, -0.033688597, -0.014612562, 0.011490787, -0.01649891,
      -0.007917352, 0.012035438, -0.013802229, -0.037620705, 0.0142007535,
      -0.00085599726, 0.019514412, -0.027657595, -0.0027946525, 0.007472333,
      0.0021852423, -0.0037394874, -0.014997803, -0.022569766, 0.0060276818,
      -0.010222152, 0.010222152, 0.027817005, -0.013363852, 0.023619214,
      0.0028245419, -0.024323273, -0.025306301, 0.0082959505, -0.009856838,
      -0.018265702, 0.005210707, -0.02264947, 0.0036498194, -0.019076036,
      0.015914408, 0.0052538803, 0.0092191985, -0.026794124, -0.005214028,
      0.0018282308, 0.013961639, 0.019700391, -0.031138042, -0.015290054,
      0.012699645, 0.013264221, -0.031138042, -0.03360889, -0.006927683,
      -0.007930636, -0.0054797106, 0.025200028, 0.009265693, -0.01707013,
      -0.021480467, 0.0005849176, -0.009172704, 0.011318093, 0.236139,
      -0.029650217, -0.0020092274, 0.026953535, 0.006323254, -0.00035244503,
      0.01607382, 0.0035966828, 0.010295214, -0.0017501864, 0.009797059,
      0.004297422, -0.018876774, -0.008442076, -0.011504072, -0.04083547,
      -0.041871633, -0.018504817, -0.029995605, -0.014997803, 0.0008933589,
      0.0067284205, 0.008143182, 0.0057985303, 0.017349096, 0.00007103905,
      -0.029065715, 0.019554265, 0.029570512, 0.017216256, -0.019766811,
      0.008422149, -0.012135069, -0.0077180895, -0.015396327, 0.0038324764,
      0.032811843, -0.009205914, 0.02001921, -0.009298903, -0.0033592288,
      -0.0027182687, -0.017574927, 0.028587485, -0.036664248, -0.005675652,
      -0.0006210339, -0.015104076, -0.0016057213, 0.024044307, -0.01370924,
      0.009710711, 0.02149375, 0.01922216, -0.0088406, 0.004237643,
      -0.002514025, 0.019780096, -0.012241342, 0.017442085, 0.02594394,
      0.014665699, 0.0020291535, 0.016299648, -0.008415507, 0.0069143986,
      -0.019966073, -0.00087011163, 0.0067948415, -0.01732253, 0.019169025,
      0.00030117654, 0.02385833, 0.033794872, -0.032041363, -0.0021038768,
      -0.016950572, 0.03987901, 0.023074564, 0.014028059, -0.0063298964,
      0.032679003, -0.012380825, -0.014705551, 0.011570493, -0.028082687,
      0.017694484, 0.0014255551, -0.012872339, 0.004416979, 0.00915942,
      0.018890057, -0.025226597, -0.015409611, 0.007884141, -0.0020142088,
      0.014240606, 0.033582326, -0.010580824, 0.02197198, -0.028746895,
      0.030447265, -0.0014164222, 0.013842082, -0.024110727, 0.010202225,
      0.0015235257, 0.03039413, -0.00092158775, -0.03036756, 0.0037693768,
      -0.031669408, 0.0072597866, -0.005429895, -0.00621366, 0.006741705,
      0.000062528896, 0.00025634252, 0.022888586, -0.0031433613, 0.013509978,
      -0.0076782373, -0.006127313, 0.01425389, 0.0077845105, -0.01947456,
      -0.01918231, -0.005310338, 0.0018730648, -0.043040637, 0.020988952,
      -0.011318093, 0.0073328493, 0.008202961, 0.002744837, -0.0013657764,
      -0.011590418, -0.025545415, -0.0011399459, 0.021387476, -0.012600014,
      -0.013828797, -0.005144286, -0.018026588, 0.010574182, -0.0100760255,
      -0.0014081196, 0.00919263, 0.0031732507, 0.00807012, -0.005054618,
      0.0008601485, -0.01825242, 0.010886359, 0.04423621, -0.014400016,
      -0.03900226, -0.047105588, 0.005585984, 0.007505543, -0.021759434,
      0.005752036, 0.009478239, -0.01473212, -0.020962385, -0.0092524085,
      -0.17014337, 0.01313138, 0.006137276, -0.031881955, 0.023101132,
      0.018212566, 0.022503346, 0.016711457, 0.0006347332, 0.0012229718,
      0.033290073, 0.005204065, -0.045086395, -0.004121407, -0.002289855,
      -0.00621366, -0.029225124, 0.028614054, 0.03039413, 0.018013304,
      0.031350587, -0.04535208, 0.017375665, 0.013051675, 0.009677501,
      -0.0055228844, -0.021347625, 0.0006799823, -0.014028059, -0.016857583,
      -0.0013250937, 0.019673822, 0.03658454, 0.009371966, 0.0048088613,
      -0.0155026, 0.019288583, -0.02149375, -0.0021503714, 0.028693758,
      0.015170496, 0.010959421, -0.016777879, 0.03974617, -0.002748158,
      0.021918843, 0.0320945, 0.0063631064, 0.0013857026, -0.01063396,
      0.008256097, -0.022490062, 0.029543944, 0.02517346, 0.027551321,
      0.026129918, 0.0030022173, 0.026860546, -0.015688578, -0.008747611,
      -0.022144673, -0.008774179, -0.0048354296, -0.017800758, 0.009504807,
      -0.012128427, -0.0017236181, -0.001934504, -0.018185997, 0.012719572,
      -0.0048287874, -0.0150907915, 0.0077778683, -0.03443251, 0.02818896,
      0.009391892, -0.022862017, -0.0032346898, -0.00868119, -0.00996311,
      -0.000055108456, 0.025638405, -0.030952064, 0.0044701155, -0.010540971,
      -0.003978602, 0.017442085, 0.00036012492, -0.03974617, -0.016738025,
      -0.018132862, -0.018943194, -0.011570493, -0.016512195, 0.03735502,
      0.0054597845, 0.009205914, 0.023300394, 0.011072337, -0.00150526,
      0.016910719, -0.015834704, -0.029676786, 0.022144673, 0.025505563,
      0.0008526762, 0.0052937325, 0.030500403, 0.035734355, -0.008143182,
      -0.00019646008, 0.0019461276, 0.02741848, 0.014134333, 0.012168279,
      0.0007642536, -0.020351313, -0.03414026, 0.019966073, -0.0076051746,
      0.040649492, -0.044794146, 0.0010685436, -0.000858488, 0.0028328444,
      -0.007485617, -0.046627358, -0.00595794, -0.0021702976, 0.0041479752,
      0.021002237, 0.008083404, 0.00080950273, 0.026926966, -0.016312933,
      0.015130644, 0.01066717, -0.033874575, 0.0028079366, -0.009803701,
      -0.003696314, 0.0070937346, 0.011802965, -0.036531404, -0.01950113,
      0.018983047, 0.013529904, -0.01956755, -0.018850205, 0.004397053,
      -0.03549524, 0.034193397, 0.0074191964, 0.0058084936, 0.03238675,
      -0.002693361, -0.012028796, -0.007571964, 0.01130481, -0.015383042,
      -0.0077180895, -0.0034040627, -0.019447992, -0.020457586, -0.0016638394,
      -0.01255352, 0.020856112, -0.011876028, 0.036903363, -0.030898927,
      -0.009909974, 0.015263486, 0.005041334, 0.013310716, 0.015701862,
      -0.016631752, -0.026315896, -0.0038125503, -0.03238675, 0.0042874585,
      -0.003965318, -0.0077180895, -0.009292261, 0.002651848, -0.018451681,
      -0.0006318273, -0.013596325, -0.020895964, -0.024217, 0.016897436,
      0.025664972, 0.004413658, -0.018332124, -0.04957644, 0.019142456,
      0.011537282, 0.00028976047, 0.0024774936, -0.0037262035, 0.025492279,
      -0.027790437, -0.027551321, -0.013509978, 0.005655726, 0.015808135,
      0.00704724, -0.013204442, -0.024017738, 0.014373448, -0.028534349,
      -0.017787473, 0.01729596, -0.002200187, 0.013317358, 0.0150907915,
      -0.018757217, -0.0030752802, 0.00011270769, 0.005778604, 0.009511449,
      -0.009604438, -0.0067118155, 0.0025422538, -0.025093755, 0.02453582,
      -0.012493741, -0.013775661, -0.02510704, -0.07842961, 0.00003951515,
      -0.022955006, 0.0068147676, -0.008940231, -0.023845045, 0.018278986,
      -0.004954987, -0.017375665, -0.008016983, -0.008993368, -0.0054531423,
      0.022636186, -0.011311452, -0.021918843, -0.021440614, 0.024309989,
      0.017508507, 0.026289327, 0.035654653, -0.0040450227, 0.0029191915,
      0.0012835808, -0.0017817363, -0.026090065, 0.043040637, 0.003785982,
      0.02485464, -0.0049350606, 0.001806644, 0.0026601504, -0.0023811834,
      0.004257569, 0.01870408, -0.0035468673, -0.012792634, -0.023154268,
      0.029570512, -0.0041712224, 0.0131778745, -0.014519573, -0.011311452,
      0.016538763, -0.022955006, -0.006582295, -0.0050346917, 0.011590418,
      -0.010235435, 0.0056988993, -0.0062867226, -0.006784878, 0.016219944,
      0.012819203, -0.008701117, -0.0008593183, -0.022915155, 0.009451671,
      0.0012661453, 0.014506289, -0.04691961, 0.018106293, -0.0003520299,
      0.015210349, -0.026833978, 0.011291525, -0.0066719633, -0.011078979,
      -0.019115888, -0.009092999, -0.013171232, -0.029543944, 0.0150243705,
      0.010315141, 0.030952064, -0.006140597, 0.016286364, 0.012673076,
      -0.016791163, -0.018411828, 0.001322603, -0.012174921, 0.011975659,
      -0.033422913, 0.01915574, 0.009743922, 0.036797088, 0.016392637,
      -0.009803701, -0.024057591, 0.0032263873, -0.0037726979, 0.030925496,
      -0.007963846, -0.019806664, 0.0122214155, 0.0075786063, 0.007691521,
      -0.021613307, -0.00015577738, 0.016605183, 0.02290187, 0.010753517,
      -0.0036763877, -0.009292261, -0.009571228, 0.03459192, -0.0073992703,
      -0.039400782, -0.002616977, -0.007957204, 0.034538783, 0.0018332123,
      0.013749093, 0.013025107, -0.04099488, 0.020484155, 0.03472476,
      -0.004702588, -0.0074789748, 0.009371966, 0.017628064, -0.01044134,
      0.017030276, -0.015967546, 0.021520318, -0.011749828, 0.017335812,
      -0.007850931, 0.001393175, 0.00090664305, 0.003795945, -0.025053902,
      -0.016100386, -0.012002227, 0.017721053, 0.019394856, 0.008760896,
      0.023167552, -0.008468644, 0.10111893, 0.028640622, -0.010348351,
      0.020524008, 0.0150907915, 0.026833978, 0.0011881009, -0.0013649461,
      -0.01370924, -0.013270863, 0.002693361, 0.001751847, 0.003430631,
      -0.03408712, -0.028268665, -0.001079337, 0.0013309055, 0.020284893,
      -0.016126955, -0.026462022, 0.013416989, -0.011424366, -0.015755,
      -0.003606646, -0.012015511, 0.024376411, 0.013503335, -0.0018182676,
      -0.0023911465, -0.013616251, 0.0048520346, 0.012732855, -0.018053155,
      -0.013051675, -0.03584063, -0.000963931, 0.006847978, -0.00650259,
      0.002806276, 0.010135804, -0.002117161, 0.029942468, -0.03360889,
      -0.04702588, -0.025704825, -0.005469748, 0.008289308, -0.010255362,
      -0.02353951,
    ],
  },
  {
    id: "faq-6",
    question: "Είναι όλα τα προϊόντα άμεσα διαθέσιμα ;",
    answer:
      "To carespot.gr χρησιμοποιώντας σύγχρονα προγράμματα παρακολούθησης αποθεμάτων αποθήκης έχει ενημερωμένο live το απόθεμα των προϊόντων του οπότε τα προϊόντα με ένδειξη Άμεσα Διαθέσιμο αποστέλλονται άμεσα. Σε περίπτωση που κάποιο προϊόν δεν είναι άμεσα διαθέσιμο, ο χρόνος αναμονής μπορεί να φτάσει τις 3 - 4 εργάσιμες μέρες.\n\nΣτα προϊόντα με ένδειξη Προσωρινά Μη Διαθέσιμο μπορείτε να επιλέξετε το κουμπί Ενημέρωση με e-mail οπότε θα σας αποσταλεί αυτοματοποιημένα email μόλις το προϊόν γίνει και πάλι Διαθέσιμο.\n\nΣε περίπτωση εξαντλήσεως ή έλλειψης κάποιου κωδικού, η ομάδα του carespot.gr θα έρθει σε επικοινωνία μαζί σας και θα σας ενημερώσει για τον ακριβή χρόνο παραλαβής των προϊόντων σας.",
    embedding: [
      0.010206354, -0.013774118, -0.011091924, -0.039831538, -0.035269897,
      -0.009148766, -0.01353202, 0.0023286033, 0.018182855, -0.018437695,
      0.011627088, 0.0071227862, -0.029688895, -0.0060556424, -0.0006816978,
      -0.020438192, 0.01846318, 0.0010360851, 0.003895871, -0.011607976,
      -0.0009500765, 0.0024958423, 0.0063582654, -0.017252687, 0.019049313,
      0.015124772, 0.010722405, -0.02055287, -0.0059919325, -0.012939516,
      0.015800098, -0.005609672, -0.0018189226, -0.012359754, -0.0024926567,
      0.00019740168, 0.017341882, -0.008562634, 0.035473768, 0.01688317,
      0.01074789, 0.0146150915, 0.014576865, 0.0047591426, -0.015022836,
      -0.00009292712, -0.010002482, -0.000247872, -0.001396047, 0.010970875,
      0.009919658, 0.028924374, -0.044877376, -0.014385735, 0.016641071,
      0.0069061723, 0.0081867445, 0.014551381, -0.017736884, -0.008435214,
      0.011072811, 0.010301919, -0.027981464, -0.00047145455, 0.00702085,
      0.015456064, -0.013481052, -0.0021613643, -0.00076252996, -0.016679298,
      0.012085801, 0.028185338, -0.014220089, -0.017609464, 0.0014119745,
      0.005976005, 0.017163495, 0.016195102, -0.024681283, 0.017940758,
      0.023802083, -0.015736388, -0.0032651413, 0.009378123, 0.023623696,
      0.02535661, 0.0030087084, 0.023993215, -0.0017950314, -0.020094158,
      0.010958133, 0.021100776, 0.014079927, 0.0052911215, -0.010645953,
      0.011722654, -0.025917258, 0.019189475, -0.0024289466, -0.010919907,
      0.009824093, -0.0075878697, -0.00746045, 0.0016062902, -0.010977246,
      -0.010301919, 0.0003275096, 0.0030580836, 0.01574913, -0.0038353465,
      -0.006982624, 0.01865431, 0.023827568, -0.041819293, 0.008702796,
      0.047349326, 0.032390203, -0.012569997, -0.03236472, -0.024821445,
      0.005963263, -0.00082186, 0.011697169, 0.009078685, 0.006931656,
      -0.000872828, -0.0341486, 0.0006988199, -0.015761873, -0.0018380356,
      0.03249214, -0.006740526, 0.006918914, 0.020998841, -0.016526394,
      0.014054443, -0.035142478, 0.048419658, -0.028108886, 0.013238953,
      0.024553863, 0.016233327, -0.0015943446, 0.0131752435, -0.0039245407,
      0.012092172, -0.004332285, 0.019495282, 0.008103921, 0.0044979313,
      0.0037461524, -0.015201224, 0.026809199, -0.012181366, 0.004704989,
      0.003972323, -0.01878173, -0.017290914, -0.010900794, -0.0019399718,
      0.008135777, -0.0063582654, 0.015010093, -0.0025436247, -0.017978983,
      0.04029025, 0.026885651, -0.0038608306, 0.019788349, -0.0017679546,
      -0.016844943, 0.02492338, -0.015647193, 0.019189475, 0.0017106155,
      0.02270627, 0.025445804, -0.004185752, -0.0016254033, -0.0062658857,
      -0.041972198, -0.01611865, 0.0052082986, 0.016628329, -0.013557504,
      0.005526849, -0.014130894, -0.000013625495, 0.012691047, -0.0072247223,
      -0.0057179793, -0.0018714834, 0.0101936115, -0.018998343, -0.6242568,
      -0.021865297, 0.0046954327, -0.01878173, 0.012053946, 0.03837895,
      0.03389376, 0.01732914, -0.0149209, 0.03871024, 0.0040933723,
      -0.00083938026, -0.015800098, -0.014372993, 0.009454575, 0.003510425,
      0.010919907, 0.01093902, 0.0054854373, 0.0053325333, -0.010824341,
      -0.01150604, 0.0058294716, 0.026809199, 0.016411716, 0.018730761,
      0.02364918, 0.0032906253, -0.010537647, 0.03623829, -0.005651084,
      0.03453086, 0.0023923132, 0.007033592, 0.05002515, -0.024808703,
      -0.01871802, 0.042838655, 0.037512492, 0.039117984, -0.041131224,
      -0.006495242, 0.0139779905, -0.028363725, -0.011773622, 0.006791494,
      0.025382094, -0.021228196, 0.016424457, -0.027828561, 0.014423961,
      -0.010289177, -0.012550885, -0.0021072107, 0.0044756327, 0.0009859134,
      0.04314446, -0.022158364, -0.023356114, -0.0004567216, 0.008696425,
      -0.013086049, -0.012882177, -0.0185906, -0.019966738, 0.0119010415,
      -0.004574383, -0.0062849987, 0.010480307, 0.018475922, 0.01600397,
      0.019915769, -0.017494787, 0.047909975, 0.016539136, -0.0019893472,
      0.019011086, 0.0073648845, -0.00802747, 0.009371752, -0.03427602,
      0.0015816026, -0.014067184, 0.01138499, 0.031906005, -0.0032109877,
      -0.008078437, -0.016424457, 0.005109548, 0.03580506, 0.025751611,
      0.042150587, 0.032466654, 0.012053946, -0.021801587, 0.019750124,
      0.00049375306, 0.02655436, 0.0023158612, 0.019329637, 0.01681946,
      0.00891941, 0.0121367695, 0.0062754424, 0.0053803157, 0.033995695,
      -0.020399965, 0.015468806, 0.0173164, -0.013200727, -0.00059728196,
      -0.0069061723, -0.001957492, -0.009403607, 0.011996607, -0.025050802,
      0.02971438, 0.0050968057, -0.010684179, -0.007479563, -0.00904046,
      -0.004306801, 0.023610953, -0.012914032, -0.00017420723, 0.018424954,
      0.0010313068, -0.019864801, -0.044087373, 0.004545714, 0.006918914,
      -0.014806221, 0.010932649, -0.017176237, 0.016016712, 0.009021346,
      0.011557007, -0.017634949, 0.027522752, -0.017647691, 0.015201224,
      -0.0004527397, 0.0017265431, -0.007562386, -0.002266486, -0.020438192,
      0.0077598873, 0.0066003636, -0.04294059, 0.023980472, -0.012965,
      -0.0066831866, -0.0036187323, 0.016577361, 0.017278172, -0.018947376,
      -0.022234816, -0.020259803, -0.0037620799, -0.0011404103, 0.019125765,
      0.007562386, -0.0039978074, 0.0042940592, -0.023929505, -0.0037716364,
      -0.00053755374, 0.0142455725, -0.024719508, -0.04263478, 0.0023158612,
      -0.014347509, -0.020654807, 0.0099260295, 0.008288681, 0.0012001385,
      0.0031488705, -0.0030676401, 0.021139003, 0.0075369016, 0.0018459994,
      -0.0015601005, -0.011187489, 0.024528379, 0.02181433, -0.0034276021,
      0.0097922385, 0.024757735, -0.02996922, 0.022655303, 0.0168322,
      -0.0056861243, 0.0031584268, 0.009008604, 0.0034180456, -0.019877544,
      0.011378619, 0.014831705, -0.010684179, 0.011639831, 0.02821082,
      0.02629952, 0.023738373, -0.015608968, 0.036034416, -0.013621214,
      0.004590311, 0.0036665148, 0.022018202, 0.020960614, 0.016934138,
      0.0057370923, 0.019941254, 0.003781193, -0.016730266, 0.016386231,
      -0.04788449, 0.0108116, 0.0019558992, 0.017889788, 0.01688317,
      -0.011162005, -0.008390617, -0.011091924, -0.04439318, 0.013251696,
      0.002317454, 0.060499087, -0.011907413, -0.014092669, -0.03697733,
      0.0028478403, -0.015099288, 0.011244828, 0.029867282, -0.0018364429,
      0.0032969965, 0.004128413, 0.018807214, 0.027420817, 0.006189434,
      0.021355618, 0.01897286, 0.0020052746, -0.0005347664, 0.0005291918,
      0.028720502, 0.006077941, 0.006501613, 0.0024815074, -0.010314661,
      -0.007645209, -0.028057916, 0.0029179214, 0.012525401, -0.02194175,
      0.024783218, 0.0024273538, 0.0057275356, 0.027981464, 0.009709415,
      -0.010499421, 0.011378619, -0.0023126758, -0.012034833, 0.027650172,
      -0.022527883, -0.009308042, 0.0058804397, -0.008976749, -0.0036410308,
      0.0064474596, 0.006657703, 0.030122124, 0.014640575, 0.012359754,
      0.018042693, 0.0021613643, -0.0045266007, 0.0059855613, -0.039857022,
      -0.056523576, -0.021776104, 0.024528379, -0.002162957, -0.0304789,
      -0.023929505, 0.020017706, -0.009817722, 0.013825086, 0.01359573,
      0.023152241, -0.00016484981, -0.0007187293, 0.0064984276, -0.012047575,
      0.042456392, 0.013481052, -0.0022569294, -0.021279166, 0.004956644,
      0.0031918748, 0.016704781, -0.026860168, 0.03641668, 0.004023291,
      -0.038863145, -0.013315406, 0.0028733243, -0.046737712, -0.00032651413,
      -0.000662983, -0.01827205, -0.013684924, 0.024120634, -0.002470358,
      -0.011531523, -0.0033607064, 0.009562883, -0.017558496, -0.008932153,
      -0.024413701, 0.00891941, 0.006476129, 0.034938604, 0.018373985,
      0.000054999713, 0.006033344, -0.0022234817, -0.0045680124, -0.010722405,
      0.00019590848, -0.007785371, 0.018195597, -0.027879529, -0.012359754,
      0.00004959929, -0.004249462, 0.00061997864, 0.009339897, 0.020744,
      0.0032746978, 0.0023509017, -0.009186992, -0.009620221, 0.019801091,
      0.0019049313, 0.00505858, -0.010301919, -0.011678057, 0.020858679,
      0.008798362, 0.0018810399, -0.0040009925, 0.008728281, -0.0007454079,
      -0.0091232825, -0.0037525233, -0.019329637, 0.0100598205, -0.016156875,
      0.03333311, 0.021546748, 0.0213811, 0.023381598, 0.020884162, 0.010021594,
      0.0019670485, 0.010773374, 0.00802747, -0.0071418993, -0.0072438354,
      0.020476418, -0.0021549934, 0.0102955485, 0.0006187841, -0.012448949,
      -0.0033766339, 0.002175699, 0.016093165, -0.010410226, -0.019801091,
      0.0029370345, -0.022566108, -0.00066019566, 0.004115671, -0.0019861616,
      -0.0002514557, 0.010665067, -0.040443156, -0.019558992, 0.016297037,
      0.008925782, -0.043424785, 0.020896904, -0.017443819, -0.03470925,
      -0.027344365, 0.029332118, 0.037334103, 0.012722902, 0.03042793,
      0.019915769, 0.029586958, 0.013646698, -0.020259803, 0.019558992,
      -0.020145126, -0.0049853134, 0.014079927, -0.0028414694, -0.037894752,
      0.007893678, 0.015863808, 0.009607479, 0.008715538, 0.006816978,
      -0.043271884, -0.025891773, -0.021916267, 0.003813048, 0.02485967,
      -0.015264934, -0.014844447, 0.0057593905, 0.009136025, -0.018692536,
      -0.010983617, -0.007683435, 0.020591097, 0.0037461524, -0.014525897,
      -0.029255666, -0.0072629484, 0.011563378, 0.005514107, 0.000682096,
      -0.010327403, -0.015290418, 0.016615588, -0.0011993422, 0.035218928,
      -0.0015959374, 0.009811351, -0.0033033674, -0.03672249, 0.018794471,
      -0.014946383, -0.032721493, 0.0057052374, -0.0027634245, -0.030682772,
      0.0036091758, 0.007982872, 0.0037875639, 0.03384279, 0.0024353175,
      -0.025407577, -0.016258812, 0.0019845688, -0.014895416, 0.016551876,
      -0.006189434, -0.014640575, -0.04311898, -0.0071418993, -0.022362236,
      -0.020157868, -0.027344365, -0.026082905, 0.010989988, -0.008498924,
      0.021240938, 0.01871802, 0.0036505873, 0.015634453, -0.03249214,
      -0.024566604, 0.016105907, -0.036467645, 0.010786115, -0.019699154,
      0.024528379, 0.055249378, 0.015175739, -0.009352639, 0.009696674,
      -0.008791991, 0.0066322186, 0.004940716, 0.00019222524, -0.027089523,
      -0.016908653, 0.029867282, 0.02491064, 0.012767499, 0.03376634,
      0.0073330295, 0.0043928097, 0.022604335, -0.008626344, -0.018616084,
      -0.022502398, -0.012092172, 0.013901538, -0.028134368, 0.0122387055,
      0.0010496235, -0.004211236, -0.004459705, 0.0026981216, -0.0084861815,
      0.009155137, 0.012557256, 0.018794471, -0.02168691, 0.010384742,
      -0.016692039, -0.006495242, 0.0027873158, 0.0022951555, -0.010633212,
      -0.031906005, 0.05020354, -0.010524904, 0.00420805, -0.004797369,
      0.0021326947, -0.01783882, -0.0074668205, -0.01688317, 0.0047018034,
      -0.020259803, -0.00081947085, -0.028491145, -0.015201224, -0.03814959,
      -0.009269816, -0.01929141, 0.00067214126, -0.02181433, 0.00790642,
      0.0038990565, -0.01314976, -0.02150852, 0.0065302826, 0.03320569,
      -0.009327155, 0.031116001, 0.027803076, 0.0066704447, -0.027522752,
      -0.061314575, -0.0026662666, -0.003018265, -0.0026041493, 0.028695017,
      0.020540128, -0.037665397, 0.0005662233, 0.018552374, -0.036595065,
      -0.028108886, 0.015698163, 0.010786115, 0.009460946, 0.00058294716,
      0.020145126, 0.01940609, -0.005205113, -0.009212477, -0.012257818,
      -0.008569005, -0.0138123445, 0.018616084, 0.029051794, -0.021610457,
      0.026350487, -0.006026973, -0.016042197, -0.013927022, -0.019750124,
      -0.011276683, 0.0070909313, -0.022935627, 0.0018762617, 0.0061830627,
      0.025458546, 0.020782227, 0.0024416887, -0.015545258, -0.005842214,
      -0.0038417175, 0.019699154, 0.014194605, -0.018934634, 0.0077089192,
      0.017214462, 0.012385239, 0.008632715, 0.0047591426, -0.003069233,
      -0.010658695, -0.0035040542, 0.013557504, -0.0031297575, -0.023037562,
      -0.010142644, -0.0068042357, -0.03756346, -0.009569253, -0.019558992,
      0.01606768, 0.021355618, -0.026528874, -0.02497435, -0.006848833,
      0.008575376, 0.01277387, 0.0151502555, -0.0029609257, 0.0055746315,
      -0.020820452, -0.0045584557, 0.033689886, -0.013774118, 0.008097551,
      0.027369848, -0.0030756039, -0.012187737, 0.031345356, 0.03231375,
      -0.0216232, 0.020323513, 0.008893927, -0.007798113, -0.004870635,
      0.00663859, 0.028745987, 0.009811351, -0.003395747, -0.039296374,
      -0.011818219, -0.02024706, -0.028567597, -0.00790642, -0.005832657,
      -0.025012575, -0.010461194, 0.02048916, 0.0014318839, -0.011219344,
      -0.0023126758, -0.025560481, -0.013825086, 0.017724143, -0.008193116,
      -0.02516548, 0.0016086794, -0.015647193, -0.012748386, 0.003234879,
      -0.017354624, -0.019431572, 0.009371752, 0.020094158, 0.017813336,
      0.029459538, 0.0023190468, -0.019788349, -0.011779993, 0.00027216147,
      -0.014627833, -0.014513155, -0.015354128, 0.01372315, 0.008110292,
      -0.046686742, -0.02466854, -0.0061129816, -0.004717731, 0.0304789,
      0.002201183, 0.020935131, 0.0036474017, 0.0134428255, -0.004096558,
      0.010907165, -0.00011776409, 0.0056861243, -0.0026041493, 0.018004468,
      -0.0006295352, -0.023802083, -0.007868194, -0.018552374, -0.031396326,
      0.010843455, 0.04294059, 0.014003474, 0.009607479, 0.039653152,
      -0.013748634, -0.0019160805, 0.0054535824, -0.0069252853, 0.014283799,
      0.025828063, -0.018131888, -0.031956974, -0.015277676, 0.011926526,
      0.01720172, -0.019431572, 0.0013625992, 0.018807214, 0.010282806,
      0.011971123, -0.009480059, -0.025789838, 0.002747497, -0.019393347,
      0.0062149176, -0.006300926, 0.012894919, 0.0061448365, -0.00420805,
      -0.017494787, -0.015647193, -0.0015505439, -0.005609672, -0.011881929,
      0.01327718, -0.02655436, -0.0024353175, 0.012576369, -0.0046094237,
      -0.023623696, -0.005507736, -0.019558992, -0.012092172, -0.0034817555,
      -0.0005395447, -0.0071610124, -0.0154178385, 0.016398974, 0.01694688,
      0.020119641, -0.024235312, -0.021177229, 0.015213965, -0.011869187,
      0.0012869436, 0.0048833773, -0.006931656, -0.040315736, 0.0028398766,
      0.015851067, -0.009957884, 0.0216232, 0.23547244, -0.014181863,
      0.0013363188, 0.040774446, 0.0058294716, -0.030173091, 0.005925037,
      -0.012391609, -0.010582243, 0.012640079, 0.016386231, 0.0068042357,
      -0.009868691, -0.00011338403, -0.014831705, -0.016972363, -0.026274035,
      -0.018832698, -0.019329637, 0.033078272, 0.0028749171, 0.010518533,
      -0.0012781834, -0.0073075457, 0.015086546, 0.020348998, -0.016105907,
      -0.018221082, 0.013570246, 0.0008099143, 0.0045361575, -0.005103177,
      0.015124772, 0.0071100444, 0.002169328, 0.005007612, 0.033613436,
      -0.010881681, 0.026834683, -0.008944894, -0.015647193, 0.025139995,
      0.0072565777, 0.008702796, -0.025853548, -0.024681283, -0.029357603,
      -0.016577361, 0.0017631763, 0.023317887, -0.020794969, 0.012257818,
      0.012512659, 0.025955485, -0.020833194, -0.030555353, -0.0032683269,
      0.006931656, -0.006848833, 0.011964752, 0.018068178, 0.020310773,
      0.0011220936, 0.015736388, -0.03198246, 0.0005224226, -0.0481903,
      -0.011773622, 0.0038480884, -0.0016166432, 0.023407081, -0.0048961192,
      0.02996922, 0.012480804, -0.026707264, -0.0012399573, 0.020450935,
      0.012047575, 0.016220585, 0.006272257, 0.011225715, 0.025331127,
      -0.025343867, 0.007931904, -0.027293395, -0.016997848, 0.013353632,
      0.008237713, -0.02877147, -0.012391609, 0.004501117, 0.010913536,
      -0.01624607, 0.0036028046, -0.012092172, 0.0032125805, -0.0032109877,
      0.038226046, -0.017711401, -0.0020132384, 0.005488623, 0.038863145,
      -0.013328148, 0.020017706, -0.027701141, -0.0044756327, -0.0071418993,
      0.0086900545, 0.011117408, -0.019329637, 0.005988747, -0.01846318,
      0.016526394, -0.005135032, -0.01011716, -0.00066616846, -0.010448452,
      -0.019571735, -0.0026758232, 0.02364918, 0.024133377, -0.014449445,
      -0.005007612, 0.0031616124, 0.011544266, -0.023814825, -0.019316895,
      0.0057976167, 0.0047272877, -0.031727616, 0.016857686, -0.017596724,
      0.029790832, 0.008683683, -0.0023222321, -0.007689806, -0.0029306635,
      -0.009301671, -0.0026965288, -0.0024958423, -0.009480059, -0.008524408,
      -0.017685916, -0.01372315, 0.015392354, -0.018323017, -0.02206917,
      0.00016475026, -0.009747641, -0.016921395, -0.020998841, -0.018424954,
      -0.0046890615, -0.02048916, 0.01485719, -0.011174747, -0.032797948,
      -0.024426444, 0.015061062, 0.03422505, -0.016488167, -0.03628926,
      0.033129238, -0.012652821, -0.0020594283, -0.0019781978, -0.15912229,
      0.0073457714, -0.013621214, -0.021534005, 0.015277676, 0.0027554608,
      0.021610457, 0.019125765, -0.032976333, -0.019953996, 0.034989573,
      -0.020323513, -0.048419658, -0.007594241, -0.008282309, -0.015481548,
      -0.026350487, 0.012143141, -0.010231838, -0.011920155, 0.02422257,
      -0.028006949, -0.008683683, 0.010391113, -0.015035578, -0.016526394,
      -0.036085386, 0.038557336, 0.008326907, 0.0026200768, -0.0013904724,
      -0.009804981, 0.01688317, 0.00048260382, 0.009269816, -0.01897286,
      0.008307794, -0.0120029785, -0.01264645, 0.015570742, -0.009855948,
      0.016870428, 0.007798113, 0.03440344, -0.0003906224, 0.025241932,
      0.024248054, -0.010435711, -0.0032778834, -0.033791825, 0.009505543,
      -0.015838325, -0.015341386, 0.004262204, 0.035167962, 0.02276998,
      0.0170743, 0.02125368, -0.0016054938, -0.011435959, -0.024464669,
      -0.008040211, 0.007619725, -0.00955014, 0.009448204, -0.020705774,
      0.0038799436, 0.0038353465, -0.021483038, 0.0142455725, 0.0035836918,
      -0.0104293395, 0.012627337, -0.03585603, 0.00072948035, 0.0347857,
      -0.02187804, -0.014818964, 0.02567516, 0.01884544, -0.014653317,
      0.027420817, -0.02055287, 0.010868939, -0.0073011746, 0.009314413,
      0.024566604, 0.004128413, -0.03616184, 0.033409562, -0.0019081167,
      -0.02144481, 0.0005917073, -0.011875558, 0.014869931, 0.02655436,
      0.017278172, -0.0038735725, 0.004666763, -0.020272546, 0.016628329,
      0.0019909397, -0.028720502, 0.037283137, 0.02940857, 0.020896904,
      -0.013544762, 0.0021804774, 0.021126261, -0.012933145, 0.017290914,
      0.01012353, 0.01138499, 0.051961936, 0.009709415, 0.024999833, 0.0194698,
      -0.029332118, 0.0026790085, -0.024120634, 0.0346328, -0.028312758,
      -0.013927022, 0.02245143, 0.0013681739, -0.007855453, -0.08103921,
      0.0011388176, 0.010760631, -0.0020705773, -0.0013562283, 0.04034122,
      0.0064028627, -0.002798465, -0.0034053035, -0.0021231382, -0.0063391523,
      -0.010741519, 0.0051318463, 0.018603342, 0.007409482, -0.010168128,
      -0.016641071, 0.009021346, -0.03397021, 0.015443322, 0.023241436,
      -0.025203705, -0.009913288, -0.0062881843, -0.03483667, 0.026172098,
      -0.03244117, 0.0037939348, 0.030325996, 0.024107892, -0.010301919,
      -0.023725633, -0.009448204, -0.0168322, 0.0037142974, 0.00038644142,
      -0.05027999, -0.023929505, -0.0012503102, -0.03244117, 0.015723646,
      -0.012678305, 0.02580258, -0.026350487, 0.003105866, -0.008224971,
      0.013621214, 0.0030644545, 0.0075560147, 0.0053484607, -0.01359573,
      0.0018077734, -0.02118997, -0.03820056, 0.021368358, 0.007900049,
      -0.0063646366, -0.0040392186, 0.0036601438, 0.0027029, -0.021342875,
      -0.00071673834, -0.017163495, 0.048929337, 0.02586629, 0.010136273,
      -0.026783716, -0.0037334103, 0.019762864, -0.004064703, 0.004826038,
      0.0062563294, 0.0064984276, 0.00033288513, -0.049489986, -0.009250703,
      -0.015456064, -0.011582492, 0.013914281, -0.0024241684, 0.0060014892,
      -0.012799354, -0.012786612, -0.0016707967, -0.018616084, 0.02131739,
      -0.016539136, -0.0052688234, 0.012366125, -0.0142455725, -0.0018141444,
      -0.005154145, 0.039907992, -0.0013291514, -0.026477907, -0.008256826,
      0.0073967394, -0.030376963, 0.026477907, -0.012296044, -0.032670528,
      -0.018934634, -0.07375078, 0.023458049, -0.026707264, -0.010454823,
      -0.031600196, -0.012174996, 0.010760631, -0.026987588, 0.025649676,
      0.010512162, -0.023266919, -0.009875062, -0.016653813, 0.006090683,
      -0.011875558, -0.009269816, 0.010110789, 0.028465662, 0.030937612,
      -0.005577817, -0.016080422, 0.0022250745, -0.009817722, -0.014767995,
      -0.046839647, 0.02624855, -0.00916788, 0.019941254, -0.0062021757,
      -0.00034224257, 0.013251696, -0.014818964, 0.009875062, 0.023878535,
      -0.0013347261, -0.015570742, -0.004036033, 0.021062551, 0.0134428255,
      -0.00047424185, -0.022056429, -0.006148022, 0.0017472488, -0.027216943,
      -0.021801587, 0.010970875, 0.01624607, -0.010913536, 0.016781233,
      -0.004083816, 0.017188977, 0.0129076615, 0.0064410884, -0.031600196,
      -0.010021594, -0.018819956, 0.019113023, -0.022413203, 0.0346328,
      -0.04003541, 0.0052019274, 0.013850571, 0.015328644, -0.0072756903,
      0.016335264, -0.005141403, 0.0139779905, -0.009072315, 0.009346268,
      -0.0113340225, -0.026223067, 0.0013625992, -0.0064920564, 0.0071992385,
      0.0040519605, -0.012920403, -0.0117672505, -0.010467566, -0.010843455,
      0.00420805, -0.010002482, -0.0007398332, -0.051324837, 0.011365877,
      0.027726624, 0.019992221, 0.008174002, 0.0017297285, -0.034989573,
      -0.0015322273, -0.04034122, 0.0076069827, -0.007963759, 0.017724143,
      0.0036123611, 0.0009620221, 0.0043227286, -0.012843951, 0.009811351,
      0.028261788, 0.007791742, -0.012965, 0.010798858, -0.024235312,
      -0.033384077, 0.019113023, -0.013200727, -0.04288962, -0.037461523,
      0.020654807, 0.030937612, 0.006278628, 0.027522752, 0.010142644,
      -0.0228974, 0.0071928673, -0.0038863146, -0.003962767, -0.021202713,
      0.008001985, 0.013251696, 0.003074011, 0.0034307875, 0.012805725,
      0.02168691, -0.023789342, 0.018195597, 0.006874317, 0.008683683,
      0.012041204, -0.003962767, -0.01726543, -0.02232401, -0.01820834,
      -0.009824093, 0.0109963585, 0.004306801, 0.02042545, -0.014372993,
      0.08985669, 0.007511418, -0.010225467, 0.019202216, -0.0005160516,
      0.013952507, 0.017112525, 0.017724143, -0.0052656378, -0.009556511,
      0.0018300719, -0.0064347177, 0.008696425, -0.026732747, -0.013850571,
      -0.002946591, -0.004552085, 0.017978983, -0.027242428, -0.008275938,
      0.025089027, -0.020323513, -0.024846928, -0.006211732, -0.009575624,
      0.008645457, 0.03346053, -0.008174002, -0.0026296333, -0.037206683,
      0.013684924, 0.018896408, -0.00961385, -0.01372315, -0.011678057,
      -0.00910417, 0.0054312837, 0.0035613931, -0.017303657, -0.0026598957,
      -0.024311764, 0.020310773, -0.032721493, -0.012933145, -0.010161757,
      -0.030631803, -0.014678801, -0.018807214, -0.011716283,
    ],
  },
  {
    id: "faq-7",
    question:
      "Πώς μπορώ να πραγματοποιήσω την πληρωμή για κάποια παραγγελία μου ;",
    answer:
      "· Με Αντικαταβολή, κατά την παράδοση των προϊόντων από τον διανομέα της εταιρίας ταχυμεταφορών που συνεργάζεται το carespot.gr (Η επιπλέον χρέωση ανέρχεται στα 1,5 €).\n\n(Αφορά παραγγελίες μέχρι 499€ - Για παραγγελίες από 500€ και πάνω θα πρέπει να επιλεγεί ένας από τους άλλους τρόπους πληρωμής)\n\n· Με χρέωση της πιστωτικής σας κάρτας. Για απόλυτη ασφάλεια, οι συναλλαγές γίνονται μέσω του ασφαλούς συστήματος της Τράπεζας Πειραιώς (GLOBE SSL).\n\n· Online πληρωμή μέσω της υπηρεσίας PayPal (εμπεριέχει προμήθεια)\n\n· Κατάθεση/έμβασμα σε τραπεζικό λογαριασμό της εταιρίας.\n\n· Με πληρωμή στο κατάστημά μας (για παραλαβές από το φαρμακείο)\n\nΓια περισσότερες λεπτομέρειες επισκεφτείτε τη σελίδα μας Τρόποι Πληρωμής.",
    embedding: [
      0.0050525335, 0.0030298904, -0.009322739, -0.0357263, -0.021344509,
      0.005300271, -0.016624466, 0.0066171894, -0.0125954775, -0.012738904,
      0.027016386, 0.009185832, -0.0049286652, -0.00071998604, 0.013253937,
      -0.021135889, 0.0242652, 0.023900114, -0.011826188, 0.009922524,
      -0.021839984, -0.00047713847, 0.00045146834, 0.0016917839, -0.0034389826,
      0.0074581914, 0.013768969, 0.0012680229, 0.0073278034, -0.0045309816,
      0.013860241, -0.028215956, 0.0022068163, -0.017954422, -0.017146017,
      0.0069301203, 0.025047528, 0.021214122, 0.029337293, 0.008136209,
      0.008859863, 0.000845077, 0.009309701, -0.009576996, -0.011956576,
      0.017693646, -0.026546989, -0.028711429, -0.0038953405, 0.013240898,
      0.0042832447, 0.0062977388, -0.037238803, -0.003954015, 0.00455054,
      0.027564015, 0.014251404, 0.018880177, 0.001809133, -0.025217032,
      -0.0016616316, -0.0027707443, -0.010659216, -0.015203237, -0.018358625,
      0.0017944643, -0.0036084868, 0.0029598067, -0.028189877, -0.0056718765,
      0.013586426, 0.010789604, -0.0038497047, -0.021500975, 0.014238366,
      -0.01342996, 0.01753718, -0.00595873, -0.011474141, 0.008814227,
      0.016846124, -0.022022527, -0.003227102, 0.007790681, 0.022374574,
      0.007432114, 0.0162333, 0.01473384, -0.026912076, -0.0034487618,
      0.029441603, 0.020171018, 0.005329608, 0.0076016183, 0.0052611544,
      0.021188045, -0.010991706, 0.029780611, 0.010783085, -0.0026142786,
      -0.011337234, 0.005016677, -0.017250327, 0.002123694, -0.011421986,
      -0.026468758, 0.011976135, 0.024343433, 0.008755552, -0.008247038,
      -0.018384703, 0.045218546, 0.020457871, -0.017680608, 0.023430718,
      0.012817137, 0.0019411509, -0.020940308, -0.031553887, -0.008644722,
      0.005111208, -0.0066237086, -0.010202859, 0.0011254111, 0.019740738,
      0.008794668, 0.00008826654, -0.0044592684, -0.017380716, -0.0045016445,
      0.017602375, 0.018371664, -0.012615035, 0.017954422, -0.005688175,
      0.00671498, -0.04540109, 0.025712507, -0.032440525, -0.0006490876,
      0.020575222, 0.018515091, 0.013312611, -0.004752641, -0.008964173,
      0.009824733, 0.010952589, -0.014642568, 0.01266719, -0.008292674,
      -0.0015671003, 0.011259001, 0.02482587, -0.005437178, 0.001776536,
      0.014525219, -0.021579208, 0.008618644, 0.009309701, -0.008970692,
      0.0059033153, 0.010750487, 0.01473384, -0.0048634713, -0.021826945,
      0.036039233, 0.0037290957, 0.015398819, 0.010118106, -0.020875113,
      -0.017941384, 0.027068542, -0.0028131204, 0.015672633, -0.0016820048,
      0.013821124, -0.012862773, -0.009329258, 0.014929422, 0.0038920806,
      -0.041411217, 0.017941384, -0.0017602375, 0.038516603, 0.0090945605,
      0.0032352514, 0.00368346, -0.020888152, 0.0041756746, -0.0140819,
      -0.009472686, 0.0065096193, 0.009629151, -0.012804098, -0.62294155,
      -0.0021807386, 0.010150703, -0.009948602, 0.0059522106, 0.049990747,
      0.013690736, -0.014864228, -0.025842894, 0.028789662, 0.0039833523,
      0.0035432929, -0.015033732, 0.0014277482, 0.01940173, -0.029285137,
      0.010685294, 0.0006478652, -0.014564335, 0.008064495, -0.007973224,
      0.0021367327, -0.017276404, 0.024995374, 0.0084361015, 0.0011906051,
      0.008931575, -0.027824791, 0.0018889956, 0.016298495, 0.000012815965,
      0.017745802, 0.0073473617, 0.012425973, 0.06003062, 0.011122094,
      -0.013390844, 0.031918973, 0.004247388, 0.018893216, -0.02936337,
      -0.013253937, 0.0008573009, -0.016494077, -0.010470154, -0.023808843,
      0.012452051, -0.013821124, 0.029963154, 0.0030152216, -0.017054746,
      0.025477808, -0.018945372, -0.023456795, 0.030849792, 0.018788906,
      0.017980501, -0.0203666, -0.0056360196, 0.016807009, 0.013795047,
      -0.02826811, -0.008566489, -0.027564015, 0.0025947206, -0.0017357898,
      -0.01572479, -0.00011979394, -0.003347711, 0.004201752, -0.003973573,
      0.0091206385, -0.018058732, 0.02610367, 0.004081143, 0.010235455,
      -0.0059685092, -0.014342676, -0.0083578685, -0.0036476033, -0.014929422,
      -0.005450217, -0.030197853, 0.013038796, 0.026442679, -0.010652697,
      -0.0061184554, -0.01765453, 0.018984487, 0.05233773, 0.014916383,
      0.05189441, 0.013951512, 0.005727291, -0.016428884, 0.0091727935,
      -0.024656365, -0.0012126081, 0.011754475, 0.018541168, 0.009107599,
      0.0009461277, 0.011852266, -0.017693646, 0.021292355, -0.003934457,
      -0.0407332, 0.0010528828, 0.041828457, -0.026416602, 0.001722751,
      -0.011037341, 0.010320207, -0.01440787, 0.012021771, -0.025477808,
      0.01788923, 0.009557438, 0.00048773247, 0.012804098, 0.00017694054,
      0.01787619, 0.015242353, -0.024630286, 0.002783783, 0.013625543,
      0.004420152, -0.029728455, -0.020496989, -0.006858407, -0.02221811,
      -0.021005502, -0.0006653861, -0.001039844, 0.011415467, -0.014864228,
      0.0105679445, -0.0020128642, 0.025164878, -0.013560348, 0.0068323296,
      -0.004860211, 0.0015809541, -0.011291598, 0.009772578, -0.013703775,
      0.01266719, -0.009479205, -0.022817893, 0.04146337, -0.004625513,
      -0.010470154, -0.0031244217, 0.0027903025, 0.0019949358, -0.010887395,
      -0.0041691554, 0.0023746907, -0.021631364, -0.0057501094, 0.011056899,
      0.0062227654, -0.019414768, 0.0017830554, -0.030719405, 0.007321284,
      0.010307169, -0.0040615853, -0.020144941, -0.03650863, 0.02393923,
      0.0018075032, -0.009713903, 0.0057012136, 0.02709462, 0.010561425,
      0.014329637, 0.00844914, -0.00844914, -0.009440089, 0.0031977647,
      0.006359673, -0.0030918247, 0.01689828, 0.012582439, 0.0046450715,
      0.007999302, 0.04952135, -0.0043288805, 0.020914229, 0.02165744,
      0.0010023575, 0.0041984925, 0.014812073, -0.008820746, -0.002578422,
      0.007947146, 0.012836695, 0.0051535843, 0.03413557, 0.013149627,
      0.01830647, 0.036691174, -0.010359324, 0.019036643, -0.007921069,
      0.029780611, 0.012941006, 0.02589505, 0.03259699, -0.0015785093,
      0.005371984, 0.00022838266, -0.0049449634, 0.015672633, 0.014499142,
      -0.024838908, -0.0046613696, 0.020614337, 0.021201083, 0.010554906,
      0.007816758, -0.007373439, -0.017237289, -0.013964551, 0.008136209,
      -0.0031537588, 0.027251085, -0.0016298495, -0.0030706367, -0.055023722,
      0.013025758, -0.019466924, 0.0266513, 0.046965744, -0.011493699,
      -0.0035987077, 0.014486102, 0.011206846, -0.0039474955, 0.0035041766,
      0.004808056, 0.014746878, -0.0126997875, -0.006033703, -0.0014676795,
      0.021527054, 0.0017520883, -0.0016485929, 0.00044168922, -0.04300195,
      0.0074907886, -0.029519835, -0.016533194, 0.027590094, -0.03718665,
      -0.0020992463, 0.006274921, 0.023222096, 0.030093541, 0.021096773,
      -0.009433569, 0.0084621785, -0.01799354, 0.004485346, 0.024721557,
      -0.009016328, -0.01775884, -0.013090951, 0.002263861, -0.013012718,
      -0.016767893, 0.0024464042, 0.0075494633, 0.004266946, -0.012106523,
      0.0010382142, 0.0021139148, 0.011891383, -0.005424139, -0.042949796,
      -0.033614017, 0.014655607, 0.0084882565, -0.0007517681, -0.043601736,
      -0.023587182, 0.009035886, -0.003184726, 0.019153992, -0.007562502,
      -0.0018058734, -0.005430659, -0.010457114, -0.009511802, -0.01863244,
      0.055440962, 0.024578132, 0.0038431853, -0.01820216, -0.0016640763,
      0.0066595655, 0.0025360459, -0.02730324, 0.02912867, 0.020679532,
      -0.017615413, -0.00082429644, -0.005828342, -0.045531478, 0.004778719,
      0.010548387, -0.0058218227, -0.018332548, 0.012419454, -0.0012435752,
      -0.012517245, -0.0003239326, 0.018919293, 0.011571932, -0.0044951253,
      -0.01830647, -0.007862395, 0.006728019, 0.06696726, 0.009583515,
      0.0154118575, 0.000920865, -0.00866428, -0.0036736808, -0.013312611,
      -0.024734598, 0.022309382, 0.00704095, 0.0075690215, 0.0026827324,
      0.018371664, 0.019571234, 0.007177857, 0.0140167065, -0.012804098,
      0.0024203267, 0.003585669, -0.03217975, 0.00021982596, 0.0017471988,
      0.0034259437, 0.013169184, 0.01668966, 0.0031782067, 0.036430396,
      -0.0062586223, -0.008403504, -0.03228406, -0.006411828, -0.022518001,
      -0.010770046, -0.0048471726, -0.009472686, 0.008618644, -0.0004363922,
      0.014655607, 0.017941384, 0.012080445, 0.015894292, 0.017054746,
      0.00037832884, 0.011774033, 0.02156617, -0.023782765, -0.023039553,
      0.0025865713, -0.0020927268, -0.008051457, 0.010450595, -0.01246509,
      -0.0029320994, -0.008735994, 0.00682581, -0.0030641172, -0.012412935,
      -0.0041137403, -0.0046678893, 0.019101838, -0.025164878, -0.009068483,
      0.022518001, -0.013025758, 0.0077059288, -0.025269188, -0.003618266,
      0.03705626, -0.0024284758, -0.014277482, 0.022922205, -0.021057656,
      -0.033014234, -0.010033354, 0.022596234, 0.016754853, 0.016807009,
      0.022257226, 0.016767893, 0.013482115, 0.016702699, -0.031997208,
      0.0049286652, -0.013599465, -0.009218429, 0.022491924, -0.008853342,
      -0.017980501, 0.009068483, 0.016585348, 0.0077841613, 0.020809919,
      0.00030335574, -0.03131919, -0.017211212, 0.014290521, -0.027485782,
      0.023691494, -0.011604529, -0.0020536105, 0.0049221455, 0.013025758,
      -0.01753718, -0.037421346, -0.013782008, 0.023795804, 0.020066708,
      0.006780174, -0.026416602, -0.017146017, 0.023417678, -0.011943538,
      0.031006258, 0.0006947234, -0.009590034, 0.026573068, -0.019766815,
      0.025516925, 0.0037062778, 0.013703775, -0.015450974, -0.02436951,
      0.017367678, 0.026403563, -0.046757124, 0.0043582176, -0.006620449,
      -0.002317646, 0.03160604, -0.024239123, 0.0067540966, 0.033405397,
      -0.0048471726, -0.015907332, -0.016937397, 0.015972525, 0.011154691,
      -0.007986262, -0.008742513, -0.006411828, -0.011545855, -0.00563276,
      -0.018123927, -0.009674787, 0.02167048, -0.041776303, -0.008168806,
      0.0036639017, 0.0066269683, 0.030589016, 0.004182194, 0.0016738555,
      -0.03369225, -0.01711994, 0.0014741989, -0.0021041357, 0.028476732,
      -0.021983411, 0.00060630403, 0.01766757, 0.027772637, 0.02373061,
      0.018267354, -0.009505282, -0.013768969, -0.030302163, -0.00036529003,
      -0.01842382, -0.020092785, 0.025934165, 0.020575222, 0.034839664,
      0.036482554, -0.0013307722, -0.0033444513, 0.00062463986, -0.009902966,
      -0.012171716, -0.034370266, -0.026677378, 0.013795047, -0.00541436,
      0.01006595, -0.00433214, -0.011923979, -0.015581362, 0.010665735,
      -0.027042463, 0.016481038, 0.018697634, 0.0155161675, -0.031553887,
      0.021579208, -0.012830175, 0.014746878, 0.018163044, -0.029389447,
      -0.014812073, 0.00443971, 0.012980122, 0.00097465, -0.010039873,
      -0.018110888, 0.016976513, -0.035361215, -0.0015581362, -0.009824733,
      0.0006474577, -0.011024303, 0.013808086, -0.030589016, -0.011545855,
      -0.0047493814, 0.00476568, -0.001527169, -0.007314765, -0.02352199,
      0.0105157895, -0.00014515848, -0.017445909, -0.0047102654, -0.013821124,
      0.013097471, -0.005297011, 0.017628454, 0.02005367, 0.0003337117,
      -0.024304317, -0.02730324, 0.008729475, 0.0024186966, -0.015503129,
      0.031032335, 0.017745802, -0.031110568, 0.015424896, 0.005645799,
      -0.021605285, -0.009583515, 0.01940173, -0.0036345646, 0.00964871,
      -0.0010642918, 0.009798655, -0.011435024, 0.041750226, 0.005169883,
      -0.0009339038, -0.025412614, -0.010985186, -0.016807009, 0.020523066,
      0.00986385, 0.030536862, -0.011995693, -0.023613261, -0.033509705,
      -0.027329318, -0.009518322, 0.042428244, -0.009844291, 0.0057044732,
      -0.0021155446, 0.021579208, 0.003044559, -0.015620478, -0.002524637,
      -0.013390844, -0.006268401, 0.013573387, -0.019792894, 0.0062455833,
      0.014251404, 0.01625938, 0.006636747, 0.034031257, 0.00638901,
      -0.0016917839, -0.008566489, -0.000080575686, 0.011350272, -0.017980501,
      -0.041958846, 0.0077580838, -0.009394453, -0.012086964, -0.00037343928,
      0.009981198, 0.021409703, 0.024643326, -0.006891004, -0.020822957,
      0.0065748133, 0.024604209, 0.019479962, 0.032962076, 0.021644402,
      0.0203666, -0.014525219, -0.0046548503, 0.010026835, -0.021957332,
      -0.01451218, 0.0015067959, -0.03011962, -0.0141731715, 0.04094182,
      -0.006317297, -0.023574144, -0.0111742485, 0.0021693297, 0.017732764,
      0.01029413, 0.009016328, -0.001202014, 0.012236911, -0.0004995489,
      -0.018880177, -0.007073547, -0.039820485, 0.003271108, -0.023678454,
      -0.009035886, -0.032623067, -0.02352199, -0.018671557, 0.0033509706,
      0.005776187, -0.023339445, -0.02491714, 0.0054469574, 0.034970053,
      -0.006956198, -0.017380716, -0.003173317, 0.0024578131, -0.0010243604,
      0.020731686, 0.0077580838, -0.018150005, -0.013625543, 0.024200005,
      0.0349179, 0.020849036, -0.009205391, -0.0032662186, 0.0042702057,
      -0.01992328, 0.008957653, -0.030354317, -0.03890777, 0.032649145,
      0.011617567, -0.04412329, -0.0155422455, -0.026938153, -0.027694404,
      0.024943218, -0.0009885038, 0.023561105, 0.0153597025, 0.028215956,
      -0.014160133, 0.009596555, 0.008364388, 0.0020324225, -0.008312233,
      0.012504206, -0.010287611, -0.030067464, -0.056379754, 0.0037453943,
      -0.02685992, -0.00010919992, 0.05351122, -0.006701941, 0.029624145,
      0.023013476, -0.012549842, -0.025634274, 0.007725487, 0.001115632,
      0.010320207, 0.00791455, 0.012771501, -0.026729533, 0.021527054,
      -0.018997526, 0.014799034, -0.03217975, 0.00049099216, 0.036560785,
      -0.012504206, 0.00017194913, -0.022244187, -0.035282984, 0.010959108,
      -0.030484706, 0.023248173, 0.0014016706, -0.017250327, 0.015085887,
      0.008690358, -0.028581042, -0.021487936, 0.007816758, -0.0035824094,
      -0.00758206, 0.00062871445, -0.0422457, 0.0031032336, 0.0020585,
      -0.0026990308, -0.0026550249, -0.004693967, -0.033327162, -0.010783085,
      0.000088826804, 0.0036736808, 0.016454961, -0.029624145, -0.00335749,
      -0.0066758636, -0.0076016183, -0.03802113, -0.0349179, -0.002361652,
      -0.0044136327, 0.008892459, 0.020236213, -0.004390815, -0.034631044,
      0.010874356, 0.0017651271, 0.0062423237, 0.015294508, 0.24471213,
      -0.031345267, -0.007894991, 0.020457871, 0.00010354638, 0.0063075176,
      0.022231149, -0.0105679445, -0.0058316016, 0.0002611834, -0.010776565,
      -0.010600542, -0.025516925, 0.0009322739, -0.012504206, -0.036639016,
      -0.037004106, -0.013729853, -0.016833086, -0.018436858, 0.0042343494,
      0.012641113, 0.020718647, 0.013143106, 0.016859164, 0.016624466,
      -0.0003675311, 0.002956547, 0.022491924, 0.016741814, -0.014538258,
      0.010685294, 0.010548387, -0.009707384, 0.011435024, 0.013964551,
      0.03358794, -0.02436951, 0.03283169, 0.001278617, 0.013782008,
      0.020144941, 0.012367299, 0.008872901, -0.019753776, 0.002902762,
      -0.012178236, -0.024095695, 0.0073604006, 0.02186606, -0.033822637,
      -0.0012769871, 0.026338369, 0.031058414, -0.015946448, -0.02709462,
      -0.008579528, 0.0057012136, -0.01636369, 0.013860241, 0.0015654705,
      0.012993161, 0.017067784, 0.022791816, -0.010261533, -0.0029532874,
      -0.006708461, -0.028633198, 0.020144941, -0.0047721993, -0.0030201112,
      -0.0074777496, 0.025908088, 0.027564015, -0.030641172, -0.0011400798,
      -0.011239443, 0.02002759, 0.019688582, 0.024539014, 0.0030184814,
      0.036326088, -0.0020112344, -0.022400653, -0.0042213104, -0.023743648,
      0.012869292, 0.01569871, -0.016468, -0.018554207, -0.002958177,
      0.016754853, -0.030823715, 0.002676213, -0.004354958, -0.016663581,
      0.018410781, 0.04746122, -0.0064509446, 0.01580302, -0.0014684944,
      0.023104748, -0.0034031258, 0.00077947555, -0.013612503, -0.0027104397,
      0.0019851567, 0.037447423, 0.008110131, -0.03283169, 0.010144183,
      -0.030484706, 0.008429582, -0.010202859, -0.000920865, 0.005596903,
      -0.005319829, 0.00028644607, 0.018280393, 0.019792894, 0.0049677812,
      -0.015437935, -0.010404959, 0.00061852793, 0.015620478, -0.028242033,
      -0.026781688, 0.0030087023, 0.01962339, -0.03217975, 0.010255014,
      0.00015707675, 0.023052592, 0.0067671356, -0.040498503, 0.034057338,
      -0.019779854, -0.013012718, -0.0055121514, -0.02143578, -0.0062260255,
      -0.00024182892, -0.019805932, -0.009948602, 0.009400972, -0.018619401,
      0.0002090282, -0.013495155, 0.009237987, -0.0084882565, -0.03859484,
      0.0028978726, 0.008690358, -0.009955121, 0.018475974, 0.0024105476,
      -0.022113798, -0.044801306, 0.011180768, 0.0015833988, -0.01753718,
      0.012993161, 0.027537938, -0.008618644, -0.010215897, -0.020144941,
      -0.16387159, -0.0023567625, -0.0011123723, -0.0017325301, 0.009244506,
      0.028867895, 0.0069953143, 0.0072300127, -0.013090951, -0.0031879856,
      0.009870369, -0.018801944, -0.021592246, -0.019662505, -0.009870369,
      -0.028972205, -0.032023285, 0.017498065, 0.0049123666, 0.008957653,
      0.03932501, -0.023026515, -0.0071582994, 0.0038203674, 0.0155422455,
      -0.00855345, -0.03564807, 0.022830933, -0.020431794, -0.0037616927,
      -0.008207923, 0.045766175, 0.0058870167, 0.0019493, 0.016637504,
      -0.0017814256, 0.022778777, 0.0015988824, -0.015372741, 0.026129749,
      0.013821124, 0.014368754, -0.011995693, 0.032779533, -0.018071773,
      0.03304031, 0.05048622, -0.0022931984, -0.0008047382, 0.009544399,
      0.01602468, -0.013345208, -0.015894292, 0.013742891, 0.013221339,
      0.012256469, 0.01170232, 0.01615507, -0.01745895, 0.0050297156,
      -0.02221811, -0.013560348, 0.0065030996, 0.007386478, 0.005068832,
      -0.0016591868, 0.0044462294, 0.010600542, -0.024121772, 0.012106523,
      0.016220262, -0.029832765, 0.0053882827, -0.010124626, 0.009713903,
      0.011865305, -0.026416602, 0.0014033004, -0.014903344, 0.01137635,
      0.01473384, 0.024878023, -0.021096773, 0.0011669723, -0.010900434,
      0.017628454, 0.027146773, 0.0076994095, -0.028033413, 0.0072169737,
      -0.006578073, -0.022400653, 0.011219884, -0.024943218, 0.015020694,
      0.006512879, 0.02220507, 0.003184726, 0.004746122, -0.011141651,
      0.022713583, -0.0030967142, -0.030849792, 0.04354958, 0.024630286,
      0.0015907332, 0.018684596, 0.03077156, 0.018215198, -0.0140688615,
      -0.0032580693, 0.006164091, 0.016663581, 0.047695916, 0.011734917,
      0.0127258655, 0.013899357, -0.022283303, 0.0036150063, -0.0069627175,
      0.044149365, -0.036326088, 0.00682581, 0.0022084462, -0.004795017,
      -0.0010781455, -0.09127157, -0.0010919992, 0.0154900905, -0.005792485,
      0.020288367, 0.0097465, 0.0092771035, 0.0035237346, -0.0106200995,
      0.0026908817, -0.02416089, -0.015346663, 0.004615734, 0.00015789167,
      -0.016337613, -0.013364767, -0.0035074363, -0.00975302, -0.0011148171,
      0.017028669, 0.024851946, -0.009140196, -0.0158291, -0.0026191683,
      -0.027459705, 0.013521232, -0.010711371, 0.0155422455, 0.028215956,
      0.016937397, -0.0073538814, -0.009955121, -0.012171716, -0.01397759,
      -0.012034809, -0.01906272, -0.02881574, -0.010313688, -0.0020275328,
      -0.009889927, 0.010698332, -0.0074842693, 0.031475656, -0.026194941,
      0.003238511, -0.0030168516, -0.01765453, 0.007510347, 0.02186606,
      -0.00014138945, -0.04639204, -0.007432114, -0.037942898, -0.01094607,
      0.01961035, -0.023130825, -0.035882767, 0.029806688, -0.030354317,
      0.006838849, -0.01766757, 0.0077580838, -0.035361215, 0.028320266,
      0.020431794, 0.016520156, -0.0026159086, -0.009166274, -0.0052089994,
      -0.004752641, 0.007334323, 0.021957332, -0.006672604, 0.018019617,
      -0.03366617, -0.0162333, -0.03304031, -0.017185133, 0.0022606014,
      0.023274252, -0.023339445, -0.027381472, -0.0154118575, -0.009322739,
      -0.011793591, 0.021644402, -0.0084361015, 0.01072441, 0.0069822753,
      -0.018880177, -0.01775884, 0.015111965, -0.0030722665, -0.0008678949,
      -0.032570913, -0.023235135, -0.0011392648, -0.0031684276, 0.020966385,
      -0.017954422, -0.024878023, -0.005776187, -0.05653622, 0.014955499,
      -0.012830175, -0.002501819, -0.013286534, -0.031371344, 0.03197113,
      -0.020783842, -0.020979423, 0.0011612679, -0.01765453, -0.003553072,
      -0.012693268, -0.015894292, -0.017615413, -0.018828021, 0.018228237,
      0.0034063857, 0.023574144, 0.011402427, -0.022022527, -0.014590413,
      -0.012439012, -0.0071126632, -0.01461649, 0.018762829, -0.003973573,
      0.03087587, -0.0060695596, -0.0119109405, -0.015555284, -0.02143578,
      -0.006728019, 0.018984487, -0.0018563987, -0.0074842693, 0.004064845,
      0.024434704, -0.0034683198, 0.027172852, 0.0001450566, -0.017693646,
      0.017250327, -0.030641172, -0.0014195989, 0.0010536978, 0.004853692,
      -0.00184173, 0.01809785, 0.00281638, 0.021396665, 0.029963154,
      0.008957653, -0.0141210165, -0.013860241, -0.029676301, 0.00043883699,
      -0.02699031, 0.04052458, -0.035256907, 0.010698332, -0.01765453,
      -0.02306563, -0.012823656, -0.008312233, -0.022374574, -0.01842382,
      -0.0062455833, 0.009902966, -0.035178673, -0.0022443028, 0.021514013,
      0.0013332169, 0.004504904, 0.013808086, 0.005277453, -0.0005570011,
      -0.016402805, -0.010092028, 0.019114876, 0.016872203, -0.0021318432,
      -0.048791178, 0.012073926, 0.031267032, 0.033561863, 0.0003469542,
      -0.012888851, -0.009511802, 0.0037388748, -0.004909107, 0.019792894,
      -0.009414011, -0.009557438, 0.021644402, 0.0026240577, 0.018006578,
      -0.03520475, -0.012073926, 0.033640094, 0.024134813, 0.011259001,
      0.009061963, -0.022048606, -0.020392677, 0.010241974, -0.022687506,
      -0.0394554, 0.0058218227, 0.020510027, 0.00062463986, 0.009948602,
      0.031267032, 0.0050134175, -0.0017194913, 0.011950057, 0.03455281,
      -0.022231149, -0.028711429, 0.024082657, 0.014968539, 0.0047982773,
      0.013573387, -0.0010634768, 0.01842382, 0.008123171, -0.00060467416,
      0.0041919732, -0.0053817634, 0.019453885, -0.0077972, -0.02524311,
      -0.05752717, -0.024317356, 0.018762829, 0.0042865044, -0.019766815,
      0.008605606, 0.00014261184, 0.08782933, -0.0049580024, -0.001592363,
      -0.0037616927, 0.030171774, 0.0033770483, 0.015737828, -0.0039148987,
      -0.0139906285, -0.0022720103, -0.0033835678, -0.0029842546, 0.008031898,
      -0.034813587, 0.010633139, 0.011337234, -0.01743287, 0.0119891735,
      -0.025099684, -0.019545157, 0.011330714, 0.010652697, -0.021631364,
      -0.0059554703, -0.0020193837, 0.005120987, 0.008957653, -0.011663203,
      0.010092028, -0.02610367, 0.0010553276, 0.014746878, 0.00076317706,
      -0.022726621, -0.016820047, -0.021422742, -0.004279985, -0.004883029,
      -0.01636369, 0.015646556, -0.011082977, 0.028581042, -0.040550657,
      -0.022178993, -0.03087587, -0.016481038, 0.013006199, 0.008207923,
      -0.045036003,
    ],
  },
  {
    id: "faq-8",
    question: "Πόσος χρόνος απαιτείται για να παραλάβω την παραγγελία μου ;",
    answer:
      "Το carespot.gr εγγυάται την παράδοση με courier σε 1-3 εργάσιμες μέρες για όλη την Ελλάδα, ανάλογα με το αν ο προορισμός είναι αστικός, δυσπρόσιτος ή νησιωτικός. Στις αστικές περιοχές, η παράδοση γίνεται συνήθως την επόμενη εργάσιμη ημέρα, εφόσον η παραγγελία έχει καταχωρηθεί μέχρι τις 17:00 και τα προϊόντα είναι άμεσα διαθέσιμα στο φαρμακείο.\n\nΓια περισσότερες λεπτομέρειες επισκεφτείτε τη σελίδα μας Τρόποι Αποστολής και για την Πολιτική Αποστολών δείτε εδώ.",
    embedding: [
      0.017738758, -0.008003433, -0.015075319, -0.032066215, -0.029363416,
      -0.0007499119, -0.008606971, -0.0013956807, -0.023052512, -0.004388768,
      0.01746323, 0.009039944, -0.015547654, -0.011604979, 0.013842003,
      -0.022868827, 0.027920173, 0.02366917, 0.0018483339, 0.0077672666,
      -0.009859968, -0.011847706, 0.0030144078, 0.0026470372, 0.0030931302,
      0.010935839, 0.019824898, -0.0140650505, 0.010719352, -0.03114779,
      0.030386806, -0.015009718, -0.009964931, -0.0041198, -0.020047944,
      -0.026542535, 0.024823764, -0.0010422504, 0.03298464, 0.0105028665,
      0.016754728, -0.007340854, 0.012615248, -0.0032472948, -0.033378255,
      0.008167438, -0.021097576, -0.03951859, 0.0018007725, 0.02301315,
      0.014957236, 0.004559333, -0.0446093, -0.022567058, -0.005015266,
      0.014366819, 0.0066028326, 0.0061928206, 0.0099846115, -0.006202661,
      0.0066618742, -0.0031406917, -0.010050213, -0.00822648, -0.01335655,
      0.0049923058, -0.015062199, 0.0019975782, -0.027027989, -0.005080868,
      0.005622084, 0.028025137, 0.0026240766, -0.0067832377, 0.03301088,
      -0.0073998957, 0.011513135, -0.0024207106, -0.0108046355, 0.0044314093,
      0.01440618, -0.022842586, 0.0013571396, 0.016912173, 0.019719936,
      0.021622391, 0.0021173018, 0.015193403, -0.028654916, -0.022120966,
      0.014983477, 0.015810061, 0.013435272, 0.012851415, 0.004247724,
      -0.002332148, -0.0122281965, 0.021464946, 0.005930413, -0.018945832,
      0.0054646395, -0.0074523776, -0.016072469, -0.0012898976, -0.0022845867,
      -0.03663211, 0.006448668, 0.018630944, 0.0011439334, -0.015075319,
      -0.035844885, 0.06544447, -0.002806122, -0.022068484, -0.0003286246,
      0.010083014, 0.01130321, 0.0033096166, -0.03463781, -0.011644339,
      0.008069036, -0.012162595, -0.003022608, 0.008082156, 0.045186598,
      -0.0034342601, 0.0053465557, -0.015928145, -0.018263573, 0.0051563103,
      0.021687992, 0.005376077, 0.00583529, 0.023865975, -0.028654916,
      0.014550504, -0.031357713, 0.0065995525, -0.039833482, 0.019824898,
      0.007852549, 0.010529107, 0.012864536, -0.021779835, -0.0035949848,
      -0.017253304, 0.014957236, 0.0071834093, -0.00023391182, -0.0036737071,
      0.020480918, 0.0067373165, 0.028025137, -0.002674918, 0.0033407775,
      0.022514576, -0.035818644, -0.0029340456, 0.004926704, 0.0017712517,
      0.014602986, 0.0075179795, -0.0036540267, -0.0130351, -0.014248735,
      0.02537482, 0.015114681, 0.01683345, 0.022252169, 0.004874222,
      -0.015167163, 0.033535697, 0.0040705986, 0.026686858, 0.010870237,
      0.03699948, 0.0025929157, -0.008488888, 0.023078753, 0.013946967,
      -0.06355514, 0.015403329, 0.0059140124, 0.012031391, -0.019680575,
      0.024180865, 0.015075319, -0.003949235, -0.0017023697, -0.0016269274,
      0.0059796143, 0.016977776, 0.008718494, 0.00028659837, -0.62516,
      0.0074523776, 0.008023114, -0.0019467368, -0.0058024894, 0.026804943,
      0.0072621317, -0.002850403, -0.027631527, 0.031751327, 0.0028996046,
      0.0032817358, -0.017069617, -0.013422151, 0.012457804, -0.020966372,
      0.009197388, -0.0023879097, -0.013566475, 0.0008552849, -0.017069617,
      0.0015457451, 0.0040607583, 0.01885399, 0.021005733, 0.0029914472,
      0.0015506652, -0.016072469, -0.0034605009, 0.011952668, 0.010535668,
      0.009722204, -0.020690843, 0.008344564, 0.05211416, -0.016269274,
      -0.010962079, 0.028392509, 0.02198976, 0.02429895, -0.040961836,
      -0.0014129012, 0.013474633, -0.026240766, -0.018421017, -0.02302627,
      0.020756446, 0.0028914043, 0.04395328, -0.017108979, 0.006346985,
      0.02575531, -0.004218203, -0.027736489, 0.0066749947, -0.009394194,
      0.02126814, -0.025453543, -0.013094142, -0.005763128, 0.026949266,
      -0.009492597, -0.0052940743, -0.010312621, -0.00480206, -0.00958444,
      0.0073277336, 0.00652083, 0.008764416, 0.020848287, 0.0060747373,
      0.017607553, -0.021176297, 0.03256479, 0.024889367, -0.011683701,
      -0.010883357, -0.014327458, -0.0192476, 0.0034211397, -0.009728764,
      -0.011198247, -0.023039391, 0.0076688635, 0.0460263, -0.018998314,
      -0.009348272, -0.009230189, 0.011591858, 0.017030256, 0.0076295026,
      0.042719968, 0.013179424, 0.008620092, -0.022160327, 0.011795224,
      -0.018053647, -0.0023829895, 0.0024420312, 0.010345422, 0.01440618,
      -0.008121517, 0.015600135, -0.027159192, 0.017227063, 0.00017282004,
      -0.035871126, 0.019431287, 0.03699948, -0.004907023, 0.0027339598,
      0.0012849775, -0.010004292, -0.016177433, 0.00583201, -0.023131235,
      0.0013325389, -0.0042247633, 0.0021074614, 0.007163729, 0.007137488,
      0.0055007203, 0.024836885, -0.009518838, -0.0034506607, 0.030570492,
      -0.0022042242, -0.025597867, -0.02094013, -0.0046216547, -0.020034824,
      -0.010693111, 0.013553355, -0.00892842, 0.005038227, -0.021123815,
      0.018578462, -0.030780418, 0.027841453, -0.01923448, 0.008705374,
      0.01680721, 0.004697097, -0.014379939, 0.0019024555, -0.02368229,
      0.010607829, 0.0046905368, -0.021674871, 0.020441556, 0.00033149467,
      -0.016728489, -0.01817173, 0.009919009, 0.00068759004, -0.01028638,
      -0.011027682, -0.026673738, -0.013415591, -0.012661169, 0.01713522,
      0.009479476, -0.008679133, 0.004834861, -0.022567058, 0.00040386178,
      0.004592134, 0.00789191, -0.018644065, -0.032144938, 0.022409614,
      -0.00924987, -0.013389351, 0.0044051684, 0.014524263, 0.011053923,
      -0.0017548512, -0.008180559, -0.00044404296, -0.004765979, -0.0005654065,
      0.0031111708, -0.0013563195, 0.020966372, 0.017686276, 0.013868244,
      -0.0098468475, 0.032328624, -0.020008584, 0.021687992, 0.0342442,
      -0.008265841, 0.0032325343, 0.019733056, 0.008160878, -0.007996873,
      0.008187119, 0.0042313235, 0.0157707, 0.030649213, 0.018670304,
      0.012451244, 0.022265289, -0.0022419454, 0.013933847, -0.0018909752,
      0.025558505, 0.013776402, 0.02605708, 0.021924159, 0.004313326,
      0.011723062, -0.004329726, 0.0029914472, 0.0039328346, 0.006451948,
      -0.031016584, 0.0027995617, -0.004428129, 0.03324705, 0.009761564,
      -0.007439257, 0.0003669607, -0.018211091, -0.03156764, 0.006756997,
      0.012648049, 0.045842618, 0.008338003, -0.01642672, -0.04773195,
      0.004966065, -0.029704547, 0.022199687, 0.02432519, -0.0192476,
      0.010411024, -0.0031259311, 0.01440618, 0.0027438, 0.018447258,
      0.009374513, 0.021687992, -0.011742743, -0.024141503, -0.022816345,
      0.02878612, 0.017922442, 0.00031365914, 0.001475223, -0.041722815,
      0.0007240811, -0.008042795, -0.0082855215, 0.015928145, -0.036894515,
      -0.002017259, 0.014327458, 0.015560774, 0.03744557, 0.015127801,
      0.031646363, 0.015193403, -0.012877655, 0.008383924, 0.019182,
      -0.023144355, -0.015377088, 0.012129794, -0.0078000673, -0.008357684,
      -0.015534533, 0.023144355, 0.0060156956, -0.0002566675, -0.0041427608,
      0.016400479, -0.004523252, 0.007872229, -0.010470065, -0.048256766,
      -0.050670918, 0.0020582601, 0.008738175, -0.008659452, -0.036133535,
      -0.017279545, 0.021924159, -0.010535668, 0.016872812, -0.00032841958,
      0.006340425, -0.00068553997, -0.0028996046, 0.000018002087, -0.000786813,
      0.07011533, 0.010345422, -0.0057992092, -0.033352014, 0.000857745,
      0.0011775544, 0.017332025, -0.04214267, 0.02261954, 0.007727905,
      -0.013828883, -0.01545581, -0.011342571, -0.03839024, -0.013415591,
      0.011093284, 0.0035457835, -0.004628215, 0.00034133496, -0.0035589037,
      -0.016256154, 0.0031095308, 0.022330891, 0.0010217498, 0.008055915,
      -0.011067043, -0.01650544, 0.013933847, 0.07200466, 0.021136936,
      0.019063916, -0.0041394806, -0.012962938, 0.012871095, -0.021320622,
      -0.014957236, 0.01336311, 0.009859968, 0.00034236, 0.0017286104,
      0.0061961007, 0.005379357, 0.013245027, 0.013448392, 0.010148616,
      0.016872812, 0.019090157, -0.02366917, -0.01029294, 0.02019227,
      0.0034014592, 0.010063333, -0.025912756, -0.03287968, 0.021530548,
      0.014314338, -0.0068357196, -0.01884087, 0.010686552, -0.016518561,
      0.0046839765, -0.0072555714, -0.0010807915, 0.0020795807, -0.006934122,
      0.01674161, 0.01403881, 0.011552497, 0.027946414, 0.014104411,
      0.0009733684, 0.020297231, 0.030439287, -0.018945832, -0.01920824,
      0.020638362, 0.01167714, -0.0112704085, 0.017108979, 0.0020467797,
      -0.030386806, -0.00022263649, -0.007413016, -0.00017507511, -0.004995586,
      -0.005248153, 0.010889918, 0.0052645537, -0.016597284, -0.018302934,
      0.012392201, -0.013185984, 0.0009832087, -0.030281844, -0.012772692,
      0.015928145, 0.001608067, -0.016557923, 0.015508292, -0.0322499,
      -0.017213942, -0.00094220747, 0.0141175315, 0.015206523, 0.018421017,
      0.01886711, 0.018749027, 0.027893934, 0.00856105, -0.022081604,
      0.015823182, -0.0018630944, -0.015836302, 0.025938997, -0.020244751,
      -0.018066768, 0.010115815, 0.024115263, 0.016557923, 0.028261304,
      0.012123234, -0.026778702, -0.015272126, -0.0130351, -0.026870543,
      0.020270992, -0.0015031039, -0.011427853, 0.009912449, 0.006481469,
      -0.008397045, -0.024285829, -0.015206523, 0.026214525, 0.035162624,
      0.0141175315, -0.021792956, -0.012615248, 0.0061928206, -0.019588731,
      0.008082156, -0.007681984, -0.0058221696, 0.025453543, 0.0016679286,
      0.04838797, 0.016321756, 0.021884799, -0.017358266, -0.014878514,
      -0.0013628798, 0.0072490117, -0.021661751, 0.012136354, -0.010247019,
      0.00017938024, 0.017410748, -0.0026978788, 0.011060483, 0.0384952,
      -0.009774685, -0.024115263, -0.033168327, 0.021281261, -0.0059894547,
      0.005727047, -0.01095552, -0.0036146655, -0.012142914, -0.013907606,
      -0.02609644, -0.01781748, 0.023288678, -0.031698845, -0.01440618,
      -0.0017696116, 0.00093974744, 0.020638362, 0.01473419, 0.0030570491,
      -0.035110146, -0.01233972, -0.017922442, -0.0066028326, 0.017043376,
      -0.021832317, 0.00045880338, 0.03219742, 0.030229362, -0.0027323198,
      0.00025174735, -0.019654334, -0.00307837, -0.016623525, 0.018630944,
      -0.029205972, -0.019601852, 0.029757028, 0.009991172, 0.030727936,
      0.03290592, 0.0037589897, -0.0011775544, 0.016676007, -0.018302934,
      -0.024902487, -0.038626406, -0.03844272, 0.022330891, -0.0028602434,
      0.015718218, -0.00062157813, 0.00821336, -0.016347997, 0.020074185,
      -0.016046228, 0.0069472427, 0.020992612, 0.014222495, -0.030806659,
      0.0066356338, -0.020887649, -0.00027347798, -0.0017302504, -0.021648632,
      -0.007071886, 0.008875939, 0.0061272187, 0.006934122, -0.010837436,
      -0.014983477, 0.018749027, -0.03185629, -0.012851415, -0.026844302,
      -0.01372392, -0.013592716, 0.006953803, 0.0013833804, -0.0131597435,
      -0.016885933, -0.0039820364, -0.0023141075, -0.018421017, -0.027946414,
      0.014314338, 0.002566675, -0.020166028, -0.0005424458, 0.0036638668,
      0.02713295, 0.004490451, 0.02810386, 0.026437571, -0.00051989517,
      -0.013205665, -0.028130101, 0.013618957, -0.0026831182, 0.0065601915,
      0.036842033, 0.010601269, -0.001268577, 0.006140339, 0.020966372,
      -0.018801508, -0.016072469, 0.01745011, -0.017082738, 0.009308912,
      -0.0029914472, 0.011500016, -0.018119248, 0.04397952, 0.0033752185,
      -0.011467215, -0.023223078, -0.013513994, -0.0058812117, 0.006789798,
      -0.00137272, 0.019037675, 0.008278961, -0.024666319, -0.020979492,
      -0.030124398, 0.00480206, 0.025584746, -0.0047495784, 0.014878514,
      0.013382791, 0.018578462, -0.009315472, -0.015547654, -0.0022517857,
      -0.010069894, -0.024141503, 0.019300083, -0.0013866605, -0.020861408,
      -0.004251004, 0.029520862, 0.011336011, 0.033168327, 0.00084872474,
      -0.00446749, 0.0069210017, -0.0036277857, 0.011204807, -0.019313203,
      -0.045449007, -0.017764999, -0.003394899, 0.002573235, 0.0018696545,
      0.010811196, 0.008475767, 0.024587598, -0.010791515, -0.014944116,
      0.0058484105, 0.018630944, 0.01372392, 0.024089023, 0.008233041,
      0.01611183, -0.016308635, -0.02122878, 0.009971491, 0.000582217,
      -0.0026142362, 0.015691977, -0.031042825, -0.02334116, 0.05316379,
      0.006717636, -0.009066184, -0.010155176, 0.009164588, 0.01851286,
      -0.0017122099, -0.014458662, 0.0044084485, 0.0071506086, -0.00719653,
      -0.010023972, -0.02776273, -0.01545581, 0.003985316, -0.009315472,
      -0.016190553, -0.010771834, -0.0059632137, -0.0017876521, 0.0018844149,
      0.004998866, -0.027710248, -0.01510156, 0.014078171, 0.018250452,
      -0.018263573, -0.026188284, -0.013842003, -0.024902487, -0.00032411446,
      0.037576776, 0.0017663315, -0.016780969, -0.013140063, 0.014379939,
      0.006140339, 0.021858558, -0.012700531, -0.000616658, 0.013986328,
      -0.020861408, -0.011250728, -0.030413046, -0.042798687, 0.03049177,
      0.026463812, -0.04628871, -0.015652617, -0.02470568, -0.003736029,
      0.01817173, 0.0032292542, 0.018368537, 0.009781245, 0.015259005,
      -0.011099843, 0.003988596, -0.016151192, 0.0069800434, -0.03949235,
      0.022475215, 0.00008020859, -0.0370782, -0.024430152, -0.004533092,
      -0.010319181, -0.009426995, 0.050802123, -0.004628215, -0.0009479477,
      0.037235647, -0.025991479, -0.02397094, 0.0036802674, -0.011290089,
      0.011427853, -0.0015637856, -0.017988045, -0.018985193, 0.030150639,
      -0.009184268, 0.020034824, -0.02705423, 0.0024469513, 0.039544832,
      -0.010791515, 0.0039557954, -0.017502591, -0.030124398, 0.000721211,
      -0.034217957, 0.02462696, 0.0075901416, -0.0026552374, 0.01612495,
      0.0033653781, -0.023721652, -0.036553387, 0.01062751, -0.013592716,
      -0.029704547, -0.006481469, -0.030964103, 0.0066946754, -0.0029389658,
      0.0071046874, 0.00094138744, 0.00060476764, -0.019378806, -0.00616002,
      -0.02227841, 0.017056497, 0.0267131, -0.0439008, -0.011395052,
      0.013238466, -0.0002882384, -0.015075319, -0.032328624, 0.008095277,
      -0.0031406917, 0.01957561, 0.00961724, -0.006481469, -0.02986199,
      0.013245027, -0.0019254162, -0.0023485485, 0.007996873, 0.24624334,
      -0.023577327, 0.000513335, 0.02439079, -0.0002886484, -0.0078000673,
      0.029520862, -0.0029652065, -0.01786996, 0.0034211397, -0.001436682,
      -0.006002575, -0.018578462, 0.00019034806, -0.011447534, -0.040305816,
      -0.026923025, -0.01714834, -0.023695411, -0.009643481, 0.010069894,
      0.030649213, 0.010706232, 0.0016662886, 0.014970357, 0.012484044,
      -0.015993746, -0.015901905, 0.008442966, 0.016767848, -0.010529107,
      0.0015367249, 0.0011193326, 0.016885933, -0.009807486, 0.016676007,
      0.05040851, -0.029757028, 0.023236198, -0.00052973547, -0.014891635,
      0.025860274, 0.012018271, 0.020428436, -0.007905031, 0.0075573404,
      -0.011368812, -0.01645296, -0.023288678, 0.028392509, -0.016767848,
      -0.00616986, 0.014537384, 0.016977776, 0.0013144984, -0.0108046355,
      0.0028979646, 0.0024239908, -0.021320622, 0.01545581, 0.004939824,
      0.0069013215, 0.01474731, 0.013553355, -0.006383066, 0.004697097,
      -0.03151516, -0.013946967, 0.008954661, -0.011257288, 0.006022256,
      0.00308657, 0.011919867, 0.0031259311, -0.043244783, 0.007019405,
      0.0009725484, 0.026791822, 0.020061065, 0.011454094, -0.0023387081,
      0.0157707, 0.0061468994, -0.016925294, 0.0067373165, -0.007747586,
      0.016190553, 0.0069472427, -0.003050489, -0.008606971, 0.014301217,
      0.011493456, -0.021373104, 0.007065326, -0.022173448, -0.012864536,
      0.026673738, 0.062243097, -0.0017646914, -0.003670427, 0.013697679,
      0.023472365, -0.008416725, 0.01779124, -0.028287545, -0.008869379,
      -0.016374238, 0.013684559, 0.021071335, -0.0315414, -0.0027667608,
      -0.02156991, 0.0027848012, -0.017332025, -0.01130321, -0.01642672,
      -0.017436989, -0.00445437, 0.012661169, 0.0041198, 0.007413016,
      -0.007977192, -0.011421293, -0.0033784986, 0.0017105698, -0.046472397,
      -0.026516294, -0.0090793045, 0.012713651, -0.038154073, 0.0053334357,
      -0.016925294, 0.0065569114, 0.01609871, -0.016033107, 0.014694829,
      -0.01851286, -0.0028946844, -0.004588854, -0.019378806, -0.0033686582,
      -0.0109752, -0.004700377, 0.0131335035, 0.0017236903, -0.0122281965,
      -0.013881365, -0.0033719384, -0.006248582, 0.009859968, -0.03532007,
      0.002161583, -0.00444453, -0.012608687, 0.031252753, -0.00824616,
      -0.021727353, -0.03188253, -0.003227614, -0.0026601576, -0.013855124,
      -0.012785813, 0.022921309, 0.011421293, -0.009571319, 0.00096434815,
      -0.1659466, -0.013087582, 0.0049857455, -0.021845438, 0.010666871,
      0.020152908, 0.018224211, 0.018604703, -0.024954967, -0.00412308,
      0.036946997, -0.0032751756, -0.017660035, -0.016689127, -0.010430704,
      -0.020428436, -0.031016584, 0.024233347, 0.0076951045, 0.012759572,
      0.0144193005, -0.030019436, -0.015823182, 0.003019328, -0.0033719384,
      -0.013868244, -0.029993195, 0.019129518, -0.0061534597, 0.009643481,
      -0.024745042, 0.02126814, 0.012090432, -0.0017417308, 0.0024125103,
      -0.008954661, 0.009026824, -0.012930137, -0.017279545, 0.045370284,
      0.010069894, 0.011447534, 0.0066061127, 0.03049177, -0.017568192,
      0.043480948, 0.043218542, -0.015849423, -0.0130219795, -0.005182551,
      -0.00446093, 0.002020539, -0.00034789514, 0.015600135, 0.017620673,
      0.013527115, 0.015180283, 0.023564206, -0.024902487, 0.00958444,
      -0.019431287, -0.0018630944, 0.021491187, 0.0033440576, 0.011145765,
      -0.010115815, -0.012188835, 0.0007376115, -0.0059599336, 0.016256154,
      0.019077037, -0.02467944, 0.012582447, -0.012136354, 0.0097090835,
      0.00446093, -0.02439079, 0.011283529, 0.010935839, 0.002428911,
      0.008541369, 0.010371663, -0.021176297, 0.010752154, -0.006704516,
      0.011388492, 0.023433004, 0.009466356, -0.029993195, -0.008954661,
      -0.0038639526, -0.018683424, -0.0036310658, -0.029704547, 0.024850005,
      0.0010684911, 0.028917324, 0.012215076, -0.003258775, -0.005271114,
      0.017751878, -0.011290089, -0.038154073, 0.04253628, 0.024941847,
      0.0035425033, 0.009026824, 0.020074185, 0.012910456, 0.0045527727,
      0.0047167777, 0.0011160525, 0.015731338, 0.03807535, -0.009663162,
      0.02470568, 0.012602127, -0.0308329, -0.009236749, -0.012484044,
      0.040515743, -0.036448423, -0.000823714, 0.0055040005, 0.00011746843,
      -0.0016958094, -0.08229104, 0.0076623033, 0.015678857, 0.010798075,
      0.021281261, 0.005379357, 0.015442691, -0.008055915, -0.007970632,
      0.006412587, -0.0034605009, -0.01094896, 0.0034867418, 0.002976687,
      -0.006133779, -0.012267558, 0.0006892301, -0.022685142, -0.011454094,
      0.037340607, 0.019418167, -0.024233347, -0.0075573404, 0.0036573068,
      -0.0064355475, -0.009125226, -0.0171221, 0.01541645, 0.022553938,
      0.027395358, 0.0039951564, -0.0274216, -0.0061862604, -0.0026486772,
      -0.034821495, -0.017541952, -0.038941298, -0.015219644, 0.00008087486,
      -0.012188835, 0.013828883, -0.014471782, 0.018407896, -0.023695411,
      -0.008830017, -0.0014202815, 0.011618099, 0.016282395, 0.02364293,
      0.0012415162, -0.02156991, -0.015652617, -0.022724504, -0.024180865,
      0.013166304, -0.007498299, -0.02334116, 0.015587015, -0.027342878,
      0.010489746, -0.018801508, -0.008416725, -0.019431287, 0.0240103,
      0.035818644, 0.023039391, -0.012917017, -0.008705374, -0.009807486,
      0.011053923, 0.0041198, -0.00066093926, 0.00755078, 0.0260702,
      -0.04762699, -0.010870237, -0.015849423, -0.012333159, 0.006816039,
      0.02946838, -0.011565617, -0.022370253, -0.008423286, 0.00001663111,
      -0.014156893, 0.018447258, -0.0053695166, 0.0023108274, 0.0125299655,
      0.009158027, -0.015665736, 0.012982619, 0.016544802, -0.010739033,
      -0.015941264, 0.016676007, 0.009695963, -0.023813495, 0.03427044,
      -0.012247877, -0.045790136, -0.0031488917, -0.06255799, 0.014524263,
      -0.030937862, -0.0010783315, -0.005084148, -0.026175164, 0.015587015,
      -0.002610956, -0.034427885, -0.0061665797, 0.0061928206, -0.014655467,
      -0.017056497, -0.008502008, -0.010515987, -0.004926704, 0.016033107,
      -0.0037819503, 0.030098157, 0.015600135, -0.010187977, -0.0039623557,
      -0.022016002, -0.005523681, -0.02847123, 0.012470923, -0.008423286,
      0.024456393, -0.0022452255, 0.0020090586, 0.000027701433, -0.016203672,
      0.018434137, 0.01886711, 0.002091061, -0.0074589374, 0.015941264,
      0.011283529, -0.0033194567, 0.041302964, -0.00026179262, -0.02433831,
      0.026109561, -0.027867693, -0.009171148, 0.011893627, 0.0026617977,
      0.0053662364, 0.015888784, 0.0068554, 0.014432421, 0.011021121,
      0.008764416, -0.015521413, -0.0072621317, -0.034873977, -0.005113669,
      -0.028313786, 0.025059931, -0.05006738, 0.0058680912, -0.0035785844,
      -0.00072449114, -0.024495754, 0.0014793231, -0.016006866, -0.024600718,
      -0.002505993, -0.0012111753, -0.012201956, -0.019811777, 0.025099292,
      0.015600135, 0.0026535974, -0.0019713375, 0.0198905, -0.0031078907,
      0.0008741455, -0.016282395, 0.008987462, 0.008272401, -0.0100370925,
      -0.03261727, 0.00027655307, 0.01957561, 0.038521443, 0.00239939,
      -0.0032407346, 0.000020359656, -0.009367953, 0.0074851783, 0.018683424,
      0.012720211, 0.0036081052, 0.011906748, 0.012582447, 0.0046446156,
      -0.04313982, -0.0078066275, 0.019969223, 0.019680575, -0.008692253,
      0.009538518, -0.014183134, -0.028261304, 0.0025141933, -0.01508844,
      -0.036920756, 0.007478618, 0.011355692, 0.013697679, 0.01062751,
      0.03600233, 0.022580178, -0.029757028, 0.009479476, 0.018932711,
      -0.01714834, -0.028156342, 0.023551088, 0.018079888, -0.0005231753,
      0.026818063, -0.006888201, 0.02196352, -0.0094073145, 0.011473775,
      -0.011493456, 0.0084495265, 0.013422151, -0.00096598815, -0.018696545,
      -0.062138133, -0.0118739465, 0.022147207, 0.017961804, -0.009997732,
      0.014143772, -0.023590447, 0.07126992, 0.00009824912, -0.011578738,
      0.013697679, 0.029966954, 0.00958444, 0.015573895, -0.0073474143,
      0.006953803, -0.0082396, -0.0037196286, -0.027920173, -0.0038606727,
      -0.03941363, -0.011283529, -0.019313203, -0.00240595, 0.03114779,
      -0.016374238, -0.016636645, 0.007268692, -0.0008675853, -0.039203703,
      0.0065765916, -0.0002808582, -0.0008798857, 0.033561938, -0.0047725393,
      0.007406456, -0.030360566, 0.009518838, 0.02570283, -0.008967781,
      -0.010739033, -0.01777812, -0.017095858, 0.009525398, -0.018119248,
      -0.0027536403, 0.011624659, -0.011027682, 0.020730205, -0.034375403,
      -0.014471782, -0.023800375, -0.017017137, 0.01062751, -0.010522547,
      -0.02912725,
    ],
  },
  {
    id: "faq-9",
    question: "Μπορώ να ακυρώσω την παραγγελία μου ;",
    answer:
      "Μπορείτε να ακυρώσετε τη παραγγελία σας, εφόσον επιθυμείτε, χωρίς επιβάρυνση, με την προϋπόθεση ότι ΔΕΝ έχει πραγματοποιηθεί η αποστολή των προϊόντων από το κατάστημά.\n\nΑυτό μπορεί να γίνει είτε τηλεφωνικά στο 2644038033 , καθημερινά από 09:00 έως 17:00 εκτός Σαββάτου, Κυριακής και Αργιών είτε στέλνοντας e-mail στο info@carespot.gr.",
    embedding: [
      -0.009125458, -0.006893933, -0.026392445, -0.031022698, -0.041260704,
      0.021479232, -0.014340924, 0.006562741, -0.009916459, -0.006553095,
      0.026276689, 0.018623909, -0.007980756, 0.0014348963, 0.026598234,
      -0.018315224, 0.028141651, 0.01338915, -0.0037652964, 0.00006923274,
      -0.0054662717, -0.006758884, 0.008868221, -0.0068489164, 0.01700332,
      0.008038634, 0.015511349, -0.0182895, -0.003620601, -0.017119076,
      0.02232811, -0.005781386, 0.015331284, -0.007974326, -0.005504857,
      -0.015948651, 0.030714015, -0.0026704343, 0.037402157, -0.005598105,
      0.011170487, 0.0025450317, 0.0072926492, -0.01282323, -0.005157588,
      0.025337776, -0.0027926217, -0.011742837, -0.0040225326, 0.027241325,
      0.011659236, 0.00956919, -0.03230888, -0.018894006, 0.00024115904,
      0.03187158, 0.0011205856, 0.010604567, -0.0061865333, -0.021839362,
      0.014906844, 0.003379442, -0.017967956, -0.0140451025, 0.0069903964,
      0.0072669256, -0.00008852546, 0.0042733382, -0.023035511, -0.022276664,
      0.010527396, 0.0182895, -0.0056849224, -0.008900376, 0.027292771,
      0.0065723876, 0.019189829, 0.01211583, -0.004520928, -0.0013641563,
      0.01913838, -0.01621875, -0.0082058385, -0.0011680136, 0.037633672,
      0.029787963, 0.007916447, 0.005266913, -0.022803998, -0.02614807,
      0.008244423, 0.026701128, -0.0014959899, 0.0023987286, 0.00081672525,
      0.006610973, -0.024205936, 0.020000122, 0.012360204, -0.0051190024,
      0.004257261, -0.009865012, -0.011382706, 0.005221897, -0.000512061,
      -0.03881696, 0.019434202, 0.012810368, 0.019382756, -0.024887612,
      -0.02734422, 0.04164656, 0.005334438, -0.011884317, 0.00051809,
      0.017903646, -0.005479133, -0.001183287, -0.041698005, -0.013016157,
      0.0042958464, 0.009202628, -0.018546738, -0.01942134, 0.030688291,
      -0.005192958, 0.010861803, -0.020604627, -0.03601308, 0.00044895773,
      0.03699058, 0.0014139957, 0.010707461, 0.0145852985, -0.011800716,
      0.01601296, -0.03377513, 0.034984138, -0.026173795, -0.000058481066,
      0.014122273, 0.031974472, 0.0025402084, -0.01232805, 0.006385891,
      0.022238078, 0.015498487, 0.0056656296, 0.01232805, 0.014816811,
      -0.00871388, 0.0009992023, 0.0034662592, 0.0010136719, -0.018701078,
      0.004643115, -0.028090205, -0.006440554, -0.006893933, -0.006311936,
      0.010971128, -0.0044469726, 0.011228365, -0.023189852, -0.019678578,
      0.035189927, 0.0004638292, 0.0048585506, 0.008398766, 0.00442768,
      -0.011472739, 0.04164656, -0.005032185, 0.0040514716, -0.009119027,
      0.026186656, -0.0009260507, 0.0017090137, 0.011093316, 0.020591766,
      -0.08540245, 0.0036624018, 0.00999363, 0.019781472, -0.021877948,
      0.020849003, 0.02649534, -0.006964673, 0.002860146, -0.021967981,
      -0.0030514656, 0.012848954, 0.013504906, -0.005845695, -0.62683344,
      -0.007884293, 0.017402036, 0.006482355, 0.003049858, 0.04982667,
      0.022212354, 0.00878462, -0.011221934, 0.049157858, -0.015897203,
      0.009672085, -0.028321717, -0.01239879, 0.014675331, -0.029093426,
      -0.0127139045, 0.0005502445, -0.0012331265, 0.005016108, 0.001018495,
      -0.0024742917, -0.0037813736, 0.016707499, 0.00985215, -0.004231537,
      0.010430932, -0.019858643, -0.0040128864, -0.0037652964, -0.016257335,
      0.018868282, 0.0002606527, 0.0076849344, 0.06466921, -0.011299104,
      -0.0055112876, 0.044810563, 0.0038103128, 0.025955142, -0.015704276,
      -0.008508091, 0.008816774, -0.020115878, -0.006469493, -0.016115854,
      0.015344146, -0.011221934, 0.03938288, -0.010945405, -0.0030707584,
      0.02281686, -0.0052122506, -0.016167302, 0.005678491, 0.02126058,
      0.022701103, -0.03153717, -0.03182013, 0.00017916107, 0.021093376,
      0.0017910078, 0.004974307, -0.02160785, -0.0049936, 0.0021350614,
      -0.00046905433, -0.009183336, 0.0020000122, 0.0055370117, 0.0037460036,
      -0.007138307, -0.025466394, 0.028784743, 0.020283083, 0.011022575,
      -0.009009701, -0.010881095, 0.0046366844, -0.0065273712, 0.0019501727,
      -0.00722834, -0.030842632, -0.002596479, 0.035884466, -0.03699058,
      -0.017016182, -0.017389175, -0.0018874714, 0.030508226, 0.005887496,
      0.043550108, 0.022971202, 0.016848978, -0.0007017728, 0.008315164,
      -0.024205936, 0.008321594, 0.012141554, 0.016000098, 0.009910028,
      0.0059035732, 0.0010699423, -0.021993704, 0.022919755, -0.0011334475,
      -0.036373213, 0.01807085, 0.025852248, -0.021221995, 0.004408387,
      -0.0022058012, -0.0012258918, -0.030405331, 0.02160785, -0.028527506,
      0.021775054, 0.01083608, -0.0033601492, 0.004839258, -0.004987169,
      0.015472763, 0.015639966, -0.028424611, 0.013311978, 0.031434275,
      0.0017829691, -0.025877971, -0.04216103, -0.013119051, -0.015601382,
      -0.015485626, 0.0007363389, -0.005861772, 0.000641885, -0.009331247,
      0.013633524, -0.017119076, 0.031588618, 0.00074638723, -0.008900376,
      -0.022225216, -0.015974374, -0.01906121, 0.0028874774, -0.016553156,
      0.0141865825, 0.007800691, -0.031794406, 0.021029068, 0.01232805,
      0.00030727682, 0.009337678, 0.0049485834, -0.001047434, -0.018971177,
      -0.021286303, -0.01630878, -0.024154488, 0.0061768866, 0.017633548,
      0.02493906, -0.014649607, 0.007350527, -0.030533949, 0.0044534034,
      0.008752465, -0.0005803894, -0.010295883, -0.03387802, 0.040591888,
      -0.010038647, -0.015408454, -0.0011157625, 0.026726851, 0.0024871535,
      -0.005861772, 0.016501708, -0.0039646546, -0.0027829753, 0.011639942,
      0.019292723, 0.0067202984, 0.016064407, 0.024167351, 0.0061479476,
      0.01034733, 0.044630498, 0.006244411, 0.013440597, 0.02126058,
      0.0038135282, -0.0026752576, 0.011652805, -0.004530574, -0.017967956,
      0.019807195, 0.025209159, 0.00648557, 0.034984138, 0.023138406,
      0.02798731, 0.03544716, -0.012212293, 0.011286243, -0.008977547,
      0.030559672, -0.0082187, 0.025029093, 0.01183287, -0.0058424794,
      -0.0011543479, 0.0021447076, 0.004739579, 0.021466369, 0.01091325,
      -0.020527458, -0.0005124629, 0.0092798, 0.024128765, 0.00985215,
      -0.0014340924, -0.015061186, -0.014688193, -0.024823302, 0.015961513,
      0.024347415, 0.042701226, -0.006292643, -0.0023922976, -0.05044404,
      0.02104193, -0.012077244, 0.021131963, 0.03089408, -0.0082637165,
      0.010482379, 0.0072219092, 0.012295895, 0.0032652933, -0.0054952106,
      0.0047588716, 0.030353883, 0.0075370236, -0.008848929, 0.000014469542,
      0.024218798, -0.014611022, -0.0043440782, -0.0006081227, -0.046662666,
      -0.011260519, -0.027035536, 0.007839276, 0.040360376, -0.02734422,
      -0.000665197, -0.0021993704, 0.028450334, 0.036064528, 0.021466369,
      0.0024935845, 0.00442768, -0.007048275, -0.006868209, 0.011922902,
      -0.007517731, -0.008726741, -0.005797463, 0.004861766, -0.020566043,
      -0.013980793, 0.010366623, 0.028836189, 0.013402011, -0.012353773,
      0.010463087, 0.009369832, -0.00035008253, -0.0072219092, -0.033980917,
      -0.048000295, 0.020167327, 0.0049067824, -0.01303545, -0.042984188,
      -0.019639991, 0.02380722, 0.011112608, 0.013993655, -0.007871431,
      0.0064662774, -0.022263803, 0.006617404, -0.006070777, -0.00018498908,
      0.063434474, 0.008083651, -0.0011093316, -0.008321594, 0.0051382952,
      -0.0045466516, 0.007241202, -0.032231707, 0.008540246, 0.017466346,
      -0.010328038, -0.015331284, -0.0057428004, -0.0460453, -0.0077749672,
      0.0035948774, 0.009157612, -0.018945454, 0.010257297, 0.022058014,
      -0.014559574, -0.0037138492, 0.022431005, 0.0029324938, -0.015279836,
      -0.009684946, -0.009807134, 0.0036366782, 0.06122224, 0.009125458,
      0.008379472, 0.006675282, -0.008012911, 0.017479207, -0.022379559,
      -0.022842584, -0.0019164105, -0.007524162, -0.006086854, -0.0071125836,
      0.01409655, 0.016913287, 0.0014935783, 0.000014117852, 0.014623884,
      0.0076206257, 0.010437363, -0.025350638, -0.000038560323, 0.012836091,
      -0.01297114, 0.015074047, -0.007459853, -0.019395618, 0.027832968,
      -0.0052090352, -0.010450224, -0.026984088, 0.005961451, 0.0032283156,
      -0.003974301, -0.013080466, -0.010848941, 0.003832821, 0.016964734,
      0.0042058136, -0.0015755724, -0.003585231, 0.031717237, 0.01530556,
      0.006604542, 0.0012114223, 0.018083712, -0.002149531, -0.026958365,
      0.018829698, -0.009119027, 0.0054405476, 0.024887612, -0.009697809,
      -0.01792937, 0.0036463246, 0.0044759116, 0.011016144, -0.004633469,
      -0.0051608034, 0.00053979433, 0.022521038, -0.0054437635, -0.0053601614,
      0.016926149, -0.009530605, -0.00599039, -0.042752672, -0.011697821,
      0.019228414, -0.0076141944, -0.015601382, 0.026469616, -0.028990531,
      -0.043498658, -0.00906758, 0.018791111, 0.028784743, 0.021492094,
      0.010192988, 0.020321667, 0.021209134, 0.019935813, -0.033903744,
      0.0021012991, -0.019151242, -0.0101351105, 0.005446979, -0.0026350643,
      -0.023845805, 0.008823205, 0.036501832, 0.018109435, 0.022199493,
      0.022829723, -0.028990531, -0.021247718, -0.0006177691, -0.01409655,
      0.03202592, -0.0004947779, -0.0074019744, 0.018546738, 0.020643214,
      -0.023537122, -0.02295834, -0.007832846, 0.011594926, 0.022778274,
      0.001442131, -0.02947928, 0.009260507, 0.012295895, -0.016385952,
      0.023434227, -0.011131901, -0.0048714126, 0.0122894645, 0.005839264,
      0.033260655, -0.0046238224, 0.0027974448, -0.015537073, -0.0005233151,
      0.0015024208, -0.002737959, -0.01835381, 0.019434202, -0.0086881565,
      -0.009035425, 0.03210309, -0.021504955, -0.00992289, 0.030199543,
      0.0024276676, -0.025054816, -0.032797627, 0.013299117, 0.0020144817,
      -0.0019614268, -0.0129132625, -0.003955008, -0.021119101, -0.009472727,
      -0.021967981, 0.0071318764, -0.002612556, -0.036733344, -0.008610985,
      -0.0026013022, 0.0047492255, 0.021414923, -0.0016752514, 0.013209084,
      -0.023614293, -0.018482428, -0.0038167436, -0.0044437572, 0.017620686,
      -0.011530617, -0.00885536, 0.047768783, 0.019832918, -0.00086334936,
      0.007942171, -0.0120901065, -0.0009525782, -0.02302265, -0.00655631,
      -0.0063601676, -0.025877971, 0.024900474, 0.025170572, 0.02586511,
      0.03452111, 0.0013673718, -0.009749256, 0.021492094, -0.017980818,
      -0.010283021, -0.02578794, -0.02713843, -0.0010000061, -0.010778201,
      0.01984578, -0.004890705, -0.0055241496, -0.016385952, 0.018701078,
      -0.0144438185, 0.007311942, 0.015472763, 0.0014469542, -0.027755797,
      0.013684971, -0.013787866, 0.012578855, -0.015472763, -0.0120322285,
      0.0055370117, -0.0049421526, 0.025697907, -0.0017266987, -0.01722197,
      -0.010778201, 0.0059357276, -0.008585261, -0.0013601369, -0.0065498794,
      0.0065916805, 0.0033762264, -0.00012299111, -0.009157612, -0.0246561,
      -0.024630375, -0.03408381, 0.00062982703, -0.0069325184, -0.024167351,
      0.025312053, 0.0024035517, -0.0286304, -0.012019366, -0.0067781764,
      0.001771715, 0.008553107, 0.027164154, 0.021067653, -0.0025305622,
      -0.027652903, -0.020141603, -0.0074855764, 0.0031575756, -0.010469518,
      0.023395643, 0.025633598, -0.009530605, 0.006823193, 0.02976224,
      -0.01920269, -0.020424562, 0.01657888, 0.0047009937, 0.0046656234,
      0.0005868203, 0.016784668, -0.00627335, 0.021993704, -0.014816811,
      -0.0057331542, -0.028167374, -0.022984063, -0.002766898, 0.010675306,
      -0.017260555, 0.029376386, 0.0072733564, -0.03117704, -0.022418143,
      -0.031485725, -0.007247633, 0.026546786, -0.021286303, 0.011241226,
      -0.020463148, 0.0030386038, 0.010643152, -0.016797531, 0.0072669256,
      -0.0007853746, -0.009884305, 0.019858643, -0.016475985, -0.005244405,
      -0.009215491, 0.02267538, 0.0064083994, 0.0141865825, 0.006099716,
      0.004909998, 0.0075434544, -0.0058649876, 0.013074035, 0.013093328,
      -0.027241325, -0.017633548, -0.015884342, -0.014906844, -0.0032331387,
      0.01621875, 0.0111833485, 0.01778789, -0.006958242, -0.032128815,
      -0.003090051, 0.0120322285, 0.011569203, 0.0021704312, 0.010887527,
      0.012308757, -0.02204515, -0.03655328, -0.0067781764, -0.0028633615,
      -0.015318422, 0.0039357156, -0.030019477, -0.008045065, 0.045453656,
      0.013697833, -0.007845707, -0.017170522, -0.00020538711, 0.032154538,
      -0.0033890882, -0.021016207, 0.009884305, -0.0062797815, -0.007800691,
      -0.029427834, -0.02742139, -0.021427784, -0.017080491, -0.005890711,
      -0.014147997, -0.015408454, -0.016681774, -0.0022090168, -0.0033312102,
      0.009903598, -0.04030893, -0.012932555, 0.013916484, 0.018045127,
      -0.0025996943, -0.033389274, 0.0019566037, 0.00017333306, 0.0016157655,
      0.02847606, 0.0036302474, -0.0066495584, -0.007067567, 0.0062669194,
      -0.0053022834, 0.037067752, -0.0062251184, -0.00400324, 0.00382639,
      -0.00850166, -0.0042829844, -0.022212354, -0.027447114, 0.028501783,
      0.017350588, -0.020347392, -0.025968004, -0.008733173, 0.00097106706,
      0.021916533, -0.005993606, 0.025672182, 0.021234857, 0.015241251,
      -0.011646373, 0.009086872, 0.017350588, 0.0070932913, -0.00906758,
      0.010887527, -0.022212354, -0.02302265, -0.019459926, -0.018598184,
      -0.02932494, 0.0018376318, 0.035318546, -0.017247694, 0.013492044,
      0.050701275, -0.0055659506, -0.008051496, 0.014996877, -0.016115854,
      0.017594963, 0.012077244, -0.010591704, -0.029299214, 0.021337751,
      0.01289397, 0.004881059, -0.020746108, 0.0035209218, 0.044476155,
      -0.0038488982, 0.009176905, -0.017530654, -0.020244498, -0.00057275273,
      -0.02176219, 0.016643189, 0.017453482, -0.019781472, 0.012392359,
      -0.0017443837, -0.029427834, -0.030919803, 0.008469505, -0.013684971,
      0.0010787847, 0.016746083, -0.023768634, -0.00059686863, 0.0020627135,
      -0.0035369992, -0.005244405, -0.0006097304, -0.016180163, 0.0057846014,
      -0.028656125, 0.011594926, 0.013029018, -0.019254137, -0.0123730665,
      0.005749231, -0.000394496, -0.024270246, -0.031048423, 0.0006877052,
      -0.004057903, 0.026276689, 0.011633512, -0.0070804292, -0.03884268,
      0.00814796, 0.00435694, 0.01246953, 0.026418168, 0.2479758, -0.03032816,
      -0.014623884, 0.00913832, -0.01544704, 0.007588471, 0.01630878,
      -0.0077556744, -0.0155499345, 0.00007295061, -0.019601407, -0.009363402,
      -0.020887587, 0.0016848978, 0.00800648, -0.026932642, -0.024746133,
      -0.017877923, -0.025106262, -0.017234832, 0.01254027, 0.0037299264,
      0.020656075, 0.009858581, 0.026675405, 0.026160931, 0.007562747,
      -0.008520952, 0.0008971116, 0.020000122, -0.009061148, -0.005508072,
      0.016398814, 0.009093303, -0.005392316, 0.0050386162, 0.030019477,
      -0.009717101, 0.01218657, 0.004379448, -0.014855397, 0.019511374,
      0.010887527, 0.014508127, -0.011382706, -0.0053247917, -0.013003295,
      -0.019048348, -0.01437951, 0.024746133, -0.032771904, 0.006334444,
      0.032334603, 0.022469591, 0.004299062, -0.032000195, -0.0034115964,
      0.010604567, -0.029273491, 0.005610967, 0.0016254119, 0.0145852985,
      0.0029324938, 0.021787915, -0.0072733564, 0.016771806, -0.019176966,
      -0.017877923, 0.014791087, -0.0137621425, 0.0062058256, -0.010141541,
      0.007382682, 0.025054816, -0.041698005, -0.0065209405, -0.017376313,
      0.03194875, 0.016964734, 0.008707449, 0.003043427, 0.03953722, 0.01580717,
      -0.016231611, -0.018701078, -0.013504906, 0.029916583, 0.0067010056,
      -0.0035273528, -0.01381359, -0.0067846077, -0.0030080571, -0.025762215,
      0.0043054926, -0.00038103128, -0.009980769, 0.016771806, 0.044270366,
      -0.005938943, 0.005192958, -0.006958242, 0.023832943, 0.0076077636,
      0.0016093346, -0.032128815, -0.0034276738, -0.005414824, 0.021453507,
      0.000049889775, -0.03259184, 0.024180213, -0.019549958, 0.002860146,
      -0.0061865333, -0.013164068, 0.009492019, -0.0048681973, -0.0046399,
      -0.008057927, 0.01012868, 0.01041164, -0.009871443, -0.0052604824,
      -0.007035413, 0.01544704, -0.019781472, -0.012726766, 0.000004619075,
      0.003160791, -0.03436677, 0.015884342, -0.013363426, 0.03344072,
      0.015755724, -0.018611047, 0.0073440964, -0.013260531, -0.021697883,
      0.0020305591, -0.025607875, 0.0006270135, 0.014135134, -0.00793574,
      0.0069196564, 0.004974307, -0.0145852985, 0.008823205, -0.010681737,
      0.0075113, 0.00189551, -0.025170572, 0.00058360485, -0.011369845,
      0.001271712, 0.017556379, 0.0040514716, -0.019395618, -0.024720408,
      -0.009980769, 0.011498462, -0.015639966, 0.00017845769, 0.015704276,
      -0.0060675615, -0.003655971, -0.01204509, -0.16216177, 0.009086872,
      0.0023456735, -0.020913312, 0.02054032, 0.025209159, 0.010797494,
      -0.00077291473, -0.028244546, -0.017839337, 0.038559724, -0.008739604,
      -0.018328087, -0.02657251, -0.02847606, -0.015704276, -0.031588618,
      0.011453446, 0.02289403, 0.018906869, 0.002419629, -0.05746659,
      0.0069260877, 0.0036816946, 0.0021640004, -0.02176219, -0.031408552,
      0.049646605, -0.01530556, 0.008630278, -0.037324987, 0.0300452,
      0.0071447385, 0.01466247, 0.0071447385, -0.011877886, 0.01034733,
      -0.0044116024, -0.030816909, 0.026160931, 0.013865037, 0.026276689,
      -0.011556341, 0.046096746, 0.00073111383, 0.024180213, 0.031357106,
      0.010887527, -0.0035723692, -0.02564646, 0.010167265, -0.004791026,
      0.0019453495, -0.0022058012, 0.0051608034, 0.0053119296, 0.000040444378,
      0.016360229, -0.0068617784, 0.018456705, -0.029685069, -0.010919681,
      0.0066495584, 0.015331284, 0.014623884, 0.0028890853, -0.0014566006,
      0.003955008, -0.016900426, 0.01715766, 0.0028810466, -0.033389274,
      0.008019342, -0.007382682, 0.004459834, 0.013929346, -0.01211583,
      -0.0028328148, -0.0051897424, 0.024553206, 0.00023995324, 0.0058231866,
      -0.03938288, 0.016025823, -0.013209084, 0.005392316, 0.020385977,
      0.00035068544, -0.041106362, 0.008302302, 0.0017459914, -0.036450386,
      0.00040936747, -0.019241275, 0.013800728, 0.010720323, 0.013942207,
      0.01800654, -0.005045047, -0.011916472, 0.018521015, -0.025209159,
      -0.0154341785, 0.024295969, 0.028398888, -0.009093303, 0.0024871535,
      0.028681848, 0.017350588, -0.006102931, 0.02806448, 0.007910016,
      0.009890736, 0.028244546, 0.013980793, 0.008598124, 0.026829746,
      -0.013556353, 0.017492069, -0.010881095, 0.04838615, -0.02126058,
      -0.0017154446, -0.004752441, -0.0267783, -0.033054866, -0.07269498,
      -0.017414898, 0.025569288, -0.019588545, 0.009196198, 0.0052797752,
      0.010668876, 0.015254113, -0.013131914, 0.0057009994, -0.012739628,
      -0.011537048, -0.009086872, 0.008842498, 0.0021366691, 0.0024694686,
      -0.0026640035, -0.008051496, -0.019588545, 0.019858643, 0.019447064,
      -0.011022575, -0.026083762, -0.002395513, -0.021144824, 0.0044855583,
      -0.0057942476, 0.02762718, 0.016077269, 0.015176942, -0.025890835,
      -0.022443868, 0.01297114, -0.00043006695, -0.022405282, -0.009106165,
      -0.03840538, -0.025312053, 0.0072733564, -0.018752526, 0.020463148,
      -0.012701043, 0.040411822, -0.029170597, 0.0014855396, -0.009221922,
      -0.003691341, 0.022366697, 0.010328038, 0.006032191, -0.037968077,
      -0.021556402, -0.022482453, -0.005173665, 0.027164154, -0.006128655,
      -0.01303545, 0.010643152, -0.04115781, 0.041209254, 0.0060482686,
      -0.010328038, -0.036527555, 0.031845853, 0.020514594, 0.019215552,
      -0.007067567, -0.011453446, 0.0041061346, -0.003067543, 0.004909998,
      0.0077621057, 0.0022733258, 0.025685044, -0.041929517, -0.015344146,
      -0.03207737, -0.022906892, -0.0023890822, 0.016990459, -0.006765315,
      -0.0140451025, -0.009260507, -0.008585261, -0.0045144972, 0.020283083,
      -0.020077294, 0.018263778, 0.01530556, 0.008977547, 0.00046985818,
      0.00023874744, -0.0003772129, -0.010964697, -0.01912552, -0.0027620748,
      -0.0051061404, -0.0033054864, 0.017492069, -0.006610973, -0.028939083,
      0.0014437387, -0.06616118, 0.023395643, -0.017131938, -0.007781398,
      -0.021903671, -0.018508151, 0.026328135, 0.0030482502, -0.02778152,
      -0.0089711165, 0.013324841, -0.0014927745, -0.010855372, -0.016270196,
      -0.019588545, -0.018366672, 0.027241325, -0.0059646666, 0.025826525,
      0.008932531, -0.018263778, -0.021736467, -0.0063376594, -0.018984038,
      -0.019357031, 0.015511349, -0.0053505152, 0.03117704, -0.006321582,
      -0.011440584, 0.0026591802, -0.02749856, 0.0048585506, 0.018829698,
      -0.006958242, -0.011717114, 0.0041029193, 0.021517817, 0.007594902,
      0.017209109, -0.0044116024, -0.026623957, 0.019614268, -0.019549958,
      -0.00023271846, -0.0008553107, -0.018173745, -0.0026559648, 0.01367211,
      -0.010868234, 0.02742139, 0.023884391, 0.0044116024, -0.02295834,
      -0.004713855, -0.03500986, 0.01423803, -0.021286303, 0.026083762,
      -0.032051645, -0.00027592614, 0.0019469573, -0.021955118, -0.002950179,
      -0.004913213, -0.0013705872, -0.028424611, -0.017466346, -0.0019067641,
      -0.014919706, -0.026984088, 0.029968029, 0.025041955, 0.0056656296,
      0.012315188, -0.024128765, -0.0012057952, -0.004739579, -0.027035536,
      0.010424501, 0.030276712, -0.008340887, -0.04030893, -0.0063955374,
      0.016566018, 0.028218823, -0.005749231, -0.019639991, -0.01700332,
      0.004045041, -0.0047074244, 0.021710744, -0.0053826696, 0.00439231,
      -0.009035425, 0.004855335, 0.014057964, -0.042572606, -0.012784644,
      0.022263803, 0.024643239, 0.016990459, 0.005961451, -0.025414947,
      -0.01544704, 0.03400664, 0.006694575, -0.040231757, -0.018533876,
      0.018456705, 0.0009702632, 0.026521062, 0.029710794, 0.009202628,
      -0.011652805, 0.01736345, 0.0038810528, -0.015099771, -0.022996925,
      0.027086983, 0.01799368, -0.0027588594, 0.016707499, 0.0036624018,
      0.0143152, -0.029633623, 0.01225731, -0.002048244, 0.01659174,
      0.009247645, 0.0009212275, -0.029119149, -0.04465622, -0.019176966,
      0.0072540636, 0.016398814, -0.012906832, 0.008475936, -0.02160785,
      0.075113, 0.010720323, -0.02110624, 0.009324816, 0.03380085, 0.0125467,
      0.016463123, 0.006887502, -0.011009714, 0.004057903, 0.017492069,
      -0.0090289945, 0.008353749, -0.040206034, 0.006977535, 0.0062669194,
      -0.00053376536, 0.014649607, -0.014546713, 0.000030320725, 0.014559574,
      -0.0011012929, -0.017337726, -0.0012604579, -0.0006330425, 0.00048071035,
      0.009228352, -0.014996877, -0.01140843, -0.02713843, 0.020849003,
      0.01473964, 0.006334444, -0.026804022, -0.009897167, -0.023678603,
      0.0033923038, -0.013517768, -0.014701054, 0.012643164, -0.008945392,
      0.016694637, -0.043138526, -0.017749306, -0.007241202, -0.020643214,
      0.008514522, -0.010778201, -0.028939083,
    ],
  },
  {
    id: "faq-10",
    question: "Μπορώ να επιστρέψω κάποιο προϊόν ;",
    answer:
      "Φυσικά και μπορείτε να επιστρέψετε κάποιο προϊόν που έχετε αγοράσει από το carespot.gr αρκεί να είναι στην αρχική του κατάσταση. Το carespot.gr δέχεται την επιστροφή προϊόντων (ΥΠΑΝΑΧΩΡΗΣΗ) εντός 14 ημερολογιακών ημερών υπό ορισμένες προϋποθέσεις.\n\nΔείτε αναλυτικά τους όρους στη σελίδα μας Πολιτική Επιστροφών.\n\nΜπορείτε να υποβάλετε ηλεκτρονικά το αίτημα Υπαναχώρησης ΕΔΩ",
    embedding: [
      0.0006458536, -0.021307858, -0.018445022, -0.011850045, -0.043190897,
      -0.0012729157, -0.00428118, -0.003256638, 0.0049119187, -0.0055034407,
      0.033308234, 0.016301164, -0.0009885931, -0.0032664423, 0.021046411,
      -0.010150645, 0.020536592, 0.015399176, 0.0002808503, -0.012150708,
      -0.016627973, -0.0042615714, 0.009895735, -0.02563479, 0.026471417,
      0.00784992, 0.010791188, -0.0075557926, 0.0136867035, -0.023046475,
      0.04745247, -0.011771611, 0.0073858523, -0.010235615, -0.008941457,
      -0.0131572755, 0.0049511357, 0.011464411, 0.037726674, -0.0043138606,
      -0.0016724381, 0.007980642, 0.017451528, -0.0043105925, 0.0021536623,
      -0.0051276116, -0.016601827, 0.002142224, 0.012379473, 0.022275208,
      -0.0062354896, 0.028105456, -0.051661752, 0.001326022, 0.01457562,
      0.02098105, 0.010647393, 0.011431731, -0.00093058473, -0.029804856,
      0.014170378, 0.006552493, -0.026392985, -0.009320553, -0.015373031,
      0.009248656, -0.0049838163, -0.0054282746, -0.014614837, 0.010104892,
      0.0006127643, 0.027033526, -0.012124564, -0.014353391, 0.002869371,
      0.00068302796, 0.007137479, 0.009823837, -0.019752253, -0.0038595982,
      0.03139968, -0.020118278, -0.011385978, -0.0069544665, 0.027007382,
      0.021556232, 0.016654117, 0.0061537875, -0.00081129995, -0.022405932,
      0.0045687705, 0.018784903, 0.00779763, 0.0040131975, -0.0043073245,
      0.02682437, -0.02687666, 0.025046537, -0.0064544505, -0.01343833,
      0.01000685, -0.029464977, -0.01843195, 0.0020376456, -0.019974483,
      -0.027739432, 0.008536215, 0.0072093764, 0.018680325, -0.016758695,
      -0.027765576, 0.033883415, 0.009712723, -0.026249189, 0.016340382,
      0.021556232, 0.019033276, -0.0076734433, -0.029491121, -0.030040158,
      0.014536403, 0.00083907857, 0.015464537, -0.022928823, 0.01973918,
      -0.003719071, -0.0076407623, 0.006634195, -0.016797911, -0.0065982463,
      0.035112213, 0.0012990603, 0.01119643, 0.010928447, -0.02120328,
      0.015582188, -0.02013135, 0.03312522, -0.033778835, -0.0004799987,
      0.039216917, 0.019490806, 0.003513182, -0.01586978, -0.018784903,
      0.022235991, 0.0027517204, 0.0037419475, 0.0012557583, 0.0007144832,
      -0.0027942052, 0.00029208433, 0.018784903, 0.003810577, -0.0055295853,
      0.018706469, -0.027634853, -0.009908807, -0.030798351, -0.0071766954,
      0.007529648, -0.015856706, 0.00941206, -0.014484114, -0.025843948,
      0.03613185, 0.0067910627, 0.017163936, 0.025386417, -0.0045099454,
      -0.0030899663, 0.024026897, -0.027190395, 0.012235678, -0.011320616,
      0.025203405, 0.0007173428, 0.008797661, 0.0075231115, 0.008366276,
      -0.05349187, 0.0126736, 0.014693271, 0.020432014, -0.0058760014,
      -0.00075696816, 0.004741979, -0.00839242, 0.010144109, -0.022039907,
      -0.008738836, 0.0054119346, -0.0016397573, -0.01250366, -0.6207776,
      -0.023504006, -0.010614712, -0.000030919065, 0.011157213, 0.048053794,
      0.034066427, 0.030040158, -0.02217063, 0.052864403, 0.006467523,
      0.0104578445, -0.023137981, 0.0001629953, 0.00847739, -0.023634728,
      0.0075623286, -0.0057942993, 0.006941394, 0.008091757, -0.014183451,
      -0.0120918825, -0.024589006, 0.020941833, 0.023595512, 0.009706187,
      0.020863399, -0.012830468, -0.009170222, 0.0010237249, -0.011222574,
      0.029726421, -0.005179901, 0.020039843, 0.063531406, -0.0015458001,
      -0.017216226, 0.04133463, 0.021307858, 0.034510884, -0.021124845,
      0.0036341008, 0.0031112086, -0.024941958, -0.03226245, -0.01666719,
      0.012412154, -0.009451277, 0.020510446, -0.021255568, 0.00094855914,
      0.02993558, -0.0024298148, -0.017373094, 0.022549726, 0.017046286,
      0.026013887, -0.029386543, 0.0016242339, -0.01173893, 0.003810577,
      -0.003081796, -0.003031141, -0.019752253, -0.01173893, -0.005451151,
      -0.00445112, 0.003725607, -0.0042484994, 0.004483801, 0.00941206,
      -0.004575307, -0.027451841, 0.032863777, 0.00073735975, 0.018536529,
      -0.00067444926, 0.009673506, -0.003467429, -0.006876033, -0.006526348,
      -0.0042975205, -0.025948526, -0.004709298, 0.037465226, -0.014588692,
      -0.01615737, -0.018118216, 0.014170378, 0.04303403, 0.021569304,
      0.03375269, 0.017425383, 0.01114414, -0.015268452, 0.023608584,
      -0.024066115, 0.0007357257, -0.0030115324, 0.024772018, 0.0036863901,
      0.014131162, -0.0002763567, 0.0024657636, 0.007941426, 0.020340508,
      -0.028889794, 0.0071047978, 0.035870407, -0.02954341, 0.009085252,
      -0.0007022279, 0.014196523, -0.021059483, 0.010104892, -0.027425697,
      0.025255693, -0.0088564865, -0.020928761, -0.009634289, -0.0007349087,
      0.016641045, 0.009974169, 0.0035491309, -0.006072086, 0.023856957,
      0.004526286, -0.032079436, -0.0072616655, -0.006771454, -0.0044739963,
      -0.013464474, -0.00024939506, -0.009608144, 0.005392326, 0.0064936676,
      0.025373345, -0.022811173, 0.020771893, -0.021164062, -0.00402627,
      -0.009202903, -0.004526286, -0.023673944, 0.011804292, -0.013830499,
      0.005774691, -0.007196304, -0.027347263, 0.035347514, 0.004023002,
      -0.0052387263, -0.005947899, 0.01377821, -0.007150551, -0.010915375,
      -0.017621467, -0.016039718, -0.030275458, 0.0035589351, 0.006297583,
      0.019556168, -0.015464537, 0.002400402, -0.020079061, -0.012804323,
      0.021804605, 0.0070917257, -0.020928761, -0.039033905, 0.011765075,
      -0.011882725, -0.024405994, -0.008680011, 0.019007131, 0.0009166954,
      0.0044641923, 0.0093859155, 0.003513182, 0.0027076013, 0.013569053,
      0.032001004, -0.006666876, 0.031085942, 0.025530212, 0.008490462,
      0.020392796, 0.04768777, -0.016000502, 0.03294221, 0.024981175,
      0.01060164, 0.026445273, 0.010059139, -0.01122911, -0.011771611,
      0.016680261, 0.02410533, 0.005251799, 0.029752567, 0.024366777,
      0.011869653, 0.03328209, -0.02512497, 0.025752442, -0.021124845,
      0.010327121, 0.0021520283, 0.028000878, 0.038432576, -0.0033203654,
      0.0025392952, 0.0023383086, -0.0061537875, -0.0033301697, 0.020824183,
      -0.035530526, 0.016235802, 0.005604751, 0.02432756, -0.005699525,
      0.011392514, -0.015020079, -0.028602203, -0.044707283, 0.018536529,
      -0.011202966, 0.046432827, 0.0014673662, -0.0055099768, -0.024392921,
      0.007993715, -0.008510071, -0.000013863792, 0.047504757, -0.0037027304,
      0.007895673, 0.012274895, 0.024419066, 0.013621342, 0.003001728,
      0.0370992, 0.014902428, -0.007183232, 0.021386292, 0.00045262856,
      0.016471105, 0.004072023, 0.0046112556, 0.002263143, -0.04303403,
      -0.005853125, -0.027347263, -0.0077257324, 0.030615339, -0.03560896,
      -0.0032827826, 0.021686954, 0.016092008, 0.023883102, -0.004751783,
      -0.0058302484, 0.019360084, 0.005823712, -0.00030965023, 0.022026835,
      0.0007108066, -0.013569053, 0.003000094, 0.0035883477, -0.007274738,
      -0.0075623286, -0.0052354583, 0.0253472, 0.022758884, -0.0034380164,
      0.031504255, -0.005908682, -0.0033987993, -0.003256638, -0.046432827,
      -0.04541319, -0.0003860415, 0.0104643805, -0.0046112556, -0.027530275,
      -0.013634414, 0.0088564865, -0.011771611, 0.016980924, 0.004062219,
      0.009425133, 0.003640637, 0.0010122866, -0.0073466357, -0.017477673,
      0.056106333, 0.016209658, -0.0035001098, -0.02132093, -0.006173396,
      0.008745372, 0.010967664, -0.009738867, 0.017817551, 0.0057191337,
      -0.010765043, -0.015072368, 0.010085284, -0.05960971, -0.0013121327,
      -0.01224875, 0.0031733022, -0.0069936835, 0.008144046, 0.015634477,
      -0.007758413, 0.0048988466, 0.0073727802, 0.007954498, -0.00071121514,
      -0.013464474, -0.009555855, 0.01253634, 0.041125473, 0.0063890894,
      -0.0005151305, 0.0025834143, 0.008529679, 0.00006786365, -0.0073727802,
      -0.007503503, 0.012549413, -0.0037778963, -0.006385821, -0.008144046,
      0.037282214, 0.002911856, -0.015111585, -0.004843289, 0.009098325,
      0.019229362, 0.010405555, -0.01656261, -0.01911171, 0.009255192,
      -0.009425133, 0.018954843, 0.00659171, -0.0035197183, 0.018745685,
      0.012980799, 0.008588505, -0.032393172, -0.011470947, -0.015725983,
      -0.006823743, 0.01304616, -0.011549382, -0.013699776, -0.0056210915,
      0.021281714, 0.0009371209, 0.011719322, 0.011163749, 0.020405868,
      0.01457562, 0.0045524305, 0.025791658, 0.0030098984, -0.00915715,
      0.003594884, -0.0056668445, -0.007366244, 0.006725701, 0.0050263014,
      -0.020889543, -0.00822248, 0.016824057, -0.002521321, 0.007660371,
      -0.008431637, -0.005516513, 0.009686578, 0.003970713, -0.009268264,
      0.014379536, -0.006771454, 0.013451402, -0.038328, -0.0023383086,
      -0.0035229863, -0.018641107, -0.030092446, 0.013320679, -0.029386543,
      -0.013209565, -0.024850452, 0.022954969, 0.026209973, 0.007987179,
      0.004627596, 0.0011054268, 0.024170693, 0.032523897, -0.02964799,
      -0.001926531, -0.024745874, 0.008019859, 0.014196523, 0.008130974,
      -0.023569366, -0.0008799296, 0.008640794, 0.008287841, 0.01826201,
      0.018131288, -0.021582376, -0.019804543, -0.008581968, -0.003258272,
      0.031216664, -0.015843634, -0.009588536, 0.03498149, 0.018654179,
      -0.005490368, -0.03356968, -0.02205298, 0.02760871, 0.005562266,
      -0.024928886, -0.023582438, 0.015072368, 0.026955094, -0.009758476,
      0.0120722735, -0.017895985, 0.011640888, 0.00793489, -0.009215975,
      0.03555667, 0.0043890267, 0.0054250066, 0.00036561603, -0.026902804,
      0.015098512, 0.0068041347, -0.02268045, 0.0019052885, -0.0028154477,
      -0.019137856, 0.02682437, -0.015098512, -0.011124532, 0.038014263,
      -0.019896049, -0.019608459, -0.024798162, 0.003036043, 0.017373094,
      0.0312951, -0.0026030228, -0.03851101, -0.0485244, -0.006732237,
      -0.011712786, -0.015908996, -0.03566125, -0.034903057, 0.0069610025,
      -0.012098419, 0.0042223544, 0.016627973, -0.0020507178, 0.0011258523,
      -0.038877036, -0.022144485, 0.0051766327, -0.021582376, 0.01318342,
      -0.015438393, 0.011719322, 0.03958294, 0.026798226, 0.0055263173,
      0.010340193, -0.018523457, 0.0012802689, -0.028053166, 0.002957609,
      0.0024053042, -0.03236703, 0.021072555, 0.017830625, 0.023098763,
      0.01729466, 0.02149087, -0.024680512, 0.009287873, -0.014928573,
      -0.024562862, -0.03464161, 0.0039478363, 0.010418627, -0.030746061,
      0.011150677, -0.0028481286, -0.0057648867, -0.010647393, 0.023608584,
      -0.012876221, -0.0056308955, 0.030380037, 0.019098638, -0.03448474,
      -0.0016487445, -0.0052452623, 0.022954969, -0.011712786, -0.00070018537,
      -0.008431637, -0.01729466, 0.017190082, -0.01114414, -0.014183451,
      -0.024458284, 0.011464411, -0.011340225, 0.009621217, -0.014863211,
      -0.008333595, -0.014170378, 0.0035589351, -0.023451716, -0.016131224,
      -0.022654304, -0.012353328, -0.00024081637, 0.010843477, -0.0153076695,
      0.0126082385, -0.01020947, -0.03356968, -0.008725764, 0.007921817,
      0.014876284, -0.005643968, 0.03657631, 0.032733053, -0.004124312,
      -0.03288992, -0.04826295, -0.0057322057, -0.0030687237, 0.00039319042,
      0.04410596, 0.029961724, -0.048184518, 0.001841561, -0.0017484209,
      -0.015961284, -0.022405932, 0.017137792, -0.001928165, 0.0033497782,
      0.0049478677, 0.025870092, -0.0017860037, 0.011065707, -0.009908807,
      0.009575464, -0.009660434, -0.036942333, -0.017163936, 0.017320804,
      -0.0011781416, 0.023164125, -0.0067845266, -0.025713224, -0.006771454,
      -0.03095522, -0.010477453, 0.029098952, -0.0034445524, 0.019608459,
      -0.0032435658, 0.027085816, 0.017477673, -0.019020204, -0.020941833,
      -0.007595009, -0.018131288, 0.02052352, -0.021177135, 0.0024984444,
      -0.00088401465, 0.025098827, 0.0049772803, 0.00074879796, -0.0025033464,
      -0.0060328688, -0.021673882, -0.0044380478, 0.015843634, 0.009261728,
      -0.045282464, -0.00056660274, -0.014118089, -0.015412248, -0.019190144,
      -0.0018382929, 0.022471292, 0.006630927, -0.021869967, -0.027948588,
      0.019255506, 0.018797975, 0.007751877, 0.00453609, 0.020863399,
      0.02006599, -0.04337391, -0.0076146177, 0.017477673, -0.014013511,
      -0.014196523, 0.010954591, -0.009693114, -0.002699431, 0.021974545,
      0.0037125347, -0.015242308, -0.010693146, -0.001542532, 0.011150677,
      0.0011323885, 0.006003456, 0.03675932, -0.0047746594, -0.008503535,
      -0.026955094, -0.024066115, -0.030432327, -0.020615024, 0.012327184,
      -0.0153076695, -0.044550415, -0.024039969, -0.0011168651, -0.0031422554,
      0.0030164344, -0.014026583, -0.00377136, 0.009843446, 0.018510384,
      0.01547761, -0.029308109, 0.0066015143, -0.010235615, -0.0078826,
      0.010229079, 0.006425038, -0.016980924, -0.0067910627, 0.019373156,
      0.026902804, 0.018301228, -0.000007110619, -0.019124782, 0.0027729627,
      0.0006536153, 0.0076407623, -0.024536718, -0.006640731, 0.023634728,
      -0.0012974263, -0.034537032, -0.018837191, -0.010542815, -0.012653992,
      -0.008019859, 0.022536654, 0.012693209, 0.010555887, 0.019386228,
      -0.037046913, 0.017778335, 0.005189705, 0.017543033, -0.00029678218,
      0.019987555, -0.0033955313, -0.0142357405, -0.010810796, -0.029281965,
      -0.025046537, -0.007327027, 0.03317751, 0.002062156, 0.03119052,
      0.032131728, 0.013216101, -0.013895861, -0.004480533, -0.016039718,
      0.019582313, 0.0037419475, -0.0041471887, -0.019425446, 0.0006597429,
      0.005918486, 0.011725858, -0.017163936, -0.009719259, 0.037125345,
      0.011046098, 0.007895673, -0.0026193631, -0.019164, 0.010327121,
      -0.02517726, -0.0016977657, 0.008144046, 0.0017909058, 0.013085377,
      0.009804229, -0.025896236, -0.01809207, -0.0038399897, 0.01785677,
      -0.0017239102, 0.011418658, -0.021294786, 0.0033661185, 0.002906954,
      0.006215881, -0.00986959, -0.0004005436, -0.028889794, 0.0027223076,
      -0.037073057, 0.010751971, 0.022039907, -0.011085315, -0.012784715,
      0.0120788105, 0.00075655966, -0.02682437, -0.04162222, 0.014810922,
      -0.014248813, 0.028994374, 0.032576185, -0.008130974, -0.051191147,
      0.020824183, 0.0013897495, 0.0036537093, 0.021177135, 0.24408609,
      -0.0058792694, -0.011797756, 0.003255004, 0.009013355, 0.0047811954,
      0.019896049, -0.002222292, -0.012745498, 0.010614712, 0.030014012,
      0.020549664, -0.02495503, -0.0039118873, -0.010915375, -0.024602078,
      -0.028157746, -0.011647424, -0.017556107, 0.0153076695, 0.015529899,
      0.010183326, 0.011836972, 0.0036537093, 0.031033652, 0.015556043,
      -0.009562391, -0.006980611, 0.017647613, 0.0033465102, -0.019203216,
      -0.0033791908, 0.012562485, -0.015268452, 0.017634539, 0.011340225,
      0.038850892, -0.020118278, 0.021922257, -0.0033661185, 0.01615737,
      0.013791282, -0.0036079562, 0.005862929, -0.01695478, 0.015712911,
      -0.026314551, -0.01026176, 0.0029641453, 0.016889418, -0.035635103,
      0.0029690473, 0.028393047, 0.028000878, 0.001110329, -0.0056668445,
      -0.009261728, 0.005663576, -0.013020016, -0.008889168, 0.0055884104,
      0.023373282, 0.00034192248, 0.028889794, -0.019347012, 0.0021683685,
      -0.04272029, -0.020235928, 0.007451214, -0.021242496, 0.013817427,
      -0.0070459726, 0.013791282, 0.021425508, -0.023216413, -0.016732551,
      0.0023039938, 0.010928447, 0.036445588, 0.012170317, 0.00055107934,
      0.032654617, -0.0007986362, 0.004529554, -0.02319027, -0.016131224,
      0.017922131, 0.013065769, -0.0028857114, -0.013333752, 0.0064021614,
      0.0011470948, -0.029334253, -0.0028808094, 0.0004493605, -0.010503598,
      0.0057583502, 0.020680387, -0.0031896424, -0.00046896894, 0.00050205825,
      0.041543785, -0.000686296, -0.0038661342, -0.0022402664, -0.016680261,
      0.011274863, 0.015229235, 0.015373031, -0.024118403, 0.017386166,
      -0.02614461, 0.013895861, -0.0038367216, -0.0093663065, 0.00034437355,
      -0.021255568, 0.0038726705, 0.008529679, 0.0021144454, 0.027190395,
      -0.014418753, -0.0024183765, 0.0017860037, 0.013189956, -0.016810985,
      -0.01712472, -0.0050263014, 0.005179901, -0.027504131, 0.011784683,
      -0.018667253, 0.0332298, -0.0051733647, -0.009222511, -0.004075291,
      -0.0037386792, -0.0121311, 0.0076407623, -0.0073727802, -0.005650504,
      0.028288469, -0.013379505, -0.01700707, 0.013935077, -0.007987179,
      -0.015203091, 0.005101467, -0.0069217854, -0.028000878, -0.023843884,
      -0.0056243595, -0.012667064, -0.01581749, 0.0029151242, 0.012850076,
      -0.037229925, -0.025307983, 0.006928322, 0.017490745, -0.034563176,
      -0.010392482, 0.03759595, -0.0038890108, -0.018523457, -0.016000502,
      -0.1654431, 0.0077649495, 0.010242151, -0.015922068, 0.019817615,
      0.027713288, 0.01542532, 0.02006599, -0.013000407, -0.006552493,
      0.029308109, 0.007529648, -0.050772835, -0.038850892, -0.027294973,
      -0.034798477, -0.026510635, 0.011921942, -0.0042158184, 0.0050622504,
      0.02495503, -0.03022317, -0.004791, 0.014719415, 0.010667001,
      -0.004454388, -0.029857146, 0.036654744, -0.014091945, 0.00256544,
      0.0060557453, 0.00070059387, 0.015320742, 0.00015952297, 0.00392496,
      -0.011856581, -0.0041341167, 0.009555855, -0.006219149, 0.026981238,
      0.007895673, 0.019817615, 0.0071570873, 0.043400053, -0.011405586,
      0.031216664, 0.03171341, -0.020497374, -0.0059707756, -0.024589006,
      0.014614837, -0.025948526, -0.012359865, 0.016601827, 0.019373156,
      0.0174646, 0.013752066, 0.0142095955, -0.012810859, -0.0077780215,
      -0.021569304, -0.0054871, 0.025046537, -0.0044707283, 0.015137729,
      -0.0005359645, 0.0024118403, 0.0061374474, -0.025046537, 0.013752066,
      0.0075557926, -0.0075623286, 0.024811236, 0.010385946, -0.0012892561,
      0.019830687, -0.012987335, -0.012627847, -0.010144109, 0.021164062,
      -0.0035589351, 0.032079436, -0.015399176, 0.0071113342, -0.03173956,
      0.016366526, 0.022667378, -0.0004620243, -0.027477985, 0.017569179,
      -0.012229142, -0.029334253, 0.0072028404, -0.026732864, 0.01797442,
      0.023268703, 0.00574201, 0.001200201, -0.006379285, 0.0021977813,
      -0.00436615, -0.01037941, -0.022314426, 0.04274644, 0.033778835,
      0.020915689, -0.0033269017, 0.024811236, 0.021059483, -0.015608332,
      0.026000815, -0.0011903968, 0.017229298, 0.052524522, -0.0002614461,
      0.017333876, 0.014706343, -0.011359833, 0.004748515, -0.023504006,
      0.03294221, -0.035974983, 0.009529711, -0.000835402, -0.007634226,
      -0.025307983, -0.080107085, -0.008882632, 0.005248531, -0.027059672,
      -0.012941582, 0.013634414, 0.0053694495, -0.0023693554, 0.0032827826,
      -0.0051766327, -0.008385884, -0.025242621, -0.0048204125, 0.016797911,
      -0.00003569352, 0.025268765, -0.009464349, 0.0063106553, -0.020405868,
      0.014091945, 0.016993998, -0.007281274, -0.01819665, -0.012451371,
      -0.019543096, 0.031138232, -0.01724237, 0.010117964, 0.033648115,
      0.0019853564, -0.015020079, -0.018275082, 0.021765389, -0.034772333,
      -0.001756591, -0.012686673, -0.035007633, -0.0074577504, -0.0005069603,
      -0.017647613, 0.016850201, -0.01695478, 0.015242308, -0.03992282,
      -0.0014461238, -0.017516889, -0.008006787, 0.033726547, 0.004411903,
      0.0034086036, -0.0281316, 0.0048530935, -0.036497876, -0.014706343,
      0.017516889, -0.022118341, -0.022366714, 0.007823775, -0.023948463,
      -0.0013742261, -0.021360146, 0.0034282121, -0.031896424, 0.027347263,
      0.022366714, 0.0059707756, -0.0047746594, -0.0047615874, 0.0054184706,
      -0.014039656, 0.024079187, 0.009196367, -0.011843508, 0.018052854,
      -0.039713662, -0.0036635136, -0.029621843, -0.022902679, 0.014105017,
      0.0048628976, -0.010765043, -0.010477453, -0.010693146, -0.020798037,
      -0.03147811, 0.022889607, -0.01569984, 0.013287999, 0.0120592015,
      -0.00059356436, -0.012209533, 0.011091851, 0.017791407, -0.012006912,
      -0.015464537, 0.007889137, -0.0058367844, -0.0028334223, 0.025765514,
      -0.009052572, -0.02047123, -0.011568991, -0.064315744, 0.024314487,
      -0.016850201, -0.007235521, -0.020850327, -0.020654242, 0.037674382,
      -0.021647738, 0.002439619, -0.01394815, -0.0018628035, 0.0016209659,
      -0.01627502, -0.012575557, -0.0063531403, -0.010137573, 0.030981364,
      -0.004787732, 0.01752996, -0.026079249, 0.0030295067, -0.007366244,
      -0.0009877761, -0.015150802, -0.025242621, 0.007961034, 0.00847739,
      0.033804983, -0.001113597, -0.013294535, -0.0020474498, -0.032131728,
      0.0010408823, 0.0278963, 0.0115101645, -0.008653866, -0.011464411,
      0.0183143, 0.006876033, 0.01318342, -0.005098199, -0.017399238,
      0.032001004, -0.027242685, -0.0032648083, -0.0024330828, -0.012314112,
      -0.013490619, 0.01569984, 0.0040033935, 0.013817427, 0.019020204,
      0.016758695, -0.03474619, -0.019660747, -0.042955596, 0.010385946,
      -0.019464662, 0.023124907, -0.033465102, 0.00411124, 0.002392232,
      -0.012752034, -0.018497312, 0.00013460389, -0.019477734, 0.011039562,
      -0.009163686, 0.011568991, -0.025621718, -0.010451308, 0.012222606,
      0.010817332, 0.010235615, -0.0057158656, -0.018458094, -0.016536467,
      -0.029334253, -0.012804323, 0.017647613, -0.0026144611, -0.018889481,
      -0.033935703, 0.0006123558, 0.018327372, 0.02993558, 0.0013006944,
      -0.014706343, -0.008915313, 0.0088172695, -0.0060524773, 0.008666938,
      -0.017909057, 0.00077249156, 0.015856706, -0.010189862, 0.006457719,
      -0.019935265, -0.0072159125, 0.027321119, -0.0021209815, 0.003895547,
      0.0110134175, -0.037412938, -0.023634728, 0.024301415, -0.0065492247,
      -0.026981238, -0.0281316, 0.0070525086, -0.0022402664, 0.014719415,
      0.021935329, 0.0046079876, -0.015438393, 0.008418565, -0.0063008512,
      -0.025151115, -0.021412436, 0.017634539, 0.027033526, -0.012392545,
      0.0029674133, -0.004326933, 0.024772018, -0.0055491934, 0.034693897,
      -0.0024347168, 0.010163717, 0.028654493, 0.0012181754, -0.024941958,
      -0.030406183, -0.029778711, 0.0010416993, 0.005898878, -0.015242308,
      0.004545894, -0.017765263, 0.08659095, 0.0006458536, -0.006889105,
      -0.0014003707, 0.015072368, 0.013235709, 0.021804605, -0.00077249156,
      -0.0066505354, 0.010797724, 0.013752066, -0.011725858, 0.0174646,
      -0.013974294, -0.0017860037, 0.015556043, -0.00008374445, 0.007242057,
      -0.012242214, 0.00016493573, 0.011608207, 0.0032386635, -0.023020329,
      -0.0028154477, 0.010340193, 0.0028481286, 0.002089935, -0.024968103,
      -0.0166149, -0.024419066, 0.0010376142, 0.02670672, -0.019752253,
      -0.014431825, 0.00036112242, -0.02070653, -0.006934858, 0.016092008,
      -0.0012892561, 0.017059358, -0.0049838163, 0.023948463, -0.030589195,
      -0.0069479304, -0.0074708224, -0.032733053, -0.000428118, -0.017686829,
      -0.015922068,
    ],
  },
  {
    id: "faq-11",
    question: "Πώς μπορώ να επικοινωνήσω μαζί σας ;",
    answer:
      "Μπορείτε να επικοινωνείτε μαζί μας μέσω:\n\nΤηλεφώνου στο 2644038033 , καθημερινά από 09:00 έως 17:00 εκτός Σαββάτου, Κυριακής και Αργιών.\nΜέσω e-mail στο info@carespot.gr\nΜέσω Facebook Messenger στη σελίδα μας ΕΔΩ\nΜέσω Instagram στη σελίδα μας ΕΔΩ\nΜέσω Viber & WhatsApp στο 6948470358\nΜέσω Google My Business ΕΔΩ\nΘα χαρούμε να σας εξυπηρετήσουμε!",
    embedding: [
      0.005878698, 0.005906501, -0.004417519, -0.024972882, -0.0304469,
      0.019684216, -0.011646186, 0.010225166, 0.0021531542, -0.013814786,
      0.0132710915, 0.0039108945, -0.013765359, -0.015915425, 0.0031107983,
      -0.026270336, 0.025850208, 0.011108669, 0.013159881, 0.0024806068,
      -0.0049643028, -0.015235806, 0.008316057, 0.001597103, 0.0015214182,
      0.016545616, 0.010608223, 0.0030845404, 0.007982425, -0.04109837,
      0.022835175, -0.0070927436, -0.005202169, -0.0015878355, -0.0064378385,
      0.00039290433, 0.04050525, -0.0025964507, 0.03074346, -0.016273769,
      -0.009662936, -0.013345232, 0.0046553854, -0.013135168, -0.01930116,
      0.010206631, -0.02269925, -0.020709822, 0.002670591, 0.03286881,
      -0.005270131, 0.004744971, -0.040258117, -0.011324912, 0.014259627,
      0.026690464, 0.012257842, 0.03637811, -0.0031972951, -0.010564975,
      -0.000989308, -0.00040584023, -0.027580146, 0.0068950364, -0.00093447516,
      0.008186311, -0.00076032296, -0.015668292, -0.012239307, 0.0049612136,
      0.005625386, 0.01043523, -0.007883572, -0.015767144, 0.0070371386,
      0.013888926, 0.012523511, 0.008668222, -0.026196197, -0.0018720395,
      0.017027527, -0.019943707, -0.003744079, 0.016990457, 0.025652502,
      0.028840529, 0.009465229, 0.012900391, -0.023959633, -0.02337887,
      0.006101119, 0.0123072695, -0.0005904184, 0.0020295873, -0.0002023409,
      0.011578225, -0.017534152, 0.009335484, 0.007920642, -0.0052670417,
      -0.0026304317, -0.00196317, -0.0249111, -0.000088668945, -0.0030104,
      -0.031262442, 0.018967528, 0.015001029, 0.007012425, -0.0024466258,
      -0.012727397, 0.039294295, 0.013975424, -0.028321547, 0.0039850343,
      0.025998488, -0.0008896821, -0.027110592, -0.039467286, -0.0073584127,
      0.0119180335, -0.030026771, -0.0075190496, -0.02423148, 0.013629436,
      0.008334592, -0.004454589, -0.0032868811, -0.032176837, -0.0017021348,
      0.018448547, 0.0030737282, 0.004290863, 0.01592778, -0.009971853,
      -0.005504908, -0.029310083, 0.01762065, -0.025874922, -0.0018257018,
      0.024182055, 0.015211093, 0.019424727, -0.010064529, -0.0067900047,
      0.02869225, -0.0059713735, -0.0014063464, 0.005714972, 0.0061474564,
      0.009625866, 0.0017870872, 0.01802842, -0.0016882336, -0.009205739,
      0.015594151, -0.008816503, 0.005078602, 0.0023014348, -0.009879178,
      0.0014889819, 0.010836823, 0.007945356, -0.0073398775, -0.031336583,
      0.04356971, -0.009718541, 0.013975424, -0.002812693, 0.00011806437,
      -0.010898606, 0.018238483, -0.017670076, 0.015865998, -0.002662868,
      0.013493512, 0.000707807, -0.006925928, 0.011788288, -0.0008927713,
      -0.042951874, 0.0012611554, 0.018337337, 0.023576576, -0.013246378,
      0.007512871, 0.005501819, -0.014346125, 0.011998352, 0.0021191733,
      -0.00418892, 0.007506693, 0.026616324, -0.015495297, -0.6279179,
      -0.000121829304, -0.0081554195, -0.006975355, 0.007346056, 0.043792132,
      0.010070708, 0.022884602, -0.027481291, 0.04744971, -0.008445802,
      0.0020172307, 0.0091501335, -0.002655145, 0.011392874, -0.023168806,
      0.016965743, -0.012949818, 0.020240268, -0.0051898123, -0.031731997,
      -0.012647078, -0.018485617, 0.027481291, 0.015161666, 0.0011298654,
      0.023428297, -0.009477586, -0.017002814, 0.019956063, -0.02109288,
      0.013542939, 0.025825495, 0.0108306445, 0.059509847, -0.003373378,
      -0.01963479, 0.05703851, 0.017558865, 0.022600397, -0.038997732,
      -0.020746892, 0.014222558, -0.0030459256, -0.0027292853, -0.026912885,
      -0.008692936, -0.025578361, 0.040406395, -0.025454793, -0.0015847463,
      0.010966568, -0.023811353, -0.018905746, 0.012653257, 0.006376055,
      0.026542183, -0.013085742, -0.014988672, 0.011022173, -0.0028065145,
      -0.017126381, -0.01638498, -0.024429189, -0.019696573, -0.011534976,
      0.0047140797, 0.002502231, 0.009767968, 0.0044051623, 0.019832497,
      0.004466946, -0.0024914187, 0.032844096, 0.006159813, 0.011590581,
      0.0015878355, -0.0059188576, 0.0016805107, 0.0012426203, -0.011442301,
      -0.013036314, -0.029878492, -0.005891055, 0.03830576, -0.014210201,
      -0.029161803, -0.001847326, 0.010632937, 0.021797212, 0.02423148,
      0.04683188, -0.0018921191, 0.0052979332, 0.0006155179, -0.0057767555,
      -0.01963479, 0.00916249, 0.0027138393, 0.016372623, -0.0014040296,
      -0.016805107, 0.009248987, -0.020598613, -0.0083037, 0.0068085394,
      -0.01694103, 0.018843962, 0.033190086, -0.012066314, 0.0072657373,
      -0.014531475, 0.009452873, -0.0029764192, 0.020054918, -0.026888171,
      0.027580146, 0.022921672, 0.004797487, -0.0029038235, 0.005313379,
      0.019869568, 0.011905677, -0.017929565, -0.0047635064, 0.015161666,
      0.012949818, -0.019140523, -0.013963067, -0.00706803, -0.021648932,
      -0.017027527, -0.000802413, 0.007278094, 0.0012318081, -0.02913709,
      0.018584471, -0.020783963, 0.028593395, -0.0002805356, -0.01189332,
      0.00010454923, 0.008007139, -0.0046275826, 0.024503328, -0.008563191,
      -0.0064687305, -0.005486373, -0.02064804, 0.015087526, 0.013542939,
      -0.0028775656, -0.005171277, 0.010311663, 0.009965675, -0.017114025,
      -0.019313516, -0.009174847, -0.014976316, -0.004244525, 0.011998352,
      0.010527905, -0.002965607, 0.0053998763, -0.038330473, -0.008291343,
      -0.002744731, 0.0024589826, -0.014012493, -0.0150751695, 0.026641037,
      0.00047727738, -0.030842314, -0.016619757, 0.025553647, 0.006549049,
      0.0051527424, 0.007543763, 0.0025501132, -0.013234021, -0.0041950983,
      0.0016789661, -0.01705224, 0.026196197, 0.014828036, -0.002621164,
      0.029013522, 0.036180407, -0.009335484, 0.012016887, 0.010107777,
      -0.008643509, 0.00035120046, -0.0015484485, 0.006925928, 0.0011352715,
      0.0066664377, 0.010484656, -0.0008943159, 0.045645636, 0.03867646,
      0.016236698, 0.043174297, -0.027679, 0.027802566, -0.017781286,
      0.020858103, 0.008717649, 0.0463129, 0.027703712, 0.0010495469,
      -0.0064687305, 0.0016171826, 0.002876021, 0.0066108326, 0.017793642,
      -0.028865242, 0.004222901, -0.0024775176, 0.014049564, -0.008872108,
      0.015433514, -0.0036421362, -0.024280908, -0.01537173, 0.009940962,
      0.02229148, 0.052787807, 0.0049766595, -0.0011669355, -0.05254067,
      0.019227019, -0.005934303, 0.018201413, 0.03766321, -0.0071977754,
      -0.011942747, 0.0062555773, 0.019894281, 0.009483764, -0.0011677077,
      0.020376192, 0.019746, 0.010249879, 0.015013386, 0.010379625,
      0.0016542526, -0.010367268, 0.01402485, -0.0011383606, -0.046090476,
      -0.011003638, -0.023304729, -0.008773254, 0.0411478, -0.036303975,
      -0.0056655454, 0.001273512, -0.0072163106, 0.02236562, 0.015717717,
      0.0054215, 0.0015669835, -0.00788975, -0.008007139, 0.0126409,
      0.013468799, -0.019437082, -0.02505938, -0.009298414, -0.004368092,
      -0.013505869, 0.0073522343, 0.0038552892, 0.0016851444, -0.016236698,
      0.018189056, -0.0132093085, 0.0023740302, -0.0030829958, -0.038454037,
      -0.039467286, 0.0046770093, 0.008050388, -0.028247407, -0.041345507,
      -0.020079631, 0.03220155, -0.016038992, 0.029829064, 0.004454589,
      -0.011602937, 0.004219812, 0.0039634104, -0.00019625909, 0.0012372142,
      0.05318322, -0.0034135373, 0.017670076, -0.013184595, 0.011114848,
      -0.003410448, 0.009564083, -0.024787532, 0.033091232, 0.0042507034,
      -0.009767968, 0.00032745244, -0.021784855, -0.04611519, -0.0021068167,
      -0.00064949883, -0.021352371, -0.010367268, 0.01552001, 0.008779433,
      0.0037904165, -0.01286332, 0.029013522, -0.018399121, -0.0072842725,
      -0.041839775, -0.03242397, -0.0030335688, 0.046708312, 0.006870323,
      0.0110159945, -0.009329306, -0.004312487, -0.0053597167, -0.010867714,
      -0.02973021, -0.004828379, 0.0023508614, -0.008260451, -0.0025949061,
      0.015668292, 0.017941922, 0.0020172307, 0.008260451, 0.007525228,
      0.012325805, 0.00025640146, -0.030397473, 0.011695613, -0.004967392,
      0.005381341, 0.020956956, 0.013827143, -0.0060516917, 0.007098922,
      0.012189881, -0.015013386, -0.025850208, 0.014963959, -0.0035587284,
      0.0031030753, -0.017768929, -0.018263197, 0.012424658, -0.017633006,
      0.020487402, 0.0075870114, 0.01533466, 0.026764603, 0.031460147,
      0.005575959, 0.014197844, 0.009378732, -0.0018102559, -0.0036483146,
      0.0049612136, 0.005158921, 0.0076426165, 0.029680785, -0.007821789,
      -0.02101874, -0.010707078, -0.010083064, 0.019276446, 0.006901215,
      -0.011658543, -0.008173955, -0.012152811, -0.014000136, 0.011318734,
      0.024787532, -0.016990457, -0.0075684763, -0.03855289, -0.019449439,
      0.020697465, -0.002801881, -0.010836823, 0.021735428, -0.039343722,
      -0.0304469, -0.0040190155, 0.037168942, 0.0244539, 0.015668292,
      0.023539506, -0.005823093, 0.024775175, -0.0022906226, -0.022847531,
      0.030397473, -0.02124116, -0.00062633003, 0.0061350996, -0.005217615,
      -0.016928673, 0.021982562, 0.02572664, 0.009174847, 0.01705224,
      0.002726196, -0.008711471, 0.0033146837, 0.023996703, -0.022934029,
      0.026245622, -0.0123999445, -0.019585364, 0.015062813, 0.0035093017,
      -0.026912885, -0.03205327, -0.0078094318, 0.029458363, -0.0004255337,
      0.014828036, -0.03168257, 0.018189056, 0.01828791, -0.021475937,
      0.035266012, -0.014457335, 0.0010534084, 0.043322578, 0.014630328,
      0.01784307, -0.0026304317, 0.015631221, 0.0031478684, -0.040702958,
      0.012715041, 0.0037595248, -0.045497354, 0.0085446555, -0.0026041737,
      -0.014457335, 0.011800645, -0.010663829, 0.014766252, 0.036402825,
      -0.014568545, -0.020289695, -0.024775175, 0.018263197, 0.012121919,
      0.003781149, 0.0020434887, -0.0026088075, -0.02352715, -0.017595936,
      -0.022266766, -0.02090753, -0.03059518, -0.037366647, -0.01847326,
      0.016273769, 0.0038769133, 0.03388206, -0.0026505112, 0.008346948,
      -0.028395688, -0.019461796, 0.012949818, -0.028000273, 0.0033054163,
      -0.0026227087, 0.0027478202, 0.019202305, -0.0007178468, 0.01297453,
      0.016508546, -0.012097205, -0.019128166, -0.03294295, -0.01395071,
      0.002323059, -0.0040035695, 0.036254548, 0.02760486, 0.027085878,
      0.040085122, 0.018164344, -0.010299306, 0.019313516, -0.011880963,
      -0.01406192, -0.037836205, -0.03650168, 0.020734536, -0.017410586,
      0.017756572, 0.011281664, -0.0037131873, -0.013172238, -0.0016542526,
      -0.019622434, 0.022526257, 0.003799684, 0.000056425695, -0.03548843,
      -0.0026396993, -0.015680647, 0.01982014, -0.010045994, -0.0053751627,
      -0.012900391, 0.01933823, 0.037366647, -0.00347841, -0.01103453,
      0.0044484106, 0.01518638, -0.01978307, 0.0056717237, -0.019535936,
      -0.009817395, -0.021080524, 0.0032405437, -0.033610214, -0.012665614,
      -0.016508546, -0.0044329646, 0.0007166884, 0.008285165, -0.015396443,
      0.01638498, -0.0015113784, -0.02681403, -0.01653326, 0.00059814134,
      0.027357725, 0.020598613, 0.022489186, 0.0051558316, 0.020672753,
      -0.02105581, -0.040455822, -0.0037379006, 0.0027354637, -0.008241916,
      0.018386764, 0.009279879, -0.024799889, 0.015643578, 0.025195304,
      -0.00057265564, -0.008563191, 0.023934921, 0.0055512455, -0.008859751,
      -0.010194275, 0.012158989, -0.011491727, 0.041444357, 0.010886249,
      -0.005007551, -0.016001921, -0.00026875813, 0.0030042217, 0.027679,
      -0.001254977, 0.031138875, 0.0032003843, -0.018806892, -0.013147525,
      -0.019016955, -0.008427267, 0.0169781, -0.008421089, 0.021797212,
      -0.009860643, 0.009248987, 0.010039816, -0.000099432786, -0.009953319,
      -0.0007039455, -0.015198736, 0.017002814, -0.010218987, -0.009211917,
      -0.0053010224, 0.018584471, -0.0031293333, 0.021982562, 0.0071421703,
      0.006141278, -0.032967664, -0.011683256, 0.017781286, 0.0068765013,
      -0.010793574, -0.018349694, -0.0073089856, -0.023354156, 0.0019013867,
      -0.012152811, 0.006697329, 0.018757464, -0.0016341731, -0.05318322,
      0.015693003, 0.010447587, 0.024305621, 0.015717717, 0.018349694,
      0.025269443, -0.024565112, -0.012023065, 0.017509438, -0.018720394,
      -0.024861673, 0.008235738, -0.0137530025, -0.032028556, 0.017744215,
      0.014926889, -0.0014094356, -0.0019183771, 0.010608223, 0.02592435,
      0.01799135, -0.008038031, 0.027061164, 0.0090574585, -0.005860163,
      -0.022625111, -0.015322303, -0.019461796, -0.025047023, -0.011195167,
      -0.016656827, -0.02295874, -0.006691151, -0.0009993478, -0.0056933477,
      0.0017793642, -0.021994919, -0.029310083, -0.00894007, 0.018497974,
      0.016619757, -0.026344476, 0.0066849724, -0.013283448, -0.008896821,
      0.0367241, 0.006975355, -0.0039139837, -0.005755131, 0.032547537,
      0.031064734, 0.021426512, -0.0064934436, -0.025034666, 0.01657033,
      -0.0141731305, 0.002434269, -0.011813002, -0.020314408, 0.034129195,
      0.01788014, -0.032003842, -0.025578361, -0.010243701, -0.014630328,
      0.01592778, 0.00032262562, 0.003018123, 0.002670591, 0.013011601,
      -0.022563327, 0.01570536, 0.018485617, -0.0054153223, -0.0086064385,
      0.014444978, -0.00046646528, -0.028519254, -0.027110592, -0.0105340835,
      -0.02797556, 0.030990593, 0.047869842, -0.035809707, -0.0027215623,
      0.029235942, -0.011924212, -0.014766252, 0.003605066, -0.013468799,
      0.003133967, 0.020215554, -0.0037224547, -0.033634927, 0.017571222,
      0.0077105784, 0.0066108326, -0.032918237, 0.0065922975, 0.030249191,
      0.000092820024, 0.020413263, 0.0025346673, -0.033041805, 0.023490079,
      -0.026245622, -0.010225166, 0.0091501335, -0.0213153, 0.0023323265,
      -0.021377085, -0.022946384, -0.02535594, 0.023280015, -0.011139561,
      -0.009712364, 0.0145932585, -0.017954279, -0.011083957, -0.0048191114,
      -0.003249811, -0.014679755, -0.0027570878, -0.012813894, -0.01851033,
      0.0040715314, 0.012189881, 0.012702684, -0.0067343996, -0.021513008,
      -0.005603762, -0.0023894762, -0.024157342, -0.025306514, 0.0039634104,
      -0.008297522, 0.025331227, 0.037341937, 0.0010680819, -0.01435848,
      0.0006298054, 0.0024929633, 0.0026798584, 0.02277339, 0.2435752,
      -0.024787532, -0.010670007, 0.035340153, -0.0017809088, 0.006305004,
      0.0030721836, -0.001986339, 0.0056006727, 0.011565868, 0.004080799,
      -0.011936569, -0.012146632, 0.0013152158, -0.015235806, -0.025899636,
      -0.030397473, -0.027654286, -0.013963067, -0.016137846, -0.0008039575,
      -0.0056500994, 0.016842177, 0.013555296, 0.013159881, 0.03037276,
      -0.015865998, -0.003209652, 0.0105958665, 0.01406192, -0.008451981,
      0.01915288, 0.01825084, -0.007988604, 0.013382302, -0.0053875195,
      0.047894552, -0.021611862, 0.019066382, -0.0022504632, -0.0013237111,
      0.016088419, 0.0035988877, -0.0013561473, -0.02644333, -0.013258735,
      -0.009811217, -0.009434338, 0.00785268, 0.025528934, -0.032547537,
      -0.0027277407, 0.006672616, 0.017707145, -0.01391364, -0.024243837,
      -0.008013317, 0.010305485, -0.0042692386, 0.001660431, 0.014185487,
      0.011090135, 0.0042352574, 0.04880895, -0.004207455, 0.009224273,
      -0.0019817052, -0.008964783, 0.0068950364, -0.00051357516, 0.010756504,
      -0.00531029, 0.010163383, 0.006567584, -0.03721837, -0.0070494954,
      -0.0018581381, 0.0030042217, 0.030348046, 0.02797556, -0.003944875,
      0.02109288, -0.012220773, -0.002041944, -0.023947276, -0.0024095557,
      0.020709822, -0.005804558, -0.020203197, -0.0047480604, -0.0026798584,
      0.015235806, 0.000054350156, -0.01121988, -0.009965675, -0.016026635,
      0.007871215, 0.03430219, -0.007111279, 0.02120409, -0.0104722995,
      0.034351613, 0.023984347, 0.0005267042, -0.016459119, -0.0029795084,
      -0.0031277887, 0.010824466, 0.0096320445, -0.017707145, 0.0064501953,
      -0.020351479, 0.011541154, -0.0064007686, -0.0029146357, 0.0027246515,
      0.00841491, -0.011429944, 0.0110716, 0.0076240813, 0.023848424,
      -0.0221432, -0.0077785403, 0.0068826796, 0.011318734, -0.024095558,
      -0.022785747, -0.008921535, 0.0071977754, -0.022872245, 0.03160843,
      -0.004133315, 0.025405368, -0.009323128, -0.017188163, 0.015668292,
      0.0011700246, 0.005863252, -0.0060424246, -0.0141731305, -0.009224273,
      -0.012647078, -0.017583579, 0.000069892565, 0.00504771, -0.018497974,
      0.01211574, -0.0145932585, 0.018003706, -0.00097617897, -0.016632114,
      -0.008025674, -0.009693828, 0.0042846845, 0.026097342, -0.0066664377,
      -0.027407153, -0.029903205, -0.006542871, 0.006586119, 0.0061320104,
      0.002139253, 0.02247683, -0.006215418, -0.0066108326, 0.0041240472,
      -0.15262991, 0.010070708, 0.012301091, -0.021846639, 0.0037409898,
      0.018708037, 0.0032776135, 0.011701792, -0.007945356, -0.010632937,
      0.031138875, -0.022044346, -0.027110592, -0.030990593, -0.043742705,
      -0.020623326, -0.013456442, 0.0055110864, 0.011640008, 0.0083037,
      0.025009952, -0.02913709, 0.009421981, -0.0042290795, 0.019659504,
      0.0041271364, -0.027333012, 0.025047023, -0.031064734, -0.011695613,
      -0.017768929, 0.022081416, 0.010231344, -0.0072719157, 0.0027879796,
      -0.018535044, 0.012572939, -0.020030204, -0.016199628, 0.020623326,
      0.020289695, 0.016459119, -0.0077476483, 0.041839775, -0.019066382,
      0.0068456098, 0.012016887, -0.009032745, -0.004398984, -0.0022489186,
      0.0006070227, -0.012715041, 0.0094466945, 0.0055450676, 0.009255165,
      0.0110716, -0.003549461, 0.026196197, 0.007976247, -0.008705293,
      -0.02517059, 0.008797968, 0.0005491007, -0.0009831296, 0.018300267,
      -0.016224341, -0.0025624698, 0.012949818, -0.033115946, 0.01948651,
      0.017633006, -0.021908423, 0.007210132, -0.02232855, 0.03131187,
      0.012492619, -0.017608292, -0.010521727, -0.016483832, 0.015643578,
      -0.011454658, 0.020042561, -0.029878492, 0.015989564, -0.028543968,
      0.00013640634, 0.008822681, 0.0019415459, -0.04458296, 0.013666506,
      0.0011383606, -0.023774283, 0.0016341731, -0.008007139, 0.025034666,
      0.021006383, 0.038132764, 0.016928673, 0.016125489, 0.0003923251,
      -0.0053875195, -0.015606507, -0.023218231, 0.039986268, 0.021154664,
      -0.012702684, 0.0001755037, 0.007945356, 0.032448683, -0.017324088,
      0.009082172, -0.01114574, 0.010058351, 0.038874164, 0.009316949,
      0.012715041, -0.0012264021, -0.007648795, 0.011096313, -0.003078362,
      0.039590854, -0.041345507, 0.0044082515, 0.00078426406, -0.007815611,
      -0.017311731, -0.06811011, -0.024663966, 0.0020512117, 0.0021145397,
      0.027011737, 0.010243701, 0.013061028, -0.0048191114, -0.0032436326,
      0.0137530025, -0.014444978, -0.015470584, 0.009551726, -0.0025701928,
      -0.009607331, -0.019313516, -0.023848424, -0.014197844, -0.0042105443,
      0.009434338, 0.012183703, -0.02255097, -0.010280771, 0.015099883,
      -0.028395688, 0.01391364, -0.0075746546, 0.019857211, 0.024762819,
      0.0114361225, -0.015248163, -0.027184732, -0.01784307, -0.02363836,
      -0.037168942, 0.00063636986, -0.026566897, -0.013580009, 0.010447587,
      -0.017608292, 0.023910208, 0.008760897, 0.006499622, -0.04559621,
      -0.008872108, 0.010663829, -0.01125695, 0.016076062, -0.0010294672,
      0.004689366, -0.038898878, -0.01963479, -0.047820415, -0.012962175,
      0.011911855, -0.010564975, -0.012461728, 0.024107914, -0.047153153,
      0.015347017, -0.0018164343, -0.009403446, -0.026220908, 0.025825495,
      0.022019632, 0.0055790483, 0.0012572939, 0.00027088195, 0.01682982,
      -0.008031853, 0.007346056, 0.009354019, -0.0044607674, 0.018831605,
      -0.053430352, -0.012813894, -0.020512115, -0.0011383606, 0.01686689,
      0.014815679, -0.009001853, -0.024317978, -0.007970069, -0.015940137,
      -0.010663829, 0.025158234, -0.013332875, 0.016916318, 0.004099334,
      -0.00073406496, -0.022007275, 0.0075499415, 0.015458227, 0.017818356,
      -0.022217339, -0.011683256, -0.005826182, -0.018745108, 0.023144092,
      -0.012548225, -0.041765634, -0.019004598, -0.07636438, 0.0094466945,
      -0.023626003, 0.0072966293, -0.012702684, -0.026418617, 0.01843619,
      -0.021142308, 0.011009816, 0.011176632, 0.005004462, -0.009181025,
      0.0043032193, -0.00463685, 0.00024134172, -0.009304592, 0.02760486,
      0.014296697, 0.015989564, -0.009063637, -0.019622434, -0.023514792,
      -0.00074951083, -0.010843, -0.022340907, 0.021772498, -0.012239307,
      0.012220773, -0.029878492, -0.004939589, -0.000053191714, -0.037317224,
      0.0034567858, 0.028667536, -0.0045997803, -0.0020743804, 0.022995811,
      0.045645636, -0.0010449131, 0.019795427, 0.0026088075, -0.027654286,
      0.011640008, -0.007865037, -0.008835038, 0.026468042, -0.002655145,
      0.0071977754, 0.011899498, 0.00009475076, 0.014617971, 0.015272876,
      0.011114848, -0.016113132, -0.011133383, -0.04122194, -0.0104414085,
      -0.022563327, 0.019956063, -0.03721837, 0.018609185, 0.010527905,
      -0.0018859408, 0.0061103865, -0.000076553595, -0.011985995, 0.013023958,
      -0.0012928193, 0.0070371386, -0.02229148, -0.016656827, 0.029557217,
      0.0043186652, 0.017719502, 0.00063328067, 0.007939178, 0.010447587,
      0.0010812109, -0.025627788, 0.031287156, 0.031114161, -0.004420608,
      -0.037366647, 0.016298482, 0.03650168, 0.038503464, 0.010385803,
      -0.0108306445, -0.020017847, 0.011158097, -0.013567653, 0.023934921,
      0.0113743385, 0.019424727, 0.018930458, 0.0042846845, 0.02681403,
      -0.034944735, -0.019029312, 0.016916318, 0.0055265324, 0.014630328,
      0.016743323, -0.035018876, -0.016063705, 0.021253517, -0.022452116,
      -0.037490215, 0.009082172, 0.015767144, -0.00050083234, 0.026270336,
      0.014383194, 0.006691151, -0.011998352, 0.0052207042, 0.02150065,
      0.0006070227, -0.02696231, 0.031114161, 0.010867714, -0.0074634445,
      0.0018164343, -0.008285165, 0.017373515, -0.011788288, 0.002947072,
      -0.021006383, 0.009885357, 0.018992241, -0.015594151, -0.021228803,
      -0.022662181, -0.012220773, 0.0038583784, 0.0072533805, -0.012727397,
      0.0091192415, -0.010966568, 0.09959497, -0.0008765531, -0.012177524,
      0.01911581, 0.022118486, 0.003886181, 0.027085878, -0.005921947,
      -0.012375231, -0.012109562, 0.0078032534, 0.0061350996, 0.012801537,
      -0.034994163, 0.0035988877, 0.021253517, -0.015693003, 0.0029686962,
      -0.034648176, -0.014197844, 0.006178348, 0.008421089, -0.039541427,
      -0.014519118, -0.011207524, 0.013147525, 0.021908423, -0.005344271,
      -0.0009638223, -0.0118562495, 0.0021438869, 0.011806823, -0.014506761,
      -0.019362943, -0.013172238, -0.005118761, -0.00039097358, -0.018979885,
      -0.009304592, 0.02935951, -0.013827143, 0.014284341, -0.046906017,
      -0.015211093, -0.022192625, -0.016372623, 0.014309054, -0.007234846,
      -0.03810805,
    ],
  },
  {
    id: "faq-12",
    question: "Είναι ασφαλή τα προσωπικά μου δεδομένα ;",
    answer:
      "Το carespot.gr θεωρεί κάθε πληροφορία που παρέχει ο χρήστης σχετικά με προσωπικά του δεδομένα εμπιστευτική και απόρρητη και γι’ αυτό δεσμεύεται ρητά για τη μη δημοσιοποίηση, ανταλλαγή, πώληση ή αποκάλυψη προσωπικών δεδομένων και στοιχείων των πελατών του. Δείτε περισσότερες πληροφορίες στη σελίδα μας Όροι Χρήσης και Λειτουργίας",
    embedding: [
      0.005210974, -0.0102652265, 0.01225036, -0.054434434, -0.03547119,
      0.014509754, -0.021901762, -0.005178324, 0.010532958, -0.021940943,
      0.016599368, 0.008750257, -0.0076074996, -0.011910798, 0.007032856,
      -0.009050639, 0.02301187, 0.016129205, 0.026707875, -0.013700029,
      -0.0016439382, 0.0027099675, -0.011669186, -0.00026222202, 0.011140252,
      -0.010937821, 0.003839665, -0.030194916, 0.003712329, -0.048113354,
      0.036254797, -0.011016182, 0.0041988175, -0.005991314, -0.0122373,
      -0.002365508, 0.007378948, -0.0022544973, 0.027321698, 0.029385192,
      0.012896834, 0.0011239835, 0.009553452, -0.005064048, -0.022528647,
      0.01998193, -0.012518091, -0.012700933, -0.0121850595, 0.031683765,
      0.008423755, 0.008299684, -0.042314675, -0.037586924, -0.014431394,
      -0.008410695, 0.0035621382, -0.004290238, -0.01998193, -0.008129903,
      0.019237505, -0.0012496869, -0.022332745, -0.021261819, 0.015019097,
      0.017239312, -0.009592633, 0.008430284, 0.0058998936, -0.007868702,
      0.017905377, 0.0048485566, -0.010297877, -0.012328721, 0.010147686,
      -0.0017125036, 0.014875437, -0.010095445, -0.018049037, 0.013778389,
      0.0127205225, -0.02301187, -0.009364081, 0.008214793, 0.02747842,
      0.02935907, 0.0024601936, 0.009115939, -0.004874677, -0.017043412,
      0.01828412, 0.023377553, 0.0045579695, 0.009266131, -0.03228453,
      0.018323299, -0.028131424, 0.012864184, 0.01678221, -0.006454947,
      0.008665366, -0.01228301, -0.0045024646, 0.000537096, -0.023939136,
      -0.007372418, 0.026355252, 0.013184156, 0.0059358086, -0.030221038,
      0.004355538, 0.051273894, 0.0063537313, -0.03233677, -0.002154914,
      0.01974685, 0.041948993, -0.008554356, -0.050229087, -0.01693893,
      -0.0009648138, 0.0054329955, -0.004156372, 0.0009370611, 0.021731982,
      -0.0065561626, -0.003385827, -0.020230072, 0.0020471683, 0.010559079,
      0.039441455, -0.02431788, 0.009997495, 0.004786521, -0.017957617,
      -0.0041073966, -0.024344, 0.03844889, -0.029463552, -0.00382334,
      0.022580886, 0.024748862, -0.006108855, 0.009827714, 0.01692587,
      0.0011550012, -0.003996386, 0.032963656, 0.013301697, 0.013517188,
      0.006454947, 0.0031931908, 0.0141179515, 0.0061121196, -0.019707669,
      0.013112325, -0.020739416, 0.01526724, -0.003158908, -0.030403879,
      -0.03178825, 0.012909894, 0.028105304, -0.019080784, -0.0022528647,
      0.046520025, -0.010128096, 0.003852725, 0.020256191, 0.0146403555,
      -0.0055799214, 0.021105098, -0.019146085, 0.008802498, -0.0062590456,
      0.026459733, 0.0063537313, -0.019302806, -0.0024993739, 0.0029581094,
      -0.06728556, 0.019224444, 0.02292045, -0.0016978111, -0.02276373,
      0.02773962, 0.008456405, 0.018035978, 0.0061121196, -0.016181445,
      -0.0016333269, 0.008391105, -0.0071634566, -0.023168592, -0.621869,
      -0.006797774, 0.020948377, -0.0032078833, 0.009684053, 0.036359277,
      0.014757896, 0.025310446, -0.038945172, 0.048896957, 0.006458212,
      0.014078772, -0.00009529781, -0.011891208, 0.00011427575, -0.021731982,
      -0.0060827346, 0.015097458, 0.029881475, 0.003522958, -0.01989051,
      0.013353937, -0.00076075, 0.02165362, 0.017552754, 0.00767933,
      0.031056883, -0.034191303, -0.03727348, 0.015528441, -0.014927677,
      0.0011525525, 0.015854944, 0.016063904, 0.051691815, -0.027217217,
      -0.0029336216, 0.036359277, 0.010226047, 0.02912399, -0.01529336,
      -0.015463141, 0.024383178, -0.0050183376, -0.0023704055, 0.013425767,
      0.026668694, -0.015894124, 0.02589815, -0.006732474, 0.001502726,
      -0.018414719, -0.016442647, -0.016155325, 0.008306214, 0.008240914,
      0.031135242, -0.02909787, 0.0011852027, -0.0104350075, -0.008645777,
      0.00764668, -0.009324901, -0.040225063, 0.006451682, -0.0036502937,
      0.003656824, 0.0045057293, 0.007235287, -0.011101073, 0.01991663,
      0.0052403593, -0.003846195, 0.01854532, 0.012785823, 0.009984435,
      0.02141854, -0.02314247, -0.026799295, -0.010833341, -0.0095469225,
      -0.013118856, -0.027008256, 0.008031952, 0.019564008, -0.018323299,
      -0.013321287, -0.015802704, 0.0051685288, 0.020412913, 0.03215393,
      0.023338372, 0.031213604, 0.010212987, -0.0033564419, 0.014718716,
      -0.0155153815, -0.026603393, -0.015789643, 0.033068135, 0.01689975,
      0.004760401, 0.019577067, -0.019394226, 0.016024724, -0.0011998953,
      -0.013360467, 0.026982136, 0.0274523, -0.020334553, 0.0028226108,
      0.012015278, 0.011806317, -0.0026332396, 0.00019579925, -0.031056883,
      0.039650418, 0.025427986, -0.021287939, 0.00533831, 0.017343793,
      0.0010203192, 0.0010374605, -0.015815763, 0.013158035, 0.016690789,
      0.011780197, -0.020491274, -0.0068500144, -0.0004248609, -0.0059064236,
      -0.025349624, 0.0011329624, -0.015071338, -0.0038070148, -0.014601175,
      -0.003833135, -0.024030557, 0.016142266, -0.013255986, -0.008554356,
      -0.015437021, -0.0041596373, -0.016651608, 0.008952688, -0.02907175,
      0.010252167, -0.0021157337, -0.032780815, 0.034739826, 0.019394226,
      0.0010570507, 0.013366997, 0.002484681, 0.018793462, -0.009938724,
      -0.011055362, -0.012609513, -0.010004025, 0.012831533, 0.0018218819,
      0.026368313, -0.023913017, 0.0058672433, -0.01233525, 0.0012537682,
      0.017709475, 0.004907327, -0.010598259, -0.041975114, 0.023534274,
      -0.011048832, -0.031422563, 0.004939977, 0.012165469, 0.030064316,
      -0.009448972, -0.005798678, 0.020230072, 0.0035621382, 0.007032856,
      -0.015228059, 0.010474188, 0.017461333, 0.029594153, -0.002014518,
      -0.0011566338, 0.04761707, -0.010004025, 0.014261613, 0.023573454,
      -0.026655633, 0.0011696939, 0.017944558, 0.006507187, -0.007085096,
      0.047094665, 0.014875437, 0.0119173275, 0.041792274, 0.027034376,
      0.007796871, 0.039049655, -0.011558175, 0.020269252, -0.031579286,
      0.0022773524, -0.003168703, 0.029933715, -0.0048387614, 0.0020928786,
      -0.0057790875, 0.0040192413, 0.008136433, -0.00613824, 0.024997003,
      -0.023312252, 0.008600066, -0.011055362, 0.016481828, 0.007235287,
      0.008456405, -0.014548935, -0.018715102, -0.02132712, 0.009070229,
      0.033329338, 0.04774767, -0.0106505, -0.016298987, -0.033433817,
      -0.00074891426, -0.018414719, 0.02165362, 0.025271265, -0.006758594,
      -0.01088558, -0.0042282026, 0.009586102, 0.022110725, -0.013249456,
      0.02610711, 0.03364278, -0.0038102798, -0.014718716, 0.006797774,
      0.028653827, -0.0048420266, -0.0007374867, 0.011773666, -0.015201939,
      -0.004153107, 0.004793051, 0.000027599634, 0.027582899, -0.025689187,
      0.0139481705, 0.001838207, 0.010101976, 0.032937534, 0.008874328,
      0.014953798, -0.0035947885, -0.015019097, 0.008802498, 0.005266479,
      -0.026982136, -0.0149929775, -0.00924654, 0.0064092367, -0.0139481705,
      0.0010154216, -0.000058413272, 0.038945172, 0.023168592, -0.0042020823,
      0.03513163, 0.0002791593, 0.0070589758, -0.0004840394, -0.061852563,
      -0.058091257, -0.0081494935, 0.037116762, -0.000022026139, -0.021731982,
      -0.025245145, 0.011512465, -0.009573042, 0.0056223664, 0.0020373734,
      0.014222433, -0.006154565, -0.011270854, 0.008724137, -0.017800895,
      0.045214016, -0.0049987477, 0.004897532, -0.013660849, 0.00039894477,
      -0.014966858, 0.017996797, -0.03359054, 0.024892522, 0.0031638057,
      -0.017291553, -0.006863075, 0.01371309, -0.038030967, 0.015711281,
      0.0023034725, -0.0064973924, -0.01223077, -0.001998193, 0.017657235,
      -0.023442853, -0.0068500144, 0.029045628, 0.007992772, 0.0009786901,
      -0.03249349, -0.028732188, 0.012518091, 0.04288932, 0.017604996,
      -0.002522229, -0.011022712, 0.009990965, 0.008632716, -0.0018822848,
      -0.022306625, -0.008704547, -0.0003171152, -0.019381166, 0.023390612,
      -0.008071133, 0.011251263, 0.008142963, 0.014627296, 0.0036176436,
      0.014039592, -0.011812847, -0.03236289, -0.02747842, 0.010010555,
      0.010506839, 0.022123784, -0.009599162, -0.027791861, 0.020060292,
      0.008887388, -0.013210276, -0.018780403, 0.010546018, 0.019080784,
      -0.0103370575, -0.024774982, -0.024918642, 0.02917623, -0.020621873,
      0.022528647, -0.0065300427, 0.02768738, 0.02455296, 0.025258204,
      0.021980124, 0.015606802, 0.018323299, -0.017043412, -0.001294581,
      0.022215204, -0.001192549, -0.009919135, -0.009416321, -0.0043849237,
      0.005044458, -0.028653827, -0.012198119, -0.0031083003, -0.0013411075,
      -0.018937124, -0.019146085, -0.008573946, -0.02165362, 0.00026365047,
      0.0076727998, -0.016142266, -0.013517188, -0.027844101, -0.009533862,
      0.020386793, -0.0044926694, -0.011192493, 0.04427369, -0.01992969,
      -0.039023533, -0.031605408, 0.027582899, 0.03377338, 0.022398045,
      0.0027230277, 0.0075552594, 0.014653415, 0.017409094, -0.028183663,
      0.012008748, -0.002378568, -0.0107027395, 0.00768586, -0.005227299,
      -0.040851947, 0.014588115, 0.03254573, 0.011238203, -0.0029042363,
      0.004430634, -0.004446959, -0.015032158, -0.01076151, -0.006856545,
      0.048792478, -0.008097253, 0.003673149, 0.033956222, 0.019263625,
      -0.014026531, -0.005243624, 0.01386981, 0.031370323, -0.00012345862,
      0.0056550168, 0.0006407604, 0.008776377, -0.019394226, 0.00044118598,
      0.013464948, -0.008933098, -0.014862377, 0.009866894, -0.0067063537,
      0.026289951, 0.0035817283, 0.0055635963, -0.0073985383, -0.029463552,
      0.00767933, -0.011329624, -0.032806933, 0.003545813, 0.0075095487,
      -0.022345806, 0.0024536634, -0.013033965, 0.0038657852, 0.018806523,
      -0.0077185105, -0.014509754, -0.015463141, -0.0007889108, 0.0146403555,
      0.018832643, -0.0016300619, -0.0043979837, -0.022593947, -0.005681137,
      -0.009697113, -0.017644174, -0.010944352, -0.03079568, -0.007411598,
      -0.007992772, 0.023403673, 0.026642574, 0.0005517886, 0.017539695,
      -0.028235903, -0.026538093, 0.009488151, -0.0066443183, 0.008619656,
      -0.0010594995, 0.015946364, 0.014339973, 0.01381757, 0.003709064,
      0.0026838474, -0.019472586, 0.007868702, -0.013269046, -0.015502321,
      -0.0013117223, -0.023847716, 0.0118324375, 0.00048608004, 0.0027817981,
      0.037299603, 0.014104892, -0.010199927, 0.013895931, 0.0014570158,
      -0.024030557, -0.03983326, -0.030090436, 0.0024961086, -0.029881475,
      0.020164771, -0.014235493, -0.02619853, -0.0029972894, -0.011212083,
      -0.02177116, -0.006856545, 0.003005452, 0.022189084, -0.027974702,
      -0.012811944, -0.011721427, 0.01844084, -0.016298987, -0.019459527,
      -0.0048093763, -0.005775823, 0.045292374, -0.00768586, 0.009945255,
      0.030168796, -0.0042412626, -0.01675609, 0.014052652, -0.011721427,
      -0.0046755103, -0.001160715, 0.007378948, -0.0018986099, -0.0052632145,
      -0.048191715, -0.008554356, -0.015985545, -0.014222433, -0.016951991,
      0.012648692, -0.00920083, -0.020700235, -0.021026736, 0.021235699,
      0.026355252, -0.009945255, 0.028653827, 0.02907175, 0.0028634237,
      -0.036594357, -0.025153724, -0.0005330147, -0.01680833, 0.008188673,
      0.02766126, -0.004153107, -0.040956426, 0.013922051, 0.020987557,
      -0.012054458, -0.019733788, 0.015737403, 0.0141179515, 0.009573042,
      -0.0023508153, 0.019511767, -0.014535875, 0.016664669, -0.00026875208,
      -0.004766931, -0.026577273, -0.011061892, 0.014823196, 0.019106904,
      -0.026120171, 0.018924063, -0.016403466, -0.020556575, -0.014509754,
      -0.04315052, 0.0009893015, 0.012224239, -0.01837554, 0.008293154,
      0.013373527, -0.009906075, 0.006298226, -0.005805208, -0.0025809994,
      0.012818473, -0.010624379, 0.02167974, 0.0018022917, -0.008795967,
      -0.005361165, 0.011368804, 0.004851822, 0.017474394, 0.0139351105,
      -0.0126225725, 0.008077662, -0.01228954, 0.011133723, 0.001147655,
      -0.0015280299, -0.026642574, -0.009442441, -0.029541912, 0.014522814,
      0.00920083, 0.009755883, 0.013399648, -0.018558381, -0.0054166703,
      -0.002755678, 0.02148384, 0.022646187, 0.028888907, -0.002525494,
      0.0042412626, -0.022424165, -0.0048224363, 0.03520999, 0.0069479654,
      -0.011355744, 0.0059717237, -0.008645777, -0.031239724, 0.025480226,
      0.01068315, -0.018179638, -0.016377347, 0.016168386, 0.022437226,
      0.008769847, 0.0075683193, 0.022202145, 0.00382987, -0.014666475,
      -0.012694403, -0.0057627624, -0.017396033, -0.031944968, -0.02293351,
      -0.010441538, -0.010617849, -0.0071765166, -0.0067259436, -0.014561995,
      0.015854944, -0.041008666, -0.017761715, -0.0012284643, 0.0127923535,
      -0.008887388, -0.01837554, 0.013686969, -0.0034903078, -0.00765321,
      -0.0013451887, -0.0017663765, -0.018558381, -0.0014602807, 0.003980061,
      0.0060729394, 0.014013471, -0.007822991, -0.014418334, 0.003158908,
      -0.026289951, 0.0021353238, 0.003545813, -0.018767342, 0.025571646,
      0.016116144, -0.04335948, -0.03554955, -0.027791861, -0.012439731,
      0.025323505, -0.02915011, 0.028209783, 0.024383178, 0.012779294,
      -0.024957823, 0.013491068, 0.023978315, 0.02598957, -0.00028079184,
      -0.008515175, 0.008071133, -0.038135447, 0.0037025341, -0.011825907,
      -0.03398234, -0.0026871124, 0.01372615, -0.01530642, 0.009070229,
      0.025401866, -0.010121566, -0.0099126045, -0.014509754, 0.009429381,
      0.0039180256, 0.0030446323, -0.026407491, -0.023390612, 0.02135324,
      0.01074192, 0.031448685, -0.03249349, 0.007920941, 0.031187484,
      -0.006500657, 0.000802379, -0.010421948, -0.0031866608, 0.021849522,
      -0.031657647, 0.0013100898, 0.011355744, -0.0077576907, 0.036228675,
      -0.006272106, -0.001109291, -0.01863674, 0.017265432, -0.015476201,
      -0.011427575, 0.0123679, -0.021157337, 0.023246951, 0.009736293,
      -0.005984784, -0.034870427, 0.004946507, -0.0015068073, -0.008946158,
      -0.017356854, -0.0008709444, -0.0024748861, -0.03544507, 0.000920736,
      -0.003235636, 0.011179433, -0.014731776, -0.028758308, 0.0017500514,
      -0.011218613, 0.02150996, 0.014940738, 0.016063904, -0.036333155,
      0.0036960042, 0.0025499817, 0.01533254, 0.009024519, 0.23612633,
      -0.020269252, -0.0040682163, 0.020334553, -0.009488151, -0.0037972198,
      0.017931497, -0.0023393878, -0.0029613744, 0.0025108014, -0.014509754,
      -0.0028781162, 0.0031736006, -0.000039001698, 0.007209167, -0.023743235,
      -0.015763523, -0.006745534, -0.014575055, -0.0013500863, 0.001042358,
      0.008698016, 0.0076009696, 0.01991663, 0.028497105, 0.013478008,
      -0.007235287, -0.0065463674, 0.016664669, -0.0101672765, -0.0056615467,
      -0.025297385, 0.006745534, -0.013223336, -0.012361371, -0.0037351844,
      0.029776994, -0.008698016, 0.024226457, -0.011029242, -0.006291696,
      -0.0022495997, 0.0017859667, 0.00048363127, -0.015175818, -0.0028568937,
      -0.01995581, 0.0020945112, -0.034583107, 0.036280915, -0.033277098,
      0.0018855497, 0.00063096534, -0.0084172245, -0.023808535, -0.016403466,
      -0.0024095858, 0.016011665, -0.031683765, -0.014418334, 0.020230072,
      0.0016023092, 0.0067781843, 0.020151712, -0.016521007, 0.01708259,
      -0.03356442, -0.0067259436, 0.028314264, -0.00930531, 0.019263625,
      -0.016364286, 0.025205964, 0.026616454, -0.05041193, 0.0010790895,
      0.0011256161, 0.033172615, 0.003209516, 0.00764015, 0.007999302,
      -0.00033262404, 0.008750257, -0.0018104543, -0.028444866, -0.016455708,
      0.027347818, -0.015619862, -0.00074728177, -0.021314058, 0.006500657,
      0.01675609, -0.017526634, -0.020974496, 0.00009565492, -0.0063863816,
      0.01384369, 0.04414309, -0.014039592, 0.0064092367, -0.0062231305,
      0.021039797, 0.00082278537, 0.02019089, -0.014627296, -0.015907183,
      -0.012589922, 0.013530248, -0.0038821104, -0.03220617, 0.010115036,
      -0.027817981, -0.00078687014, -0.009298781, -0.014000411, 0.014823196,
      0.003398887, -0.012942544, -0.0011762239, 0.007809931, 0.0339301,
      -0.004763666, 0.0034707177, 0.013961231, 0.011120662, -0.028627707,
      -0.008058072, -0.015645983, -0.00025324323, -0.0462327, 0.023847716,
      -0.017422153, 0.025075363, 0.023886895, -0.016481828, 0.007235287,
      -0.009853834, 0.0009721601, 0.012857654, 0.012962135, -0.013223336,
      -0.0064973924, -0.014431394, -0.015175818, 0.01989051, -0.008626186,
      -0.013660849, 0.017356854, -0.0012349943, -0.008698016, -0.027034376,
      -0.012949075, -0.014470574, 0.009083289, 0.022528647, -0.0028062859,
      -0.02135324, -0.025062304, -0.0119303875, 0.020334553, -0.011865087,
      0.0021026738, 0.043437842, -0.015632922, -0.008293154, -0.008267034,
      -0.16466156, 0.013340876, -0.006419032, -0.026237711, 0.00068075693,
      0.018075157, 0.0037319192, 0.03084792, -0.032806933, -0.00921389,
      0.041191507, -0.018767342, -0.025832849, -0.012426671, -0.02773962,
      -0.002388363, -0.011199023, 0.01381757, 0.023416733, 0.018009858,
      0.025101483, -0.025114544, -0.0006464742, 0.0074050683, 0.006004374,
      -0.015698222, -0.037351843, 0.03693392, -0.006249251, -0.0015272137,
      0.006856545, 0.023273071, 0.038892932, 0.025036182, 0.012511562,
      -0.0068500144, 0.003712329, -0.0029499468, -0.026420552, -0.004476344,
      0.00024895786, 0.023560394, 0.008619656, 0.026747053, 0.0019704404,
      0.01692587, 0.035862993, -0.016442647, -0.0056386916, -0.0073201777,
      -0.010977002, -0.021131217, 0.015894124, 0.0041988175, 0.0040649516,
      0.0063569965, 0.012459321, 0.03534059, 0.0031099326, -0.016194506,
      -0.021758102, -0.027765742, 0.020870015, -0.0080646025, 0.017748656,
      -0.023455912, -0.0029548442, -0.022071544, -0.022058483, 0.031814367,
      0.015528441, -0.027530659, 0.022437226, -0.025532465, 0.0008570681,
      0.03071732, -0.032571852, -0.0031376854, 0.0032372687, 0.0071308063,
      -0.007516079, 0.012119759, -0.021235699, -0.0033401167, -0.010585199,
      0.016298987, 0.02314247, 0.0032046183, -0.026707875, 0.018022917,
      0.02002111, -0.028575467, 0.0074442485, -0.011388394, 0.025610827,
      0.009520802, 0.019668488, 0.031892728, 0.0020439033, 0.0039245556,
      0.009468562, -0.0129751945, -0.02021701, 0.020726355, -0.005034663,
      0.0035654032, 0.0016586308, 0.008103783, 0.01076151, -0.009690583,
      0.019198325, 0.007522609, 0.023952195, 0.02760902, -0.007855641,
      0.011688776, -0.006007639, -0.019446466, -0.0065561626, -0.022384986,
      0.0431244, -0.026747053, 0.0018006592, -0.0020030905, -0.018780403,
      -0.005103228, -0.079405315, -0.0086849565, 0.022228265, -0.010369708,
      0.015476201, 0.014144072, 0.004460019, -0.005230564, -0.03249349,
      0.007986242, -0.009403261, -0.027217217, -0.0014774221, 0.0021206313,
      -0.031161362, 0.01072233, -0.015619862, -0.003709064, -0.025049243,
      0.02750454, 0.011349214, -0.008214793, -0.011016182, 0.008423755,
      -0.013151506, 0.003712329, -0.017565815, 0.032049447, 0.032911416,
      0.011427575, -0.011545115, -0.017187072, 0.0031083003, -0.031631526,
      -0.021065917, 0.00013325368, -0.03523611, -0.025088424, 0.009553452,
      -0.02002111, 0.017356854, -0.0005611755, 0.03231065, -0.024004437,
      -0.017918436, -0.0046657156, 0.004038831, 0.014274673, -0.008136433,
      -0.005348105, -0.04618046, -0.026694814, -0.0071177464, -0.0052599492,
      0.022215204, 0.002138589, -0.012753173, 0.00909635, -0.0183886,
      -0.0019345251, -0.013608608, -0.026681753, -0.028235903, 0.031370323,
      0.025441045, 0.022345806, -0.009670993, -0.014744836, 0.027400058,
      -0.004456754, 0.020282311, -0.01072233, -0.0058900984, 0.013255986,
      -0.038866814, -0.018858762, -0.025454106, -0.008084193, 0.0012366268,
      -0.009690583, 0.01083987, -0.0006595343, -0.008782907, -0.0063994415,
      -0.013778389, 0.020765536, 0.004453489, 0.005560331, 0.005658282,
      0.018597562, -0.017761715, 0.008946158, 0.011264323, 0.0004085358,
      -0.014196312, -0.0062590456, -0.008789437, -0.023168592, 0.0089396285,
      -0.016116144, -0.0024275433, -0.017970677, -0.071255825, 0.014692596,
      -0.017644174, 0.002928724, -0.023795474, 0.0010701107, 0.024879461,
      -0.018780403, -0.010376237, 0.0152803, 0.0060925297, 0.014797077,
      -0.011721427, 0.010859461, -0.025832849, 0.0032552262, 0.021144278,
      0.0019198325, 0.03513163, 0.013242926, -0.009044109, 0.013321287,
      -0.020856956, -0.032624092, -0.03244125, 0.02004723, -0.0043424782,
      0.025362685, -0.021927882, -0.018114338, 0.021000616, -0.01989051,
      -0.007999302, 0.020621873, -0.0049530375, -0.011910798, -0.010637439,
      0.016586307, 0.030012075, 0.015672103, -0.018767342, -0.035575673,
      0.015828824, -0.010670089, 0.002909134, 0.010526429, -0.012341781,
      -0.018140458, 0.02293351, -0.009710173, 0.027164977, 0.017526634,
      0.005700727, -0.014979918, -0.016468767, -0.023273071, 0.009050639,
      -0.020386793, 0.031004641, -0.052475423, 0.022450287, 0.005677872,
      -0.006435357, -0.0105198985, -0.0071699866, -0.010030146, -0.021092037,
      -0.0031605405, 0.017343793, -0.0074050683, -0.010598259, 0.0073397676,
      0.008045012, 0.018728161, 0.0056060413, -0.006641053, 0.016325107,
      0.009396731, -0.023782415, 0.0139481705, 0.00013151913, -0.026929896,
      -0.043411724, 0.0045742947, 0.036176436, 0.0096448725, 0.008051543,
      -0.0055995113, -0.007307118, 0.009031049, -0.007085096, 0.028575467,
      -0.004793051, -0.005821533, -0.011780197, -0.0051424084, 0.007548729,
      -0.019537887, 0.016534068, 0.037795886, 0.014170192, 0.005534211,
      0.011199023, -0.025858968, -0.027765742, 0.014718716, -0.019067723,
      -0.04456101, 0.0055929814, 0.018205758, 0.035105508, 0.018558381,
      0.00769892, 0.008129903, -0.012700933, 0.005815003, 0.010970471,
      0.009801594, -0.021888703, 0.011003122, 0.025532465, 0.007039386,
      0.015254179, -0.013974291, 0.014888497, -0.0031017703, 0.028418746,
      -0.00004318501, 0.01380451, 0.010820281, 0.010715799, -0.013105796,
      -0.01067662, -0.008384574, -0.0092922505, 0.020883076, -0.0049530375,
      0.010062795, -0.022358866, 0.08139045, 0.016586307, -0.0062133353,
      0.01080069, 0.02004723, -0.015554561, 0.016455708, 0.013399648,
      0.011630006, 0.006321081, 0.010382768, -0.01539784, -0.009873425,
      -0.022463346, 0.0013615139, 0.018258, -0.020504333, -0.0015574151,
      -0.015228059, -0.014339973, 0.013595548, -0.0066639083, -0.021888703,
      -0.019224444, 0.007026326, 0.005886833, 0.0185192, -0.005096698,
      -0.0074573085, -0.017996797, 0.0067063537, 0.009279191, -0.0018349419,
      -0.00922042, -0.01537172, -0.014248553, 0.009076759, -0.025845908,
      0.0026365046, -0.0016537333, -0.020569634, 0.016285926, -0.023873836,
      -0.024840282, 0.012054458, -0.037665285, 0.004430634, -0.011146783,
      -0.0060468195,
    ],
  },
  {
    id: "faq-14",
    question:
      "Τι διαφορά έχουν τα έξοδα μεταφοράς/αποστολής από τα έξοδα αντικαταβολής ;",
    answer:
      "Τα έξοδα μεταφοράς έχουν να κάνουν μόνο με την αποστολή του δέματος ενώ τα έξοδα αντικαταβολής έχουν να κάνουν με την διαδικασία είσπραξης των χρημάτων (είναι η αμοιβή που παίρνει η εταιρεία ταχυμεταφορών για την εκτέλεση/διεκπεραίωση της συναλλαγής). Είναι δύο διαφορετικές χρεώσεις. Σε περίπτωση που δεν θέλετε να χρεωθείτε με έξοδα αντικαταβολής επιλέξτε κάποιον άλλον τρόπο πληρωμής που δεν έχει καθόλου χρέωση (πιστωτική/χρεωστική κάρτα, κατάθεση σε λογαριασμό του φαρμακείου). Δείτε όλους τους τρόπους πληρωμής ΕΔΩ.",
    embedding: [
      0.011369786, -0.0018644552, 0.0077086748, -0.019661527, -0.024827763,
      -0.01038671, -0.017139427, 0.008623953, -0.020664943, 0.0030933006,
      0.008820568, 0.010285012, -0.016542802, -0.0036373825, 0.016054654,
      -0.0004288247, 0.015308872, 0.028502434, 0.009803644, -0.017668255,
      -0.026929513, 0.01625805, -0.016746197, -0.012752873, 0.015593625,
      0.035878897, 0.014630889, -0.018929305, -0.0009237528, -0.030129595,
      0.0059662564, -0.004237398, -0.020163234, -0.0067594973, -0.018224202,
      -0.037370462, 0.0043390957, -0.015607185, 0.044204537, 0.01787165,
      0.014874962, 0.012739314, -0.0045899497, -0.00020699689, 0.006983232,
      0.027092228, -0.021722598, -0.025654903, -0.007566298, 0.01704451,
      0.013871547, 0.025993895, -0.02956009, -0.009607029, 0.009620588,
      0.004400114, 0.00831886, -0.014183419, -0.0029746534, -0.015430909,
      -0.005945917, 0.002417012, -0.024204018, -0.001830556, -0.005081488,
      0.0138105275, 0.017451301, -0.0023526035, -0.0042204484, -0.0147664845,
      0.012142688, 0.025559986, -0.02414978, -0.017641136, 0.020854779,
      -0.021424284, 0.017654695, -0.007247646, -0.015905498, 0.02127513,
      0.023254842, -0.024393853, -0.006901874, 0.009593469, 0.0152546335,
      0.026373565, 0.01830556, 0.016054654, -0.011017235, -0.010325692,
      0.006078124, 0.018929305, 0.038048446, 0.024217578, 0.02244126,
      0.007742574, -0.019336095, 0.024637928, -0.005447599, -0.013966464,
      0.003488226, -0.013668152, -0.012163027, -0.0083053, -0.003620433,
      -0.005939137, 0.0107731605, 0.010251113, -0.0062171104, -0.03118725,
      -0.013451196, 0.042631615, -0.006491694, -0.040977336, 0.0035594143,
      0.030699102, 0.020190354, -0.010922317, -0.024909122, -0.012102009,
      0.010746041, 0.003240762, 0.013600353, -0.022644656, 0.02871939,
      -0.010176536, -0.005952697, -0.0076273168, -0.017342823, -0.010664684,
      -0.000073095114, -0.006261179, 0.016041094, 0.022088708, -0.021220889,
      0.015349551, -0.021031054, 0.029532969, -0.02958721, 0.0053831907,
      0.014915641, -0.0037085707, -0.00019311941, -0.004874703, -0.011742678,
      0.0062543997, 0.012800332, 0.023417557, 0.013763069, 0.020447988,
      0.0054204795, 0.0028068526, -0.0075595183, -0.010746041, -0.0037187405,
      0.01914626, -0.0060916836, -0.0024882, -0.005444209, -0.025709141,
      0.00207463, 0.011959633, 0.02162768, -0.02409554, -0.006695089,
      0.020854779, -0.017681815, 0.014196979, 0.020339511, -0.018698791,
      -0.017668255, -0.0013280006, -0.019810684, 0.025993895, 0.01620381,
      0.033058487, 0.027675295, -0.008793448, -0.015471588, -0.01586482,
      -0.04325536, -0.009810424, 0.025926096, 0.008956165, -0.02161412,
      -0.0082307225, -0.0014669872, 0.01203421, 0.024678607, -0.003827218,
      -0.0077968123, 0.0057187923, -0.0025678633, -0.008786669, -0.63741314,
      -0.012346083, 0.010725702, -0.023634512, 0.011288429, 0.03441445,
      0.026332887, 0.018902186, -0.03463141, 0.015105477, 0.012969828,
      0.015688542, 0.013003727, 0.0035458547, -0.010495188, -0.017966568,
      0.011532502, 0.0077154543, 0.01785809, 0.02126157, -0.012230826,
      0.018549634, -0.0051764054, 0.029885521, 0.014400374, -0.011695219,
      0.013613912, -0.0012271504, -0.019092022, 0.022698894, -0.019024223,
      0.0038984062, -0.0026373565, 0.00025212517, 0.049764004, 0.0073086643,
      -0.004654358, 0.034875482, 0.030509267, 0.0283126, -0.014400374,
      -0.013939345, 0.019756446, -0.013580013, 0.013783408, -0.0054679387,
      0.02823124, -0.005369631, 0.041899394, -0.035092436, -0.0056272647,
      0.032977127, -0.020936137, 0.0048780926, 0.022387022, 0.0040848516,
      0.032163545, -0.017207226, -0.00540353, -0.009383295, 0.00019513219,
      -0.0029068552, -0.016583482, -0.021098852, -0.0038984062, -0.018983543,
      0.003539075, 0.012434221, 0.019051341, -0.0076679955, 0.017085189,
      0.0049289414, -0.017369943, 0.024637928, -0.0039831544, -0.01081384,
      0.011674879, -0.02454301, 0.010034159, 0.013668152, -0.020583585,
      -0.0016195335, -0.033329677, 0.016881794, 0.038075566, -0.011776577,
      -0.01711231, 0.0021102242, 0.0057899808, 0.019417454, 0.016881794,
      0.032733053, 0.01663772, -0.01953949, -0.01122741, 0.0340619,
      -0.017844532, 0.009681608, -0.008712091, 0.010427389, -0.004210279,
      0.014441052, -0.005942527, -0.014495292, 0.0063560973, 0.0069696726,
      -0.036014494, 0.021370046, 0.036041614, -0.02454301, -0.009701947,
      -0.0026593911, -0.0028102424, -0.0049289414, -0.011186731, -0.03205507,
      0.022481939, -0.004457743, -0.019295417, -0.021831075, 0.0056747235,
      0.016081773, -0.0010161281, 0.002328874, 0.00873243, 0.036421284,
      0.00069239084, -0.034251735, -0.008962944, -0.004047563, -0.025180314,
      0.01663772, 0.0073357834, -0.010474849, 0.007939189, -0.016515683,
      -0.0043831645, -0.01411562, 0.00832564, -0.019864922, -0.009607029,
      -0.005993376, 0.023498915, -0.039295934, 0.00290855, -0.028665151,
      0.008040886, 0.0060849036, -0.021993792, 0.041085813, 0.0038814566,
      0.0053120023, -0.021329368, -0.008908706, -0.012827451, -0.010576545,
      -0.01291559, -0.01621737, 0.0076747756, 0.0029407542, -0.007979868,
      0.023919266, -0.010868079, 0.02042087, -0.047594454, -0.012922369,
      0.024909122, 0.00249159, -0.0038780668, -0.029288895, 0.009491771,
      -0.011505383, -0.00291194, 0.0010220604, 0.021044614, -0.0011034184,
      0.0013220682, 0.0065832217, -0.007688335, -0.011871494, 0.010339252,
      0.0022373463, -0.019078461, 0.0015008864, 0.021898873, 0.023878586,
      0.015946176, 0.0027695633, -0.026468484, 0.04523507, -0.00006435548,
      -0.020285273, 0.011329108, 0.0052374243, -0.0077154543, -0.009512112,
      0.025071837, 0.024895562, 0.0022254814, 0.010149416, 0.048028365,
      -0.0091866795, 0.030834699, -0.00997314, 0.0316754, -0.0029034652,
      0.015037678, -0.002378028, 0.04819108, 0.022278544, 0.013851207,
      -0.011627421, 0.0049594506, -0.00748494, -0.031973712, 0.016868234,
      -0.037614536, 0.016380087, -0.0027288843, -0.010630785, -0.023159923,
      0.015973296, 0.018427597, -0.0065018637, -0.03202795, -0.016434325,
      0.021410726, 0.0071798475, 0.0019288636, 0.0008334962, -0.008583274,
      -0.00023771804, -0.02077342, 0.022522619, 0.05101149, -0.004735716,
      0.01876659, 0.009891782, 0.029858403, -0.012454561, 0.010027379,
      0.03731622, 0.0133427195, 0.006990012, -0.002871261, 0.0060882936,
      0.031458445, 0.023037886, 0.009247698, 0.0067222086, -0.008474796,
      -0.010861299, -0.011132493, -0.0036984012, 0.017559778, -0.026915953,
      0.0126986345, 0.010732482, 0.009390074, 0.0015652948, 0.014020703,
      -0.019390333, 0.010854519, 0.002952619, -0.0011907088, 0.043092642,
      -0.021939553, -0.005952697, 0.004647578, 0.0040611224, -0.009457872,
      -0.011308768, 0.005322172, 0.012868131, 0.01414274, 0.01995984,
      0.023987064, 0.004295027, 0.030455027, 0.013186783, -0.035851777,
      -0.055187874, 0.013742729, 0.016936032, -0.008773109, -0.04412318,
      -0.024190458, 0.032190666, -0.029993998, 0.0398112, -0.004542491,
      -0.010162976, -0.010135856, -0.006617121, 0.008176483, -0.009315496,
      0.03829252, 0.0062272805, 0.003327205, -0.014373254, -0.0033882235,
      -0.011607081, 0.0032068628, -0.015241073, 0.05068606, 0.004240788,
      -0.0012957964, -0.016000416, 0.0017932669, -0.04038071, 0.0014720721,
      0.0018373359, -0.030319432, -0.0030221124, 0.018292, 0.0031407594,
      -0.005579806, -0.011701998, 0.027282065, 0.0006279824, -0.034251735,
      -0.012142688, -0.0030119426, -0.0052306443, 0.020122556, 0.023810787,
      0.011749458, 0.0055526863, -0.011824036, -0.0069561126, -0.002247516,
      -0.005942527, -0.0012618972, 0.00011292666, -0.018441157, 0.0011779966,
      0.03037367, 0.001305966, -0.0052645435, 0.023159923, 0.0011788441,
      -0.0022509058, 0.023919266, -0.026387125, 0.0011517248, 0.00020392478,
      0.010590105, 0.018427597, -0.013512215, -0.005779811, 0.0067154285,
      -0.0004978943, 0.017139427, -0.0048543634, 0.00011536316, -0.016380087,
      0.0058950684, 0.017817412, -0.026061693, 0.0023831127, -0.014264777,
      0.0044272337, 0.0044306233, 0.015891938, 0.013457976, 0.022726014,
      0.012766433, 0.002284805, 0.015593625, -0.007647656, 0.01121385,
      0.011430806, -0.00058603217, -0.008556155, 0.0046679177, -0.02493624,
      -0.016502123, 0.0037526398, 0.019295417, 0.001791572, -0.013444417,
      0.014820724, -0.006067954, -0.007986648, -0.009864663, -0.018875066,
      0.024841323, -0.007186627, 0.00034619542, -0.04404182, -0.0010686718,
      0.009939241, -0.0055628563, -0.009823984, 0.020190354, -0.025627784,
      -0.020678503, -0.010088397, 0.03463141, 0.02947873, 0.020258153,
      -0.0071323887, 0.0020695452, 0.02040731, -0.018549634, -0.00051272515,
      0.0025915927, -0.024041303, -0.015580066, 0.023146363, -0.018969985,
      -0.008203602, 0.0013186783, 0.028068526, -0.01371561, 0.0012059635,
      -0.017817412, -0.017708935, -0.014237657, -0.002420402, 0.0046068993,
      0.039567128, 0.0041695996, -0.009301936, 0.00874599, 0.0022305665,
      -0.018942865, -0.013078306, -0.015891938, 0.019281857, 0.019810684,
      -0.0052645435, 0.0031424544, -0.008108685, -0.0030627914, -0.022753133,
      0.0006173889, -0.016013974, -0.006013715, 0.027336303, 0.0013076611,
      0.014698687, 0.013600353, 0.017614016, -0.008949385, -0.029777044,
      0.0106240045, 0.003942475, -0.03617721, 0.02583118, -0.008474796,
      -0.00791885, 0.014942761, -0.0033170353, 0.004183159, 0.034333095,
      0.003362799, -0.029451612, -0.02080054, 0.016868234, -0.006325588,
      0.0191327, -0.0013830868, 0.017275024, -0.0046102894, 0.0002197091,
      -0.016352966, -0.018088605, -0.0072069666, -0.037397582, -0.013993584,
      0.013688491, 0.012834231, 0.015335991, 0.015430909, 0.009308716,
      -0.02958721, -0.013661372, -0.008115465, -0.024258256, -0.0049086018,
      -0.028936345, 0.0037695894, 0.03791285, 0.017315704, -0.00787139,
      0.020136116, -0.0064713545, -0.003376359, -0.022115828, 0.0036339925,
      -0.0050950474, -0.011810476, 0.036475524, 0.03365511, 0.01624449,
      0.006830686, 0.027960047, 0.013444417, 0.025559986, -0.020217475,
      -0.028366838, -0.03205507, -0.028963463, 0.023607392, -0.0013974939,
      0.04618425, 0.0074646007, -0.023593832, -0.0029339744, 0.0070374706,
      -0.02543795, -0.008013767, 0.015458029, 0.016732637, -0.010698583,
      0.0128545705, 0.0022136169, -0.005169626, 0.008529035, 0.0020170014,
      -0.01873947, -0.013668152, 0.03577042, -0.016515683, 0.020068318,
      0.002749224, 0.0008686666, -0.008847687, -0.015037678, -0.022983648,
      0.006739158, -0.023390438, -0.018088605, -0.017722493, -0.025166756,
      0.001226303, 0.00034280552, -0.0049628406, 0.010515527, -0.0060001556,
      0.0020254762, -0.020434428, -0.023607392, 0.0049628406, -0.0048441933,
      0.026848154, -0.0038984062, 0.01574278, 0.025071837, -0.006817126,
      -0.019322535, -0.07528331, 0.002539049, -0.00016398731, 0.013783408,
      0.024692167, 0.013851207, -0.016922474, 0.0049628406, 0.011173171,
      0.013627472, -0.012956268, 0.02405486, -0.00999348, 0.018020807,
      0.008590054, 0.0072544254, 0.0050984374, 0.023824347, -0.009905342,
      0.015430909, -0.0050136894, -0.016176691, 0.0032983907, 0.03452293,
      0.0077154543, 0.036095854, -0.0032119479, -0.02455657, -0.0068781446,
      -0.03403478, 0.0028593962, 0.025397269, -0.0034611067, 0.0050170794,
      -0.006393386, 0.0095053315, 0.018685231, -0.012644396, -0.023742989,
      -0.019810684, -0.004871313, 0.025193874, 0.0022678555, -0.0029068552,
      0.0046306285, 0.019241177, 0.008983284, 0.014061382, 0.0065967813,
      0.00048518207, -0.017410621, -0.012095229, 0.0086985305, 0.022563297,
      -0.03690943, -0.010901978, 0.0013898666, 0.011695219, -0.0022356512,
      -0.019471692, 0.03365511, -0.002245821, -0.02618373, 0.005962867,
      -0.001498344, 0.026536282, 0.016542802, 0.014020703, -0.008644292,
      0.022332784, -0.018414037, -0.01582414, 0.009118881, 0.0024831153,
      -0.00499674, 0.029288895, -0.03492972, -0.038455237, 0.0372891,
      0.018576752, -0.017424181, -0.0053730207, -0.0002175904, 0.01585126,
      0.009295156, 0.00913244, -0.0016085163, 0.008684971, 0.0043628253,
      -0.0085425945, -0.020841219, -0.009735846, -0.0150919175, -0.010725702,
      -0.008284961, -0.012285065, -0.0051357266, 0.010447728, -0.025858298,
      -0.005061148, -0.022278544, -0.014725806, 0.0073764627, 0.02457013,
      -0.029370254, -0.021139532, 0.02162768, -0.037804373, -0.012820671,
      0.0046848673, 0.0026780355, -0.02703799, -0.0063866065, 0.0027576988,
      0.023987064, 0.042496018, -0.018888626, -0.013430857, 0.011457925,
      0.000758918, -0.013830868, -0.008813788, -0.015973296, 0.03750606,
      0.0014356305, -0.016434325, 0.002367858, -0.00022140406, -0.018807268,
      0.031051653, 0.013363059, 0.009078202, 0.017925888, 0.013851207,
      -0.007240866, 0.013437637, 0.022129389, 0.009729066, -0.01662416,
      0.010562986, -0.008061226, 0.004874703, -0.026834594, -0.01825132,
      -0.05372343, 0.0015491927, 0.046292726, -0.027349863, 0.022970088,
      0.044258777, -0.018861506, -0.009240918, -0.010881638, -0.009668048,
      -0.00035594145, 0.0048001246, -0.0023051447, -0.032217786, 0.0073154443,
      0.007966309, 0.024773525, -0.023770109, 0.02078698, 0.028149882,
      0.004406894, 0.015634304, -0.009946021, -0.02823124, 0.016420765,
      -0.01121385, -0.0008695141, 0.040923096, -0.0055153975, 0.0041458705,
      0.011898614, -0.0125426985, -0.00582388, -0.0052204747, 0.013749509,
      -0.005145896, 0.000008044435, 0.0004758598, 0.0104612885, -0.023403997,
      -0.0067222086, -0.009491771, 0.0009932461, -0.020597145, 0.004620459,
      -0.025248112, -0.0048814826, 0.008000208, -0.032190666, 0.0066272905,
      0.023756549, 0.004406894, -0.023892146, -0.025180314, -0.0074781603,
      -0.003583144, 0.029451612, 0.003610263, -0.008630732, -0.02206159,
      0.008590054, -0.03365511, -0.016352966, -0.0067086485, 0.2144598,
      -0.023336198, 0.011139272, 0.031133011, 0.0073425635, -0.0028916004,
      0.0005067928, -0.0016788571, 0.00022161592, 0.018427597, 0.028529555,
      -0.0079052895, -0.008162924, 0.0011110458, -0.013166443, -0.0157699,
      -0.02454301, -0.0283126, 0.00080764806, 0.010732482, -0.0068476354,
      0.00096782175, 0.0036136531, -0.008291741, 0.018400477, 0.023336198,
      -0.008949385, -0.015620745, 0.018997103, -0.0015449553, -0.031160131,
      -0.031865235, -0.00084154727, 0.024271816, -0.011491824, 0.00624423,
      0.0030814358, -0.00623406, 0.021437844, -0.0004915382, 0.003956035,
      0.0036984012, -0.01785809, 0.010596885, -0.022305664, -0.016393647,
      -0.015213954, -0.006861195, -0.009295156, -0.009274817, -0.016922474,
      0.0062577897, 0.005281493, 0.013518995, 0.003705181, -0.013030847,
      0.00415265, 0.008027327, 0.003915356, 0.015105477, -0.002832277,
      0.012264725, -0.003949255, 0.04005528, -0.010278232, 0.026251528,
      -0.0241769, -0.025031159, 0.009735846, -0.014820724, 0.019186938,
      -0.005617095, 0.024461651, -0.0083798785, -0.0067493278, -0.009729066,
      0.013661372, 0.024854882, 0.012291844, 0.008332419, -0.0053933603,
      0.014319016, -0.022603976, 0.0011390126, -0.015010559, -0.0066883094,
      0.036421284, 0.012746094, -0.013824088, -0.026685439, -0.0032746613,
      -0.024488771, -0.03582466, 0.0045221513, 0.01204777, -0.003286526,
      0.019173378, 0.023878586, -0.01412918, 0.0027407492, -0.0106104445,
      0.08694463, -0.009207019, 0.0008915486, -0.013898666, -0.0033831387,
      0.005610315, 0.019376773, 0.034848362, -0.01787165, 0.017586896,
      -0.02907194, -0.00031229624, -0.022603976, -0.008922266, -0.00748494,
      -0.01456309, -0.025478628, 0.010956217, 0.011830815, 0.013037627,
      -0.01040705, -0.008034107, 0.0014932591, 0.009518892, -0.019837802,
      -0.026237968, 0.0035661943, 0.022224305, -0.025858298, 0.0056442143,
      -0.04295705, 0.025953216, 0.0008262926, -0.023824347, -0.013037627,
      0.011824036, -0.03075334, -0.00091358303, -0.008196823, -0.009471432,
      -0.0011246054, 0.003074656, -0.0112613095, -0.0016975016, -0.014956321,
      0.004484862, 0.0071730674, -0.015715662, -0.016339406, -0.02290229,
      -0.008868027, -0.007762913, -0.013756289, 0.0045933397, 0.010346031,
      -0.021356486, -0.036421284, 0.03075334, 0.008081566, -0.040245112,
      -0.013261361, 0.00913244, -0.018373359, -0.016813995, -0.0010347726,
      -0.17388926, 0.011159612, 0.017139427, -0.013912225, 0.022997208,
      0.004281467, 0.019254737, 0.017953008, -0.021573441, -0.0012966439,
      0.042116348, 0.0052950527, -0.024353174, -0.0065120333, -0.04406894,
      -0.01910558, -0.031078773, 0.017722493, 0.012807112, 0.0040068836,
      0.038753547, -0.036665358, 0.0028051576, -0.022319224, 0.0096544875,
      0.02782445, -0.023756549, 0.035607703, 0.0048577534, -0.010718922,
      0.0002224634, 0.021559881, 0.011044354, 0.010095177, -0.008623953,
      0.0022729402, 0.01121385, -0.005159456, -0.0021119192, 0.0056340443,
      0.0050170794, 0.0055120075, -0.010766381, 0.029044822, -0.025587104,
      0.017152987, 0.014386814, -0.00498657, -0.0096612675, -0.004542491,
      -0.015458029, 0.0013118985, 0.00028178698, 0.0074713803, 0.019607289,
      0.009634148, 0.025193874, 0.026075253, -0.011959633, -0.000820784,
      -0.015688542, -0.010922317, 0.0023509085, -0.0060882936, -0.0036984012,
      -0.014020703, 0.0057594716, 0.011105373, -0.011620641, 0.0039255256,
      -0.012176587, -0.0076205367, 0.022373462, -0.011295209, 0.00748494,
      0.028773628, -0.01456309, -0.00498318, 0.0049391114, -0.009525671,
      -0.011118933, 0.029343134, -0.0131800035, 0.012969828, 0.006908654,
      0.025709141, -0.003579754, 0.011864714, -0.029451612, 0.009607029,
      0.0059255776, -0.027309183, 0.00414926, -0.025953216, 0.014725806,
      0.030699102, 0.0012500325, -0.006017105, 0.005996766, 0.00008686666,
      0.007322224, -0.013620692, -0.021844635, 0.031946592, -0.00068772974,
      0.012407102, -0.013085085, 0.0074781603, 0.04035359, -0.010264673,
      0.0034289025, 0.00041357006, 0.008644292, 0.021898873, -0.013661372,
      0.021451404, 0.023946384, -0.015986856, 0.0015474978, -0.018156404,
      0.05695063, -0.042604495, 0.0038577272, 0.016176691, -0.0125359185,
      -0.014630889, -0.08905994, -0.0067120385, -0.0122172665, -0.010786721,
      0.014942761, 0.013112205, -0.00072629005, 0.018888626, 0.00064408453,
      0.006610341, -0.0063391477, -0.014481732, 0.016461445, -0.007030691,
      0.0042645177, 0.0004949281, -0.01412918, -0.00498657, -0.021302247,
      0.020624263, 0.015024119, -0.0054679387, -0.003364494, 0.016868234,
      -0.010339252, 0.00081908907, -0.029207537, 0.00582388, 0.027797332,
      0.0048001246, 0.0077832527, -0.01498344, -0.0056713335, -0.027905809,
      -0.010169756, -0.02202091, -0.042577375, -0.018603873, -0.005569636,
      -0.010481628, 0.010644344, 0.0031322846, 0.013410518, -0.027485458,
      -0.0006928146, -0.03753318, 0.011857935, 0.000061601175, -0.013227462,
      -0.003445852, -0.028366838, -0.031973712, -0.030916058, -0.028529555,
      0.011139272, -0.0038373878, -0.027214266, -0.002447521, -0.026441364,
      -0.0012966439, -0.032597456, 0.01040027, -0.014847843, 0.014685127,
      0.040597662, -0.01122063, -0.002993298, 0.0008762939, 0.0011746067,
      0.0107799405, -0.0050916574, 0.006206941, 0.0019373384, -0.0014195284,
      -0.039540008, -0.0046916474, -0.020990375, -0.0018220812, 0.0032255074,
      0.0071459482, -0.0043085865, -0.022590417, 0.001353425, -0.016936032,
      0.008847687, 0.032326262, -0.008990064, 0.0057052327, 0.008474796,
      -0.01704451, -0.024339614, 0.010651124, 0.015281753, -0.02166836,
      -0.026644759, 0.0035017857, 0.007973088, -0.004044173, 0.010434169,
      -0.0096544875, -0.03943153, -0.011186731, -0.073168, 0.018346239,
      0.0018424207, 0.013112205, -0.02581762, -0.019173378, 0.02833972,
      -0.028854987, -0.0015059713, 0.0055120075, -0.028800748, -0.013803748,
      -0.0142918965, -0.007600197, -0.0024322665, -0.0066883094, 0.0107731605,
      0.01000026, 0.033790708, 0.017614016, -0.0034289025, -0.013451196,
      0.0010949437, 0.018508956, -0.032000832, 0.004488252, -0.015715662,
      0.018007247, -0.010481628, -0.000011606498, 0.0076679955, -0.016488563,
      -0.0059289676, -0.0023627733, -0.01290203, 0.008271401, -0.009851103,
      0.024705727, 0.023187043, 0.038400996, -0.01575634, -0.017329263,
      0.013878326, -0.032597456, -0.016407205, 0.018007247, 0.00995958,
      0.020353071, 0.007240866, 0.005223864, 0.007274765, 0.009118881,
      0.01414274, -0.03246186, 0.0018356409, -0.031051653, -0.004108581,
      -0.03655688, 0.03501108, -0.03902474, 0.02245482, -0.0018169963,
      -0.013003727, -0.020339511, 0.0026339667, -0.034360215, -0.033058487,
      -0.019878482, -0.0067561073, -0.01574278, -0.009830764, 0.013227462,
      0.003827218, 0.011498604, -0.007973088, 0.014590209, -0.0023458237,
      -0.004783175, -0.0039289156, -0.0054204795, -0.0007983258, -0.0022136169,
      -0.044231657, 0.010786721, 0.020909017, 0.010190095, 0.0074713803,
      -0.005603535, -0.0058408296, 0.007362903, -0.016515683, 0.030400788,
      -0.019241177, -0.014102061, 0.0039797644, 0.01536311, 0.010535867,
      -0.013980024, 0.0020424258, 0.029180419, -0.008034107, 0.007362903,
      -0.014210538, -0.01830556, -0.030482147, 0.023729429, -0.023580274,
      -0.051689476, 0.011383346, 0.013641032, 0.001454275, -0.020705622,
      0.011308768, 0.0017237735, -0.014671567, -0.00088561623, 0.02244126,
      -0.017600456, -0.03034655, 0.009722286, 0.023078565, 0.015878377,
      0.0042780773, -0.003620433, 0.010359591, -0.019281857, 0.02543795,
      -0.022563297, 0.0011076558, 0.012318964, 0.01000704, -0.011912174,
      -0.032597456, -0.009457872, 0.0023068395, 0.011803696, 0.010535867,
      0.021085292, -0.008990064, 0.09649064, 0.020692062, -0.0017983518,
      0.011369786, 0.0016390256, 0.0020661554, 0.019010663, 0.012508799,
      0.013952904, -0.009220579, 0.010644344, -0.017830972, -0.0024729455,
      -0.032760173, -0.013464756, 0.011301988, -0.017329263, 0.015525826,
      -0.0125426985, -0.0007419684, 0.0064408453, -0.004237398, -0.010427389,
      -0.007973088, -0.0020017468, 0.02203447, 0.007403582, 0.0007089167,
      0.0073357834, -0.008020547, -0.007688335, 0.015512267, -0.019349655,
      -0.006406946, 0.00089748093, -0.027119348, -0.011885054, -0.01456309,
      -0.0024237917, -0.0043933345, 0.0065188133, 0.03533651, -0.02912618,
      -0.026848154, -0.0028644812, -0.04198075, -0.0010610445, -0.013430857,
      -0.011118933,
    ],
  },
  {
    id: "faq-15",
    question: "Ποιοι είναι οι διαθέσιμοι τρόποι αποστολής παραγγελιών;",
    answer:
      "Οι παραγγελίες προϊόντων με την ένδειξη «Άμεσα Διαθέσιμο» που καταχωρούνται έως τις 17:00 αποστέλλονται σε 1-2 εργάσιμες ημέρες με courier (ACS ή ΕΛΤΑ). Η παράδοση γίνεται συνήθως την επόμενη εργάσιμη, ενώ για δυσπρόσιτες ή νησιωτικές περιοχές ενδέχεται να χρειαστούν 2-3 ημέρες. Για πολύ ογκώδεις παραγγελίες ή δυσπρόσιτες περιοχές, προηγείται επικοινωνία με τον πελάτη για έξοδα και τρόπο αποστολής. Οι παραδόσεις γίνονται εργάσιμες ημέρες 09:00-17:00, χωρίς δυνατότητα καθορισμένης ώρας. Μπορείτε να παρακολουθείτε την παραγγελία σας μέσω ACS (https://www.acscourier.net/el/home) ή ΕΛΤΑ (https://www.elta-courier.gr/search).\n  \n  Εναλλακτικά:\n  - Παραλαβή από το φυσικό κατάστημα στη Χαριλάου Τρικούπη, Θέρμο (για παραγγελίες άνω των 20€).\n  - Παραλαβή από BoxNow: Αυτόματο Μηχάνημα Παραλαβής 24/7, κοντά σας.",
    embedding: [
      0.009707566, -0.00422728, -0.00599376, -0.020913573, -0.027540294,
      0.007453447, -0.017219143, 0.015591526, -0.010928278, -0.005121823,
      0.024892189, 0.030072141, -0.020861901, -0.0075051174, 0.010398657,
      -0.01016614, 0.018278385, 0.02841869, 0.019557225, 0.005250999,
      -0.00023534228, 0.0134213725, -0.004427503, 0.000007713968, 0.0077828458,
      0.024491744, 0.028186172, -0.010566586, 0.011845427, -0.03366323,
      0.025357222, -0.014958565, -0.014583955, -0.015526938, -0.016405335,
      -0.0032584611, 0.022437848, 0.003820376, 0.02327749, 0.016276158,
      0.01915678, 0.0070336256, 0.018123373, -0.005967925, -0.014738966,
      0.025964348, -0.0048150304, -0.0065298397, -0.0099336235, 0.038287725,
      0.0250472, 0.014919812, -0.03151891, -0.013382619, 0.024737177,
      0.014713131, -0.003794541, 0.015514021, -0.016560346, -0.021417357,
      0.010534291, -0.0038752758, -0.01316302, -0.0053285044, 0.008745206,
      0.013524713, -0.0046632485, 0.0068592383, -0.028160337, -0.031648085,
      0.017632505, 0.006239194, -0.026003102, -0.018226715, 0.011018701,
      -0.024530496, 0.020590633, 0.002002226, -0.02205032, 0.011367476,
      0.01631491, -0.020732725, -0.000766578, 0.008286632, 0.01765834,
      0.0030727708, 0.014067251, 0.017412907, -0.028832052, -0.012400882,
      0.01273674, 0.022747869, 0.024659673, 0.015798207, 0.013305114,
      0.009965918, -0.021197759, 0.012181284, -0.0039172582, -0.017089967,
      0.0016138909, 0.012220036, -0.02633896, -0.0008767812, -0.0067106863,
      0.0043273913, 0.0065911985, 0.010657009, 0.017167471, 0.0021184841,
      -0.014493532, 0.044488166, -0.017399989, -0.031028042, 0.0016138909,
      0.015526938, 0.023342079, -0.020099765, -0.021856556, -0.010295317,
      0.010657009, -0.011703333, -0.0018181503, -0.007640752, 0.025111787,
      0.0087775, -0.009016475, -0.009785072, -0.021068582, 0.0070077907,
      0.007931398, 0.0071950955, 0.0064975456, 0.014261015, -0.008170374,
      0.028625371, -0.020164352, 0.021753214, -0.034541626, 0.015514021,
      0.0062844055, 0.010346986, 0.022670364, -0.00005515204, -0.018149208,
      -0.0003404995, 0.0086677, 0.0025512234, 0.00716926, 0.015604444,
      0.0128982095, -0.003269764, -0.0013700714, -0.014583955, 0.008202667,
      0.020732725, -0.014726048, -0.0030324033, -0.0053801746, -0.025085952,
      0.0009922321, -0.0008033124, 0.024130052, -0.011981062, 0.00086628564,
      0.021921143, 0.018562572, 0.031002207, 0.014751883, 0.00289031,
      -0.038081042, 0.0048763887, 0.008964805, 0.013214691, -0.0014919812,
      0.023484172, 0.014855224, -0.008150997, 0.007098214, 0.0059743836,
      -0.03720265, -0.0047020013, -0.008661241, 0.025796419, -0.007182178,
      0.0053543393, -0.0042498857, -0.00024704883, -0.0047924244, -0.017038297,
      0.0064684814, 0.001868206, 0.023187067, -0.0072855186, -0.6402989,
      -0.02162404, -0.0049894177, -0.019544307, 0.009281286, 0.03660844,
      0.010676385, 0.0065362984, -0.031544745, 0.017348317, 0.012885292,
      -0.003313361, -0.0055093504, -0.0038623582, 0.000048188653, -0.021714462,
      0.008848547, 0.016276158, 0.0111285, 0.014299768, -0.008280173,
      0.00017226812, -0.003510354, 0.022812458, 0.019440968, 0.012904668,
      0.00027792994, -0.0114837345, -0.00476336, 0.031828932, -0.02375544,
      0.034877483, 0.012103778, -0.020900654, 0.04469485, 0.0114837345,
      -0.021546533, 0.045624916, 0.010017588, 0.03226813, -0.043583937,
      -0.013615136, 0.034179933, 0.0014532284, -0.0013765303, -0.0061875237,
      0.020396868, -0.00043556487, 0.018162126, -0.0067946506, 0.0054996624,
      0.020693973, -0.014764801, -0.01798128, 0.034386616, 0.022437848,
      0.013653888, -0.028056998, -0.034799978, -0.0032842963, 0.0010325996,
      0.0036589063, -0.018691747, -0.013020927, 0.004295097, 0.0014298153,
      -0.007840975, 0.016340747, 0.01792961, -0.009010016, 0.008176832,
      0.016289076, -0.0072532245, 0.022076154, 0.0068204855, -0.0071563427,
      -0.0066396394, -0.02499553, -0.007298436, 0.0034716013, -0.014751883,
      -0.019311791, -0.0060744947, 0.008092868, 0.01830422, 0.017102884,
      -0.027230272, -0.01915678, 0.010495539, 0.030743856, 0.009003557,
      0.044720683, 0.021210676, -0.0016841303, -0.016612016, 0.017632505,
      -0.006839862, 0.0063651404, 0.0014935959, 0.0061035594, 0.0058258316,
      -0.0015896704, 0.014803554, -0.012904668, 0.006865697, -0.0003362609,
      -0.042834714, 0.009597766, 0.0323198, -0.016030725, 0.01792961,
      -0.010230728, -0.014700213, -0.010153223, 0.012187743, -0.028857887,
      0.020254776, 0.006807568, -0.024414238, 0.0016905891, -0.014364356,
      -0.009171486, 0.019660566, -0.006410352, 0.011742086, 0.022760786,
      -0.014273933, -0.026532723, -0.023858782, 0.0027643635, -0.028832052,
      -0.01787794, 0.0013902552, -0.0039301757, 0.011393311, -0.0081316205,
      0.004201445, -0.019893082, 0.023729606, -0.015191081, -0.016547428,
      0.0018762794, 0.01862716, -0.03844274, 0.0033973253, -0.024892189,
      0.009797989, 0.0036363006, -0.018588407, 0.024181722, 0.0074986587,
      -0.0040109106, -0.0025819025, -0.00035705016, -0.0010075717, -0.005270375,
      -0.027204437, -0.01541068, 0.0056546736, -0.0005397129, 0.017580835,
      0.00085013866, -0.009171486, 0.0015234678, -0.033043187, -0.0136280535,
      0.01423518, -0.016276158, 0.012291083, -0.041517124, 0.01947972,
      0.0016243864, -0.0067881914, 0.030278822, 0.013473042, -0.008286632,
      0.009701108, 0.0037913115, -0.003558795, -0.00925545, 0.017994197,
      -0.0011109124, -0.01942805, 0.0004476751, 0.014390191, -0.002938751,
      0.002370377, 0.04394563, -0.017348317, 0.02627437, 0.0011859959,
      -0.0064394167, 0.0047956537, 0.019518472, 0.008880841, -0.015203999,
      0.013989746, 0.019789742, -0.002883851, 0.021611122, 0.040690396,
      0.004211133, 0.040225364, -0.023329161, 0.02927125, -0.011425605,
      0.00807995, 0.0068592383, 0.033534054, 0.031079713, 0.0066977683,
      -0.02547348, -0.002199219, -0.0009906174, -0.0024591854, 0.020758562,
      -0.016844532, 0.009197322, -0.0042692623, -0.002596435, 0.0017584064,
      0.003061468, 0.0035006658, -0.012110237, -0.031777263, -0.00023070001,
      0.0025883613, 0.0025011676, 0.016121147, 0.013343867, -0.035833385,
      -0.008138079, -0.017257895, 0.030072141, 0.044436496, -0.037771024,
      0.013053221, -0.004185298, 0.018743418, -0.0048182597, 0.011664581,
      0.011419146, 0.015242752, 0.0060228244, 0.007950774, 0.0036976591,
      0.012852998, 0.0053188163, -0.0011351329, -0.0073501067, -0.039734494,
      -0.0015283119, -0.02183072, -0.0011682342, 0.020861901, -0.02445299,
      0.01568195, -0.0143126855, -0.007795763, 0.016767027, 0.012381506,
      -0.017697094, 0.013757229, -0.012058566, 0.016082395, 0.025357222,
      -0.01702538, 0.002365533, -0.009823824, -0.021417357, -0.0012126385,
      -0.0018423707, 0.023367913, 0.009165027, 0.014222262, 0.0026884726,
      -0.008635406, 0.012730281, 0.013227608, 0.013757229, -0.036246747,
      -0.04469485, 0.018433396, 0.016366582, -0.018975934, -0.045728255,
      -0.04019953, 0.032371473, -0.050714444, 0.012433177, 0.004469485,
      0.0053058984, 0.001121408, -0.0030776148, 0.016689522, -0.006110018,
      0.05084362, 0.025835173, -0.010217811, -0.015526938, 0.01535901,
      -0.0074146944, 0.005486745, -0.0132405255, 0.051282816, 0.0039172582,
      -0.015191081, -0.007582623, -0.022334507, -0.048260104, -0.002173384,
      0.0014709901, -0.02017727, -0.009604225, 0.03257815, -0.01016614,
      -0.008196209, -0.013795982, 0.020732725, -0.008945429, -0.016896203,
      -0.031260557, 0.0057935375, -0.005699885, 0.04960353, 0.019738073,
      0.0078086806, 0.010450327, -0.018485066, 0.0023768358, -0.014467697,
      -0.006775274, -0.00470846, 0.009339415, -0.007950774, -0.003323049,
      0.0136280535, 0.0019004999, 0.018317137, 0.021042747, -0.015333175,
      -0.0111414185, 0.016069477, -0.04650331, -0.011922932, 0.006410352,
      0.01658618, 0.0011706563, 0.0053091277, -0.013640971, 0.020564796,
      0.012575271, -0.0064361873, -0.0213011, 0.0055803973, -0.014545202,
      0.0030194859, 0.003510354, -0.005961466, 0.011393311, -0.012148989,
      0.017619587, 0.03082136, 0.019040521, 0.013963911, 0.008325384,
      -0.006678392, 0.009701108, 0.015023152, -0.0141060045, -0.0042143627,
      0.019001769, 0.008674159, -0.019557225, 0.0125236, -0.011283511,
      -0.0060163657, -0.005454451, -0.0104115745, 0.0066977683, -0.017994197,
      0.0076019997, 0.009171486, -0.014803554, -0.0064394167, 0.0020006113,
      0.008021821, -0.011322265, -0.0037493294, -0.054822236, -0.018808005,
      0.017167471, -0.0013934847, -0.012962798, 0.033069022, -0.024478827,
      -0.026532723, -0.016211571, 0.018342972, 0.017425824, 0.016211571,
      0.011102665, 0.026287287, 0.023070809, -0.009242533, -0.0097269425,
      0.011793756, -0.010928278, -0.020371033, 0.02782448, -0.030330492,
      -0.013640971, 0.009662354, 0.019027604, -0.005961466, 0.01198752,
      -0.01840756, -0.036763452, -0.0084739365, 0.012291083, 0.0062424233,
      0.02906457, -0.0030211005, -0.010082176, -0.0004953087, -0.004372603,
      -0.022877045, -0.019440968, -0.019143863, 0.026946085, 0.021611122,
      -0.0070142495, -0.00065637485, -0.015423598, -0.0008436798, -0.018213797,
      0.006994873, -0.0020587402, -0.010534291, 0.031260557, -0.011955226,
      0.021055665, 0.013137185, 0.003910799, -0.011367476, -0.036040064,
      0.018846758, 0.018485066, -0.042343847, 0.013098433, -0.006513693,
      -0.018097539, 0.01675411, -0.013066138, 0.006613804, 0.034722473,
      0.02033228, -0.00829309, -0.022295754, 0.0070400843, -0.0070723784,
      0.020371033, -0.020267693, 0.012878833, -0.0022654217, -0.01086369,
      -0.00861603, -0.014493532, 0.0035814007, -0.031131383, -0.0077376342,
      0.0018665912, -0.0009131119, 0.018691747, -0.008002445, 0.016392417,
      -0.022437848, -0.016392417, -0.007220931, -0.015526938, 0.013072597,
      -0.0173354, 0.009494426, 0.014713131, 0.029529601, 0.00850623,
      0.020758562, -0.006374829, 0.015462351, -0.002215366, -0.006190753,
      -0.017361237, -0.0013377775, 0.027591964, 0.016973708, 0.011677498,
      0.029555438, 0.0053285044, 0.016418252, 0.0100692585, -0.0049216,
      -0.024711343, -0.013938076, -0.040922914, 0.005325275, -0.013550548,
      0.026003102, 0.0007556788, -0.0025479938, 0.004505008, 0.0026981607,
      -0.01857549, -0.00081178953, 0.008125162, 0.008835629, -0.028108668,
      0.010715138, 0.0077247163, 0.0020635843, 0.0049797297, -0.0070013315,
      -0.02445299, -0.010637633, 0.027023591, 0.01648284, -0.0045922017,
      -0.0015162016, -0.0010947654, -0.011102665, -0.012749658, -0.014041416,
      0.009830283, -0.01091536, 0.0070723784, -0.033585723, -0.016715357,
      -0.02050021, 0.003591089, -0.007647211, -0.009326497, -0.027385283,
      -0.000798872, -0.009920706, -0.02310956, -0.032500647, -0.013124268,
      0.024285061, 0.010075717, 0.026713569, 0.028987063, 0.0025237733,
      -0.011283511, -0.058955863, 0.0028967687, -0.008034739, -0.0060777245,
      0.04306723, 0.020409787, -0.029038733, 0.012878833, 0.013615136,
      -0.010934737, 0.0016082395, 0.016624933, -0.0014556505, 0.014416026,
      0.018614242, 0.014545202, -0.012730281, 0.040173694, 0.0006055118,
      0.004408126, -0.017361237, -0.006303782, -0.008377055, 0.025073035,
      -0.01599197, 0.02434965, 0.0022444306, -0.013091974, -0.015203999,
      -0.04195632, -0.0062262765, 0.012517141, -0.015798207, 0.009300662,
      0.018368807, 0.02028061, 0.018058786, 0.009307121, -0.010301775,
      -0.001433852, -0.016741192, 0.023587512, -0.0038655878, -0.011425605,
      0.020538962, 0.0067623565, 0.005683738, 0.017115802, 0.014364356,
      0.019040521, 0.000050938685, -0.011567699, 0.030330492, -0.0079249395,
      -0.04694251, -0.0032746082, 0.0005917869, -0.022541188, 0.0060777245,
      -0.0031825702, 0.029942965, 0.014002663, -0.009236074, -0.0023122479,
      -0.013305114, 0.021223594, 0.0046051196, 0.025886843, 0.0020409785,
      0.01685745, -0.017722929, -0.021856556, 0.010418033, -0.018846758,
      -0.010088635, 0.013511796, -0.005357569, -0.040974583, 0.038520243,
      0.020164352, 0.001792315, -0.001549303, 0.005838749, 0.003205176,
      0.0056611323, 0.020048093, -0.0068915323, 0.02022894, 0.00038954595,
      -0.006239194, -0.008758123, 0.00017297456, -0.015862796, -0.006988414,
      -0.0038785052, -0.009571931, 0.0070142495, 0.00551258, -0.0050314,
      -0.006432958, 0.0034586836, -0.027591964, -0.00187305, 0.021210676,
      -0.024323815, -0.0313639, 0.008138079, -0.0019021146, -0.012181284,
      0.009410461, 0.0023929828, -0.012161908, -0.009320038, 0.023484172,
      0.02279954, 0.031131383, -0.026119359, -0.009100439, -0.0095913075,
      -0.014803554, -0.0042079035, -0.0060874126, -0.03301735, 0.031803098,
      0.010644091, -0.040871244, -0.019118028, -0.008073491, -0.014506449,
      0.033069022, 0.007453447, 0.020732725, -0.0041691507, 0.021184841,
      0.0066331807, -0.0020651992, -0.00009602409, -0.0020199874, -0.017968362,
      -0.003034018, -0.0049571237, -0.02370377, -0.02279954, -0.003510354,
      -0.03286234, 0.0011456284, 0.06892824, -0.016082395, 0.011587075,
      0.04265387, -0.02756613, -0.02841869, -0.0056223795, -0.021649875,
      -0.018485066, 0.0065782806, 0.010495539, -0.043971464, 0.020086847,
      -0.008603113, -0.0026529492, -0.027591964, 0.017787516, 0.027178602,
      -0.010508456, 0.028005326, -0.0056417556, -0.025977265, 0.007937857,
      -0.027333612, 0.017684175, 0.012291083, -0.020900654, 0.010870148,
      -0.004992647, -0.024737177, -0.017374154, -0.004308015, -0.0067817327,
      -0.015488186, 0.015229834, -0.032293964, 0.008138079, -0.0070336256,
      -0.0048344065, -0.012982174, 0.00015400184, -0.009106899, -0.004850554,
      -0.011457899, 0.006426499, 0.01155478, -0.044333156, 0.006678392,
      0.004340309, -0.0031809555, -0.013795982, -0.030976372, -0.012581729,
      0.008738747, 0.025279716, 0.01065055, -0.019996423, -0.0034877483,
      0.0019392526, 0.0015315413, -0.02205032, 0.016624933, 0.24822433,
      -0.03601423, 0.0009680116, 0.037435167, 0.009022934, 0.0056805084,
      0.017645422, -0.004779507, -0.009307121, 0.01038574, -0.0129434215,
      -0.00460189, -0.020745644, 0.0030033388, -0.009055228, -0.04159463,
      -0.033043187, -0.0313639, 0.00095024996, 0.0036685944, -0.0028725483,
      0.023135398, 0.008357679, -0.007931398, 0.0051767225, 0.03821022,
      -0.0037008885, -0.011806673, 0.007589082, 0.008628948, -0.009449215,
      -0.0031244413, 0.0045534493, 0.01081202, -0.006442646, 0.007828057,
      0.039837837, -0.010269481, 0.024052545, -0.0024172033, 0.005528727,
      0.018820923, -0.0016260012, 0.0020409785, -0.023535842, -0.013369702,
      -0.012827164, -0.010153223, -0.013356784, 0.011444981, -0.014829389,
      -0.004682625, 0.027798645, 0.034774143, -0.001086692, -0.01535901,
      -0.01760667, 0.019776825, -0.010237187, 0.016353665, 0.0020958784,
      0.014170592, 0.00095186464, 0.02547348, 0.00006019797, 0.0026125817,
      -0.028599536, -0.026119359, -0.007434071, -0.02290288, 0.009559014,
      0.010624714, 0.022437848, 0.016469922, -0.03505833, -0.018911347,
      0.012827164, 0.028315349, 0.015656114, 0.028547866, -0.0085643595,
      0.026558558, 0.00023554411, 0.009261909, -0.0071369666, -0.012013355,
      0.02188239, 0.0041336277, -0.017826268, -0.012852998, -0.005344651,
      0.0043435385, -0.032423142, 0.021159006, -0.0109605715, 0.0022621923,
      0.012368589, 0.027436953, -0.010127388, -0.0008339917, -0.008880841,
      0.07171844, 0.002081346, 0.0025383057, -0.017206226, -0.0055254977,
      -0.007711799, 0.013873488, 0.0143126855, -0.0129434215, -0.0045728255,
      -0.034257438, 0.0124654705, -0.014687295, 0.0067946506, -0.006352223,
      -0.011664581, -0.02017727, 0.006917367, 0.023238737, 0.006533069,
      -0.026196865, -0.023510007, 0.013130726, 0.0020668139, -0.014519366,
      -0.021856556, -0.0009244148, 0.027591964, -0.012833622, 0.0006842284,
      -0.0051831813, 0.03002047, -0.008202667, -0.003297214, 0.014273933,
      0.008603113, -0.0031971026, 0.0072338483, -0.022244083, -0.004446879,
      -0.004194986, -0.025512233, 0.0007714221, -0.0033973253, -0.01605656,
      0.0060712653, -0.011664581, 0.011664581, -0.007821598, -0.00238168,
      -0.012575271, -0.0041336277, -0.006704227, 0.032293964, -0.0030469357,
      -0.0012731896, -0.026235618, 0.011722709, 0.015824042, -0.0050152526,
      -0.014454779, 0.029607108, -0.010217811, -0.015229834, -0.0049474356,
      -0.16317494, 0.009268368, -0.010902443, -0.033043187, 0.0025689849,
      0.013292196, 0.01675411, 0.011115583, -0.02547348, 0.008790418,
      0.03681512, -0.009145651, -0.025770584, -0.01423518, -0.01942805,
      -0.015203999, -0.013150102, 0.020151434, 0.00022504857, 0.0048473245,
      0.021817803, -0.0018342972, -0.011664581, -0.010359904, 0.0053866333,
      -0.02697192, -0.029710447, 0.022747869, -0.00026460868, -0.0025076263,
      -0.008428725, 0.029813789, 0.004537302, 0.0010753891, -0.006310241,
      -0.008480395, 0.010185516, -0.012471929, -0.009410461, 0.023290409,
      0.0052090166, 0.028315349, -0.0018084621, 0.020642303, -0.0016792862,
      0.00572572, 0.034541626, 0.005050776, -0.0076084584, -0.019841412,
      -0.02077148, 0.0027643635, 0.004908683, -0.0020329051, 0.021210676,
      0.024000876, 0.024556331, 0.0262227, -0.0077570104, -0.0060744947,
      -0.01975099, -0.018963017, 0.0047246073, 0.0067106863, -0.006846321,
      -0.012329836, -0.0060551185, -0.0076665874, -0.02836702, 0.01423518,
      0.007853893, -0.0013741083, 0.016444087, -0.04430732, 0.001600166,
      0.011302888, -0.02692025, 0.01857549, 0.0016017806, -0.0023267802,
      -0.004537302, 0.01862716, -0.021223594, 0.0065589044, 0.0114837345,
      0.005415698, 0.008622489, 0.008648324, -0.044617344, -0.0011859959,
      0.0075180354, -0.009791531, 0.026506888, -0.025408892, 0.011606451,
      0.015152329, 0.018381726, 0.009533179, 0.016030725, 0.0065427576,
      0.0077182576, -0.016211571, -0.030046305, 0.027876152, 0.017555,
      0.0019521702, -0.00119084, 0.028806217, 0.013395537, -0.005270375,
      -0.010263022, 0.0048053423, 0.012375047, 0.005141199, 0.009785072,
      0.0073242714, 0.004911912, -0.024168804, 0.026377711, -0.0060551185,
      0.058387488, -0.024892189, 0.009184403, 0.0055028917, -0.0014177051,
      -0.003200332, -0.0818975, 0.032681495, 0.009035852, -0.003952781,
      0.020241858, 0.025228046, 0.00824142, 0.0070142495, -0.019143863,
      -0.0004238583, -0.008163914, -0.017064132, 0.00599376, -0.007208013,
      0.006604116, -0.013208232, -0.03591089, -0.017412907, -0.014751883,
      0.013253444, 0.032681495, -0.012620482, -0.0043047857, 0.007472824,
      -0.035652537, -0.0010334069, -0.032655656, 0.0059324014, 0.029142074,
      0.0023106332, -0.002268651, -0.026946085, -0.007899104, -0.003549107,
      -0.00578062, 0.0025140853, -0.039346967, -0.019893082, 0.002317092,
      -0.009571931, 0.019195532, -0.011309347, 0.027591964, -0.036195077,
      0.0015961293, -0.011625827, 0.0052413107, 0.006009907, 0.020681055,
      -0.0092489915, -0.03707347, -0.004798883, -0.026377711, -0.029012898,
      0.015772372, -0.005535186, -0.02632604, 0.01680578, -0.032759,
      0.013976828, -0.026041854, 0.0141060045, -0.034360778, 0.011819592,
      0.031157218, 0.0066202627, -0.011503111, -0.02777281, -0.0054415334,
      0.0110962065, -0.00062327355, 0.01177438, -0.006342535, 0.01022427,
      -0.022024484, -0.0055093504, -0.02194698, 0.00893897, 0.010043424,
      0.00947505, 0.0037202649, -0.0132405255, -0.0029726594, -0.005099217,
      -0.0132405255, 0.026558558, -0.012000438, -0.0013418143, 0.0075051174,
      -0.021107337, -0.0058322903, 0.021856556, 0.016495757, 0.005131511,
      -0.0276178, -0.011470816, 0.0026836286, 0.0026141966, 0.017102884,
      0.006319929, -0.027695306, 0.013460125, -0.078435585, 0.015927384,
      -0.012568811, 0.0013208232, -0.019130945, -0.019867247, 0.019660566,
      -0.032836504, 0.002643261, -0.012917587, -0.011535404, -0.010450327,
      -0.0066590155, -0.00049651973, -0.024659673, -0.006768815, 0.022166578,
      0.015966136, 0.020435622, 0.0054609096, -0.0058516664, -0.006775274,
      -0.011102665, -0.0025173146, -0.021481946, 0.012833622, -0.006148771,
      0.0148810595, -0.025408892, 0.01236213, 0.008209126, -0.031777263,
      -0.0032649199, 0.007873269, -0.0077505517, -0.006994873, 0.00014643295,
      0.029297085, 0.0016227717, 0.046193287, -0.0006713108, -0.02050021,
      0.022257, -0.016030725, -0.009701108, 0.026842745, 0.0063586817,
      0.0073242714, 0.016508674, -0.0043015564, 0.009914247, 0.018071704,
      0.013382619, -0.023135398, -0.0015872484, -0.03198394, -0.015242752,
      -0.029142074, 0.033844076, -0.0332757, 0.018640077, 0.0012917586,
      -0.011948767, -0.008461019, -0.0041723805, -0.030976372, 0.00027692076,
      -0.037951868, 0.012924045, -0.023742523, -0.017141636, 0.0201256,
      0.0031309, 0.012504224, 0.0020748873, -0.005186411, -0.0064555635,
      -0.007369483, -0.021598205, 0.0042466563, -0.008945429, -0.0064684814,
      -0.047123354, 0.0023945975, 0.030563008, 0.009655896, -0.0015008621,
      -0.013156561, -0.017593753, -0.004153004, 0.00020597495, 0.017529165,
      0.008461019, -0.0015743308, 0.010585962, 0.00027692076, 0.0070530022,
      -0.022282837, 0.00358463, 0.033947416, 0.010695761, 0.006197212,
      -0.0000026333457, -0.0048247185, -0.029503766, 0.010056341, -0.018317137,
      -0.05704406, -0.0060648066, 0.03221646, 0.0010616641, 0.0004811801,
      0.033094857, 0.0144031085, -0.016198654, -0.0025883613, 0.00899064,
      -0.01530734, -0.023342079, 0.012084402, 0.026584392, 0.019634731,
      0.006523381, 0.003681512, 0.025654327, -0.0035878597, 0.0046309545,
      -0.013208232, -0.0073501067, 0.0056708204, -0.0052283932, -0.019957671,
      -0.03761601, -0.0137184765, 0.019298874, 0.007059461, 0.0022605776,
      0.009055228, -0.015526938, 0.07290686, 0.022915797, -0.006009907,
      0.0125236, 0.0011827665, 0.010766808, 0.010695761, 0.0066105747,
      0.0034393072, 0.0007237885, -0.0155656915, 0.004469485, 0.004911912,
      -0.050585266, 0.0035329598, -0.021184841, -0.006626722, 0.0036750534,
      -0.02911624, -0.016043643, 0.008170374, -0.012103778, -0.019996423,
      -0.0069238264, -0.008247878, 0.013227608, 0.015656114, -0.017774599,
      0.024181722, -0.023936287, 0.0014273933, 0.021171924, 0.0039398638,
      -0.011541863, -0.011341641, -0.0250472, -0.009326497, -0.015643196,
      -0.008764583, 0.014764801, -0.0213011, 0.012310459, -0.045883264,
      -0.025964348, -0.012607564, -0.026313122, -0.0005873465, -0.00097124104,
      -0.02589976,
    ],
  },
  {
    id: "faq-16",
    question: "Ποιοι είναι οι διαθέσιμοι τρόποι πληρωμής στο carespot.gr;",
    answer:
      "Μπορείτε να πληρώσετε την παραγγελία σας με τους εξής τρόπους:\n  \n  - Αντικαταβολή: Πληρωμή με μετρητά κατά την παράδοση, με χρέωση 1,50€.\n  - Πιστωτική/προπληρωμένη κάρτα: Μέσω του ασφαλούς περιβάλλοντος της Τράπεζας Πειραιώς (GLOBE SSL).\n  - Google Pay/Apple Pay: Μέσω Viva, με χρήση ψηφιακών πορτοφολιών.\n  - IRIS: Πληρωμή από λογαριασμό σε λογαριασμό μέσω Viva, με αυτόματη ενημέρωση και μικρή χρέωση.\n  - Κατάθεση σε τραπεζικό λογαριασμό: Σε μία από τις παρακάτω τράπεζες:\n    PIRAEUS: GR6701712670006267040030512\n    ΕΘΝΙΚΗ: GR2601103520000035244004962\n    ALPHA BANK: GR5101402200220002330001247\n    EUROBANK: GR6602602650000040201432937\n    Δικαιούχος: ATHANASIAS TATSIOU-KECHAGIA & ΣΙΑ ΕΕ ΦΑΡΜΑΚΕΙΟ\n    Στείλτε την απόδειξη στο info@carespot.gr για να ξεκινήσει η προετοιμασία της παραγγελίας σας.\n  - PayPal: Με προμήθεια που αναγράφεται στο καλάθι.\n  - Πληρωμή στο φυσικό κατάστημα: Για παραλαβή από το φαρμακείο (ισχύει για παραγγελίες άνω των 20€).\n  \n  Η ασφάλεια πληρωμών είναι ύψιστης σημασίας. Όλες οι συναλλαγές γίνονται σε ασφαλές περιβάλλον.",
    embedding: [
      0.013938955, -0.005375987, 0.0050520534, -0.043269657, -0.049054652,
      0.014514837, -0.015627336, 0.0047575682, 0.004073709, -0.013264912,
      0.038636427, 0.017747628, -0.03518114, -0.013520132, 0.020862624,
      -0.024043063, 0.0054054353, 0.010169548, 0.006347787, 0.009364622,
      0.00284996, 0.025364973, -0.026372766, -0.013834249, 0.005693376,
      0.009436607, 0.006040214, -0.0027616145, 0.016896892, -0.021804975,
      0.0029366696, -0.01891248, 0.0010127011, -0.00831102, -0.014436307,
      0.0017620018, 0.01926586, 0.011282046, 0.011543811, 0.011687782,
      0.021831153, 0.0013480867, -0.0055199573, 0.0005955141, -0.0172241,
      0.009580578, -0.018781597, -0.005313818, 0.005251649, 0.026163355,
      0.005081502, 0.01883395, -0.03952643, -0.01905645, 0.020954242,
      -0.00087282073, 0.007591168, 0.02045689, 0.00005255739, -0.014828954,
      0.0013881695, 0.018375862, -0.030129084, 0.0006814055, 0.008232491,
      0.008363374, 0.0020335824, -0.0064328606, -0.019763215, -0.011766311,
      0.017093217, 0.015627336, -0.01004521, -0.025692178, 0.003739959,
      -0.02803497, 0.01375572, -0.0071985214, -0.019619243, 0.0313332,
      0.011727046, -0.0056475676, -0.0088541815, 0.0059485966, 0.019331302,
      -0.0062888903, 0.015116896, 0.021451594, -0.034736138, -0.0042733043,
      0.031909082, 0.015915276, 0.03251114, 0.014383955, 0.0114391055,
      0.021687182, -0.014226896, 0.0065899193, -0.008723299, -0.010974473,
      -0.009410431, 0.018428216, -0.01721101, -0.013742632, -0.022865122,
      -0.024173943, 0.015627336, -0.0010462396, 0.026137177, 0.012950795,
      -0.0012212946, 0.043662306, -0.012531972, -0.020469978, 0.012505795,
      0.028453792, 0.033819962, -0.016150864, -0.025260266, -0.0041685984,
      0.0041227895, -0.008461535, 0.013624838, 0.005690104, -0.0220013,
      0.0035338197, -0.0006331427, -0.0020483066, -0.008225947, -0.016739834,
      0.009541313, 0.0074275653, -0.002725622, 0.00963293, -0.0066717207,
      0.023048356, -0.034081727, 0.010143371, -0.037353784, 0.0085008,
      0.004679039, 0.0066651767, 0.005569038, 0.010032121, -0.010810871,
      0.008710211, -0.0038806575, 0.017341893, -0.0027550706, 0.010117195,
      0.016831452, -0.0057588173, 0.020548508, -0.01574513, 0.009174843,
      0.0022053653, -0.0064328606, 0.0067142574, -0.0017440055, -0.02176571,
      -0.015143071, -0.0010028849, 0.022040565, -0.0067273458, 0.00015480911,
      0.02832291, 0.015417924, 0.016491158, 0.027040264, 0.023192327,
      -0.03324408, 0.00495062, 0.01004521, 0.020103507, -0.0015075995,
      0.014436307, 0.011517635, -0.017616745, -0.007807124, -0.008219403,
      -0.008572785, -0.012263663, -0.004773929, 0.017917775, 0.017106304,
      -0.019737037, 0.01854601, 0.00030880023, 0.007761315, -0.0122702075,
      0.00016738608, 0.00082742097, -0.0058471626, -0.0074275653, -0.6240464,
      -0.007453742, -0.014357778, -0.0161116, 0.024749827, 0.0317782,
      0.020561595, 0.013533221, -0.019108802, 0.027380558, 0.025495855,
      -0.0008065616, -0.015273954, 0.0065113897, -0.011884104, -0.028061146,
      0.016059248, 0.027904088, 0.0016458437, 0.030469378, -0.031804375,
      0.0048884507, -0.011949546, 0.03470996, 0.014370867, 0.021739535,
      0.0012212946, -0.024095414, -0.021490859, 0.028192028, -0.009842342,
      0.033427317, 0.019527625, -0.010136827, 0.031542614, 0.013428515,
      -0.018847039, 0.0322232, -0.000111045374, 0.049499653, -0.051332004,
      -0.022982916, 0.03635908, -0.028610852, -0.0036090768, 0.006976022,
      0.00757808, -0.008873814, 0.006936757, 0.0061743683, 0.008808373,
      -0.00095298607, -0.023283945, -0.0062005445, 0.041751426, 0.017760716,
      0.002076119, -0.024409533, -0.028558498, 0.01854601, -0.0022740783,
      0.00037260528, -0.012976971, -0.039238486, -0.0062725297, -0.0014487024,
      -0.03824378, -0.0015853108, 0.033715256, -0.009482416, 0.012165502,
      0.01625557, -0.010437856, 0.011072635, -0.0068385955, -0.004211135,
      -0.00076116185, -0.013533221, -0.017302629, 0.0005677016, -0.025705267,
      -0.018323509, -0.011020282, 0.012571236, 0.016504247, 0.01735498,
      -0.007990359, -0.024082327, 0.012479619, 0.020404536, 0.02362424,
      0.036777902, 0.024959238, 0.0068320516, -0.012342192, 0.023899091,
      -0.0071330806, 0.004551429, -0.0039460985, 0.0032704193, 0.009240284,
      -0.000355427, -0.0037465033, -0.013598662, 0.012728295, 0.012008443,
      -0.031071436, -0.0009701644, 0.027747028, -0.038060546, 0.0032426068,
      -0.021857329, -0.005559222, 0.0063576032, 0.005585398, -0.034029376,
      0.02531262, 0.000944806, -0.018127186, -0.01103337, -0.005287641,
      0.0058340747, 0.014449396, -0.02023439, 0.0024818538, 0.009665651,
      0.0018830678, -0.020692477, -0.029684085, 0.0031624413, -0.017302629,
      -0.019985713, 0.00080819766, -0.016805276, 0.02324468, -0.000532936,
      -0.014017485, -0.024605855, 0.024396444, -0.026359677, 0.003978819,
      -0.010025578, 0.021975122, -0.02832291, -0.0109155765, -0.032694377,
      0.0138080735, 0.008114697, -0.018873215, 0.021019682, 0.01147837,
      -0.0059616845, -0.0055886703, 0.002426229, -0.002673269, -0.0022135454,
      -0.02368968, 0.0030299232, -0.0158891, -0.0010004309, 0.012204766,
      -0.0021775528, -0.022315416, -0.01897792, -0.03128085, -0.005385803,
      0.010490209, -0.010712709, -0.004993156, -0.04392407, 0.0064753974,
      0.015313218, -0.022747329, 0.045206714, 0.007859477, -0.0023002548,
      0.022642622, 0.0104444, -0.0067862426, -0.014815866, -0.001248289,
      -0.015902188, -0.020705566, -0.0024245929, 0.011151164, 0.0073883007,
      0.0052418327, 0.04360995, -0.013310721, 0.040337898, 0.010234989,
      -0.0052222, -0.012702119, 0.025980119, 0.02714497, -0.021543212,
      0.020064242, 0.0385579, -0.0065375664, 0.025626736, 0.055441704,
      0.024069238, 0.042536717, -0.015705865, 0.03075732, -0.0075519034,
      -0.0032082503, -0.013179839, 0.031437907, 0.015457189, 0.008749476,
      -0.0028761365, -0.00581117, -0.003726871, -0.0006638182, 0.015810572,
      -0.0067796987, -0.00378904, 0.019593067, 0.008383006, 0.014331602,
      -0.015980719, -0.0030479194, -0.019449096, -0.037641723, 0.010614547,
      -0.02141233, 0.01883395, 0.014383955, 0.010948297, -0.026398942,
      -0.009017784, -0.018873215, 0.03245879, 0.044971127, -0.018637627,
      0.007584624, 0.012819912, 0.007303227, 0.0010936845, 0.008016535,
      0.020221302, 0.024043063, 0.0066619045, 0.014632631, 0.007905286,
      0.009403887, 0.0059649567, -0.01420072, -0.014514837, -0.014449396,
      0.002496578, -0.016648216, -0.013016236, 0.024435708, -0.02950085,
      0.019331302, -0.0006225085, -0.010706165, 0.01663513, 0.006851684,
      -0.019095715, 0.0059649567, -0.014370867, 0.013703368, 0.024317915,
      -0.024043063, 0.0010805962, -0.0012507432, -0.014920572, 0.005225472,
      0.004198047, 0.00027301212, 0.016085424, 0.012617045, -0.0012990059,
      -0.0017947223, 0.004446723, 0.0054774205, -0.005284369, -0.027171146,
      -0.04141113, 0.003501099, 0.02590159, -0.020182036, -0.040076133,
      -0.031621143, 0.008252123, -0.026451295, 0.022982916, -0.012996604,
      0.010267709, 0.0053072735, -0.005137127, 0.003792312, -0.03316555,
      0.029396145, 0.016124688, -0.015705865, -0.008468079, 0.013212559,
      -0.010267709, 0.020247478, -0.020142771, 0.05120112, 0.008801828,
      -0.014030573, -0.0043452894, -0.021909682, -0.03950025, 0.0063772355,
      -0.01787851, -0.023218503, 0.009168299, 0.016687483, -0.010365871,
      0.0021088396, -0.0016981966, 0.021176742, -0.007113448, -0.020024978,
      -0.032301728, -0.012028075, -0.014658808, 0.043871716, 0.02251174,
      0.010771606, 0.018218804, -0.008723299, -0.013991308, 0.0016671121,
      -0.015025278, -0.0032475148, 0.012119693, -0.007957638, 0.0073948447,
      0.0037039665, -0.00746683, 0.019370567, 0.018938655, -0.015915276,
      0.012819912, 0.015273954, -0.041987013, -0.0135463085, -0.0127152065,
      0.009868518, 0.010909032, -0.0005476602, 0.00075666275, 0.03853172,
      0.004639774, 0.012028075, -0.014253072, 0.02243321, -0.011013738,
      -0.004567789, 0.0021530124, -0.023742033, 0.0041227895, -0.008389549,
      0.02913438, 0.022564093, 0.01913498, 0.0030511916, 0.019540714,
      -0.005729369, 0.012217854, 0.017498951, -0.024029974, 0.012728295,
      0.012381457, -0.0003613576, -0.022825858, 0.023480268, -0.010935209,
      -0.0019910457, -0.009004696, 0.0064295884, -0.009665651, -0.019763215,
      -0.008841094, 0.009534769, -0.023951445, -0.02251174, -0.016190128,
      0.00009995106, -0.0026143722, -0.005879883, -0.03539055, -0.003733415,
      0.0039886353, -0.008690579, -0.02649056, 0.025024679, -0.01759057,
      -0.017721452, -0.01139984, 0.024331003, 0.022276152, 0.0085466085,
      0.0045939656, 0.029474674, 0.03128085, -0.007708962, -0.009312269,
      -0.0006797695, -0.009789989, -0.00746683, 0.015496453, -0.016530422,
      -0.028951144, 0.023519533, 0.029186733, -0.0055886703, -0.0024000525,
      0.0020859353, -0.030914377, -0.0281135, 0.01824498, 0.0036352533,
      0.012610501, -0.021019682, -0.0023051628, 0.032327905, 0.017616745,
      -0.020548508, -0.016713658, -0.018231891, 0.030521732, 0.007159257,
      -0.018192627, 0.002195549, -0.009816166, 0.0056181187, 0.0029775703,
      0.009312269, -0.016831452, -0.008526976, 0.008919623, -0.010234989,
      0.017786892, 0.0007967455, 0.0071199923, -0.006406684, -0.024802178,
      0.03075732, 0.020718655, -0.017446598, 0.005631207, 0.0034094816,
      -0.010241533, 0.019527625, -0.017341893, -0.0019206965, 0.026464382,
      0.0086382255, -0.018297333, -0.0065931915, 0.008252123, -0.01795704,
      0.015836747, -0.0077416827, 0.011609252, -0.019959537, -0.011210062,
      -0.013127485, -0.0035207313, -0.0078333, -0.03470996, 0.0031771655,
      -0.007335948, 0.0049375314, 0.0132387355, -0.0035763565, 0.01552263,
      -0.037903488, -0.0155618945, 0.016150864, -0.01434469, 0.008494255,
      -0.023270857, 0.024893796, 0.0130227795, 0.027694676, 0.018297333,
      0.036123488, 0.0094693275, 0.0036974223, -0.0071396246, -0.015928365,
      -0.011210062, -0.013140574, 0.038636427, 0.006570287, 0.022158358,
      0.022564093, 0.006167824, 0.008454991, 0.0032933236, -0.008926166,
      -0.018035568, -0.03523349, -0.035076432, -0.0021693725, -0.021163654,
      0.034081727, -0.007048007, -0.010182636, 0.005562494, 0.0004916263,
      -0.030704966, 0.008579329, 0.017773803, 0.019985713, -0.032327905,
      0.001889612, 0.005752273, 0.006164552, 0.019252773, -0.007879109,
      -0.023441004, -0.01817954, 0.02434409, -0.013690279, 0.010575282,
      -0.005686832, -0.0056704716, -0.024200121, 0.0074930065, 0.0057653612,
      0.00897852, -0.016386453, 0.0047804727, -0.03185673, -0.005160031,
      -0.041306425, 0.006151464, 0.002200457, 0.0023673319, -0.031699672,
      0.013003147, -0.015980719, -0.00831102, -0.019331302, -0.007911829,
      0.024906885, 0.014383955, 0.039343193, 0.015705865, 0.012675942,
      -0.028192028, -0.032249376, -0.000034126515, -0.0036941504, 0.005454516,
      0.054708764, -0.0017832702, -0.039317016, 0.007453742, 0.018035568,
      -0.027066441, -0.005631207, 0.016779099, -0.002962846, 0.020208213,
      0.0023460635, 0.003612349, -0.0135463085, 0.021006595, 0.0017014687,
      -0.0031673494, -0.013664103, -0.0031657135, 0.0003077777, 0.02560056,
      -0.0014961472, 0.02338865, -0.013029324, -0.0106538115, -0.026909383,
      -0.043243483, 0.002903949, 0.014488661, 0.0048917225, 0.019828655,
      0.012682486, 0.009560945, 0.016936159, -0.011766311, -0.005958413,
      -0.015195425, -0.012342192, 0.024108503, 0.0022838945, -0.01853292,
      0.016582776, 0.011262414, 0.004934259, 0.011452193, 0.004201319,
      0.0105621945, 0.003664702, -0.01632101, 0.02803497, -0.014868219,
      -0.052352883, -0.008127785, 0.010536018, -0.021399241, -0.015535718,
      0.00017249867, 0.044499952, 0.0053170896, -0.017498951, 0.0022691702,
      -0.01810101, 0.024029974, -0.0026405486, 0.04287701, 0.0036352533,
      0.024632031, -0.022119094, 0.0065931915, 0.01313403, -0.008893446,
      -0.02273424, 0.0077285944, -0.006753522, -0.03819143, 0.046253774,
      0.00867749, -0.00042945726, -0.0028008793, 0.0059027877, -0.0022855306,
      0.011576531, 0.029448498, -0.0024556774, 0.041306425, 0.009973224,
      -0.019213509, 0.000472403, -0.005843891, -0.021621741, -0.024985414,
      -0.0030250151, -0.029893497, 0.001481423, 0.0002789427, 0.003442202,
      0.0015272318, 0.01824498, -0.035364375, 0.0006045122, 0.016438806,
      -0.024527326, -0.027302029, 0.023257768, -0.016857628, -0.020496154,
      0.022891298, 0.0035141874, -0.030914377, -0.0073228595, 0.0213338,
      0.014488661, 0.0076762415, -0.006390324, 0.0019779573, -0.006760066,
      -0.014527925, -0.013664103, -0.0023935083, -0.04620142, 0.025273355,
      0.013088221, -0.050729945, -0.018284244, 0.00249167, -0.015339395,
      0.017014688, -0.003204978, 0.04025937, 0.0121916775, 0.013042412,
      0.00001307544, 0.007957638, 0.00614492, 0.012610501, -0.0226688,
      0.02228924, 0.0034127536, -0.024082327, -0.030155262, -0.013428515,
      -0.036018785, 0.0132387355, 0.05253612, 0.007918374, 0.025744531,
      0.04104466, -0.008356829, -0.023872916, 0.012505795, 0.0016352097,
      -0.015692776, 0.004148966, 0.0042962087, -0.041254073, 0.013925867,
      -0.0030577355, 0.007643521, -0.029396145, -0.0037595914, 0.00842227,
      -0.017852332, 0.016386453, -0.011020282, -0.027825559, 0.0002018449,
      -0.017171746, 0.009037416, 0.015326307, -0.013264912, 0.0071854335,
      0.012132781, -0.02847997, -0.011995355, 0.009587122, 0.009325357,
      -0.020666301, 0.00439437, -0.03198761, 0.02405615, -0.0010576919,
      0.013520132, -0.014174543, -0.0054708766, -0.027904088, -0.012525427,
      0.007335948, 0.01125587, 0.019723948, -0.04915936, 0.013062044,
      0.022119094, 0.013677191, -0.021137476, -0.0331132, -0.01397822,
      -0.007584624, 0.007983815, 0.023925267, -0.008219403, -0.008219403,
      -0.0023673319, -0.010765062, -0.0051763915, 0.014148367, 0.24228914,
      -0.02362424, -0.0023853283, 0.028741734, 0.017250275, 0.0023051628,
      0.025613649, -0.0018650715, 0.0010127011, 0.00378904, -0.008297932,
      0.017891599, -0.026451295, -0.00014090288, -0.034683786, -0.033558197,
      -0.040730543, -0.022341592, -0.011314767, 0.019750126, -0.006020582,
      0.023859827, 0.0021153837, 0.005686832, 0.0023902364, 0.016386453,
      -0.0022642622, 0.00034438382, 0.0122702075, 0.013140574, -0.003435658,
      -0.007381757, -0.008572785, -0.00021472863, -0.010130283, -0.0014544285,
      0.023179239, -0.019095715, 0.027275853, 0.0024049606, -0.0025162105,
      0.015928365, -0.0112231495, 0.010693076, -0.041227896, -0.003488011,
      -0.0071461685, -0.022106005, 0.00061514636, 0.014828954, -0.023506444,
      0.0052320166, 0.033610553, 0.025587471, -0.018951744, -0.0065015736,
      -0.0025849235, 0.01265631, -0.01567969, 0.017538216, 0.004129334,
      0.023702769, 0.007002198, 0.026215706, -0.025574384, -0.0102219,
      -0.018742332, -0.0027599786, -0.0033931213, -0.021137476, 0.013212559,
      -0.014239985, 0.024003798, 0.011936458, -0.022236887, -0.008356829,
      0.0060107657, 0.038086724, 0.029657908, 0.016779099, 0.0028336,
      0.017433511, -0.01515616, 0.0053105457, 0.01420072, -0.030155262,
      0.013703368, 0.0021628286, -0.017551305, -0.015627336, 0.002480218,
      0.011242782, -0.029448498, -0.0075977123, -0.00047812908, -0.0036483416,
      0.022249976, 0.030547908, -0.0023395196, -0.0026225522, -0.015876012,
      0.0607817, 0.0016270295, 0.017407333, -0.0026111, 0.0029333974,
      -0.005029149, 0.029474674, 0.012329104, -0.012348737, 0.0029383055,
      -0.024474973, 0.0111773405, -0.018480567, 0.00023804202, 0.004040988,
      -0.0066619045, -0.008153962, 0.018127186, 0.009652563, 0.0061809123,
      -0.016608952, -0.035364375, 0.008710211, 0.0106538115, -0.026529824,
      -0.02625497, -0.0106080035, 0.013559397, -0.04518054, 0.010751974,
      -0.012204766, 0.025273355, -0.0053007295, -0.011268958, 0.0056475676,
      -0.0003139128, -0.026124088, 0.009718004, -0.0031804377, -0.013873514,
      -0.0015321398, -0.032877613, -0.0023280673, -0.002537479, -0.013003147,
      0.0009587122, 0.0074864626, 0.0039297384, -0.005755545, -0.004014812,
      -0.015732042, 0.0061220154, -0.011694325, 0.035259668, -0.021085124,
      -0.012348737, -0.01926586, 0.019527625, 0.010405135, -0.017760716,
      -0.013441603, 0.02338865, -0.018074833, -0.012649765, -0.00768933,
      -0.1650163, 0.008945799, -0.010843591, -0.021085124, 0.012401089,
      0.01757748, 0.028715556, 0.017040864, -0.0030675519, 0.0004032808,
      0.022852033, -0.004243856, -0.025770707, 0.0022969828, 0.010640724,
      -0.011884104, -0.029448498, 0.030286143, 0.00923374, 0.0029121293,
      0.027825559, -0.010398592, -0.002069575, 0.0041587823, 0.005392347,
      -0.009259916, -0.026176441, 0.03450055, 0.0037988562, -0.009665651,
      -0.0017865421, 0.03104526, 0.01751204, 0.007506095, 0.0017390974,
      -0.008075432, 0.018506745, -0.009305725, 0.010634179, 0.004391098,
      0.0035076432, 0.023009092, -0.0035436358, 0.022982916, 0.0083306525,
      0.010424768, 0.035207313, 0.00028712285, 0.0040638926, -0.008258668,
      -0.017865421, -0.0067208013, 0.0038904736, 0.004270032, 0.021281447,
      0.021346888, 0.024959238, 0.014881307, 0.00095707615, 0.0037759517,
      -0.030155262, -0.025417324, 0.0025620193, -0.008886902, 0.0047641126,
      -0.015574983, 0.014828954, -0.010385503, -0.030469378, 0.009057049,
      0.0010945025, -0.006550655, 0.021752624, -0.026961735, 0.0055199573,
      0.020208213, -0.022838946, -0.0035665403, 0.012924618, -0.009718004,
      -0.017551305, 0.031961437, -0.021661006, -0.0008556424, -0.010267709,
      -0.010967929, 0.011458738, 0.0035829004, -0.015902188, -0.016163953,
      -0.013428515, -0.018284244, 0.019750126, -0.023742033, 0.023362475,
      0.011236237, -0.0071396246, 0.005451244, 0.013834249, 0.015954541,
      0.011969178, 0.006818963, -0.026647618, 0.031673495, 0.0066062794,
      0.012780648, 0.0033047758, 0.03258967, 0.019082626, 0.007905286,
      -0.012152413, -0.0009996129, 0.020784095, 0.029029675, 0.00247531,
      -0.0016188493, -0.009043961, -0.029029675, 0.019671597, -0.014187631,
      0.05052053, -0.035364375, 0.00012904168, 0.0054806927, -0.0037857678,
      -0.002669997, -0.0781105, 0.007290139, 0.008245579, 0.015116896,
      0.0172241, 0.02427865, 0.0061285594, 0.012597413, -0.027302029,
      -0.005029149, -0.019985713, -0.031176142, -0.007898741, 0.0004372284,
      0.012224399, -0.009770357, -0.0027468903, -0.0037006945, -0.013441603,
      0.016046159, 0.02787791, -0.022459388, -0.007846388, 0.007872565,
      -0.042536717, 0.024252474, -0.024605855, -0.005408707, 0.025953943,
      0.014501749, -0.004276576, -0.02324468, -0.01324528, -0.009875063,
      0.015771307, -0.0128853535, -0.03214467, -0.029919673, 0.007761315,
      -0.021098211, 0.017839245, -0.009050505, 0.02816585, -0.025351884,
      0.0012662854, -0.004047532, 0.0016213034, -0.0014307061, 0.015705865,
      -0.012708662, -0.032118496, -0.0073883007, -0.022276152, -0.0170016,
      0.010392047, 0.0025096664, -0.018140275, 0.026215706, -0.0114849135,
      -0.020836448, -0.026372766, -0.0051960237, -0.031307023, 0.018454392,
      0.030835848, 0.0024458612, -0.021229094, -0.028977321, -0.00831102,
      0.005274553, 0.001889612, 0.012839545, 0.002838508, 0.012001899,
      -0.030940555, -0.012381457, -0.023899091, 0.0068385955, 0.0030577355,
      -0.022132182, -0.0043092966, -0.011936458, 0.0024556774, -0.021242183,
      -0.0025243906, 0.015980719, 0.0037563194, -0.0014356142, 0.0029988387,
      -0.031961437, -0.008703667, 0.009907783, 0.017341893, -0.007061095,
      -0.028663203, -0.025338795, -0.0059649567, -0.0132387355, 0.011857928,
      -0.0022740783, -0.01662204, 0.003442202, -0.06554581, 0.008029624,
      -0.022694975, -0.005490509, -0.017197922, -0.02464512, 0.029527027,
      -0.0023427915, -0.005091318, 0.0023297034, -0.027851734, -0.016464982,
      -0.00861205, -0.003258967, -0.037196726, -0.005817714, 0.026699971,
      0.021739535, 0.030495554, 0.022328505, -0.010032121, -0.012846089,
      -0.0012065704, -0.001833987, -0.01552263, 0.041803777, 0.008834549,
      0.024527326, -0.016019983, 0.0114391055, 0.011203517, -0.032354083,
      -0.0021431963, 0.011190429, 0.006746978, -0.0022020931, 0.0027665228,
      0.025142472, 0.014959836, 0.015103807, -0.010280797, -0.025809972,
      0.0013979856, -0.026804676, -0.008101609, 0.008893446, 0.0024164128,
      0.0029972026, 0.016569687, 0.0024474973, 0.004859002, 0.011452193,
      0.028584674, -0.019527625, -0.0067142574, -0.02074483, 0.00012934844,
      -0.011713957, 0.030469378, -0.03803437, 0.027825559, -0.0034225697,
      0.0020859353, -0.023414828, -0.0058308025, -0.018480567, -0.0029481219,
      -0.027537616, 0.013349986, -0.034552902, -0.005218928, 0.013206015,
      -0.0055035967, 0.04018084, -0.0023427915, -0.007002198, -0.0009088133,
      -0.034264963, -0.025953943, -0.00009734364, -0.012564692, -0.0022380857,
      -0.06826816, 0.019710861, 0.033819962, 0.018506745, 0.01948836,
      0.0031853458, -0.021687182, 0.003425842, 0.0077155065, 0.014554102,
      -0.0008695487, -0.019436007, 0.027799381, 0.0008589145, 0.004773929,
      -0.021804975, 0.0078856535, 0.0481123, 0.012119693, 0.005146943,
      -0.004099885, -0.0009300817, -0.014449396, 0.015574983, -0.01912189,
      -0.04929024, 0.0006891766, 0.02714497, 0.025940854, 0.008651314,
      0.006586647, 0.0059551406, -0.012486163, 0.0055461335, 0.018441303,
      -0.02324468, -0.00996668, 0.006691353, 0.015417924, 0.020195125,
      -0.0076631536, 0.0018716156, 0.035940256, -0.0054119793, 0.020273654,
      -0.015352483, 0.0012834637, 0.00875602, 0.0075519034, -0.0146849835,
      -0.013088221, -0.03279908, 0.0063608754, -0.0069105807, -0.012217854,
      0.0058373464, -0.0050095166, 0.09009931, 0.017472776, 0.0031788016,
      0.008926166, 0.014109102, 0.0122702075, 0.009187931, 0.004358378,
      -0.00768933, -0.008474623, -0.007682786, -0.006279074, -0.022564093,
      -0.041725248, -0.0085008, -0.012381457, -0.006279074, 0.0027059896,
      -0.01980248, -0.025221001, 0.013572485, -0.010392047, -0.01721101,
      0.001721101, 0.00039878173, 0.0102546215, 0.0138080735, -0.004207863,
      0.023519533, -0.029736439, 0.0010429677, 0.02368968, -0.004898267,
      -0.0058406186, -0.017341893, -0.019004097, -0.00023374744, 0.0027125338,
      -0.0044172746, 0.013965132, -0.019187331, 0.024514237, -0.0372229,
      -0.03128085, -0.016962335, -0.0022102734, 0.0012965519, 0.0022020931,
      -0.017891599,
    ],
  },
  {
    id: "faq-17",
    question: "Ποια είναι η πολιτική αποστολών του carespot.gr;",
    answer:
      "Για αποστολές με BoxNow:\n  - Δωρεάν για παραγγελίες από 49,00€ και έως 20kg.\n  - Για παραγγελίες κάτω από 49,00€ και έως 20kg, κόστος 2,30€.\n  - Ισχύει για διαστάσεις έως 62x45x36εκ. και πληρωμές με κάρτα, Paypal ή κατάθεση.\n  \n  Για αποστολές με Courier εντός Ελλάδας:\n  - Δωρεάν για παραγγελίες από 49,00€ και έως 3kg.\n  - Για μικρότερες παραγγελίες και έως 3kg, κόστος 2,90€.\n  - Κάθε επιπλέον κιλό άνω των 3kg χρεώνεται με 0,80€/kg.\n  - Αντικαταβολή: έξτρα χρέωση 1,5€ (όχι διαθέσιμη για ποσά > 500€).\n  \n  Για αποστολές στην Κύπρο (7-15 μέρες):\n  - Από 59,00€ και έως 2kg: κόστος 7€.\n  - Κάτω από 59,00€ και έως 2kg: κόστος 9€.\n  - Κάθε επιπλέον κιλό χρεώνεται με 1,5€/kg.\n  - Πληρωμές με κάρτα, Paypal ή αντικαταβολή (επιπλέον 3€).\n  \n  Για πολύ ογκώδεις παραγγελίες γίνεται συνεννόηση με τον πελάτη.",
    embedding: [
      0.0167285, -0.0065634293, 0.0023928483, -0.029947707, -0.04042281,
      0.010600433, -0.0036016407, 0.020066284, -0.0055113016, -0.016227173,
      0.017678382, 0.013944814, -0.020950202, -0.010758747, 0.009967178,
      -0.0033740643, 0.015712652, -0.017559648, 0.018997665, 0.005118815,
      -0.0024555142, 0.011411792, -0.004683452, -0.009162416, 0.01481554,
      -0.00494401, 0.00391497, -0.011853752, 0.029129753, -0.026280103,
      0.0034862035, -0.018060975, 0.002453865, 0.009360308, -0.0038028313,
      -0.0099276, 0.015778616, -0.004535033, 0.0191164, -0.0021372372,
      0.023562381, 0.0008525862, 0.0007882712, -0.0050231675, -0.012744267,
      0.011517335, -0.013918428, -0.010211245, 0.016979164, 0.028496498,
      0.0088457875, 0.02294232, -0.03248073, -0.014591262, 0.03350977,
      -0.014274634, -0.00238955, 0.010870886, 0.0067679184, -0.01887893,
      0.020198211, 0.01490789, -0.018707423, -0.0046405755, 0.00948564,
      0.0033229422, -0.0048384676, 0.0115371235, -0.032322418, -0.0053496896,
      0.022823583, 0.025211485, -0.006009331, -0.033061214, 0.016992357,
      -0.009947388, 0.0059565594, -0.01257276, -0.024947628, 0.034459654,
      0.0015023327, -0.012552971, -0.0030607348, 0.009241573, 0.017071513,
      0.0032338907, 0.018799772, 0.029103367, -0.028733969, -0.012341886,
      0.01448572, 0.011266671, 0.03435411, 0.0033641697, -0.015290482,
      0.0036742012, -0.003446625, 0.018417181, 0.01152393, -0.020791888,
      0.0033245913, 0.014683612, -0.028285412, -0.008588527, -0.0094724465,
      -0.025053171, 0.0062303105, 0.010547662, 0.030923977, -0.007097739,
      0.00011729244, 0.040501967, -0.022361835, -0.011048989, 0.00019201741,
      0.012889388, 0.01457807, -0.014050356, -0.030976748, -0.0078365365,
      0.009828653, -0.0074473484, 0.005079237, -0.0069394247, 0.004696645,
      0.0020547821, -0.0060060327, -0.007295631, -0.01119411, -0.01093685,
      -0.007071353, -0.006553535, -0.0029123155, 0.011141339, -0.020989781,
      0.019973934, -0.026319683, 0.008753438, -0.045304153, -0.01119411,
      0.007552891, 0.0006963337, 0.0030904189, 0.003031051, -0.0046108915,
      0.0071571064, 0.0041392483, 0.021926472, -0.00087814726, 0.023258947,
      0.022704849, -0.018773386, 0.0018453461, -0.0038753918, -0.020528032,
      0.026886974, -0.00939329, -0.0020712733, 0.0022708147, -0.022638883,
      -0.0131730335, 0.0033427314, 0.011702034, -0.0052474453, -0.008397232,
      0.037678704, 0.011424985, 0.025382992, 0.020818274, 0.013443487,
      -0.022599306, 0.011253478, 0.0024934434, 0.02411648, -0.000017792274,
      0.01906363, -0.003299855, -0.02084466, -0.0029898235, 0.010099106,
      -0.04182125, 0.0056003532, 0.017968625, 0.009347115, -0.003080524,
      -0.020026704, 0.0141822845, 0.011293056, 0.010125492, -0.0011873541,
      -0.0039380575, 0.010897271, -0.01608205, 0.005527793, -0.62396777,
      -0.025409376, -0.012981738, -0.017757539, 0.027361915, 0.032850128,
      0.02056761, 0.0070119854, -0.014195478, 0.009083259, 0.021900086,
      0.014063549, -0.014314213, -0.005560775, -0.020343333, -0.023535997,
      0.024485879, 0.02560727, 0.017981818, 0.019050436, -0.024881665,
      -0.0052969186, -0.002948596, 0.030026864, 0.018166518, 0.0108181145,
      -0.0005549231, -0.02378666, -0.023707503, 0.024063708, -0.0037863401,
      0.030211564, -0.009195398, -0.013496257, 0.03688713, 0.010085913,
      -0.021820929, 0.02608221, 0.007678223, 0.041583776, -0.04617488,
      -0.022454185, 0.030950362, -0.020066284, 0.0061049787, -0.010587241,
      0.014353791, -0.012566164, 0.025633655, -0.0049967817, 0.009637357,
      0.020923818, -0.019472606, -0.010593837, 0.03870774, 0.001827206,
      0.014709998, -0.03649135, -0.024076901, -0.0020003617, 0.004709838,
      0.008720456, -0.021477915, -0.03393194, 0.018588688, -0.009736303,
      -0.0111083565, -0.0070119854, 0.02939361, -0.018034589, 0.016900007,
      0.02323256, -0.007994851, -0.003336135, -0.00073261396, -0.018351216,
      0.0008641299, -0.015778616, -0.009109644, -0.0052144635, -0.031741932,
      -0.023390874, -0.017282598, 0.023219367, 0.0108181145, 0.00314319,
      -0.03205856, -0.011946101, -0.011424985, 0.018417181, 0.020356527,
      0.04095052, 0.019314293, 0.006190732, -0.0131730335, 0.028892282,
      -0.0028727371, 0.015396024, -0.01000016, 0.0032190487, -0.0039017773,
      -0.00500008, -0.007071353, -0.016293136, 0.024353951, 0.010521276,
      -0.021464722, 0.011589895, 0.028575655, -0.027335528, 0.009109644,
      -0.006365537, 0.0049143266, -0.02248057, 0.0012211606, -0.032929286,
      0.015118975, 0.004056793, -0.01128646, -0.0031530848, -0.0013778254,
      -0.0024324267, 0.027968785, -0.00750012, 0.00972311, 0.027678542,
      0.013496257, -0.029314453, -0.03066012, -0.021082131, -0.022097979,
      -0.014235056, 0.015725845, -0.005943367, 0.04037004, -0.008357653,
      -0.008964523, -0.02626691, 0.016754884, -0.0032767674, -0.0031349445,
      -0.01621398, 0.014789155, -0.015607109, -0.0163591, -0.027599385,
      0.0019838708, -0.00049638, -0.030923977, 0.030369878, -0.0035950441,
      -0.009267958, 0.00957799, 0.018918509, -0.017625611, 0.00021974296,
      -0.027652157, 0.012658513, -0.0088919625, -0.0070449673, 0.005435443,
      0.0027358616, -0.022018822, -0.018562302, -0.025831547, -0.0044492795,
      0.0095582, -0.011326038, 0.0093800975, -0.03205856, 0.014696805,
      -0.00071735977, -0.019142786, 0.027335528, 0.008522564, 0.00053719524,
      -0.0009267958, 0.009347115, 0.02420883, -0.0065172543, -0.011081971,
      -0.004155739, -0.030528191, 0.0005145201, -0.00998037, 0.008410425,
      0.00086165627, 0.040713053, -0.023760274, 0.030554578, 0.00092597125,
      0.00028673775, 0.0054222504, 0.031557232, 0.029789394, 0.0032454345,
      0.025739197, 0.02939361, -0.0026336173, 0.018931702, 0.0544072,
      0.009103048, 0.039657626, -0.012895985, 0.028813126, -0.018232482,
      -0.0048846425, -0.015805002, 0.043061376, 0.0067745144, 0.0040963716,
      -0.01584458, -0.007698012, -0.008001447, -0.014419755, 0.013384119,
      -0.016556993, 0.001402562, 0.0062962747, 0.006382028, 0.01990797,
      0.0052309544, -0.011847155, -0.007599066, -0.023351297, 0.00794208,
      -0.0056992993, 0.021345988, 0.0167285, -0.0004333018, -0.025620462,
      -0.009234976, -0.019182365, 0.036280263, 0.04543608, -0.031187834,
      -0.00053101114, -0.009683532, 0.019525377, 0.0046240846, 0.013522644,
      0.02420883, 0.008825999, 0.012592549, 0.0044031045, -0.008944734,
      0.028945053, 0.015950123, -0.008192743, 0.010270612, -0.008898559,
      -0.0037104813, 0.010085913, -0.012599146, 0.014894697, -0.013760114,
      0.0038094276, 0.0041326517, 0.0023466733, 0.03477628, 0.00825211,
      -0.013456679, 0.015963316, -0.008595124, 0.010151877, 0.0037797438,
      -0.034881823, 0.0021850613, 0.0017694874, -0.021886893, 0.0060324185,
      -0.004436087, 0.020316947, 0.007698012, 0.0010010054, -0.0068536713,
      0.008779824, 0.011240285, 0.0065040616, -0.009841846, -0.032216873,
      -0.05079237, 0.019551763, 0.024631001, -0.017190248, -0.033404227,
      -0.0338264, 0.026438417, -0.038866058, 0.010983025, -0.020884238,
      0.017915854, -0.0024819, 0.0066788667, 0.013641379, -0.031108676,
      0.0332723, 0.013001527, -0.0036049387, -0.016385486, 0.01000016,
      -0.004788995, 0.020686347, -0.015013432, 0.04321969, 0.006619499,
      -0.025158713, -0.012704688, -0.010428927, -0.03084482, 0.008779824,
      -0.0054123555, 0.0016161208, 0.0018931701, 0.008740245, 0.002036642,
      -0.0115371235, 0.009643953, 0.020290561, -0.00080063945, -0.019498993,
      -0.026464803, -0.013575415, -0.0044129994, 0.0544072, 0.043536317,
      0.005115517, 0.0127904415, -0.0055871606, -0.00525734, -0.006137961,
      -0.038575813, -0.015211325, -0.012104415, -0.021543879, 0.0025082855,
      0.006807497, -0.015277289, 0.021728579, 0.02612179, 0.002935403,
      0.01906363, 0.017599225, -0.020343333, 0.0056201424, -0.006553535,
      -0.0037962347, 0.0054222504, -0.018931702, -0.027229987, 0.005656423,
      -0.0036346228, -0.009729707, -0.016095243, 0.0144725265, -0.011649262,
      0.004861555, -0.0006316064, -0.034274954, 0.018958086, -0.0037863401,
      0.023799853, 0.01317963, 0.021148095, 0.015224517, 0.010613626,
      -0.0009507078, 0.0137996925, 0.015831387, -0.01977604, 0.009419676,
      0.027005708, 0.0030854715, -0.019934356, 0.024419915, -0.010534469,
      -0.01051468, -0.0025363201, 0.007183492, 0.0005326602, -0.017269405,
      0.012302307, 0.012176976, -0.017942239, -0.016161209, -0.024156058,
      -0.0137865, -0.0019343976, -0.0087996125, -0.031557232, -0.0027671945,
      -0.0036313245, 0.0016416819, -0.013852464, 0.02883951, -0.031900246,
      -0.024274794, -0.016293136, 0.038734127, 0.032507114, 0.022691656,
      0.0015468585, 0.020026704, 0.031847473, -0.01283002, -0.012348482,
      -0.015870966, -0.017124284, -0.0022840074, 0.008779824, -0.0059697526,
      -0.027414685, 0.006880057, 0.036385804, -0.007183492, 0.012915773,
      -0.0052672345, -0.020250984, -0.032507114, 0.0048351698, 0.009439465,
      0.022889547, -0.0034697123, -0.0047197323, 0.02626691, 0.0027787383,
      -0.033905555, -0.007453945, -0.007308824, 0.025923897, 0.010138684,
      -0.027177215, -0.0014141058, 0.0038193222, 0.014881504, -0.023944974,
      0.008839191, -0.020963395, -0.01439337, 0.0098088635, -0.0065865167,
      0.03659689, -0.0009606024, 0.012480411, -0.002933754, -0.03733569,
      0.016992357, 0.007071353, -0.020250984, -0.0035059927, -0.00030941292,
      -0.008159761, 0.013483065, -0.01957815, 0.0010595486, 0.031847473,
      0.004452578, -0.009953985, -0.013041105, 0.010455312, -0.0055244947,
      0.01963092, -0.0068998463, 0.008331268, -0.03818003, 0.0072230706,
      -0.016807657, -0.021926472, 0.00487145, -0.033113986, 0.0019360468,
      -0.013680957, 0.012348482, 0.0101452805, -0.0032883112, 0.018549109,
      -0.033219527, -0.009505429, 0.020356527, -0.0061115753, 0.014894697,
      -0.0218737, 0.00852916, 0.0076386444, 0.021517495, 0.014802347,
      0.010336577, -0.0071109314, 0.009604375, -0.009254766, -0.009901213,
      -0.0103431735, -0.015739039, 0.015593917, 0.030739278, 0.020989781,
      0.0052210595, -0.002404392, -0.003350977, -0.014525298, -0.010521276,
      -0.032586273, -0.037916172, -0.039472926, -0.0090436805, -0.012579356,
      0.026477996, 0.0017826802, -0.0063688355, 0.010303595, -0.0023186386,
      -0.007038371, 0.0061808378, 0.010626819, 0.020409297, -0.032401573,
      -0.00083980564, -0.004251387, -0.009914407, 0.008067411, -0.009525218,
      -0.013865657, -0.014340598, 0.021834122, -0.0026187752, -0.0052672345,
      -0.0067679184, 0.0054750214, -0.010244227, -0.0031563828, -0.0007016933,
      0.013707343, -0.0069130394, -0.0072494564, -0.0031926632, -0.008093797,
      -0.02902421, -0.015607109, -0.013958006, -0.007163703, -0.04628042,
      0.015224517, -0.002420883, -0.019235136, -0.013080684, -0.006263293,
      0.009109644, -0.0003997013, 0.02323256, 0.026148176, 0.0104816975,
      -0.017717961, -0.022625692, -0.0052012703, -0.018760195, 0.019973934,
      0.03812726, 0.0023103931, -0.042797517, -0.010870886, 0.033113986,
      0.002226289, -0.0005227656, 0.017163863, 0.0022642182, 0.034749895,
      0.006926232, 0.01935387, -0.012513393, 0.031794704, -0.0002308744,
      -0.013958006, -0.007117528, -0.011932909, -0.017018741, 0.007876115,
      -0.0044228937, 0.023760274, -0.017942239, -0.0067481287, -0.013027913,
      -0.036464963, 0.008139972, 0.0330876, 0.008951331, 0.013067491,
      0.0059070867, 0.022348642, 0.015211325, -0.0140899345, 0.0036742012,
      -0.005224358, -0.0070911422, 0.023773467, 0.004920923, -0.022190329,
      0.0021125008, 0.0027754402, 0.018826159, 0.0016491029, 0.008146568,
      0.0039677415, -0.008324672, -0.02939361, 0.020857854, -0.014142706,
      -0.055990342, -0.006075295, 0.011326038, -0.004587804, -0.010283805,
      -0.0026517573, 0.027625771, 0.029763008, -0.008944734, -0.0029931217,
      -0.012381464, 0.016016087, 0.0048516607, 0.01897128, 0.013232402,
      0.021240445, -0.018522723, -0.0035455711, -0.0065766224, 0.000013695284,
      -0.022546533, 0.029842166, -0.017902661, -0.034881823, 0.056834683,
      0.02312702, -0.005049553, -0.021438338, 0.0111545315, 0.011002814,
      0.00020819924, 0.021002974, -0.003031051, 0.029340839, 0.007869519,
      -0.0123155005, -0.018390795, 0.0009985318, -0.018390795, -0.027599385,
      -0.017757539, -0.021754965, 0.003255329, 0.006398519, 0.010804922,
      0.025448956, -0.0085555455, -0.03047542, 0.016847234, 0.020580804,
      -0.036860745, -0.02215075, 0.01696597, -0.03150446, -0.01668892,
      0.033615313, 0.010626819, -0.03298206, 0.008680877, 0.012981738,
      -0.0042283, 0.021768158, -0.014987047, -0.010264016, -0.008905156,
      -0.015989702, 0.0012013714, 0.010382752, -0.030871205, 0.030739278,
      0.016900007, -0.041187994, -0.007632048, -0.0016054016, -0.0017760838,
      0.007948676, 0.008060815, 0.027968785, 0.0016491029, 0.008548949,
      -0.0050759385, 0.004360228, 0.004729627, 0.01650422, -0.0330876,
      0.018535916, -0.01009251, -0.02715083, -0.013331347, -0.01051468,
      -0.03145169, 0.004380017, 0.03794256, 0.011002814, 0.013892042,
      0.048127417, 0.0028298604, -0.03973678, 0.00077301695, -0.013614994,
      -0.009822057, 0.0051583936, -0.013159841, -0.050317425, 0.019683693,
      0.0045911022, 0.021754965, -0.037731472, 0.00654364, 0.004670259,
      -0.026741851, 0.008285093, -0.019749656, -0.03870774, -0.0004914327,
      -0.012355079, 0.005999436, 0.02579197, -0.013258787, 0.0057124924,
      0.020356527, -0.017915854, -0.036913518, 0.0066392883, -0.002325235,
      -0.031055905, 0.010996218, -0.022005629, 0.021280024, 0.005791649,
      0.0140899345, -0.012183572, 0.0031844177, -0.015250904, 0.007856326,
      -0.014155899, 0.016556993, 0.032375187, -0.04029088, 0.006556833,
      0.01887893, 0.012717881, -0.030158794, -0.01963092, -0.009314133,
      -0.016794464, 0.017915854, 0.02369431, -0.021438338, -0.008832595,
      -0.001940994, -0.00052152877, -0.02566004, 0.032691814, 0.23578213,
      -0.02233545, -0.007262649, 0.02354919, 0.00187503, 0.0003964031,
      0.031108676, 0.011880137, 0.004581208, -0.004505349, -0.0005837824,
      -0.004363526, -0.028259026, -0.0019014156, -0.01275746, -0.034380496,
      -0.026939744, -0.028892282, -0.023971358, -0.002676494, 0.0058279294,
      0.028496498, 0.028575655, 0.014894697, 0.0065337457, 0.031847473,
      -0.01663615, -0.014116321, 0.0027061778, 0.025739197, -0.011550317,
      -0.011517335, -0.0020086074, -0.0035785532, -0.01584458, 0.0076716263,
      0.018351216, -0.0031745231, 0.013041105, -0.0069196355, -0.006236907,
      0.01569946, -0.00029869375, 0.02878674, -0.025396185, -0.00439321,
      -0.0009869881, -0.011339231, -0.0025148818, 0.025554499, -0.023008283,
      -0.0034103447, 0.033430614, 0.024842085, -0.010488294, 0.0091822045,
      -0.009149223, 0.02060719, -0.008482985, 0.010857693, 0.016319523,
      0.03131976, 0.004597699, 0.03435411, -0.0034334322, -0.002485198,
      -0.024261601, -0.0021108517, 0.014208671, -0.025119135, 0.020620381,
      -0.009023891, 0.0169132, 0.0014685261, -0.0224278, -0.010277209,
      0.009175608, 0.035251223, 0.030079637, 0.0013250541, 0.001876679,
      0.023997745, -0.010567451, -0.0024901454, 0.0148551185, -0.023628347,
      0.02630649, 0.0008715509, -0.005405759, -0.0165438, 0.0045449273,
      0.0125001995, -0.02354919, -0.014498913, 0.0039875307, -0.0025857934,
      0.013562222, 0.048101034, -0.03490821, 0.015554338, -0.015118975,
      0.070133045, 0.014934275, 0.018324832, -0.00020541638, 0.01301472,
      0.00013656633, 0.028417341, -0.000081836726, -0.023958167, -0.003286662,
      -0.021952858, 0.0072098775, -0.015316867, -0.004442683, -0.0013506153,
      0.004772504, -0.014644033, 0.0198552, -0.007698012, -0.013812886,
      -0.0053496896, -0.029763008, 0.021187674, 0.0038094276, -0.01733537,
      -0.028338183, -0.00067077257, 0.0059400685, -0.028892282, 0.0134632755,
      -0.02238822, 0.009624165, 0.016768077, -0.017506875, -0.011853752,
      -0.010428927, -0.023628347, 0.0024390232, 0.02317979, 0.0005013273,
      -0.0093800975, -0.019604534, -0.021794543, -0.0043965084, -0.0048186784,
      -0.0030904189, 0.015765423, -0.002773791, -0.012289114, -0.011998872,
      -0.0006151154, -0.008588527, -0.0022972003, 0.024156058, -0.012546374,
      -0.010732361, -0.026200946, 0.015039818, 0.024129674, -0.028364569,
      -0.007810151, 0.020739118, -0.021794543, -0.010956639, -0.0043404386,
      -0.16717945, 0.008403828, 0.014617648, -0.019973934, 0.011774595,
      0.01500024, 0.021741772, 0.036280263, -0.0088457875, -0.008337864,
      0.0384175, 0.019406643, -0.029657466, -0.004027109, -0.008093797,
      -0.02047526, -0.041399077, 0.020211404, 0.023905395, 0.010917061,
      0.028628426, -0.01299493, -0.0018403988, 0.0056828083, 0.0036313245,
      -0.010290402, -0.015250904, 0.016715307, 0.0051056226, -0.011622877,
      -0.0008266128, 0.015303674, 0.0323488, 0.0070449673, -0.010455312,
      -0.014050356, 0.0051583936, -0.016253557, -0.0067250417, 0.025013592,
      0.017559648, 0.021557072, 0.009841846, 0.030686505, 0.00081053405,
      0.014010778, 0.028733969, 0.008417021, 0.0019706779, 0.0034433268,
      -0.017836697, -0.009096451, 0.0123155005, -0.0045614187, 0.021939665,
      0.022111172, 0.03279736, 0.01472319, -0.006071997, 0.0140899345,
      -0.027309144, -0.026108596, 0.0037731472, -0.0075858734, 0.0087072635,
      -0.016425064, 0.004980291, 0.0033625208, -0.026491188, 0.008113586,
      -0.003317995, -0.0061214697, 0.005108921, -0.020290561, 0.015857773,
      0.0107719395, -0.016108437, 0.00015336656, 0.008324672, -0.0080344295,
      -0.011860347, 0.016834041, -0.01119411, 0.012889388, -0.0048780465,
      -0.0017299089, 0.008964523, 0.009195398, -0.034327727, -0.01639868,
      -0.016768077, -0.02257292, 0.003222347, -0.028021555, 0.026332874,
      0.01500024, -0.0031992595, 0.019710077, 0.0041920193, 0.012341886,
      0.022137556, -0.023575574, -0.038259186, 0.027916012, 0.018799772,
      0.015765423, -0.020105863, 0.040554736, 0.030923977, 0.006398519,
      0.001297844, 0.016622957, 0.016979164, 0.024604615, 0.0012607392,
      0.014604455, -0.020409297, -0.031900246, 0.016425064, -0.023865817,
      0.045277767, -0.042164262, 0.010428927, -0.0167285, -0.0003393029,
      0.0036840958, -0.06913039, 0.007519909, 0.009459254, -0.016768077,
      0.004261282, 0.017968625, -0.007632048, 0.014841925, -0.03131976,
      -0.0026154772, 0.0011815822, -0.040079795, 0.0016375592, -0.009465851,
      0.024565035, 0.021398759, -0.0072560525, -0.024222022, 0.004215107,
      0.02233545, 0.036148336, -0.028470112, -0.009947388, 0.013562222,
      -0.03066012, 0.027467458, -0.0074209627, 0.015541146, 0.029129753,
      0.009525218, -0.012209957, -0.020382911, 0.0040139165, -0.0131268585,
      0.013878849, -0.015488374, -0.033588927, -0.025765583, -0.01093685,
      -0.023522804, 0.011174321, -0.023575574, 0.012381464, -0.038470272,
      -0.012519989, 0.00957799, 0.012341886, 0.0010999516, 0.0114447735,
      -0.0067679184, -0.036095563, -0.02575239, -0.009076662, -0.01612163,
      -0.0019393449, 0.0043041585, -0.012157186, 0.017889468, -0.021055745,
      -0.000784973, -0.024617808, 0.0041392483, -0.019221943, 0.0058938935,
      0.03337784, 0.007216474, -0.015646689, -0.033430614, 0.0034103447,
      -0.003717078, -0.000051328327, 0.012968545, 0.00010203824, 0.025303835,
      -0.028338183, -0.0332723, -0.01878658, -0.006161048, 0.029551923,
      -0.0054123555, -0.007440752, -0.004627383, 0.015132168, -0.028918669,
      -0.0009902862, 0.033298686, 0.010059527, 0.0028331587, 0.012948756,
      -0.025198292, 0.0013423697, 0.013746922, 0.018245675, -0.0082191285,
      -0.020818274, -0.010488294, -0.0030986643, 0.0055146, 0.0029584905,
      -0.010923658, -0.038734127, 0.0068668644, -0.073457636, -0.0026022843,
      -0.011095164, -0.008331268, -0.023984551, -0.021675808, 0.023153404,
      0.010831308, -0.018338025, -0.0029799289, -0.014683612, -0.008113586,
      0.006807497, -0.010283805, -0.039684013, -0.005982945, 0.023971358,
      0.010442119, 0.024433108, 0.01112155, -0.01130625, 0.010508084,
      0.0046240846, -0.007453945, -0.025528112, 0.039288227, -0.007915693,
      0.02514552, -0.016807657, -0.0011749858, 0.013357733, -0.017308984,
      -0.023404067, 0.0016886813, -0.0013728781, 0.010468505, -0.0008138323,
      0.02986855, 0.00817955, 0.039789554, -0.015950123, -0.033720855,
      0.0038391114, -0.021662615, -0.0005161692, 0.011735016, -0.020066284,
      -0.00577186, 0.017031934, 0.00948564, 0.004244791, -0.0009218485,
      0.013958006, -0.016979164, 0.0033113984, -0.029736623, 0.005837824,
      0.0050264657, 0.020541225, -0.04023811, 0.019142786, -0.0010908815,
      0.005082535, -0.0148551185, 0.0020745713, -0.013337944, -0.0067415326,
      -0.031187834, -0.009281151, -0.027625771, -0.017638804, 0.0035653603,
      0.0023054457, 0.027942399, -0.01481554, 0.0051122187, 0.0027869837,
      -0.004010618, -0.00058996654, -0.0029436487, -0.0163591, -0.0070317746,
      -0.037282918, 0.022322256, 0.018087361, 0.033615313, -0.009881425,
      0.0016045771, -0.025831547, 0.0063853264, -0.0027358616, 0.01659657,
      -0.013878849, -0.022691656, -0.00018449339, -0.014353791, 0.017836697,
      -0.01310707, 0.009617568, 0.03477628, 0.002018502, 0.0037005867,
      0.0046735574, -0.022493763, -0.023430454, 0.01112155, -0.007163703,
      -0.048971757, 0.008766631, 0.023153404, 0.028654812, -0.0025857934,
      0.011062182, 0.008944734, -0.028760353, 0.02902421, 0.014973854,
      -0.014353791, -0.015356446, 0.0052309544, 0.008614914, -0.0016301381,
      0.016979164, 0.0008756736, 0.028865896, 0.0027787383, 0.020963395,
      -0.009241573, -0.0011494247, 0.01639868, 0.022586113, -0.003349328,
      -0.024578229, -0.01542241, 0.013654572, 0.0045746113, 0.010930254,
      -0.009617568, -0.027599385, 0.08126779, 0.03163639, 0.0010158473,
      0.012467218, 0.01630633, 0.009492236, -0.0018651354, -0.0008035254,
      -0.006335853, -0.008291689, 0.004762609, -0.0064578867, -0.013212612,
      -0.036755204, -0.00029993057, -0.0008080604, -0.011952697, 0.01128646,
      -0.019617727, -0.008166357, 0.015250904, -0.0025907406, -0.015303674,
      0.01542241, -0.009709918, 0.02650438, 0.019010859, -0.018298445,
      0.01112155, -0.02275762, -0.011233688, 0.008324672, -0.012256132,
      -0.021345988, -0.01990797, -0.016860427, 0.009320729, 0.0024983909,
      0.013971199, 0.01650422, -0.00084392837, 0.008885366, -0.049182843,
      -0.04324607, -0.01996074, -0.007955272, -0.0034334322, -0.018285252,
      -0.032375187,
    ],
  },
  {
    id: "faq-18",
    question: "Ποια είναι η πολιτική επιστροφών στο carespot.gr;",
    answer:
      "Σε περίπτωση ελαττωματικού προϊόντος, γίνεται άμεση αντικατάσταση χωρίς κόστος. Επικοινωνήστε τηλεφωνικά στο 26440 38033 ή μέσω email στο info@carespot.gr.\n  \n  Για μη ελαττωματικά προϊόντα:\n  - Μπορείτε να επιστρέψετε την παραγγελία εντός 14 ημερολογιακών ημερών με γραπτή ή ηλεκτρονική ειδοποίηση, εφόσον τα προϊόντα είναι στην αρχική τους συσκευασία και αμετάβλητα.\n  - Ο πελάτης επιβαρύνεται με τα έξοδα επιστροφής.\n  - Απαιτείται απόδειξη αγοράς.\n  \n  Η υπαναχώρηση γίνεται με αποστολή δήλωσης ή συμπληρώνοντας τη ΦΟΡΜΑ ΥΠΑΝΑΧΩΡΗΣΗΣ ηλεκτρονικά. Με την ηλεκτρονική υποβολή, λαμβάνετε επιβεβαίωση στο email σας.\n  \n  Σημείωση: Δεν γίνονται επιστροφές σε όλα τα είδη παιδικού γάλακτος και παιδικών τροφών.",
    embedding: [
      0.014852077, -0.0039463327, 0.001784849, -0.030850723, -0.040903207,
      0.005702851, -0.007832671, 0.014758752, 0.007706015, -0.0161453,
      0.021624837, 0.012792251, -0.019811658, -0.0073393793, 0.011752339,
      -0.006119482, 0.013258878, -0.012578936, 0.013865494, 0.0033713814,
      -0.0050329077, 0.0204916, -0.0068027577, -0.009685847, 0.008845919,
      -0.0065727774, 0.0027464344, -0.010372456, 0.020091634, -0.023598004,
      0.005686186, -0.016105304, 0.0066627697, 0.007392708, -0.0054095425,
      -0.0120856445, 0.014732087, -0.0075593605, 0.015878657, -0.007512698,
      0.022171458, -0.0019081719, -0.0046529397, -0.00713273, -0.0062728026,
      0.0070127402, -0.008212639, -0.0038463413, 0.02369133, 0.025891144,
      0.0039463327, 0.021531513, -0.02847759, -0.013785501, 0.032370597,
      -0.014945403, 0.0018831741, 0.011799002, 0.0065794433, -0.0096791815,
      0.024024634, 0.016411945, -0.019731665, 0.0024197954, 0.007992657,
      0.008525945, -0.0050929026, 0.007192725, -0.024824567, -0.00071785593,
      0.029304188, 0.03053075, -0.011592353, -0.039569985, 0.0110324,
      -0.014132138, 0.007466035, -0.011259047, -0.017131884, 0.036476914,
      0.014372118, -0.009092565, -0.0046496065, 0.0027347687, 0.022571424,
      0.0026864395, 0.021784823, 0.026517756, -0.02435794, -0.011145724,
      0.012018983, 0.014572101, 0.036423586, 0.0038596736, -0.027944302,
      0.011459031, -0.011245715, 0.016185299, 0.007892665, -0.011085729,
      0.0069727437, 0.0043096356, -0.010085814, -0.009879164, -0.013472194,
      -0.023291362, 0.0036530243, 0.01351219, 0.021678166, -0.009285881,
      -0.008112648, 0.037623484, -0.01939836, -0.015145386, 0.0021448187,
      0.017678505, 0.0173452, -0.014758752, -0.03106404, -0.0076193553,
      0.008212639, -0.0025031215, 0.010172473, -0.009165891, 0.0022598088,
      -0.0058328398, -0.003633026, -0.0027697657, -0.012532273, -0.011619017,
      0.0010424118, -0.00973251, -0.006049488, 0.0128455805, -0.016718585,
      0.015505355, -0.02163817, 0.006039489, -0.051462315, -0.020891566,
      0.013505524, -0.0032997208, -0.003426377, -0.002336469, -0.00031351513,
      0.0063894596, -0.0026214449, 0.028370934, 0.00026831063, 0.0098191695,
      0.016425278, -0.025624499, 0.013132223, 0.0049862447, -0.022558091,
      0.02387798, -0.006532781, -0.0011882328, -0.0019431689, -0.0216915,
      -0.013545521, -0.007592691, 0.011179054, 0.006049488, -0.010445783,
      0.034157112, 0.008745927, 0.014292125, 0.020358277, 0.013985484,
      -0.025251197, 0.015758667, -0.0016681922, 0.022824736, 0.00001260961,
      0.022384774, -0.007892665, -0.020011641, -0.005686186, 0.0069727437,
      -0.040236596, 0.0028514254, 0.019984975, 0.015025396, 0.0034097116,
      -0.020038305, 0.019385027, 0.0032597242, 0.005316217, -0.0022081465,
      0.0028580916, 0.011645681, -0.02858425, 0.0098191695, -0.6245872,
      -0.024677912, -0.009052567, -0.014705423, 0.03559699, 0.029810812,
      0.015465358, 0.0048729214, -0.011725675, 0.006872752, 0.027730988,
      0.006099484, -0.018238457, 0.002038161, -0.022438101, -0.02013163,
      0.02538452, 0.026744405, 0.026251113, 0.016585264, -0.029464174,
      0.00013144722, -0.002213146, 0.028797565, 0.020918231, 0.022931393,
      -0.0038630066, -0.013492192, -0.03130402, 0.025664495, -0.00737271,
      0.02381132, -0.0043662973, -0.009359208, 0.034530412, 0.010252466,
      -0.018891735, 0.016825244, 0.0092592165, 0.03826343, -0.0406099,
      -0.016171966, 0.032077286, -0.021051552, 0.0005862004, -0.009365874,
      0.0067327637, -0.021251535, 0.030450758, -0.008332629, 0.009192556,
      0.01257227, -0.01819846, -0.010232468, 0.03882338, 0.005979494,
      0.016625261, -0.0322906, -0.018758412, -0.0010599104, -0.0015532019,
      0.02025162, -0.018518433, -0.03583697, 0.014732087, -0.008905914,
      -0.014198799, -0.0036696896, 0.022464767, -0.0051995604, 0.013878826,
      0.016158633, -0.013878826, -0.008772591, -0.006402792, -0.020638254,
      -0.000752853, -0.012758921, -0.0033363844, -0.005722849, -0.022624753,
      -0.020091634, -0.019211708, 0.020051638, 0.008699264, -0.0017165215,
      -0.03258391, -0.006102817, -0.0031230692, 0.01746519, 0.032103952,
      0.03941, 0.017238542, 0.0050929026, -0.018825075, 0.02387798,
      0.0006091152, 0.00973251, -0.012718924, 0.0056295237, -0.0022498097,
      -0.0045662806, -0.011799002, -0.017878488, 0.025984468, 0.017585179,
      -0.025571171, 0.014505439, 0.03234393, -0.035650317, 0.0061494797,
      -0.0015223712, -0.0010965739, -0.018718416, -0.0029347518, -0.032663904,
      0.020531597, 0.010379122, -0.016478606, -0.0014890407, -0.0012948904,
      0.0012132307, 0.019665003, 0.0033213857, 0.012512275, 0.028077625,
      0.018971728, -0.021838153, -0.026891058, -0.017531851, -0.009325878,
      -0.012872244, 0.021971475, -0.009039235, 0.044022944, 0.0008665934,
      -0.008659268, -0.023184706, 0.016438609, -0.0053528803, -0.0023298028,
      -0.018851738, 0.014078809, -0.019305034, -0.020118298, -0.02991747,
      -0.004732933, -0.000028435095, -0.022438101, 0.033250522, 0.0001962334,
      -0.0070993993, 0.008052652, 0.021184875, -0.01481208, 0.0055528637,
      -0.024384605, 0.0062028086, -0.009719178, -0.005009576, 0.00870593,
      0.008305964, -0.021211538, -0.018211793, -0.028450927, -0.0016315287,
      0.008212639, -0.011632349, -0.0034463753, -0.03591696, 0.009279215,
      0.0058128415, -0.02278474, 0.023864647, 0.0065894425, 0.0022914729,
      0.004612943, -0.00042871374, 0.019131714, -0.008325962, -0.015865324,
      -0.011119059, -0.03807678, 0.0002731019, -0.0068927505, 0.017425193,
      0.0010232468, 0.03487705, -0.020051638, 0.027304357, 0.0021314863,
      0.0015090391, 0.0059294985, 0.02199814, 0.028210947, -0.0062594707,
      0.02139819, 0.024251282, -0.003049742, 0.018665088, 0.05247556,
      0.014572101, 0.039756637, -0.013732172, 0.026091127, -0.017785162,
      -0.0076060235, -0.015465358, 0.037756808, 0.009899163, 0.0068927505,
      -0.0019764996, -0.008799256, -0.013692175, -0.027597666, 0.014572101,
      -0.012872244, 0.0037463498, 0.010459116, 0.014065477, 0.020624923,
      0.0020114966, -0.0094592, -0.0117590055, -0.029544167, 0.006296134,
      -0.010812419, 0.018531766, 0.016878573, -0.011672346, -0.025011217,
      -0.003274723, -0.0169319, 0.034610406, 0.03869006, -0.019625006,
      -0.0035030372, -0.0046429406, 0.022944726, 0.01217897, 0.01590532,
      0.021731496, 0.013538855, 0.014558769, 0.0042263092, -0.010479114,
      0.033250522, 0.011352373, -0.01474542, 0.0033997125, -0.017878488,
      -0.008179308, 0.010799087, -0.0118523305, 0.02466458, -0.017558515,
      0.008845919, 0.0048795873, 0.007259386, 0.03234393, -0.00088242535,
      -0.015745334, 0.013232214, -0.010059149, -0.0000515321, 0.0026864395,
      -0.038903378, -0.003633026, 0.0034163776, -0.014598765, 0.003426377,
      -0.0068927505, 0.020571593, 0.011838999, 0.0013623848, -0.012978902,
      0.022171458, 0.008872583, 0.009812503, -0.013218882, -0.028077625,
      -0.05186228, 0.016331952, 0.017198546, -0.017731834, -0.029784147,
      -0.02610446, 0.016638592, -0.03615694, 0.020838238, -0.026011134,
      0.019758329, -0.001477375, 0.0023214703, 0.008852584, -0.03855674,
      0.031117368, 0.010599104, 0.0013498858, -0.011485695, 0.0058861687,
      0.00018623425, 0.024891227, -0.011345707, 0.03845008, 0.009652517,
      -0.01583866, -0.011639016, -0.0019615008, -0.03239726, 0.0050729043,
      -0.008445952, 0.005839506, 0.002354801, -0.006532781, 0.0043596313,
      -0.006672769, 0.010012486, 0.023118045, -0.009025903, -0.018598426,
      -0.024371272, -0.008299298, -0.0029780813, 0.052262247, 0.030717403,
      0.00089409103, 0.014572101, -0.0039863293, -0.0021198208, -0.0009515862,
      -0.036050282, -0.012165638, -0.017478522, -0.021891482, 0.0010374122,
      0.0051462315, -0.015492023, 0.017571848, 0.022078132, 0.01619863,
      0.016891904, 0.018158464, -0.016345285, 0.0033830472, -0.0031897302,
      0.00037330177, 0.0116656795, -0.023478014, -0.019878319, 0.01269226,
      -0.009465866, -0.004866255, -0.014505439, 0.011045733, 0.0049562473,
      0.0051795617, -0.000077024735, -0.039143357, 0.00915256, -0.00039746639,
      0.017185213, 0.0121323075, 0.015878657, 0.0061761444, 0.014532104,
      0.0031880636, 0.014092142, 0.021384858, -0.016971897, 0.011372372,
      0.032983877, 0.0016240292, -0.030104121, 0.02702438, -0.011599019,
      -0.013198883, -0.004542949, 0.018878404, -0.0062028086, -0.0075260303,
      0.012412284, 0.0008549277, -0.01257227, -0.01910505, -0.027064377,
      -0.0189184, -0.00065952755, -0.009299213, -0.026011134, 0.0044129603,
      -0.004689603, -0.00015873657, -0.016571932, 0.022598088, -0.038610067,
      -0.019864986, -0.021104882, 0.043782964, 0.025771154, 0.024557922,
      0.004522951, 0.014638762, 0.029117538, -0.008839252, -0.019238373,
      -0.020144964, -0.024197953, 0.0014690424, 0.0016115303, -0.0084992815,
      -0.032903884, 0.0037663481, 0.0378368, -0.0030197445, 0.014558769,
      -0.0028280942, -0.0082659675, -0.032903884, 0.003303054, 0.005252889,
      0.020038305, 0.0013482193, -0.0057361815, 0.029704154, 0.009565857,
      -0.04303636, -0.009039235, -0.012245631, 0.021864817, 0.013065562,
      -0.025397852, 0.0044329586, 0.000005767741, 0.014238795, -0.011652348,
      0.008912579, -0.016358616, -0.0051795617, 0.010565773, 0.0035197022,
      0.031890634, -0.006199476, 0.0042629726, 0.010519111, -0.03149067,
      0.013525522, 0.01027913, -0.010992403, 0.007019406, -0.0032447255,
      -0.00022873066, 0.006552779, -0.013865494, -0.00543954, 0.030077456,
      -0.0005432874, -0.0152520435, -0.011192387, 0.0048929197, -0.005459538,
      0.019904982, -0.00003215874, 0.0042363084, -0.04431625, 0.006569444,
      -0.012165638, -0.019958312, -0.0005891168, -0.033277184, 0.0113390405,
      -0.016545268, 0.012292294, 0.005116234, -0.008525945, 0.0153453685,
      -0.041009866, -0.0093058795, 0.029010879, -0.009239218, 0.019998308,
      -0.023864647, 0.008732595, 0.018985061, 0.028317604, 0.011205719,
      0.0075593605, 0.0022281448, 0.008025988, -0.01517205, -0.004812926,
      -0.009752508, -0.021838153, 0.017998477, 0.027837645, 0.009925827,
      0.008619271, 0.003959665, -0.005499535, -0.011699011, -0.010952407,
      -0.027277693, -0.038343422, -0.029970799, -0.012585602, -0.010652432,
      0.02242477, 0.010045817, -0.011519026, 0.00685942, 0.002754767,
      -0.002618112, 0.0065761106, 0.011645681, 0.02714437, -0.03205062,
      -0.0067060995, 0.00038913375, -0.007892665, 0.0039929957, -0.014452111,
      -0.013892158, -0.013812165, 0.016718585, -0.0127989175, -0.0012273962,
      -0.013278876, 0.007826005, -0.011932324, 0.0038163438, 0.009625852,
      0.01741186, -0.012272296, -0.0010415786, -0.0069860755, -0.013252213,
      -0.033383843, -0.023544675, -0.002789764, 0.003046409, -0.044156265,
      0.023664664, 0.0032380593, -0.010839083, -0.009512529, -0.0012823915,
      0.016118636, 0.0043596313, 0.033250522, 0.038423415, 0.0053395485,
      -0.030424094, -0.021718163, -0.006096151, -0.023518011, 0.024384605,
      0.030504087, 0.0031014043, -0.040929873, -0.015492023, 0.030104121,
      -0.0012798917, -0.0028714237, 0.019051721, -0.0018148465, 0.028664242,
      -0.0033713814, 0.025837814, -0.01607864, 0.029357517, 0.00001408735,
      -0.011912325, -0.0058028423, -0.009665849, -0.012005651, 0.013092226,
      -0.002944751, 0.022864733, -0.022558091, -0.009752508, -0.009985822,
      -0.0334905, 0.006276136, 0.039543323, 0.006359462, 0.015918653,
      0.0027181034, 0.011105727, 0.015931986, -0.019944979, 0.0021131546,
      -0.010592437, -0.015465358, 0.023118045, 0.0028464259, -0.018505102,
      -0.0036530243, 0.008199306, 0.016798578, 0.0022731412, 0.004452957,
      -0.0005116234, -0.008385957, -0.023064716, 0.027411016, -0.004002995,
      -0.059568293, -0.0072927163, 0.009565857, 0.0001030642, -0.013492192,
      -0.009565857, 0.034743726, 0.015918653, -0.011259047, 0.003078073,
      -0.009825836, 0.019891651, 0.00855261, 0.016358616, 0.009092565,
      0.022278115, -0.023984637, -0.0049062516, 0.0004999577, -0.003398046,
      -0.021144878, 0.022358108, -0.019251704, -0.026917724, 0.056421895,
      0.014585433, -0.008619271, -0.022291448, 0.0107657565, 0.0139321545,
      0.011505693, 0.015732003, -0.00076743506, 0.027837645, 0.0036563573,
      -0.013565519, -0.014425446, 0.006402792, -0.020078301, -0.019718332,
      -0.013705508, -0.031623993, 0.009652517, 0.0030547415, 0.0064927842,
      0.023238035, -0.0065127825, -0.016998563, 0.010259132, 0.018478436,
      -0.037330177, -0.019984975, 0.013852161, -0.036450252, -0.016585264,
      0.028130954, 0.005599526, -0.035037037, 0.008379291, 0.008512613,
      -0.00915256, 0.024771238, -0.010172473, -0.014838745, -0.0059095,
      -0.018985061, -0.007432705, 0.011019068, -0.031943966, 0.025184536,
      0.023504678, -0.048049267, -0.000859094, 0.001404048, -0.010499112,
      0.0035330346, 0.01151236, 0.026464429, 0.0028297605, 0.0044129603,
      -0.0086392695, -0.0014307123, 0.008505947, 0.016838577, -0.031330682,
      0.027917638, -0.016291955, -0.02495789, -0.009345876, -0.013445529,
      -0.027357686, -0.0014907073, 0.03354383, 0.0069794096, 0.01982499,
      0.048502564, -0.0041029863, -0.03661024, 0.000050152008, -0.0093058795,
      -0.0030080788, 0.0015698673, -0.008645935, -0.041489825, 0.02357134,
      0.010872413, 0.024304612, -0.041036528, 0.006552779, -0.00021289865,
      -0.020398274, 0.004619609, -0.01009248, -0.03717019, 0.0019165046,
      -0.017371863, 0.009552525, 0.028850893, -0.01801181, -0.00049370824,
      0.02151818, -0.016425278, -0.03535701, 0.009312546, -0.00271977,
      -0.02423795, 0.00909923, -0.021411521, 0.025504509, -0.003893004,
      0.018585095, -0.013325539, 0.0024431266, -0.018478436, 0.008919246,
      -0.019691668, 0.014225463, 0.03559699, -0.042876374, 0.0034163776,
      0.022558091, 0.015198714, -0.02834427, -0.024544591, -0.002698105,
      -0.020891566, 0.017931817, 0.031037375, -0.01320555, -0.0041929786,
      0.008852584, 0.00081493106, -0.021744827, 0.039623316, 0.24168622,
      -0.019718332, -0.0041829795, 0.025397852, 0.0030714069, -0.00071410625,
      0.03434376, 0.011225717, -0.0018831741, -0.00212982, 0.00052578887,
      -0.0040529906, -0.028317604, -0.0018698418, -0.02321137, -0.0271977,
      -0.025784485, -0.025477845, -0.027491009, -0.0028514254, 0.0061494797,
      0.025877811, 0.024424601, 0.018745081, 0.0094458675, 0.021811489,
      -0.014772084, -0.0037896794, 0.0044429577, 0.022944726, -0.009499197,
      -0.015265375, -0.0074393707, -0.0053362153, -0.013878826, 0.0057361815,
      0.02533119, -0.0041296505, 0.010405786, -0.008692598, -0.0032997208,
      0.0097258445, -0.0014215464, 0.020024972, -0.031037375, -0.00484959,
      -0.0028930886, -0.012445614, 0.005649522, 0.024211286, -0.021624837,
      0.0042529735, 0.03511703, 0.01946502, -0.003426377, 0.004599611,
      -0.012878911, 0.021011556, -0.0068460875, 0.0051528974, 0.009959158,
      0.035170358, 0.0046229423, 0.033597156, -0.008679266, -0.008645935,
      -0.015052061, 0.003689688, 0.0075326962, -0.025851147, 0.02158484,
      -0.013825498, 0.0121323075, -0.0022014806, -0.016491938, -0.0031880636,
      0.010045817, 0.043782964, 0.035410337, 0.012545605, -0.0008032654,
      0.014878741, -0.008045986, -0.008365959, 0.017971814, -0.023504678,
      0.022464767, -0.0017448524, -0.008119313, -0.012685593, 0.0053462144,
      0.015745334, -0.020904899, -0.017691838, 0.0204916, 0.0038463413,
      0.013918823, 0.038423415, -0.03130402, 0.012852246, -0.01351219,
      0.06111483, 0.022798073, 0.020344947, 0.010265798, 0.011132392,
      0.0015307039, 0.027757652, 0.00094991963, -0.028664242, -0.0020648253,
      -0.026757738, 0.009572524, -0.015425362, -0.0017115219, -0.0038596736,
      0.013998816, -0.018425109, 0.020118298, -0.0067227646, -0.015625345,
      -0.0018081804, -0.026091127, 0.013318873, 0.00030414094, -0.018838407,
      -0.029064208, -0.001572367, 0.00074035407, -0.030504087, 0.0220648,
      -0.023424685, 0.0071993913, 0.019851655, -0.014905406, -0.015811997,
      -0.011819, -0.02810429, 0.0065827766, 0.02025162, 0.00543954,
      0.0023564673, -0.018598426, -0.020398274, -0.002073158, 0.0021864818,
      -0.0055228663, 0.0153453685, -0.006682768, -0.01036579, -0.01474542,
      -0.0010099146, -0.0072260555, -0.0056695202, 0.017478522, -0.012958904,
      -0.015278707, -0.028210947, 0.008912579, 0.025357855, -0.0338638,
      -0.00008447202, 0.025971137, -0.015705338, -0.011179054, -0.001375717,
      -0.16979897, 0.013192218, 0.025517842, -0.02170483, 0.006796092,
      0.013798833, 0.028930886, 0.034130447, -0.005306218, -0.0012748921,
      0.031384014, 0.027304357, -0.035570323, -0.0042563067, -0.008832586,
      -0.022118129, -0.037943456, 0.021304864, 0.018945064, 0.013452196,
      0.024037967, -0.025517842, 0.0020914897, 0.010772422, 0.0009107563,
      -0.0052095596, -0.014358786, 0.018065138, 0.009545859, -0.01015914,
      -0.0040063276, 0.015132054, 0.034130447, 0.005819508, -0.0064394553,
      -0.013505524, -0.0058761695, -0.019691668, -0.009005905, 0.023677997,
      0.022504764, 0.019958312, 0.010632434, 0.022504764, -0.005129566,
      0.020904899, 0.022598088, 0.0019431689, 0.0048162593, 0.004476288,
      -0.016811911, -0.0059928265, 0.011005736, -0.0057195164, 0.02357134,
      0.022451434, 0.026731072, 0.017065223, -0.006199476, 0.012765587,
      -0.028557584, -0.019131714, -0.0004878754, -0.01710522, 0.003956332,
      -0.017785162, 0.007206057, -0.0016873573, -0.027091041, 0.00536288,
      -0.011319042, -0.0075793588, 0.0056061926, -0.0115256915, 0.021011556,
      0.009545859, -0.017691838, -0.009012571, 0.02273141, -0.0117590055,
      -0.011492361, 0.026984384, -0.01595865, 0.0074793673, -0.009165891,
      -0.00015550767, 0.0153453685, 0.017371863, -0.036556907, -0.017118553,
      -0.018758412, -0.02973082, 0.018625092, -0.03082406, 0.018691752,
      0.015465358, -0.0040163267, 0.022744743, 0.0023514677, 0.010259132,
      0.017265206, -0.013905491, -0.038370088, 0.032663904, 0.013812165,
      0.017945148, -0.017491855, 0.040903207, 0.033383843, 0.006612774,
      -0.0013998816, 0.013985484, 0.015572016, 0.020038305, -0.009399205,
      0.008532612, -0.010392454, -0.025704492, 0.018665088, -0.018345116,
      0.048209257, -0.04132984, 0.008892581, -0.013445529, -0.008812588,
      -0.0109057445, -0.07610023, -0.0070993993, 0.002044827, -0.017998477,
      -0.0021631503, 0.022158125, -0.007599357, 0.00843262, -0.027837645,
      -0.0009140893, -0.0055595296, -0.040343255, -0.0082193045, -0.0034697065,
      0.019864986, 0.01849177, 0.0002439377, -0.014545436, 0.006726098,
      0.021904813, 0.03202396, -0.024597919, -0.010465781, 0.015318704,
      -0.029570833, 0.02834427, -0.0038196768, 0.016225295, 0.026544422,
      0.007965993, -0.01705189, -0.02369133, 0.0011265713, -0.008732595,
      0.008699264, -0.021891482, -0.022824736, -0.01631862, -0.022771407,
      -0.032983877, 0.0069060824, -0.022224788, 0.009945826, -0.036076948,
      -0.020384943, 0.0048095933, 0.00713273, 0.008505947, 0.0029347518,
      -0.005969495, -0.028557584, -0.01741186, -0.011312377, -0.017091887,
      -0.00096325186, 0.0035330346, -0.009012571, 0.010972405, -0.016225295,
      -0.0045029526, -0.024411269, 0.000121656376, -0.022371441, 0.008772591,
      0.047036022, 0.007659352, -0.013705508, -0.028877558, 0.004586279,
      -0.008665933, -0.0044296253, 0.0074793673, -0.0023148041, 0.025717825,
      -0.032983877, -0.028637577, -0.01830512, -0.0038863379, 0.035516996,
      -0.010005821, -0.0052428897, -0.0033547163, 0.021104882, -0.0248379,
      -0.0048395908, 0.038396753, 0.010145809, -0.00021581507, 0.0080326535,
      -0.027411016, -0.0047929278, 0.02030495, 0.015358701, -0.0048095933,
      -0.023038052, 0.00028705905, 0.00043163015, -0.0001332179, -0.00048287582,
      -0.0063994587, -0.030664073, 0.0028197614, -0.06815424, -0.0026564419,
      -0.011239049, -0.013878826, -0.030744066, -0.019265037, 0.01710522,
      0.017838491, -0.018385112, 0.00027622664, -0.026264446, -0.0040629897,
      0.0029780813, -0.011405702, -0.043249674, -0.007959327, 0.02031828,
      0.0072260555, 0.024291279, 0.005126233, -0.0072260555, 0.011472363,
      0.006402792, -0.007726013, -0.022638084, 0.032477252, -0.0028230946,
      0.02495789, -0.011932324, -0.012145639, 0.010359123, -0.020478267,
      -0.023491347, 0.0036130277, 0.006109483, 0.0077460115, 0.00516623,
      0.029304188, 0.015825327, 0.05052906, -0.015851993, -0.029410847,
      0.0016873573, -0.02581115, 0.0010749091, 0.013325539, -0.029064208,
      -0.013152221, 0.020024972, 0.008992572, 0.010025819, -0.0013357203,
      0.0169319, -0.015558684, 0.00081868074, -0.029570833, 0.0068927505,
      0.0039929957, 0.015505355, -0.03058408, 0.015185382, 0.0065094493,
      0.006429456, -0.016998563, -0.0008049319, -0.019798325, -0.0059961593,
      -0.028450927, -0.004866255, -0.02605113, -0.015572016, 0.014465443,
      0.006486118, 0.040929873, -0.02037161, 0.004212977, -0.007246054,
      -0.008659268, 0.0046996027, -0.0027264361, -0.008012655, -0.007459369,
      -0.037863463, 0.021078218, 0.026491093, 0.028210947, -0.0074793673,
      0.0015032062, -0.021331528, -0.0002626861, -0.0015781998, 0.011265714,
      -0.020571593, -0.023278031, 0.0014582099, -0.012698926, 0.014092142,
      -0.018625092, 0.0019764996, 0.043809626, 0.0034663735, 0.0023814652,
      0.0066794353, -0.023371356, -0.025824482, 0.015585348, -0.004902919,
      -0.043969613, 0.01686524, 0.01703856, 0.014652094, -0.0017681838,
      0.0012732256, 0.006879418, -0.016385281, 0.027810981, 0.012772253,
      -0.0149720665, -0.015918653, 0.005659521, 0.00909923, -0.0004520451,
      0.014532104, 0.007332713, 0.023264699, -0.0037796802, 0.026344439,
      -0.011519026, -0.007052737, 0.016451942, 0.019478353, 0.0018115134,
      -0.023424685, -0.021651503, 0.0072527197, 0.006156146, 0.0072860504,
      -0.008632603, -0.031037375, 0.083139636, 0.02962416, -0.00049829116,
      0.01154569, 0.010752424, 0.01311889, -0.003426377, 0.007759344,
      -0.010225802, -0.014665426, 0.007872667, -0.012838914, -0.012525607,
      -0.037383504, -0.0072860504, 0.0014957068, -0.015598681, 0.0061494797,
      -0.01072576, -0.014238795, 0.017025227, -0.006472786, -0.008179308,
      0.019491686, -0.0096325185, 0.026037797, 0.027517673, -0.021318197,
      0.005599526, -0.020238288, -0.014478776, 0.015185382, -0.0154386945,
      -0.029997462, -0.01583866, -0.014945403, 0.012645597, 0.0029647492,
      0.014132138, 0.013178885, 0.0046929363, 0.0061428137, -0.043622978,
      -0.049755793, -0.016265292, -0.0126056, 0.0040529906, -0.018985061,
      -0.0259978,
    ],
  },
];
