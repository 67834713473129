import axios from "axios";
import { FAQS } from "../embeddings/embedded-faqs";
import { FAQWithEmbedding } from "../scripts/generate-faq-embeddings";
import { getEmbedding, cosineSimilarity } from "./embedding-utils";
import { PromptItemRole, PromptItemType } from "../store";
import { ulid } from "ulid";

export async function getOrderStatus(orderId: string) {
  try {
    const response = await axios.get<{
      order: {
        statuses: Array<{
          title: string;
          pivot: { updated_at: string };
        }>;
        box_now?: {
          boxnowLockerName?: string;
        };
        gifts: Array<{ name: string }>;
        courier_url?: string;
        send_address: {
          street: string;
          streetNumber: string;
          city: string;
        };
        invoice_id?: string;
        sum_total: number;
      };
    }>(`https://api.carespot.gr/api/track-order/${orderId}`);

    const order = response.data.order;
    const currentStatus = order.statuses[order.statuses.length - 1];

    return {
      status: currentStatus.title,
      estimatedDelivery: order.box_now?.boxnowLockerName
        ? `Παραλαβή από ${order.box_now.boxnowLockerName}`
        : null,
      items:
        order.gifts.length > 0
          ? order.gifts.map((g: { name: string }) => g.name) // Explicitly typed
          : ["Δεν διαθέτουμε λίστα προϊόντων"],
      courierUrl: order.courier_url,
      lastUpdated: currentStatus.pivot.updated_at,
      address: order.send_address,
      invoiceId: order.invoice_id,
      totalAmount: order.sum_total,
    };
  } catch (error) {
    console.error("Order status error:", error);
    return { error: true };
  }
}

export const findMostRelevantFaq = async (
  prompt: string,
  threshold = 0.8
): Promise<FAQWithEmbedding | null> => {
  const promptEmbedding = await getEmbedding(prompt);

  let bestMatch: FAQWithEmbedding | null = null;
  let bestScore = threshold;

  for (const faq of FAQS) {
    if (!faq.embedding) continue;

    const similarity = cosineSimilarity(promptEmbedding, faq.embedding);
    if (similarity > bestScore) {
      bestScore = similarity;
      bestMatch = faq;
    }
  }

  return bestMatch;
};

export const detectOrderRequest = async (
  prompt: string
): Promise<{
  isOrderRequest: boolean;
  orderId: string | null;
}> => {
  const trimmed = prompt.trim();

  // ✅ Case 1: User only typed the order number
  if (/^\d{6,10}$/.test(trimmed)) {
    return {
      isOrderRequest: true,
      orderId: trimmed,
    };
  }

  try {
    const { data } = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: `You are an order status detection system. Analyze if the Greek prompt requests order tracking.
            Return JSON with:
            - isOrderRequest: boolean
            - orderId: string or null
            
            Examples:
            ${JSON.stringify({
              "Μπορείς να μου πεις την κατάσταση της παραγγελίας 24114513;": {
                isOrderRequest: true,
                orderId: "24114513",
              },
              "Που είναι η παραγγελία μου;": {
                isOrderRequest: true,
                orderId: null,
              },
              "Πως παραγγέλνω προϊόντα;": {
                isOrderRequest: false,
                orderId: null,
              },
            })}
            Current prompt: ${prompt}`,
          },
          { role: "user", content: prompt },
        ],
        response_format: { type: "json_object" },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const response = data.choices[0]?.message?.content ?? "{}";
    const result = JSON.parse(response);

    return {
      isOrderRequest: result.isOrderRequest || false,
      orderId: result.orderId || null,
    };
  } catch (err) {
    console.error("Order detection error:", err);
    return {
      isOrderRequest: false,
      orderId: null,
    };
  }
};

export function createOrderStatusResponse(orderId: string, status: any) {
  if (status.error) {
    return {
      id: ulid(),
      type: PromptItemType.Message,
      role: PromptItemRole.Bot,
      text: `❌ **Δεν βρέθηκε παραγγελία με αριθμό ${orderId}**.\nΠαρακαλώ ελέγξτε τον αριθμό και δοκιμάστε ξανά.`,
      timestamp: Date.now(),
    };
  }

  const responseText = `
  📦 **Κατάσταση Παραγγελίας #${orderId}**
  
  **Τρέχουσα Κατάσταση:** ${status.status}
  
  **Τελευταία Ενημέρωση:** ${new Date(status.lastUpdated).toLocaleString("el-GR")}
  
  🚚 **Στοιχεία Παράδοσης**  
  • Διεύθυνση: ${status.address.street} ${status.address.streetNumber}, ${status.address.city}  
  • Τρόπος Αποστολής: ${status.estimatedDelivery || "Σε διαδικασία κανονισμού"}  
  ${status.courierUrl ? `• Παρακολούθηση: [Παρακολούθηση Αποστολής](${status.courierUrl})` : ""}
  
  💶 **Οικονομικά Στοιχεία**  
  • Σύνολο: ${status.totalAmount}€  
  • Αριθμός Τιμολογίου: ${status.invoiceId || "Σε διαδικασία έκδοσης"}
  `;

  return {
    id: ulid(),
    type: PromptItemType.Message,
    role: PromptItemRole.Bot,
    text: responseText,
    timestamp: Date.now(),
  };
}

export function createOrderIdPrompt() {
  return {
    id: ulid(),
    type: PromptItemType.Message,
    role: PromptItemRole.Bot,
    text: "Παρακαλώ δώστε τον αριθμό παραγγελίας σας (6-8 ψηφία) για να ελέγξω την κατάσταση. Π.χ.: 24114513",
    timestamp: Date.now(),
  };
}
