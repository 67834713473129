import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ulid } from "ulid";

export enum PromptItemType {
  Messages = "messages",
  Message = "message",
  Image = "image",
  Products = "products",
  Buttons = "buttons",
}

export enum PromptItemRole {
  User = "user",
  Bot = "bot",
}

export type PromptItemMessage = {
  id: string;
  group_id?: string;
  text: string;
  timestamp: number;
};

export type PromptButtonItem = {
  id: string;
  text: string;
};

export type PromptProductItem = {
  id: string;
  group_id?: string;
  details: {
    title: string;
    brand: string;
    url: string;
    image: string;
    price: number;
    reviewsCount: number;
    reviewsRating: number;
  };
};

export type PromptImageItem = {
  id: string;
  group_id?: string;
  src: string;
  timestamp: number;
};

interface PromptItem {
  id: string;
  group_id?: string;
  type: PromptItemType;
  options?:
    | Array<PromptItemMessage>
    | Array<PromptButtonItem>
    | Array<PromptProductItem>
    | Array<PromptImageItem>;
  hint?: string;
  role: PromptItemRole;
  text?: string;
  src?: string;
  timestamp?: number;
}

export type Thread = Array<PromptItem>;

export enum FunctionIntentType {
  OrderStatus = "order-status",
}

export const FUNC_INTENTS: Array<FunctionIntentType> = [
  FunctionIntentType.OrderStatus,
];

export enum FaqIntentType {
  ShippingTime = "shipping-time",
  RewardProgram = "reward-program",
  HowToOrder = "how-to-order",
  AccountCreation = "account-creation",
  Newsletter = "newsletter",
  ProductAvailability = "product-availability",
  PaymentOptions = "payment-options",
  DeliveryTime = "delivery-time",
  CancelOrder = "cancel-order",
  ReturnPolicy = "return-policy",
  Contact = "contact",
  Privacy = "privacy",
  ShippingVsCashOnDelivery = "shipping-vs-cash-on-delivery",
  ShippingMethods = "shipping-methods",
  PaymentMethods = "payment-methods",
  ShippingPolicy = "shipping-policy",
  ReturnPolicyDetails = "return-policy-details",
}

export const FAQ_INTENTS: Array<FaqIntentType> = [
  FaqIntentType.ShippingTime,
  FaqIntentType.RewardProgram,
  FaqIntentType.HowToOrder,
  FaqIntentType.AccountCreation,
  FaqIntentType.Newsletter,
  FaqIntentType.ProductAvailability,
  FaqIntentType.PaymentOptions,
  FaqIntentType.DeliveryTime,
  FaqIntentType.CancelOrder,
  FaqIntentType.ReturnPolicy,
  FaqIntentType.Contact,
  FaqIntentType.Privacy,
  FaqIntentType.ShippingVsCashOnDelivery,
  FaqIntentType.ShippingMethods,
  FaqIntentType.PaymentMethods,
  FaqIntentType.ShippingPolicy,
  FaqIntentType.ReturnPolicyDetails,
];

export enum IntentItemType {
  FaceCream = "face-cream",
  HairShampoo = "hair-shampoo",
  KidsSunscreen = "kids-sunscreen",
  WinterVitamins = "winter-vitamins",
  FluProtection = "flu-protection",
}

export const INTENTS: Array<IntentItemType> = [
  IntentItemType.FaceCream,
  IntentItemType.HairShampoo,
  IntentItemType.KidsSunscreen,
  IntentItemType.WinterVitamins,
  IntentItemType.FluProtection,
];

export const PRODUCTS = [
  {
    id: "116546",
    details: {
      title:
        "APIVITA - Beevine Elixir Replinshing Firming Face Oil Έλαιο Προσώπου για Αναδόμηση & Σύσφιξη - 30ml",
      brand: "Apivita",
      url: "https://carespot.gr/apivita/apivita-beevine-elixir-face-oil-anadomhsh-and-sysfi3h-30ml",
      price: 28.92,
      image:
        "https://api.carespot.gr/image/product/apivita-beevine-elixir-face-oil-anadomhsh-and-sysfi3h-30ml-AIFDgH.jpg?x=400&y=400",
      intent: IntentItemType.FaceCream,
      reviewsCount: 124,
      reviewsRating: 4.8,
    },
  },
  {
    id: "116543",
    details: {
      title:
        "APIVITA - Beevine Elixir Wrinkle & Firmness Lift Cream Light Ελαφριά Αντιρυτιδική Κρέμα Σύσφιξης & Lifting - 50ml",
      brand: "Apivita",
      url: "https://carespot.gr/apivita/apivita-beevine-elixir-cream-light-sysfi3h-and-lifting-50ml",
      price: 27.6,
      image:
        "https://api.carespot.gr/image/product/apivita-beevine-elixir-cream-light-sysfi3h-and-lifting-50ml-AGi9IQ.jpg?x=400&y=400",
      intent: IntentItemType.FaceCream,
      reviewsCount: 719,
      reviewsRating: 4.6,
    },
  },
  {
    id: "116338",
    details: {
      title: "APIVITA - Cream with Eucalyptus Κρέμα με Ευκάλυπτο - 40ml",
      brand: "Apivita",
      url: "https://carespot.gr/apivita/apivita-cream-with-eucalyptus-krema-me-eukalipto-40ml",
      price: 6.04,
      image:
        "https://api.carespot.gr/image/product/apivita-cream-with-eucalyptus-krema-me-eukalipto-40ml-qeDQQ7.jpg?x=400&y=400",
      intent: IntentItemType.FaceCream,
      reviewsCount: 382,
      reviewsRating: 4.7,
    },
  },
  {
    id: "116337",
    details: {
      title: "APIVITA - Cream with Propolis Κρέμα με Πρόπολη - 40ml",
      brand: "Apivita",
      url: "https://carespot.gr/apivita/apivita-cream-with-propolis-krema-me-propoli-40ml",
      price: 6.01,
      image:
        "https://res.cloudinary.com/carespot-gr/image/upload/v1693923001/eshop/apivita-cream-with-propolis-krema-me-propoli-40ml-pzrtYU.jpg",
      intent: IntentItemType.FaceCream,
      reviewsCount: 1945,
      reviewsRating: 4.4,
    },
  },
  {
    id: "117015",
    details: {
      title: "KORRES Yoghurt Kids Sunscreen Παιδικό Αντηλιακό SPF50 200ml",
      brand: "Korres",
      url: "https://carespot.gr/korres/korres-yoghurt-kids-sunscreen-paidiko-antiliako-spf50-200ml/",
      price: 14.42,
      image:
        "https://api.carespot.gr/image/product/korres-yoghurt-kids-sunscreen-paidiko-antiliako-spf50-200ml-jFfBQE.jpeg?x=400&y=400",
      intent: IntentItemType.KidsSunscreen,
      reviewsCount: 839,
      reviewsRating: 4.8,
    },
  },
  {
    id: "103415",
    details: {
      title:
        "FREZYDERM - Kids Sun Care SPF50+ Wet Skin Spray Παιδικό Αντιηλιακό Spray για Πρόσωπο & Σώμα - 200ml",
      brand: "FREZYDERM",
      url: "https://carespot.gr/frezyderm/frezyderm-kids-sun-care-spf50-wet-skin-spray-prosopo-soma-200ml/",
      price: 14.91,
      image:
        "https://api.carespot.gr/image/product/frezyderm-kids-sun-care-spf50-wet-skin-spray-prosopo-soma-200ml-gpoiKo.jpg?x=400&y=400",
      intent: IntentItemType.KidsSunscreen,
      reviewsCount: 1520,
      reviewsRating: 4.9,
    },
  },
  {
    id: "114274",
    details: {
      title:
        "FREZYDERM - Kids Sun Care Cream Spray Παιδική Αντηλιακή Κρέμα Spray SPF50+ - 275ml",
      brand: "FREZYDERM",
      url: "https://carespot.gr/frezyderm/frezyderm-kids-sun-care-cream-spray-paidiko-antiliako-spf50-275ml/",
      price: 16.86,
      image:
        "https://api.carespot.gr/image/product/frezyderm-kids-sun-care-cream-spray-paidiko-antiliako-spf50-275ml-RPnu9w.png?x=400&y=400",
      intent: IntentItemType.KidsSunscreen,
      reviewsCount: 2328,
      reviewsRating: 4.7,
    },
  },
  {
    id: "103598",
    details: {
      title:
        "CARESPOT - Anti Hair Loss Shampoo Σαμπουάν κατά της Τριχόπτωσης - 250ml",
      brand: "CARESPOT",
      url: "https://carespot.gr/carespot/carespot-anti-hair-loss-shampoo-gia-trichoptosi-250ml/",
      price: 10.9,
      image:
        "https://api.carespot.gr/image/product/carespot-anti-hair-loss-shampoo-gia-trichoptosi-250ml-RqDBbs.jpg?x=400&y=400",
      intent: IntentItemType.HairShampoo,
      reviewsCount: 2317,
      reviewsRating: 4.9,
    },
  },
  {
    id: "112764",
    details: {
      title:
        "BIORGA - Cystiphane Anti-Hair Loss Shampoo Σαμπουάν κατά της Τριχόπτωσης για Άνδρες & Γυναίκες - 200ml",
      brand: "BIORGA",
      url: "https://carespot.gr/biorga/biorga-cystiphane-anti-hair-loss-shampoo-kata-tis-trichoptosis-200ml/",
      price: 14.36,
      image:
        "https://api.carespot.gr/image/product/biorga-cystiphane-anti-hair-loss-shampoo-kata-tis-trichoptosis-200ml-MGeen9.jpg?x=400&y=400",
      intent: IntentItemType.HairShampoo,
      reviewsCount: 2958,
      reviewsRating: 4.8,
    },
  },
  {
    id: "104888",
    details: {
      title:
        "FROIKA - Anti Hair Loss Peptide Shampoo / Πεπτιδιακό Σαμπουάν με Διεγερτική & Προστατευτική Δράση για Λεπτά/Αδύναμα Μαλλιά με τάση Τριχόπτωσης - 200ml",
      brand: "FROIKA",
      url: "https://carespot.gr/froika/froika-anti-hair-loss-peptide-shampoo-trichoptosi-200ml/",
      price: 12.05,
      image:
        "https://api.carespot.gr/image/product/froika-anti-hair-loss-peptide-shampoo-peptidiako-sampouan-200ml-WOHsrj.jpg?x=400&y=400",
      intent: IntentItemType.HairShampoo,
      reviewsCount: 2712,
      reviewsRating: 4.7,
    },
  },
  {
    id: "111793",
    details: {
      title:
        "GARDEN - Multi Vitamins & Minerals Πορτοκάλι-Γκρέιπφρουτ - 20eff.tabs",
      brand: "GARDEN",
      url: "https://carespot.gr/garden/garden-multi-vitamins-minerals-portokali-gkeipfrout-20tabs/",
      price: 7.36,
      image:
        "https://api.carespot.gr/image/product/garden-multi-vitamins-minerals-portokali-gkeipfrout-20tabs-a4WYbl.jpg?x=400&y=400",
      intent: IntentItemType.WinterVitamins,
      reviewsCount: 3287,
      reviewsRating: 4.8,
    },
  },
  {
    id: "101115",
    details: {
      title:
        "LAMBERTS - A to Z Multi Vitamins Πολυβιταμίνη Ενέργεια & Τόνωση - 60tabs",
      brand: "LAMBERTS",
      url: "https://carespot.gr/lamberts/lamberts-a-to-z-multi-vitamins-polivitamini-60tabs/",
      price: 11.38,
      image:
        "https://api.carespot.gr/image/product/lamberts-a-to-z-multi-vitamins-polivitamini-60tabs-e18KaU.png?x=400&y=400",
      intent: IntentItemType.WinterVitamins,
      reviewsCount: 3022,
      reviewsRating: 4.7,
    },
  },
  {
    id: "112790",
    details: {
      title:
        "LANES - Vitamin C 1000mg για το Ανοσοποιητικό με Γεύση Πορτοκάλι - 20eff.tabs",
      brand: "LANES",
      url: "https://carespot.gr/lanes/lanes-vitamin-c-1000mg-geusi-portokali-20eff-tabs/",
      price: 4.54,
      image:
        "https://api.carespot.gr/image/product/lanes-vitamin-c-1000mg-geusi-portokali-20eff-tabs-UNb6z8.png?x=400&y=400",
      intent: IntentItemType.WinterVitamins,
      reviewsCount: 2927,
      reviewsRating: 4.9,
    },
  },
  {
    id: "104179",
    details: {
      title:
        "Sinomarin - Cold & Flu Relief Φυσικό Ρινικό Αποσυμφορητικό - 30ml",
      brand: "Sinomarin",
      url: "https://carespot.gr/sinomarin/sinomarin-cold-flu-relief-riniko-aposimforitiko-30ml/",
      price: 4.34,
      image:
        "https://api.carespot.gr/image/product/sinomarin-cold-flu-relief-fisiko-riniko-aposimforitiko-30ml-zfclUj.jpg?x=400&y=400",
      intent: IntentItemType.FluProtection,
      reviewsCount: 1321,
      reviewsRating: 4.7,
    },
  },
  {
    id: "114628",
    details: {
      title:
        "NASALEZE - Cold & Flu Blocker Spray για τη Μύτη Κατά των Ιώσεων & του Κρυολογήματος - 800mg",
      brand: "NASALEZE",
      url: "https://carespot.gr/sinomarin/sinomarin-cold-flu-relief-riniko-aposimforitiko-30ml/",
      price: 12.06,
      image:
        "https://api.carespot.gr/image/product/nasaleze-cold-flu-blocker-spray-gia-ti-miti-800mg-6SaYZ7.jpg?x=400&y=400",
      intent: IntentItemType.FluProtection,
      reviewsCount: 2082,
      reviewsRating: 4.8,
    },
  },
  {
    id: "100962",
    details: {
      title: "AVENE - EAU THERMALE Spray Καταπραϋντικό Ιαματικό Νερό - 50ml",
      brand: "AVENE",
      url: "https://carespot.gr/avene/avene-eau-thermale-spray-katapraintiko-iamatiko-nero-50ml/",
      price: 2.74,
      image:
        "https://api.carespot.gr/image/product/avene-eau-thermale-spray-katapraintiko-iamatiko-nero-50ml-mrMRZt.jpg?x=400&y=400",
      intent: IntentItemType.FluProtection,
      reviewsCount: 1637,
      reviewsRating: 4.8,
    },
  },
];

const createInitialThread = (): Thread => {
  const groupId = ulid();

  return [
    {
      id: ulid(),
      group_id: groupId,
      type: PromptItemType.Messages,
      role: PromptItemRole.Bot,
      options: [
        {
          id: ulid(),
          text: "Γεια σου! 👋 Είμαι ο PharmaBot, ο ψηφιακός σου φαρμακοποιός!",
          timestamp: Date.now(),
        },
        {
          id: ulid(),
          text: "Πώς μπορώ να σας βοηθήσω σήμερα",
          timestamp: Date.now(),
        },
        {
          id: ulid(),
          text: "Μπορείτε να με ρωτήσετε οτιδήποτε, από ιατρικές συμβουλές μέχρι προτάσεις προϊόντων. 😊",
          timestamp: Date.now(),
        },
      ],
    },
    {
      id: ulid(),
      group_id: groupId,
      type: PromptItemType.Buttons,
      role: PromptItemRole.Bot,
      options: [
        {
          id: ulid(),
          text: "Θέλω να αντιμετωπίσω τα πρησμένα μου μάτια με τη σωστή κρέμα.",
        },
        {
          id: ulid(),
          text: "Έχω τριχόπτωση, ποιο σαμπουάν πρέπει να χρησιμοποιήσω;",
        },
        {
          id: ulid(),
          text: "Ποιο είναι το καλύτερο αντηλιακό για παιδιά ηλικίας 3-10 ετών;",
        },
      ],
      hint: "Πληκτρολόγησε οτιδήποτε ή δοκίμασε μία από τις εξής επιλογές:",
    },
  ];
};

interface State {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  isFullScreen: boolean;
  openFullScreen: () => void;
  closeFullScreen: () => void;
  hasStarted: boolean;
  start: () => void;
  stop: () => void;
  isProcessing: boolean;
  startProcessing: () => void;
  stopProcessing: () => void;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  prompt: string;
  setPrompt: (prompt: string) => void;
  thread: Thread;
  startThread: () => void;
  setThread: (thread: Thread) => void;
  clearThread: () => void;
}

const useStore = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        isOpen: false,
        open: () => set({ isOpen: true }),
        close: () => set({ isOpen: false }),
        toggle: () => set({ isOpen: !get().isOpen }),
        isFullScreen: false,
        openFullScreen: () => set({ isFullScreen: true }),
        closeFullScreen: () => set({ isFullScreen: false }),
        hasStarted: false,
        start: () => set({ hasStarted: true }),
        stop: () => set({ hasStarted: false }),
        isProcessing: false,
        startProcessing: () => set({ isProcessing: true }),
        stopProcessing: () => set({ isProcessing: false }),
        isRecording: false,
        startRecording: () => set({ isRecording: true }),
        stopRecording: () => set({ isRecording: false }),
        prompt: "",
        setPrompt: (prompt: string) => set({ prompt }),
        thread: [],
        startThread: () => {
          console.log("startThread");
          const newThread = createInitialThread();

          set({ thread: newThread });
        },
        setThread: (thread: Thread) => set({ thread }),
        clearThread: () => set({ thread: [] }),
      }),
      {
        name: "chatbot-storage",
      }
    )
  )
);

export default useStore;
