import React from "react";
import { useShallow } from "zustand/react/shallow";
import { ArrowRightIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import TextareaAutosize from "react-textarea-autosize";

import useStore, {
  IntentItemType,
  INTENTS,
  PRODUCTS,
  PromptItemRole,
  PromptItemType,
} from "./store";
import { ulid } from "ulid";
import { askForHelp, transcribeAudio } from "./request";
import cn from "classnames"; // Assuming you have a function to handle audio transcription

export default function ChatbotHeader() {
  const inputRef = React.useRef(null);
  const isRecordingRef = React.useRef<boolean>(false);
  const audioChunk = React.useRef<Blob[]>([]);
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);

  const {
    isOpen,
    isFullScreen,
    prompt,
    setPrompt,
    isProcessing,
    startProcessing,
    stopProcessing,
    isRecording,
    startRecording,
    stopRecording,
    thread,
    setThread,
  } = useStore(
    useShallow((state) => ({
      isOpen: state.isOpen,
      isFullScreen: state.isFullScreen,
      prompt: state.prompt,
      setPrompt: state.setPrompt,
      isProcessing: state.isProcessing,
      startProcessing: state.startProcessing,
      stopProcessing: state.stopProcessing,
      isRecording: state.isRecording,
      startRecording: state.startRecording,
      stopRecording: state.stopRecording,
      thread: state.thread,
      setThread: state.setThread,
    })),
  );

  React.useEffect(() => {
    if (isOpen && !isRecording && !isProcessing && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [isOpen, isRecording, isProcessing, thread.length]);

  const handleStopRecording = React.useCallback(() => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();

      stopRecording();

      // Stop all tracks in the stream to free up the microphone
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
  }, [stopRecording]);

  React.useEffect(() => {
    if (isRecordingRef.current && !isRecording) {
      handleStopRecording();
    }

    isRecordingRef.current = isRecording;
  }, [isRecording, handleStopRecording]);

  const handleStartRecording = async () => {
    // Clear the audioChunk before starting a new recording
    audioChunk.current = [];

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = async (event: BlobEvent) => {
      if (event.data.size > 0) {
        const blob = new Blob([event.data], { type: "audio/wav" });
        const file = new File([blob], "audio.wav", {
          lastModified: Date.now(),
        });
        audioChunk.current.push(file);
      }

      const text = await transcribeAudio(audioChunk.current[0]);
      setPrompt(text);
      await handleProcess(text);
    };

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();
    startRecording();
  };

  const handleProcess = async (text: string) => {
    try {
      if (!text) {
        return;
      }

      startProcessing();

      const groupId = ulid();
      // Add user prompt to the thread
      const newThread = [
        ...thread,
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Message,
          role: PromptItemRole.User,
          text: text,
          hint: "You",
          timestamp: Date.now(),
        },
      ];
      setThread(newThread);

      const [reply, intent] = await askForHelp(text, INTENTS);

      if (INTENTS.includes(intent as IntentItemType)) {
        setThread([
          ...newThread,
          {
            id: ulid(),
            group_id: groupId,
            type: PromptItemType.Messages,
            role: PromptItemRole.Bot,
            options: [
              {
                id: ulid(),
                text: reply,
                timestamp: Date.now(),
              },
              {
                id: ulid(),
                text: "Here are some top rated products for you:",
                timestamp: Date.now(),
              },
            ],
          },
          {
            id: ulid(),
            group_id: groupId,
            type: PromptItemType.Products,
            role: PromptItemRole.Bot,
            options: PRODUCTS.filter(
              (product) => product.details.intent === intent,
            ),
            hint: "You can select one of these products:",
          },
        ]);
      } else {
        setThread([
          ...newThread,
          ...(reply !== ""
            ? [
                {
                  id: ulid(),
                  group_id: groupId,
                  type: PromptItemType.Message,
                  role: PromptItemRole.Bot,
                  text: reply,
                  timestamp: Date.now(),
                },
              ]
            : []),
          {
            id: ulid(),
            group_id: groupId,
            type: PromptItemType.Messages,
            role: PromptItemRole.Bot,
            options: [
              {
                id: ulid(),
                text: "Unfortunately we couldn't fetch any products.",
                timestamp: Date.now(),
              },
              {
                id: ulid(),
                text: "Do you want to retry with another input maybe?",
                timestamp: Date.now(),
              },
            ],
          },
        ]);
      }

      setPrompt("");
    } catch (err) {
      console.log(err);
    } finally {
      stopProcessing();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isProcessing) {
      return;
    }

    await handleProcess(prompt);
  };

  return (
    <footer
      className={cn(
        "pt-2 pb-4 relative border-x border-b h-[110px] bg-neutral-100",
        {
          "md:rounded-b-xl": !isFullScreen,
        },
      )}
    >
      <div className="text-neutral-500 text-[8px] text-center mb-2">
        Powered by{" "}
        <span className="text-black font-bold leading-4">CommerceX</span>
      </div>

      {!isProcessing && !isRecording && (
        <form
          noValidate
          className="px-4 py-4 rounded-xl drop-shadow-md bg-white flex items-start space-x-2 absolute bottom-4 left-4 right-4"
          onSubmit={handleSubmit}
        >
          <button
            type="button"
            disabled={isProcessing || isRecording}
            onClick={handleStartRecording}
            className="mt-1"
          >
            <MicrophoneIcon className="h-6 w-6" />
          </button>

          <TextareaAutosize
            ref={inputRef}
            value={prompt}
            disabled={isProcessing || isRecording}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Ask anything here..."
            className="flex-1 px-2 py-1 rounded focus:outline-none focus:border-transparent text-gray-600 resize-none text-sm italic disabled:bg-white"
          />

          <button
            disabled={isProcessing || isRecording || !prompt}
            type="submit"
            className="mt-1"
          >
            <ArrowRightIcon className="h-6 w-6" />
          </button>
        </form>
      )}
    </footer>
  );
}
