// embedding-utils.ts
import axios from "axios";

// Calls OpenAI to get an embedding for a given string
export const getEmbedding = async (text: string): Promise<number[]> => {
  const response = await axios.post(
    "https://api.openai.com/v1/embeddings",
    {
      model: "text-embedding-ada-002", // You can change this if you're using a different model
      input: text,
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response.data.data[0].embedding;
};

// Computes cosine similarity between two embeddings
export const cosineSimilarity = (vecA: number[], vecB: number[]): number => {
  const dotProduct = vecA.reduce((acc, val, i) => acc + val * vecB[i], 0);
  const magnitudeA = Math.sqrt(vecA.reduce((acc, val) => acc + val ** 2, 0));
  const magnitudeB = Math.sqrt(vecB.reduce((acc, val) => acc + val ** 2, 0));
  return dotProduct / (magnitudeA * magnitudeB);
};
