import React from "react";
import { useShallow } from "zustand/react/shallow";
import moment from "moment";
import { ulid } from "ulid";
import {
  ChatBubbleBottomCenterTextIcon,
  StopIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import ReactStars from "react-stars";
import cn from "classnames";

import useStore, {
  INTENTS,
  PRODUCTS,
  PromptButtonItem,
  PromptItemMessage,
  PromptItemRole,
  PromptItemType,
  PromptProductItem,
} from "./store";
import { askForHelp } from "./request";

function scrollToLatestThreadItem() {
  // Select all elements with the class .thread-item
  const threadItems = Array.from(document.querySelectorAll(".thread-item"));

  if (threadItems.length === 0) return; // Handle case if no thread-items are found

  // Extract all data-group (ULID) values from the elements
  const ulidGroups = threadItems
    .map((item) => item.getAttribute("data-group"))
    .filter((ulid) => ulid); // Filter out any null or undefined values

  if (ulidGroups.length === 0) return; // Handle case if no ULIDs are found

  // Find the latest ULID by sorting lexicographically (ULIDs are sortable)
  const latestUlid = ulidGroups.sort().pop();

  // Find the first thread-item with the latest ULID
  const latestThreadItem = threadItems.find(
    (item) => item.getAttribute("data-group") === latestUlid,
  );

  // Scroll to the first thread-item of the latest group
  if (latestThreadItem) {
    latestThreadItem.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}

function ProcessingView() {
  return (
    <div className="text-center h-full flex flex-col items-center justify-center px-4">
      <span className="animate-pulse inline-flex items-center justify-center bg-gradient-to-r from-[#0D9488] to-[#042F2E] rounded-full text-white flex-shrink-0 w-16 h-16 shadow-lg mb-4">
        <ChatBubbleBottomCenterTextIcon className="w-8 h-8" />
      </span>
      <p className="text-gray-600 mb-4">I am processing your input...</p>
    </div>
  );
}

function RecordingView({
  remainingTime,
  maximumTime,
  onClick,
}: {
  remainingTime: number;
  maximumTime: number;
  onClick: () => void;
}) {
  return (
    <div
      className="text-center h-full flex flex-col items-center justify-center px-4 cursor-pointer"
      onClick={onClick}
    >
      <span className="animate-pulse inline-flex items-center justify-center bg-gradient-to-r from-red-400 to-red-600 rounded-full text-white flex-shrink-0 w-16 h-16 shadow-lg mb-4">
        <StopIcon className="w-8 h-8" />
      </span>
      <p className="text-gray-600">
        Speak to describe your need and click stop to process it
      </p>
      <p className="text-gray-600 text-xs">
        Remaining recording time: {remainingTime} out of {maximumTime} seconds
      </p>
    </div>
  );
}

export default function ChatbotBody() {
  const {
    isOpen,
    isFullScreen,
    isProcessing,
    startProcessing,
    stopProcessing,
    isRecording,
    stopRecording,
    thread,
    setThread,
  } = useStore(
    useShallow((state) => ({
      isOpen: state.isOpen,
      isFullScreen: state.isFullScreen,
      isProcessing: state.isProcessing,
      startProcessing: state.startProcessing,
      stopProcessing: state.stopProcessing,
      isRecording: state.isRecording,
      stopRecording: state.stopRecording,
      thread: state.thread,
      setThread: state.setThread,
    })),
  );

  // TODO: Add this in store
  const hasThread = thread.length > 0;

  React.useEffect(() => {
    if (thread.length > 0) {
      scrollToLatestThreadItem();
    }
  }, [thread, isProcessing, isOpen]);

  const handleAddToCart = (product: PromptProductItem) => {
    if (
      window.confirm(
        `Are you sure you want to add to cart the product: ${product.details.title}?`,
      )
    ) {
      window.alert("Product added to cart!");
      const groupId = ulid();
      const newThread = [
        ...thread,
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Message,
          role: PromptItemRole.Bot,
          text: "✅ Excellent choice!! We added the following product to your cart:",
          timestamp: Date.now(),
        },
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Image,
          role: PromptItemRole.User,
          hint: "You",
          src: product.details.image,
          timestamp: Date.now(),
        },
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Message,
          role: PromptItemRole.User,
          text: product.details.title,
          timestamp: Date.now(),
        },
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Message,
          role: PromptItemRole.Bot,
          text: "Is there anything else I could help you with?",
          timestamp: Date.now(),
        },
      ];
      setThread(newThread);
    } else {
      window.alert("Product not added to cart!");

      const newThread = [
        ...thread,
        {
          id: ulid(),
          group_id: ulid(),
          type: PromptItemType.Message,
          role: PromptItemRole.Bot,
          text: "Since you didn't like this proposal, is there anything else I could help you with?",
          timestamp: Date.now(),
        },
      ];
      setThread(newThread);
    }
  };

  const handleClickButton = async (option: PromptButtonItem) => {
    try {
      if (isProcessing) {
        return;
      }

      startProcessing();

      // Add user prompt to the thread
      const existingThread = [...thread];
      const groupId = ulid();

      const newThread = [
        ...existingThread.slice(0, -1),
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Message,
          role: PromptItemRole.User,
          text: option.text,
          hint: "You",
          timestamp: Date.now(),
        },
      ];

      setThread(newThread);

      const [reply, intent] = await askForHelp(option.text, INTENTS);

      // Add bot prompt to the thread
      setThread([
        ...newThread,
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Messages,
          role: PromptItemRole.Bot,
          options: [
            {
              id: ulid(),
              text: reply,
              timestamp: Date.now(),
            },
            {
              id: ulid(),
              text: "Here are some top rated products for you:",
              timestamp: Date.now(),
            },
          ],
        },
        {
          id: ulid(),
          group_id: groupId,
          type: PromptItemType.Products,
          role: PromptItemRole.Bot,
          options: PRODUCTS.filter(
            (product) => product.details.intent === intent,
          ),
          hint: "You can select one of these products:",
        },
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      stopProcessing();
    }
  };

  const handleChangeRating = (newReviewsRating: number) => {
    alert("You rated the product: " + newReviewsRating);
  };

  const recordingTimer = React.useRef<number | null>(null);
  const countdownInterval = React.useRef<number | null>(null); // To track the interval
  const [remainingTime, setRemainingTime] = React.useState<number>(15); // 15 seconds countdown

  const handleStopRecording = React.useCallback(() => {
    stopRecording();
    startProcessing();

    // Clear the timer and interval if manually stopped
    if (recordingTimer.current !== null) {
      clearTimeout(recordingTimer.current);
    }

    if (countdownInterval.current !== null) {
      clearInterval(countdownInterval.current);
    }
  }, [stopRecording, startProcessing]);

  React.useEffect(() => {
    if (isRecording) {
      // Reset remaining time when recording starts
      setRemainingTime(15);

      // Start a countdown interval to update every second
      countdownInterval.current = window.setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // Update every second

      // Start a timer for 15 seconds to stop the recording automatically
      recordingTimer.current = window.setTimeout(() => {
        handleStopRecording();
      }, 15000); // 15 seconds

      return () => {
        // Clear the timer and interval when the recording stops or the component unmounts
        if (recordingTimer.current !== null) {
          clearTimeout(recordingTimer.current);
        }

        if (countdownInterval.current !== null) {
          clearInterval(countdownInterval.current);
        }
      };
    } else {
      // Clear the interval if recording stops before 15 seconds
      if (countdownInterval.current !== null) {
        clearInterval(countdownInterval.current);
      }
    }
  }, [isRecording, handleStopRecording]);

  return (
    <main className="border-x py-4 overflow-y-auto bg-neutral-100 flex flex-col flex-1 chatbot-body">
      <div className="w-full max-w-4xl mx-auto flex-1">
        {isProcessing && <ProcessingView />}

        {isRecording && (
          <RecordingView
            remainingTime={remainingTime}
            maximumTime={15}
            onClick={handleStopRecording}
          />
        )}

        {!isProcessing && !isRecording && hasThread && (
          <div>
            {thread.map((item) => (
              <React.Fragment key={item.id}>
                {item.type === PromptItemType.Message &&
                  (item.role === PromptItemRole.Bot ? (
                    <div
                      data-group={item.group_id}
                      className="chatbot-item first:pt-0 last:pb-0 px-4 thread-item flex justify-start space-x-2 mb-3"
                    >
                      <span className="inline-flex items-center justify-center bg-gradient-to-r from-[#0D9488] to-[#042F2E] rounded-full text-white flex-shrink-0 w-10 h-10 shadow-lg">
                        <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
                      </span>

                      <div key={item.id}>
                        {item.hint && (
                          <div className="mb-2 text-neutral-500 text-xs text-left md:text-right">
                            {item.hint}
                          </div>
                        )}

                        <div className="shadow bg-white p-3 rounded-t-2xl rounded-br-2xl text-base leading-5 text-neutral-900 mb-1 max-w-xl">
                          {item.text}
                        </div>

                        <time className="text-left text-neutral-500 text-[8px] block">
                          {moment(item.timestamp).format("HH:mm")}
                        </time>
                      </div>
                    </div>
                  ) : (
                    <div
                      data-group={item.group_id}
                      className="chatbot-item first:pt-0 last:pb-0 px-4 thread-item flex justify-end space-x-2 mb-3"
                    >
                      <span className="w-10 h-10 flex-shrink-0" />

                      <div key={item.id}>
                        {item.hint && (
                          <div className="mb-2 text-neutral-500 text-xs text-left md:text-right">
                            {item.hint}
                          </div>
                        )}

                        <div className="shadow bg-teal-600 text-white p-3 rounded-t-2xl rounded-bl-2xl text-base leading-5 mb-1 max-w-xl">
                          {item.text}
                        </div>

                        <time className="text-right text-neutral-500 text-[8px] block">
                          {moment(item.timestamp).format("HH:mm")}
                        </time>
                      </div>
                    </div>
                  ))}

                {item.type === PromptItemType.Messages && (
                  <div
                    data-group={item.group_id}
                    className="chatbot-item first:pt-0 last:pb-0 px-4 thread-item flex items-start space-x-2 mb-3"
                  >
                    <span className="inline-flex items-center justify-center bg-gradient-to-r from-[#0D9488] to-[#042F2E] rounded-full text-white flex-shrink-0 w-10 h-10 shadow-lg">
                      <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
                    </span>

                    <div>
                      {item.hint && (
                        <div className="mb-2 text-neutral-500 text-xs text-left md:text-right">
                          {item.hint}
                        </div>
                      )}

                      <div className="space-y-2 flex flex-col items-start">
                        {(item.options as Array<PromptItemMessage>).map(
                          (option) => (
                            <div key={option.id}>
                              <div className="shadow bg-white p-3 rounded-t-2xl rounded-br-2xl text-base leading-5 text-neutral-900 mb-1 max-w-xl">
                                {option.text}
                              </div>

                              <time className="text-left text-neutral-500 text-[8px] block">
                                {moment(option.timestamp).format("HH:mm")}
                              </time>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {item.type === PromptItemType.Products && (
                  <div
                    data-group={item.group_id}
                    className="chatbot-item first:pt-0 last:pb-0 thread-item mb-3 pl-16 pr-4"
                  >
                    {item.hint && (
                      <div
                        className={cn("mb-2 text-neutral-500 text-xs", {
                          "text-right": !isFullScreen,
                          "text-left": isFullScreen,
                        })}
                      >
                        {item.hint}
                      </div>
                    )}

                    <div className="space-y-2 flex flex-col items-start">
                      {(item.options as Array<PromptProductItem>).map(
                        (product) => (
                          <div
                            key={product.id}
                            className="flex border border-teal-600 bg-white hover:bg-teal-100 rounded-xl p-4 flex-shrink-0 w-full max-w-xl"
                          >
                            <img
                              src={product.details.image}
                              alt={product.details.title}
                              className="w-10 h-10 flex-shrink-0 mr-2 rounded-md"
                            />

                            <div>
                              <div className="mb-2">
                                <div className="flex items-center text-xs text-neutral-500 mb-2">
                                  <ReactStars
                                    count={5}
                                    value={product.details.reviewsRating}
                                    activeColor={"#ffd700"}
                                    edit={true}
                                    size={16}
                                    onChange={(newReviewsRating) =>
                                      handleChangeRating(newReviewsRating)
                                    }
                                  />
                                  ({product.details.reviewsRating}/5) |{" "}
                                  {product.details.reviewsCount} reviews
                                </div>

                                <h2 className="text-sm text-gray-600">
                                  {product.details.title}
                                </h2>
                                <p className="mt-1 text-xs font-semibold text-gray-600">
                                  {product.details.brand}
                                </p>
                                <p className="mt-1 text-base font-semibold text-gray-600">
                                  {new Intl.NumberFormat("en-DE", {
                                    style: "currency",
                                    currency: "EUR",
                                  }).format(product.details.price)}
                                </p>
                              </div>

                              <button
                                className="inline-flex md:flex items-center space-x-2 bg-teal-600 text-white hover:bg-teal-700 rounded-2xl py-1.5 px-4 h-8"
                                onClick={() => handleAddToCart(product)}
                              >
                                <ShoppingCartIcon className="w-5 h-5 text-white" />
                                <span className="text-sm leading-4">
                                  Add to Cart
                                </span>
                              </button>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                )}

                {item.type === PromptItemType.Buttons && (
                  <div
                    data-group={item.group_id}
                    className="chatbot-item first:pt-0 last:pb-0 pl-16 pr-4 thread-item mb-3"
                  >
                    {item.hint && (
                      <div className="mb-2 text-neutral-500 text-xs text-left">
                        {item.hint}
                      </div>
                    )}

                    <div className="space-y-2 text-right flex flex-col items-start">
                      {(item.options as Array<PromptButtonItem>).map(
                        (option) => (
                          <button
                            key={option.id}
                            className="bg-white hover:bg-teal-100 py-2 px-4 rounded-xl text-xs text-teal-600 border border-teal-600 text-left"
                            onClick={() => handleClickButton(option)}
                          >
                            {option.text}
                          </button>
                        ),
                      )}
                    </div>
                  </div>
                )}

                {item.type === PromptItemType.Image && (
                  <div
                    data-group={item.group_id}
                    className="chatbot-item first:pt-0 last:pb-0 px-4 thread-item mb-3"
                  >
                    {item.hint && (
                      <div className="mb-2 text-neutral-500 text-xs text-right">
                        {item.hint}
                      </div>
                    )}

                    <div className="flex justify-end">
                      <img
                        src={item.src}
                        alt="Selected Product"
                        className="w-14 h-14 rounded-xl border border-teal-600"
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </main>
  );
}
