import React from "react";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/solid";
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { useShallow } from "zustand/react/shallow";
import { Tooltip } from "react-tooltip";
import cn from "classnames";

import useStore from "./store";

export default function ChatbotHeader() {
  const {
    close,
    setPrompt,
    clearThread,
    isFullScreen,
    openFullScreen,
    closeFullScreen,
  } = useStore(
    useShallow((state) => ({
      close: state.close,
      setPrompt: state.setPrompt,
      clearThread: state.clearThread,
      isFullScreen: state.isFullScreen,
      openFullScreen: state.openFullScreen,
      closeFullScreen: state.closeFullScreen,
    }))
  );

  const handleMinimize = () => {
    close();
  };

  const handleClose = () => {
    close();
    setPrompt("");
    clearThread();
  };

  return (
    <header
      className={cn(
        "flex items-center justify-between border p-4 bg-white h-[80px]",
        {
          "md:drop-shadow md:rounded-t-xl md:relative": !isFullScreen,
        }
      )}
    >
      <div className="flex items-center space-x-2">
        <span className="inline-flex items-center justify-center bg-gradient-to-r from-[#0D9488] to-[#042F2E] rounded-full text-white flex-shrink-0 w-12 h-12 shadow-lg">
          <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />
        </span>

        <div>
          <h1 className="text-base mb-0.5">PharmaBot</h1>
          <h2 className="text-sm text-neutral-500">online</h2>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <Tooltip id="fullscreen-tooltip" />
        <Tooltip id="minimize-tooltip" />
        <Tooltip id="close-tooltip" />

        {isFullScreen ? (
          <button
            data-tooltip-id="fullscreen-tooltip"
            data-tooltip-content="Close Full Screen"
            data-tooltip-place="top"
            onClick={closeFullScreen}
          >
            <ArrowsPointingInIcon className="w-6 h-6" />
          </button>
        ) : (
          <button
            data-tooltip-id="fullscreen-tooltip"
            data-tooltip-content="Open Full Screen"
            data-tooltip-place="top"
            onClick={openFullScreen}
          >
            <ArrowsPointingOutIcon className="w-6 h-6" />
          </button>
        )}
        <button
          data-tooltip-id="minimize-tooltip"
          data-tooltip-content="Minimize"
          data-tooltip-place="top"
          onClick={handleMinimize}
        >
          <ChevronDownIcon className="w-6 h-6" />
        </button>
        <button
          data-tooltip-id="close-tooltip"
          data-tooltip-content="Close Session"
          data-tooltip-place="top"
          onClick={handleClose}
        >
          <XMarkIcon className="w-6 h-6" />
        </button>
      </div>
    </header>
  );
}
